import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-result-details',
  templateUrl: './result-details.component.html',
  styleUrls: ['./result-details.component.scss'],
})
export class ResultDetailsComponent {
  @Input()
  searchResultType: any;

  @Input()
  searchResultData: any;

  @Output()
  closeSearchResult: EventEmitter<boolean> = new EventEmitter();

  constructor() {}

  changeBack() {
    this.closeSearchResult.emit(false);
  }
}
