import { Component, OnInit } from '@angular/core';
import {
  AuthenticatedResult,
  OidcClientNotification,
  OidcSecurityService,
  OpenIdConfiguration,
  UserDataResult,
} from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DivisionHubService } from '../../@core/hubs/division-hub.service';
import { FacilityHubService } from '../../@core/hubs/facility-hub.service';
import { LogService } from '../../@core';
import { DivisionsApiService } from '../../@core/api/divisions-api.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  version: string | null = environment.version;
  configuration$!: Observable<OpenIdConfiguration>;
  userDataChanged$!: Observable<OidcClientNotification<any>>;
  userData$!: Observable<UserDataResult>;
  isAuthenticated = false;
  checkSessionChanged$!: Observable<boolean>;
  checkSessionChanged: any;

  constructor(
    public oidcSecurityService: OidcSecurityService,
    private router: Router,

    private divisionHub: DivisionHubService,
    private facilityHub: FacilityHubService,
    private logger: LogService
  ) {}

  ngOnInit() {
    this.configuration$ = this.oidcSecurityService.getConfiguration();
    this.userData$ = this.oidcSecurityService.userData$;
    this.checkSessionChanged$ = this.oidcSecurityService.checkSessionChanged$;

    setTimeout(() => {
      this.oidcSecurityService.isAuthenticated().subscribe((result) => {
        if (result) {
          this.router.navigateByUrl('/status/facilities');
          console.warn('authenticated: ', result);
        }
      });
    }, 500);

    if (this.router.url === '/account/logout') {
      this.oidcSecurityService.logoff();
    }
  }

  login() {
    this.oidcSecurityService.authorize();
  }

  refreshSession() {
    this.oidcSecurityService.forceRefreshSession().subscribe((result) => {
      // console.log(result)
    });
  }

  logoffLocal() {
    this.oidcSecurityService.logoffLocal();
  }

  logout() {
    this.divisionHub.stop();
    this.facilityHub.stop();
    this.oidcSecurityService.revokeAccessToken();
    this.oidcSecurityService.logoff();
  }
}
