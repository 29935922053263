<svg
  xmlns="http://www.w3.org/2000/svg"
  width="8"
  height="8"
  [attr.fill]="color"
  class="alarm-svg {{ ledConfig.tiny ? 'tiny' : '' }}"
  viewBox="0 0 8 8"
>
  <circle [attr.cx]="size" [attr.cy]="size" [attr.r]="radius"></circle>
</svg>
<div class="alarm-name" *ngIf="ledConfig.label">{{ ledConfig.alarm?.name }}</div>
