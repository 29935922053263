<!--<div class="dashboard-container">-->
<!--  <div>-->
<!--    <h3>Dashboard</h3>-->
<!--  </div>-->
<!--  <div class="grid-setup">-->
<!--    <div></div>-->
<!--    <div>[M][V][B][P]</div>-->
<!--    <div></div>-->
<!--  </div>-->
<!--  <div class="grid-setup">-->
<!--    <div></div>-->
<!--    <div>Capacity</div>-->
<!--    <div></div>-->
<!--  </div>-->
<!--  <div class="grid-setup">-->
<!--    <div>-->
<!--      <div>Verwacht</div>-->
<!--      <div>-->
<!--        <nb-icon *ngFor="let number of [0, 1, 2]" icon='car-outline' pack='eva'></nb-icon>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div>-->
<!--      <div>Aankomst</div>-->
<!--      <div>-->
<!--        <nb-icon *ngFor="let number of [0, 1, 2,3,4,5,6]" icon='car-outline' pack='eva'></nb-icon>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div>-->
<!--      <div>Aanwezig</div>-->
<!--      <div>-->
<!--        <nb-icon *ngFor="let number of [0, 1, 2,3,4,5,6,7,8,9,10]" icon='car-outline' pack='eva'></nb-icon>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div>-->
<!--      <div>Vertrokken</div>-->
<!--      <div>-->
<!--        <nb-icon *ngFor="let number of [0, 1, 2,3,4]" icon='car-outline' pack='eva'></nb-icon>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<app-subscription></app-subscription>
