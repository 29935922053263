import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NB_WINDOW, NbDialogService, NbMenuItem, NbMenuService, NbPopoverDirective } from '@nebular/theme';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ParkbaseStorageService } from '../../services/parkbase-storage.service';
import { MainMenuConfigComponent } from './main-menu.config.component';
import { Subject, Subscription } from 'rxjs';
import { NbAccessChecker } from '@nebular/security';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent extends MainMenuConfigComponent implements OnInit, OnDestroy {
  userName = '';
  @ViewChild(NbPopoverDirective) popover!: NbPopoverDirective;
  private subscription!: Subscription;

  private destroy$ = new Subject<void>();

  constructor(
    public override accessChecker: NbAccessChecker,
    public override translate: TranslateService,
    private router: Router,
    private nbMenuService: NbMenuService,
    private authService: AuthService,
    private dialogService: NbDialogService,
    private parkbaseStorageService: ParkbaseStorageService,
    private changeDetectorRef: ChangeDetectorRef,

    @Inject(NB_WINDOW) private window: any
  ) {
    super(accessChecker, translate);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.userName = this.parkbaseStorageService.retrieveUserSessionId()?.preferred_username;

    this.subscription = this.translate.onLangChange.subscribe((lang) => {
      if (lang) {
        this.menuTranslation();
        this.changeDetectorRef.detectChanges();
      }
    });

    const currentRoute = this.router.url.split('/')[1];
    this.mainMenuItems.forEach((i) => {
      if (i.link) {
        const currentLinkRoute = i.link.split('/')[1];
        i.selected = i.link === '/' + currentRoute || currentLinkRoute === currentRoute;
      }
    });
    this.loginMenuItems.forEach((i) => {
      i.selected = i.url === '/' + currentRoute;
    });
  }

  openNotificationsOverlay(notificationsDialog: TemplateRef<any>) {
    this.dialogService.open(notificationsDialog, {
      context: {
        facilitySelection: 'divisionsNotifications',
        showCloseButtonForMobile: true,
      },
    });
  }

  close() {
    this.popover.hide();
  }

  profileActive() {
    if (this.router.url === '/profile') {
      return 'profile-active';
    } else {
      return '';
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    // this.subscription.unsubscribe();
    // this.translationA.unsubscribe();
    // this.translationB.unsubscribe();
  }

  onItemClick(index: number) {
    this.mainMenuItems.forEach((i) => {
      i.selected = false;
    });
    this.mainMenuItems[index].selected = true;
  }

  onHoverItem(menuItem: NbMenuItem) {}
}
