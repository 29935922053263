import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from 'src/app/@core/domain/interfaces/config.interface';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  config: any;
  private configData!: Config;
  private readonly configPath: string = 'assets/app.config.json';
  private configLoaded = false;

  constructor(private http: HttpClient) {}

  get configuration(): Config {
    if (this.configData) {
      return this.configData;
    } else {
      throw new Error('Error loading required configuration!');
    }
  }

  async loadConfiguration(): Promise<Config> {
    const response = await this.http.get<Config>(`${this.configPath}`).toPromise();
    if (response) this.configData = response;
    this.configLoaded = true;
    return this.configData;
  }

  init() {}
}
