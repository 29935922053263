<div class='capacity-handle'>
  <h5>
    <nb-icon icon='car-outline' nbPrefix pack='eva'></nb-icon>
    {{ 'CAPACITY.TITLE' | translate }}
  </h5>
</div>
<nb-card>
  <nb-card-body>
    <ng-container *ngIf='occupation$ | async as occupation; else noData'>
      <div *ngIf="occupation !== null && occupation.occupationRatios.length > 0; else noData" class="capacity-status">
        <div *ngFor="let o of occupation.occupationRatios" class="occupation">
          <div class="occupation-fill">
            <div class="icon-container status-{{ o.fullStatus.id }}">
              <nb-icon
                class="{{ statusService.getOccupationStatus(o.fullStatus).alertClass }}"
                icon="car"
                nbPrefix
                nbTooltip="{{ statusService.getOccupationStatus(o.fullStatus).capacityState }}"
                nbTooltipPlacement="top"
                pack="customIcons"
                status="control"
              ></nb-icon>
            </div>
            <div class="label {{o.capacity - o.occupation}}">
              {{o.type.name}} {{ statusService.getOccupationStatus(o.fullStatus).capacityState.toLowerCase() }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noData>
      <div class="no-data">
        <div>
          <h5>{{ 'GENERAL.NO_DATA' | translate }}</h5>
        </div>
      </div>
    </ng-template>
  </nb-card-body>
</nb-card>
