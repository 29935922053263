import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SelectItem } from '../multi-select-dropdown/multi-select-dropdown.component';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit, OnChanges {
  @Input() itemList!: SelectItem[];
  @Input() placeholder!: string;

  dropdownList!: SelectItem[];
  selectedItems!: number[];
  searchTerm: any;
  searching: any;

  constructor() {}

  convert<T, TK extends keyof T>(arr: T[], idKey: TK, titleKey: TK): any[] {
    return arr.map((item) => {
      return { id: item[idKey], name: item[titleKey] };
    });
  }

  ngOnInit() {
    this.selectedItems = [];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && this.itemList) {
      this.dropdownList = this.convert(this.itemList, 'id', 'label');
    }
    if (this.itemList) {
      this.dropdownList = this.itemList;
    }
  }

  onSearchFilter() {}

  clearSearchForm() {}
}
