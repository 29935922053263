import { Component, Input, OnInit } from '@angular/core';
import {
  Terminal,
  TerminalAction,
  TerminalActionType,
} from '../../../../../../@core/domain/interfaces/terminal.interface';
import { TerminalsApiService } from '../../../../../../@core/api/terminals-api.service';
import { StatusPageService } from '../../../../services/status-page.service';
import { ToastMessageService } from '../../../../../../@shared/services/toast-message.service';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from '../../../../../../@core';
import { TrackingService } from '../../../../../../@shared/services/tracking.service';
import { ResponseStatus } from '../../../../../../@core/domain/interfaces/facility.interface';

@Component({
  selector: 'app-terminal-action',
  templateUrl: './terminal-action.component.html',
  styleUrls: ['./terminal-action.component.scss'],
})
export class TerminalActionComponent implements OnInit {
  @Input() terminal!: Terminal;
  @Input() action?: TerminalAction;
  @Input() disableToggleClick!: boolean;
  toastrSuccessMessage = '';
  toastrErrorMessage = '';
  actionInProgress = false;

  constructor(
    private terminalsApiService: TerminalsApiService,
    private statusPageService: StatusPageService,
    private toastMessageService: ToastMessageService,
    private translate: TranslateService,
    private logger: LogService,
    private trackingService: TrackingService
  ) {}

  ngOnInit(): void {
    this.statusPageService.triggerRefreshTerminalSummary(false);

    this.translate.get('NOTIFICATIONS.TERMINAL_ACTION.SUCCESS').subscribe((res: string) => {
      this.toastrSuccessMessage = res;
    });
    this.translate.get('NOTIFICATIONS.TERMINAL_ACTION.ERROR').subscribe((res: string) => {
      this.toastrErrorMessage = res;
    });
  }

  setAction(id: number, actionElement: TerminalActionType) {
    setTimeout(() => {
      this.actionInProgress = true;
      this.disableToggleClick = true;
      this.sendTerminalAction(id, actionElement);
    }, 200);
  }

  private sendTerminalAction(id: number, actionType: TerminalActionType) {
    setTimeout(() => {
      this.terminalsApiService.sendTerminalAction(id, { type: actionType }).subscribe((result) => {
        if (result.status === ResponseStatus.Success) {
          this.logger.info('Terminal action with type ' + actionType + ' on terminal ' + id + ' successful', []);
          this.actionInProgress = false;
          this.toastMessageService.showSuccess(this.toastrSuccessMessage + ' ' + result.message);
          this.statusPageService.triggerPreventClick(false);
          this.statusPageService.triggerRefreshTerminalSummary(true);
          this.trackingService.eventEmitter('terminalAction', 'action', 'success', '', 0);
        } else {
          this.logger.info('Terminal action with type ' + actionType + ' failed', []);
          this.toastMessageService.showFailure(this.toastrErrorMessage + ' - ' + result.message);
          this.statusPageService.triggerPreventClick(false);
          this.statusPageService.triggerRefreshTerminalSummary(true);
          this.actionInProgress = false;
          this.trackingService.eventEmitter('terminalAction', 'action', 'failed', '', 0);
        }
      });
    }, 1000);
  }
}
