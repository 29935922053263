import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(protected ref: NbDialogRef<ConfirmDialogComponent>) {}

  cancelSave() {
    this.ref.close({ cancel: true, name: 'cancel saving data' });
  }

  syncChanges() {
    this.ref.close({ save: false, sync: true, name: 'sync data' });
  }

  saveChanges() {
    this.ref.close({ save: true, sync: false, name: 'save data' });
  }
}
