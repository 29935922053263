import { Injectable } from '@angular/core';
import { FacilitySummary } from '../../@core/domain/interfaces/facility-summary.interface';
import { TranslateService } from '@ngx-translate/core';
import { TerminalStatus, TerminalStatus2 } from '../../@core/domain/interfaces/terminal.interface';
import {
  FacilityOpeningState,
  FullStatus,
  FullStatus2,
  OpeningState,
} from '../../@core/domain/interfaces/facility.interface';

export interface FacilityStatusIcon {
  alertClass: string;
  icon: string;
  openingState: string;
  capacityState: string;
}

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  red = '#f9430e';
  green = '#0CCE6B';
  lightgreen = '#00ccbc';
  grey = '#5d6c76';
  blue = '#0b6eb0';
  pink = '#d87ee1';
  brown = '#837b00';

  translations: string[] = [];

  constructor(private translate: TranslateService) {
    this.getTranslations();
  }

  terminalStatusColorClass(overview: any) {
    if (overview && overview.status) {
      switch (overview.status.id) {
        case TerminalStatus2.OffLine:
          return 'status-red';
        case TerminalStatus2.InUse:
          return 'status-green';
        case TerminalStatus2.Disabled:
        case TerminalStatus2.DisabledAndOpen:
        case TerminalStatus2.DisabledAndClosed:
          return 'status-grey';
        case TerminalStatus2.Latched:
        case TerminalStatus2.LatchedAndOpen:
        case TerminalStatus2.LatchedAndClosed:
          return 'status-brown';
        case TerminalStatus2.VehicleLoopAccess:
          return 'status-blue';
        case TerminalStatus2.CongestionMode:
          return 'status-pink';
        case TerminalStatus2.InUseAndOpen:
          return 'status-light-green';
        case TerminalStatus2.LatchedInUseAndOpen:
          return 'status-brown';
        default:
          return 'status-green';
      }
    } else {
      return 'status-green';
    }
  }

  terminalStatusColor(status: TerminalStatus) {
    if (status) {
      switch (status.id) {
        case TerminalStatus2.OffLine:
          return this.red;
        case TerminalStatus2.InUse:
          return this.green;
        case TerminalStatus2.Disabled:
        case TerminalStatus2.DisabledAndOpen:
        case TerminalStatus2.DisabledAndClosed:
          return this.grey;
        case TerminalStatus2.Latched:
        case TerminalStatus2.LatchedAndOpen:
        case TerminalStatus2.LatchedAndClosed:
          return this.brown;
        case TerminalStatus2.VehicleLoopAccess:
          return this.blue;
        case TerminalStatus2.CongestionMode:
          return this.pink;
        case TerminalStatus2.InUseAndOpen:
          return this.lightgreen;
        case TerminalStatus2.LatchedInUseAndOpen:
          return this.brown;
        default:
          return this.green;
      }
    } else {
      return this.green;
    }
  }

  getOccupationStatus(fullStatus: FullStatus, openingState?: OpeningState): FacilityStatusIcon {
    const labels: string[] = [];
    const returnEl = {
      alertClass: '',
      icon: '',
      openingState: '',
      capacityState: '',
    };
    this.translate
      .get([
        'STATUS.INFO',
        'STATUS.WARNING',
        'STATUS.ALARM',
        'STATUS.EMERGENCY',
        'STATUS.FACILITY_OPEN',
        'STATUS.FACILITY_CLOSED',
        'STATUS.FACILITY_FREE',
        'STATUS.FACILITY_PRE_FULL',
        'STATUS.FACILITY_FULL',
        'STATUS.FREE',
        'STATUS.PRE_FULL',
        'STATUS.FULL',
      ])
      .subscribe((d) => {
        labels.push(d['STATUS.INFO']);
        labels.push(d['STATUS.WARNING']);
        labels.push(d['STATUS.EMERGENCY']);
        labels.push(d['STATUS.ALARM']);
        labels.push(d['STATUS.FACILITY_OPEN']);
        labels.push(d['STATUS.FACILITY_CLOSED']);
        labels.push(d['STATUS.FACILITY_FREE']);
        labels.push(d['STATUS.FACILITY_PRE_FULL']);
        labels.push(d['STATUS.FACILITY_FULL']);
        labels.push(d['STATUS.FREE']);
        labels.push(d['STATUS.PRE_FULL']);
        labels.push(d['STATUS.FULL']);

        returnEl.alertClass = 'custom-icon-open';
        returnEl.icon = 'facility';
        returnEl.openingState = labels[4];

        if (fullStatus) {
          switch (Number(fullStatus.id)) {
            case FullStatus2.NotFull:
              returnEl.alertClass = 'custom-icon-open';
              returnEl.openingState = labels[6];
              returnEl.capacityState = labels[9];
              break;
            case FullStatus2.PreFull:
              returnEl.alertClass = 'custom-icon-pre-full';
              returnEl.openingState = labels[7];
              returnEl.capacityState = labels[10];
              break;
            case FullStatus2.Full:
              returnEl.alertClass = 'custom-icon-full';
              returnEl.openingState = labels[8];
              returnEl.capacityState = labels[11];
              break;
          }

          if (openingState && Number(openingState.id) === Number(FacilityOpeningState.Closed)) {
            returnEl.alertClass = 'custom-icon-closed';
            returnEl.icon = 'facility_closed';
            returnEl.openingState = labels[5];
          }
        }
      });

    return returnEl;
  }

  getLedColor(summary: FacilitySummary) {
    if (summary) {
      if (summary.terminalCounts.inUse === summary.terminalCounts.total && summary.terminalCounts.total === 0) {
        return 'rgb(126,129,128)';
      }
      if (summary.terminalCounts.inUse === summary.terminalCounts.total && summary.terminalCounts.total > 0) {
        return 'rgba(12,206,107,1.0)';
      } else if (summary.terminalCounts.offline === summary.terminalCounts.total) {
        return 'rgba(181,29,14,1.0)';
      } else if (summary.terminalCounts.outOfUse === summary.terminalCounts.total) {
        return 'rgba(181,29,14,1.0)';
      } else {
        return 'rgba(12,206,107,1.0)';
      }
    } else {
      return 'rgba(12,206,107,1.0)';
    }
  }

  getFacilityStatus(summary: FacilitySummary) {
    let returnString = '';
    if (summary) {
      if (summary.terminalCounts.inUse === summary.terminalCounts.total && summary.terminalCounts.total === 0) {
        returnString = this.translations[0];
      }
      if (summary.terminalCounts.inUse === summary.terminalCounts.total && summary.terminalCounts.total > 0) {
        returnString = this.translations[1] + ' ' + summary.terminalCounts.total + ' ' + this.translations[2];
      } else if (summary.terminalCounts.offline === summary.terminalCounts.total) {
        returnString = this.translations[1] + ' ' + summary.terminalCounts.total + ' ' + this.translations[3];
      } else if (summary.terminalCounts.outOfUse === summary.terminalCounts.total) {
        returnString = this.translations[1] + ' ' + summary.terminalCounts.total + ' ' + this.translations[4];
      } else {
        returnString =
          summary.terminalCounts.inUse +
          ' ' +
          this.translations[5] +
          ' ' +
          summary.terminalCounts.total +
          ' ' +
          this.translations[2];
      }
    } else {
      returnString = '-';
    }

    return returnString;
  }

  private getTranslations() {
    this.translate.getTranslation(this.translate.currentLang).subscribe((d) => {
      for (const item in d['STATUS']) {
        this.translations.push(d['STATUS'][item]);
      }
    });
  }
}
