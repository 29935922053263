import { BaseApiService } from './base-api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParkbaseStorageService } from 'src/app/@shared/services/parkbase-storage.service';
import { Terminal, TerminalSummaryPerTerminalType } from '../domain/interfaces/terminal.interface';
import { TerminalActionResponse } from '../domain/interfaces/terminal-action.interface';
import { TerminalResponse } from '../domain/interfaces/terminal-response.interface';
import { ConfigService } from 'src/app/@shared/services/config.service';
import { TerminalTypeSummary } from '../domain/interfaces/terminal-request.interface';
import { environment } from '../../../environments/environment';

const terminal = {
  availableActions: [],
  id: 0,
  name: '',
  status: { id: 0, name: '', color: 0 },
  type: { id: 0, name: '' },
};

@Injectable({
  providedIn: 'root',
})
export class TerminalsApiService extends BaseApiService {
  private _terminal = new BehaviorSubject<Terminal>(terminal);
  readonly terminal = this._terminal.asObservable();
  private _terminals = new BehaviorSubject<TerminalResponse>({});
  readonly terminals = this._terminals.asObservable();
  private _terminalsSummary = new BehaviorSubject<TerminalSummaryPerTerminalType[]>([]);
  readonly terminalsSummary = this._terminalsSummary.asObservable();
  private _terminalsScreen = new BehaviorSubject<any>(null);
  readonly terminalsScreen = this._terminalsScreen.asObservable();

  private _dataStore: {
    terminal: Terminal;
    terminals: TerminalResponse;
    terminalsSummary: TerminalSummaryPerTerminalType[];
  } = {
    terminal: terminal,
    terminals: {},
    terminalsSummary: [],
  };

  constructor(configService: ConfigService, httpClient: HttpClient, parkbaseStorageService: ParkbaseStorageService) {
    super(configService, httpClient);
    super.setStorage(parkbaseStorageService);
  }

  /**
   * GET /api/gui/v1.0/terminals/{id}
   * @param id terminal to load by id
   * @returns void
   */
  loadTerminal(id: number): void {
    if (!id) {
      throw new Error('loadTerminal, id not provided');
    }
    const apiUrl = this.parkbaseBl9BaseUrl + '/terminals/' + id;
    this.http.get<Terminal>(apiUrl, { headers: this.setHeaders() }).subscribe(
      (data) => {
        this._dataStore.terminal = data;
        this._terminal.next(Object.assign({}, this._dataStore).terminal);
      },
      (error) => this.handleError('Could not load terminal.', error)
    );
  }

  /**
   * GET /api/gui/v1.0/terminals/{id}
   * @param id terminal to load by id
   * @returns Observable<Terminal>
   */
  loadTerminalById(id: number): Observable<Terminal> {
    if (!id) {
      throw new Error('loadTerminalById, id not provided');
    }
    const apiUrl = this.parkbaseBl9BaseUrl + '/terminals/' + id;
    return this.http.get<Terminal>(apiUrl, { headers: this.setHeaders() });
  }

  /**
   * POST /api/gui/v1.0/terminals
   */
  loadTerminals(): void {
    const apiUrl = this.parkbaseBl9BaseUrl + '/terminals';
    const body = {
      query: {
        page: 1,
        pageSize: 25,
      },
    };
    const headers = this.setHeaders();
    this.http.post<TerminalResponse>(apiUrl, body, { headers }).subscribe(
      (data) => {
        this._dataStore.terminals = data;
        this._terminals.next(Object.assign({}, this._dataStore).terminals);
      },
      (error) => this.handleError('Could not load terminals.', error)
    );
  }

  /**
   * GET /api/gui/v1.0/terminals/summary
   */
  loadTerminalsSummary(): Observable<TerminalTypeSummary[]> {
    const apiUrl = this.parkbaseBl9BaseUrl + '/terminals/summary';
    const headers = this.setHeaders();
    return this.http
      .get<TerminalTypeSummary[]>(apiUrl, {
        headers,
      })
      .pipe(retry(2), catchError(this.getError));
  }

  /**
   * GET /api/gui/v1.0/terminals/{id}/screen-capture
   */
  loadScreenCapture(id: number) {
    const apiUrl = this.parkbaseBl9BaseUrl + '/terminals/' + id + '/screen-capture';
    const headers = this.setHeaders();
    return this.http
      .get<any>(apiUrl, {
        headers,
        responseType: 'blob' as 'json',
      })
      .subscribe({
        next: (data) => {
          this.createImageFromBlob(data);
        },
        error: (error) => {
          if (error && error.status == 404) {
            this._terminalsScreen.next(null);
          }
        },
      });
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this._terminalsScreen.next(reader.result);
      },
      false
    );

    if (image) {
      reader.readAsDataURL(image);
    }
  }

  /**
   * POST /api/gui/v1.0/terminals/{id}/action
   */
  sendTerminalAction(terminalId: number, requestBody: any): Observable<TerminalActionResponse> {
    if (!terminalId) {
      throw new Error('sendTerminalAction, id not provided');
    }
    const apiUrl = this.parkbaseBl9BaseUrl + '/terminals/' + terminalId + '/action';
    const headers = this.setHeaders();
    if (environment.production) {
      this.logger.info('Terminal action' + requestBody.toString() + ' sent to terminal', terminalId);
    }
    return this.http
      .post<TerminalActionResponse>(apiUrl, requestBody, {
        headers,
      })
      .pipe(retry(2), catchError(this.getError));
  }
}
