import { FacilitiesApiService } from 'src/app/@core/api/facilities-api.service';
import { Observable, of, Subscription } from 'rxjs';
import { ParkbaseStorageService } from 'src/app/@shared/services/parkbase-storage.service';
import { StatusPageService } from '../../services/status-page.service';
import { TerminalSummaryPerTerminalType } from 'src/app/@core/domain/interfaces/terminal.interface';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FacilityName } from '../../../../@core/domain/interfaces/facility.interface';
import { NbLayoutRulerService } from '@nebular/theme';

@Component({
  selector: 'app-terminals',
  templateUrl: './terminals.component.html',
  styleUrls: ['./terminals.component.scss'],
})
export class TerminalsComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() facility!: FacilityName;
  facilityTerminalsSummary$: Observable<TerminalSummaryPerTerminalType[]> = of([]);
  loading = true;
  obs!: Subscription;

  // @ViewChild('acc') accordion!: NgbAccordion;
  @ViewChild('acc') accordion!: any;

  opened = false;
  icon = 'expand';
  activePanel = false;
  firstAutomaticToggle = false;

  useMobileComponents = false;

  constructor(
    private parkbaseStorageService: ParkbaseStorageService,
    private facilitiesApiService: FacilitiesApiService,
    private cdr: ChangeDetectorRef,
    private statusPageService: StatusPageService,
    private layoutRulerService: NbLayoutRulerService
  ) {}

  @HostListener('window:resize', [])
  onResize(): void {
    this.getWindowSize();
  }

  ngOnInit(): void {
    this.getWindowSize();
    if (this.facility.id) {
      this.facilityTerminalsSummary$ = this.facilitiesApiService.facilityTerminalsSummary;
      this.facilitiesApiService.loadFacilityTerminalsSummary(this.facility.id);
      this.obs = this.statusPageService.refreshTerminalSummary.subscribe((status) => {
        if (status) {
          this.facilitiesApiService.loadFacilityTerminalsSummary(this.facility.id);
        }
      });
    }
  }

  ngAfterContentChecked() {
    if (!this.firstAutomaticToggle && this.accordion && this.accordion.activeIds.length === 0) {
      this.accordion.activeIds = ['static-0', 'static-1']; // bootstrap id
      this.accordion.expand('static-0-header');
      this.firstAutomaticToggle = true;
      this.icon = 'collapse';
      this.opened = true;
    }
  }

  open(index: number): void {
    this.activePanel = true;
  }

  trackByFn(index: number): number {
    return index;
  }

  toggleTerminals() {
    if (this.opened) {
      this.accordion.collapseAll();
      this.icon = 'expand';
      this.opened = false;
    } else {
      this.accordion.expandAll();
      this.icon = 'collapse';
      this.opened = true;
    }
  }

  ngOnDestroy(): void {
    this.obs.unsubscribe();
    this.facilityTerminalsSummary$ = of([]);
    this.firstAutomaticToggle = false;
    this.icon = 'collapse';
    this.opened = false;
  }

  private getWindowSize() {
    this.layoutRulerService.getDimensions().subscribe((data) => {
      this.useMobileComponents = data.clientWidth <= 768;
    });
  }
}
