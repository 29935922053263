import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-search-license-plate-result',
  templateUrl: './license-plate-result.component.html',
  styleUrls: ['./license-plate-result.component.scss'],
})
export class LicensePlateResultComponent {
  @Input()
  data!: any;

  constructor() {}
}
