import { AfterContentInit, AfterViewInit, Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

// import { BarcodeFormat } from '@zxing/library'
// import { ZXingScannerComponent } from '@zxing/ngx-scanner'

@Component({
  selector: 'app-identification-scanner',
  templateUrl: './identification-scanner.component.html',
  styleUrls: ['./identification-scanner.component.scss'],
})
export class IdentificationScannerComponent implements AfterContentInit, AfterViewInit {
  // code: string
  // @ViewChild('scanner', { static: false }) scanner: ZXingScannerComponent

  scannerEnabled = false;
  availableDevices!: MediaDeviceInfo[];

  selectedDevice!: MediaDeviceInfo | null;
  hasDevices!: boolean;
  hasPermission!: boolean;

  // enabledFormats: BarcodeFormat[] = [
  //     BarcodeFormat.CODE_128,
  //     BarcodeFormat.ITF
  // ]

  constructor(private dialogRef: NbDialogRef<IdentificationScannerComponent>) {}

  ngAfterViewInit(): void {
    // this.scanner.formats = this.enabledFormats
  }

  ngAfterContentInit() {
    this.scannerEnabled = true;
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find((x) => x.deviceId === selected);
    // this.activeDevice = device || null;
  }

  onCameraChange(device: MediaDeviceInfo): void {
    if (!device && !this.selectedDevice) {
      this.selectedDevice = this.availableDevices.length ? this.availableDevices[0] : null;
    }
  }

  onScanSuccess(result: any): void {
    this.dialogRef.close(result);
  }

  onScanError(error: any): void {
    if (error?.message && error.message === 'Could not start video source') {
      // this.selectedDevice = this.availableDevices[0]
    }
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
