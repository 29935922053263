<h5>{{ 'TERMINAL_EVENTS.TITLE' | translate }}</h5>
<app-terminal-title [hidden]='hideElement' [showHeading]='false'
                    [show]='showTerminalTitle' [terminalOverview]='terminal'>
</app-terminal-title>
<p class='description'>
  {{ 'TERMINAL_EVENTS.DESCRIPTION' | translate }}
</p>
<div class='own-scroll' id='terminal-card'>
  <div *ngIf='terminalNotifications && terminalNotifications.length === 0 && !loading' class='empty-list'>
    <div class='empty-list__image'>
    </div>
    <div class='empty-list__text'>
      <h5>{{ 'NOTIFICATIONS.NO_RECENT_EVENTS' | translate }}</h5>
      <p>{{ 'NOTIFICATIONS.NO_RECENT_EVENTS_DESCRIPTION' | translate }}</p>
    </div>
  </div>
  <ng-container *ngIf='terminalNotifications && loading'>
    <app-skeleton-loader
      *ngFor='let number of [0, 1, 2, 3, 4, 5, 6, 7]; let i = index'
      [styleListItem]='true'
      class='notification-list-dummy'
    ></app-skeleton-loader>
  </ng-container>
  <nb-list class='notification-list-container'>
    <nb-list-item
      *ngFor='let notification of terminalNotifications; let i = index'
      class='notification-list-item'
    >
      <div class='notification-item'>
        <div class='date-time'>
          <div class='time'>{{getTime(notification.localOccurrence)}}</div>
          <div class='date'>{{notification.localOccurrence | date}}</div>
        </div>
        <div class='event-title'>
          <div class='title'>{{notification.facility.name}} {{notification.title}}</div>
          <div class='terminal'>{{notification?.terminal?.name}}</div>
        </div>
      </div>
    </nb-list-item>
  </nb-list>
</div>
