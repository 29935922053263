<nb-card
  *ngIf="cardData$ | async as data"
  [ngClass]="{ onRegularPage: onRegularPage }"
  class="own-scroll"
  id="notifications-card"
>
  <nb-card-header class="notifications-card-header">
    <div class="notification-filters">
      <div *ngIf="filterOpen">
        {{ 'NOTIFICATIONS.FILTER.TITLE' | translate }}
        <span class="filters-selected-count">{{ filterSelectedCount }}</span>
      </div>
      <div>
        <nb-radio-group
          (ngModelChange)="onSelectedChange($event)"
          *ngIf="!filterOpen"
          [(ngModel)]="rangeSelection"
          class="rangeSelection"
          name="rangeSelection"
        >
          <nb-radio value="1">{{ 'NOTIFICATIONS.FILTER.TODAY' | translate }}</nb-radio>
          <nb-radio value="2">{{ 'NOTIFICATIONS.FILTER.LAST_WEEK' | translate }}</nb-radio>
          <nb-radio value="3">{{ 'NOTIFICATIONS.FILTER.LAST_MONTH' | translate }}</nb-radio>
        </nb-radio-group>
      </div>
      <div>
        <nb-icon (click)="toggleFilter()" class="icon-more" icon="more-vertical" pack="eva"></nb-icon>
      </div>
    </div>
    <div class="notification-filter-extended">
      <nb-accordion>
        <nb-accordion-item #item>
          <nb-accordion-item-body>
            <app-notifications-filter
              (filtersSelectedCountOutput)="onFiltersSelectedCountOutput($event)"
              (filtersSelectedOutput)="onFiltersSelected($event)"
              [filterSet]="firstNotificationCard.filterSet"
              [refreshFilter]="filterOpen"
            ></app-notifications-filter>
          </nb-accordion-item-body>
        </nb-accordion-item>
      </nb-accordion>
    </div>
    <div *ngIf="!filterOpen" class="notification-level-filters">
      <div
        (click)="clickFilterButton('alarms')"
        [ngClass]="{ filterAlarms: filterAlarms }"
        class="notification-filter-button alarm"
      >
        {{ 'NOTIFICATIONS.LEVEL_ALARM' | translate }}
      </div>
      <div
        (click)="clickFilterButton('errors')"
        [ngClass]="{ filterErrors: filterErrors }"
        class="notification-filter-button error"
      >
        {{ 'NOTIFICATIONS.LEVEL_ERROR' | translate }}
      </div>
      <div
        (click)="clickFilterButton('warnings')"
        [ngClass]="{ filterWarnings: filterWarnings }"
        class="notification-filter-button warning"
      >
        {{ 'NOTIFICATIONS.LEVEL_WARNING' | translate }}
      </div>
      <div
        (click)="clickFilterButton('info')"
        [ngClass]="{ filterInfo: filterInfo }"
        class="notification-filter-button info"
      >
        {{ 'NOTIFICATIONS.LEVEL_INFO' | translate }}
      </div>
    </div>
  </nb-card-header>
  <ng-container *ngIf="data.notificationGroups" class="notifications-container">
    <div *ngIf="data && data.notificationGroups && data.notificationGroups.length === 0" class="empty-list">
      <div class="empty-list__image">
        <img alt="" src="/assets/theme/empty_list_transparent.png" />
      </div>
      <div class="empty-list__text">
        <h5>{{ 'NOTIFICATIONS.NOTHING_TO_SHOW_TITLE' | translate }}</h5>
        <p>{{ 'NOTIFICATIONS.NOTHING_TO_SHOW_DESCRIPTION' | translate }}</p>
      </div>
    </div>
  </ng-container>
  <div *ngIf="data && !data.notificationGroups" class="loader-container">
    <app-skeleton-loader
      *ngFor="let number of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; let i = index"
      [styleListItem]="true"
      id="{{ i }}-item"
    ></app-skeleton-loader>
  </div>
  <nb-list
    (bottomThreshold)="loadNext(firstNotificationCard)"
    (topThreshold)="loadPrevious()"
    *ngIf="data && data.notificationGroups && data.notificationGroups.length > 0"
    [threshold]="500"
    nbInfiniteList
  >
    <nb-list-item
      *ngFor="let notificationGroup of data.notificationGroups; let i = index"
      class="notifications-list-item"
    >
      <ngb-accordion
        #acc="ngbAccordion"
        [closeOthers]="true"
        aria-multiselectable="false"
        class="notifications-accordion"
        id="acc-{{ i }}"
      >
        <ngb-panel id="static-{{ i }}">
          <ng-template ngbPanelHeader>
            <div class="notifications-summary status-color-{{ notificationGroup.level }}">
              <div class="align-items-center pe-3">
                <nb-icon
                  class="color-{{ notificationGroup.level }}-custom-icon__terminalType"
                  icon="{{ getIconType(notificationGroup) }}"
                  nbPrefix
                  pack="customIcons"
                ></nb-icon>
              </div>
              <div *ngIf="notificationGroup" class="col notification-group__description">
                <div class="level-indication">
                  <div
                    *ngIf="notificationGroup.level === 6"
                    class="notification-icon {{ notificationGroup.level === 6 ? 'danger' : '' }}"
                  >
                    <nb-icon
                      *ngIf="notificationGroup.level === 6"
                      class="warning-icon danger"
                      icon="alert-triangle"
                      nbPrefix
                      pack="eva"
                      status=""
                    >
                    </nb-icon>
                  </div>
                  <div
                    class="{{ notificationGroup.level === 6 ? ' danger' : '' }}{{
                      notificationGroup.level === 2 ? ' error' : ''
                    }}{{ notificationGroup.level === 1 ? ' warning' : '' }}"
                  >
                    <span *ngIf="notificationGroup.count === 1" class="span-level"
                      >{{ notificationGroup.count }} {{ 'NOTIFICATIONS.NOTIFICATION' | translate }}</span
                    >
                    <span *ngIf="notificationGroup.count > 1" class="span-level"
                      >{{ notificationGroup.count }} {{ 'NOTIFICATIONS.NOTIFICATIONS' | translate }}</span
                    >
                    &nbsp;&bull;&nbsp;
                    {{ format(notificationGroup.latestUtcOccurrence, notificationGroup.level === 6) }}
                  </div>
                </div>
                <div *ngIf="notificationGroup.terminal && notificationGroup.facility" class="terminal-name">
                  {{ notificationGroup.terminal.name }} ({{ notificationGroup.facility.name }})
                </div>
                <div>
                  <span class="notification-title">{{ notificationGroup.title }} </span>
                </div>
                <div *ngIf="notificationGroup.level !== 6 && notificationGroup.facility" class="row">
                  <button class="expand-panel-link" ngbPanelToggle>
                    <ng-container *ngIf="notificationGroup.count > 15">{{
                      'NOTIFICATIONS.LAST' | translate
                    }}</ng-container>
                    <ng-container *ngIf="notificationGroup.count <= 15"
                      >{{ 'NOTIFICATIONS.ALL' | translate }} {{ notificationGroup.count }}
                      {{ 'NOTIFICATIONS.NOTIFICATIONS' | translate }}</ng-container
                    >
                    <nb-icon (click)="open(i)" icon="chevron-down" nbPrefix pack="eva"></nb-icon>
                  </button>
                </div>
              </div>
              <button
                *ngIf="notificationGroup.level === 6; else singleActionTemplate"
                class="expand-panel-button"
                ngbPanelToggle
              >
                <nb-icon (click)="open(i)" icon="more-vertical" nbPrefix pack="eva"></nb-icon>
              </button>
              <ng-template #singleActionTemplate>
                <div
                  (click)="navigateToFacility(notificationGroup)"
                  *ngIf="onRegularPage && notificationGroup.facility"
                  class="single-action-button"
                >
                  <nb-icon icon="arrow-ios-forward" nbPrefix pack="eva"></nb-icon>
                </div>
              </ng-template>
            </div>
          </ng-template>
          <ng-template ngbPanelContent>
            <app-notification-group
              [accordionOpen]="opened"
              [filterSet]="firstNotificationCard.filterSet"
              [notificationGroup]="notificationGroup"
              class="notification-container accordion-item-body-{{ notificationGroup.level }}"
            ></app-notification-group>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </nb-list-item>
  </nb-list>
</nb-card>
