<div class="search-result-container">
  <div (click)="changeBack()" class="link-back">
    <nb-icon
      (click)="changeBack()"
      icon="arrow-back-outline"
      nbPrefix
      pack="eva"
      status="control"
    ></nb-icon>
    {{ 'IDENTIFICATION.NAVIGATE_BACK' | translate }}
  </div>
  <div class="search-result-details">
    <app-search-license-plate-result
      *ngIf="searchResultType === 0"
      [data]="searchResultData"
    ></app-search-license-plate-result>
    <app-search-identity-card-result
      *ngIf="searchResultType === 1"
      [data]="searchResultData"
    ></app-search-identity-card-result>
    <app-parker-result
      *ngIf="searchResultType > 1"
      [data]="searchResultData"
      [type]="searchResultType"
    ></app-parker-result>
  </div>
</div>
