<div class="search-results-tab-container own-scroll">
  <nb-list
    (bottomThreshold)="loadNext(firstCard)"
    (scroll)="onScroll($event)"
    *ngIf="firstCard.searchResults.length > 0"
    [threshold]="440"
    class="search-result-list"
    nbInfiniteList
  >
    <nb-list-item
      (click)="navigateToSubscribers(result, data.type)"
      *ngFor="let result of firstCard.searchResults; let i = index"
    >
      <div>
        <nb-icon icon="archive-outline" nbPrefix pack="eva"></nb-icon>&nbsp;
        {{ 'SUBSCRIPTION.HRI' | translate }}:&nbsp;
        {{ result.hri }}&nbsp;
        {{ 'SUBSCRIPTION.PUBLISHER' | translate }}:&nbsp;
        {{ result.publisher?.name }}&nbsp;
        {{ 'SUBSCRIPTION.OWNER' | translate }}:&nbsp;
        {{
        result.owner ? result.owner['lastName'] : ('GLOBAL_SEARCH.NO_RESULT' | translate)
        }}
      </div>
      <div>
        <nb-icon icon="arrow-ios-forward" nbPrefix pack="eva"></nb-icon>
      </div>
    </nb-list-item>
  </nb-list>
  <div *ngIf="data.results.length === 0" class="no-search-results">
    {{ 'GLOBAL_SEARCH.NO_SEARCH_RESULTS' | translate }}
  </div>
</div>
<div [class]="data.count > 5 ? 'showMoreIcon' : 'hideMoreIcon'" class="z">
  <nb-icon class="icon-navigate" icon="{{ scrollIcon }}" nbSuffix pack="eva"></nb-icon>
</div>
