import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FacilityStatusIcon, StatusService } from '../../services/status.service';
import { FullStatus, OpeningState } from '../../../@core/domain/interfaces/facility.interface';

@Component({
  selector: 'app-facility-status-icon',
  templateUrl: './facility-status-icon.component.html',
  styleUrls: ['./facility-status-icon.component.scss'],
})
export class FacilityStatusIconComponent implements OnInit, OnChanges {
  @Input()
  fullStatus!: FullStatus;

  @Input()
  openingState!: OpeningState;

  facilityStatusIcon!: FacilityStatusIcon;

  constructor(private statusService: StatusService) {}

  ngOnInit(): void {
    this.setOpeningIcon();
  }

  ngOnChanges() {
    this.setOpeningIcon();
  }

  private setOpeningIcon() {
    this.facilityStatusIcon = this.statusService.getOccupationStatus(this.fullStatus, this.openingState);
  }
}
