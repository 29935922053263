<div *ngIf='summary' class='transactions-summary'>
  <h5
    nbTooltip="{{ 'TRANSACTIONS.SUMMARY_EXPLANATION' | translate }}">{{title}}</h5>
  <span>{{summary.total}} {{ 'TRANSACTIONS.OPEN' | translate }} {{summary.total | i18nPlural: {
    '=1': 'transaction',
    'other': 'transactions'
  } }}
    {{ 'TRANSACTIONS.FOUND' | translate }}</span>
  <div class='transactions-container'>
    <div class='arrival'>
      <nb-icon *ngIf='!hideIcons' icon='repeat-outline' pack='eva'></nb-icon>
      <div>
        <h3>{{ 'TRANSACTIONS.FIRST_TRANSACTION' | translate }}</h3>
        <p>
          <span><ng-container>{{ 'TRANSACTIONS.ARRIVAL_DATE' | translate }}</ng-container>:<br></span>
          <span class='span-date'>{{summary.first.arrivalDate | date: dateFormat}}</span>
        </p>
        <p>
          <span><ng-container>{{ 'TRANSACTIONS.ENTRY_TERMINAL' | translate }}</ng-container>:<br></span>
          <span class='span-date'>{{summary.first.entryTerminal}}</span>
        </p>
      </div>
    </div>
    <div *ngIf='summary.last?.arrivalDate' class='arrival'>
      <nb-icon *ngIf='!hideIcons' icon='repeat-outline' pack='eva'></nb-icon>
      <div>
        <h3>{{ 'TRANSACTIONS.LAST_TRANSACTION' | translate }}</h3>
        <p><span><ng-container>{{ 'TRANSACTIONS.ARRIVAL_DATE' | translate }}</ng-container>:<br></span>
          <span class='span-date'>{{summary.last.arrivalDate | date: dateFormat}}</span></p>
        <p><span><ng-container>{{ 'TRANSACTIONS.ENTRY_TERMINAL' | translate }}</ng-container>:<br></span>
          <span class='span-date'>{{summary.last.entryTerminal}}</span></p>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
</div>

