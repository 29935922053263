<div class="search-results-tab-container own-scroll">
  <nb-list
    (bottomThreshold)="loadNext(firstCard)"
    (scroll)="onScroll($event)"
    *ngIf="firstCard.searchResults.length > 0"
    [threshold]="200"
    class="search-result-list"
    nbInfiniteList
  >
    <nb-list-item
      (click)="navigateToSubscribers(result, data.type)"
      *ngFor="let result of firstCard.searchResults; let i = index"
      class="y"
    >
      <div>
        <nb-icon icon="car-outline" nbPrefix pack="eva"></nb-icon>&nbsp;
        {{ 'IDENTIFICATION.OWNER' | translate }}:&nbsp;
        {{ result.owner ? result.owner['lastName'] : ('GLOBAL_SEARCH.NO_RESULT' | translate)}}
      </div>
      <div>
        <app-license-plate
          *ngIf="result && result.country && result.license"
          [country]="result.country"
          [licence]="result.license"
        ></app-license-plate>
      </div>
    </nb-list-item>
  </nb-list>
  <div *ngIf="data.results.length === 0" class="no-search-results">
    {{ 'GLOBAL_SEARCH.NO_SEARCH_RESULTS' | translate }}
  </div>
</div>
<div [class]="data.count > 5 ? 'showMoreIcon' : 'hideMoreIcon'" class="z">
  <nb-icon class="icon-navigate" icon="{{ scrollIcon }}" nbSuffix pack="eva"></nb-icon>
</div>
