import { Component, Input, OnInit } from '@angular/core';
import { Terminal } from '../../../../@core/domain/interfaces/terminal.interface';
import * as dayjs from 'dayjs';
import { NotificationsCard } from '../../notifications/notifications.component';
import { NotificationsApiService } from '../../../../@core/api/notification-api.service';
import { Notification } from '../../../../@core/domain/interfaces/notifications.interface';

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { StatusPageService } from '../../../../pages/status-page/services/status-page.service';
import _ from 'lodash';
import { TerminalsApiService } from '../../../../@core/api/terminals-api.service';
import { NotificationFilterSet } from '../../notifications/notificationFilterSet';

@Component({
  selector: 'app-terminal-events',
  templateUrl: './terminal-events.component.html',
  styleUrls: ['./terminal-events.component.scss'],
})
export class TerminalEventsComponent implements OnInit {
  @Input()
  terminalId!: number;

  @Input()
  showTerminalTitle = true;

  terminal!: Terminal;
  terminalNotifications: Notification[] = [];
  firstNotificationCard: NotificationsCard = {
    notificationGroups: [],
    placeholders: [],
    filterSet: new NotificationFilterSet(),
    levels: [],
    types: [],
    terminalIds: [],
    definitions: [],
    loading: false,
    pageToLoadNext: 1,
  };
  pageSize = 25;
  lastPage = false;
  loading = false;
  hideElement = false;

  constructor(
    private terminalsApiService: TerminalsApiService,
    private notificationsApiService: NotificationsApiService,
    private statusPageService: StatusPageService
  ) {}

  ngOnInit(): void {
    this.terminalsApiService.loadTerminalById(this.terminalId).subscribe((t) => {
      if (t) {
        this.terminal = t;
        this.firstNotificationCard.terminalIds.push(this.terminal.id);
        this.loadNext(this.firstNotificationCard);

        this.statusPageService.refreshTerminalSummary.subscribe((status) => {
          if (status) {
            this.loadNext(this.firstNotificationCard);
          }
        });
      }
    });
  }

  loadNext(cardData: NotificationsCard) {
    if (!this.lastPage) {
      this.loading = true;
      this.notificationsApiService.loadNotificationsFilteredForTerminal(cardData, this.pageSize).subscribe((n) => {
        if (n && n.notifications) {
          this.terminalNotifications = _.orderBy(
            n.notifications,
            [
              (n) => {
                return n.utcOccurrence;
              },
            ],
            'desc'
          );
          this.loading = false;
        }
      });
    }
  }

  getTime(localOccurrence: string) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.guess();

    return dayjs(localOccurrence).utc().format('LT').toString();
  }
}
