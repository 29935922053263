<nb-form-field *ngIf='allFacilities'>
  <nb-icon icon='car-outline' nbPrefix pack='eva'>local_parking</nb-icon>
  <nb-select (selectedChange)='onChange(selectedFacilityId)' [(ngModel)]='selectedFacilityId'
             class='appearance-outline status-basic nb-transition'
             fullWidth placeholder="{{ 'TRANSACTIONS.PARKING_FACILITY' | translate }}"
             shape='semi-round'
             size='large'>
    <nb-option *ngFor='let facility of allFacilities' [value]='facility.id'>{{facility.name}}</nb-option>
  </nb-select>
</nb-form-field>
<nb-spinner *ngIf='!allFacilities'></nb-spinner>
