<app-page-header-mobile *ngIf='!showSideNav'></app-page-header-mobile>
<nb-layout>
  <nb-sidebar *ngIf='showSideNav' fixed state='compacted'>
    <app-main-menu></app-main-menu>
  </nb-sidebar>
  <nb-layout-column>
    <div class='main-content-container'>
      <app-page-header *ngIf='showSideNav' [showHeader]='showSideNav'></app-page-header>
      <div [ngClass]='{ showSideNav: showSideNav }' class='page-layout-container'>
        <router-outlet></router-outlet>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
<app-feedback></app-feedback>
