import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Division } from '../../@core/domain/interfaces/division.interface';
import { DivisionsApiService } from '../../@core/api/divisions-api.service';
import { NbLayoutRulerService } from '@nebular/theme';
import { DefinitionsApiService } from '../../@core/api/definitions-api.service';
import { StatusPageService } from '../status-page/services/status-page.service';

@Component({
  selector: 'app-terminals-page',
  templateUrl: './terminals-page.component.html',
  styleUrls: ['./terminals-page.component.scss'],
})
export class TerminalsPageComponent implements OnInit {
  division$: Observable<Division>;
  private useMobileComponents = false;

  constructor(
    private statusPageService: StatusPageService,
    private divisionsApiService: DivisionsApiService,
    private definitionsApiService: DefinitionsApiService,
    private layoutRulerService: NbLayoutRulerService
  ) {
    this.division$ = this.divisionsApiService.division$;
    this.divisionsApiService.loadDivisions();
  }

  ngOnInit(): void {
    this.getWindowSize();
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.getWindowSize();
  }

  private getWindowSize() {
    this.layoutRulerService.getDimensions().subscribe((data) => {
      this.useMobileComponents = data.clientWidth <= 768;
    });
  }
}
