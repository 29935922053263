import { Component, OnInit } from '@angular/core';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';
import { NbDialogService } from '@nebular/theme';
import { ComponentService } from '../../utils/component.service';

/**
 * Dialog showing feedback form options
 */
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  constructor(private dialogService: NbDialogService, private componentService: ComponentService) {}

  ngOnInit(): void {
    this.componentService.currentMessage.subscribe((message) => {
      if (message) {
        this.openFeedbackDialog();
      }
    });
  }

  openFeedbackDialog(): void {
    this.dialogService.open(FeedbackDialogComponent, {
      context: {
        title: '',
      },
    });
  }
}
