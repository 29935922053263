export class ValidationAction {
  constructor(public id: string, public label: string, public type: string, public price: string) {}

  static prepareFromApi(action: any): ValidationAction {
    return {
      id: action.number,
      label: action.label,
      type: action.type,
      price: currencyFormatter(action.price),
    };
  }
}

export function currencyFormatter(price: number): string {
  return Number(price).toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' });
}
