<app-page-header></app-page-header>
<div class="page-layout-container">
  <div class="body-container">
    <div class="body-title">
      <h4>{{ 'TRANSACTIONS.TITLE' | translate }}</h4>
    </div>
    <nb-card *ngIf="accessBulkTransactionsClose; else elseBlock" class="transactions-card">
      <nb-card-body>
        <h6>{{ 'TRANSACTIONS.FILTER' | translate }}</h6>
        <p>{{ 'TRANSACTIONS.EXPLANATION' | translate }}</p>
        <app-transactions-filter-form
          #transactionsFilterForm
          (fetchTransactions)="fetchTransactionsSummary()"
          (resetForm)="onFormReset()"
          [filter]="transactionsFilter"
          [loadingTransactions]="loadingTransactions"
          [replaySearch]="replaySearch"
          [resetFormAfterCancel]="resetForm"
        >
        </app-transactions-filter-form>
        <div #summaryContainer>
          <app-transactions-summary
            *ngIf="summary && summary.total"
            [summary]="summary"
            title="{{ 'TRANSACTIONS.TRANSACTIONS_SUMMARY' | translate }}"
          >
            <button
              (click)="completeTransactions()"
              nbButton
              nbTooltip="{{ 'TRANSACTIONS.COMPLETE_TRANSACTIONS_HINT' | translate }}"
              status="success"
              type="button"
            >
              {{ 'TRANSACTIONS.COMPLETE_TRANSACTIONS' | translate }}
            </button>
          </app-transactions-summary>
          <div *ngIf="summary && !summary?.total" class="no-results">
            <p>{{ 'TRANSACTIONS.NO_MATCH' | translate }}</p>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
    <nb-card *ngIf="lastCompletionData">
      <nb-card-body>
        <app-transactions-completed
          (repeatSearch)="onRepeatSearch($event)"
          [data]="lastCompletionData"
          [summary]="summary"
        ></app-transactions-completed>
      </nb-card-body>
    </nb-card>
    <div *ngIf="initialState" class="initial-state-text">
      <h5>{{ 'TRANSACTIONS.INITIAL' | translate }}</h5>
      <p>{{ 'TRANSACTIONS.INITIAL_TEXT' | translate }}</p>
    </div>
    <ng-template #elseBlock>
      <h5>{{ 'TRANSACTIONS.ACCESS' | translate }}</h5>
    </ng-template>
  </div>
</div>
