<div class="row status-page__body">
  <div class="t1 col-xl-3 col-xs-1 pe-3">
    <app-terminals [facility]="currentFacility" id="terminals"></app-terminals>
  </div>
  <div class="t2 col-xl-5 col-xs-1 pe-3">
    <app-totals [facilityId]="currentFacility.id" id="totals"></app-totals>
    <app-capacity-count [facilityId]="currentFacility.id" id="capacity"></app-capacity-count>
    <app-activity [facilityId]="currentFacility.id" id="activity"></app-activity>
    <app-activity-line [facilityId]="currentFacility.id" id="activityLine"></app-activity-line>
  </div>
  <div *ngIf="showNotifications" class="t3 col-xl-4 col-xs-1 ps-0">
    <app-facility-emergency-alarms
      [facilityId]="currentFacility.id"
      [showHeaderIcon]="true"
      id="emergency-alarms"
    ></app-facility-emergency-alarms>
    <app-notifications
      [facilityId]="currentFacility.id"
      [facilitySelection]="selection"
      class="notifications"
      id="notifications"
    ></app-notifications>
  </div>
</div>
