import { Alarm } from 'src/app/@core/domain/interfaces/alarm.interface';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
interface AlarmLed {
  label: boolean;
  color?: string;
  alarm?: Alarm;
  tiny?: boolean;
}

/**
 * Led element to show alarm status, grey/green/red
 */
@Component({
  selector: 'app-alarm-led',
  templateUrl: './alarm-led.component.html',
  styleUrls: ['./alarm-led.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlarmLedComponent implements OnInit {
  @Input()
  ledConfig: AlarmLed;
  color: string;
  size: number;
  radius: number;

  private red = '#f9430e';
  private green = '#0CCE6B';
  private grey = '#5d6c76';

  constructor() {
    this.ledConfig = {
      label: false,
      color: '',
    };
    this.color = '#000';
    this.size = 4;
    this.radius = 4;
  }

  ngOnInit(): void {
    if (this.ledConfig.tiny) {
      this.size = 4;
      this.radius = 4;
      this.color = this.red;
      return;
    }
    if (this.ledConfig.label && this.ledConfig.alarm) {
      if (this.ledConfig.alarm.enabled === null) {
        if (this.ledConfig.alarm.going) {
          this.color = this.red;
        } else {
          this.color = this.green;
        }
      } else {
        if (this.ledConfig.alarm.going) {
          this.color = this.red;
        } else if (!this.ledConfig.alarm.going && this.ledConfig.alarm.enabled) {
          this.color = this.green;
        } else if (!this.ledConfig.alarm.going && !this.ledConfig.alarm.enabled) {
          this.color = this.grey;
        }
      }
    }
    if (this.ledConfig.color && !this.ledConfig.label && !this.ledConfig.alarm) {
      this.size = 4;
      this.radius = 4;
      if (this.ledConfig.color === 'red') {
        this.color = this.red;
      } else if (this.ledConfig.color === 'green') {
        this.color = this.green;
      } else {
        this.color = this.ledConfig.color;
      }
    }
  }
}
