<nb-layout>
  <nb-layout-column class="account-page-container">
    <div class="authentication-page">
      <nb-card>
        <nb-card-body>
          <img
            src="/assets/theme/Logo_ParkBase.png"
            alt="Logo Parkbase"
            width="225"
            height="50"
          />
          <h6>v{{ version }}</h6>
          <ng-container *ngIf="isAuthenticated; else noAuth">
            <button
              nbButton
              fullWidth
              shape="semi-round"
              status="primary"
              id="logout"
              (click)="logout()"
            >
              <span>{{ "LOGOUT" | translate }}</span>
            </button>
            <br />
          </ng-container>
          <ng-template #noAuth>
            <div class="login-button">
              <button
                nbButton
                fullWidth
                shape="semi-round"
                status="primary"
                id="login"
                (click)="login()"
              >
                <span>{{ "LOGIN" | translate }}</span>
              </button>
            </div>
          </ng-template>
        </nb-card-body>
      </nb-card>
    </div>
  </nb-layout-column>
</nb-layout>

<div *ngIf="false">
  <div>Welcome to home Route</div>
  <button *ngIf="checkSessionChanged$ | async" (click)="login()">checkSessionChanged Login</button>
  <div *ngIf="isAuthenticated; else noAuth">
    <button (click)="logout()">Logout</button>
    <button (click)="logoffLocal()">Logoff Local</button>
    <button (click)="refreshSession()">refreshSession</button>
    <hr />
    <br />
    Is Authenticated: {{ isAuthenticated }}
    <br />
    userData
    <pre>{{ userData$ | async | json }}</pre>
    {{ checkSessionChanged }}
    <br />
  </div>
  <ng-template #noAuth>
    <button (click)="login()">Login</button>
    <hr />
  </ng-template>
  Configuration loaded:
  <pre>{{ configuration$ | async | json }}</pre>
</div>
