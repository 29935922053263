import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { BehaviorSubject, delay, Observable, of, Subject, switchMap } from 'rxjs';
import { NbDialogRef } from '@nebular/theme';
import { FacilitySummary } from '../../@core/domain/interfaces/facility-summary.interface';
import { debounceTime, tap } from 'rxjs/operators';
import { FacilityOverview } from '../../@core/domain/interfaces/facility.interface';
import { Router } from '@angular/router';
import { MainMenuConfigComponent } from '../../@shared/components/main-menu/main-menu.config.component';
import { NbAccessChecker } from '@nebular/security';
import { TranslateService } from '@ngx-translate/core';
import { slideInOutAnimation } from '../../@shared/animation/animation';

export interface State {
  searchTerm: string;
}

export interface SearchResult {
  foundFacilities: FacilitySummary[];
  total: number;
}

@Component({
  selector: 'app-facility-select-mobile',
  templateUrl: './facility-select-mobile.component.html',
  styleUrls: ['./facility-select-mobile.component.scss'],
  animations: slideInOutAnimation,
})
export class FacilitySelectMobileComponent extends MainMenuConfigComponent implements OnInit, OnChanges {
  @Input()
  facilitySummary$!: Observable<FacilitySummary[]>;

  searching = false;

  showComponent = true;

  noSearchResults = false;

  private _state: State = {
    searchTerm: '',
  };

  private _search$ = new Subject<void>();

  constructor(
    public override accessChecker: NbAccessChecker,
    public override translate: TranslateService,
    private router: Router,
    protected facilitySelectDialogRef: NbDialogRef<FacilitySelectMobileComponent>
  ) {
    super(accessChecker, translate);

    this._search$
      .pipe(
        tap(() => {
          this._loading$.next(true);
        }),
        debounceTime(100),
        switchMap(() => this._search()),
        delay(100),
        tap(() => this._loading$.next(false))
      )
      .subscribe((result: SearchResult) => {
        this._facilities$.next(result.foundFacilities);
        this._total$.next(result.total);
        this.noSearchResults = result.total === 0;
      });

    this._search$.next();
  }

  private _loading$ = new BehaviorSubject<boolean>(true);

  get loading$() {
    return this._loading$.asObservable();
  }

  private _total$ = new BehaviorSubject<number>(0);

  get total$() {
    return this._total$.asObservable();
  }

  private _facilities$ = new BehaviorSubject<FacilitySummary[]>([]);

  get facilities$() {
    return this._facilities$.asObservable();
  }

  get searchTerm() {
    return this._state.searchTerm;
  }

  set searchTerm(searchTerm: string) {
    this.searching = searchTerm.length > 0;
    this._set({ searchTerm });
  }

  override ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      // changes
    }
  }

  openFacilityMobile(facilityId: number) {
    this.router.navigateByUrl('/', { skipLocationChange: false }).then(() =>
      this.router
        .navigateByUrl('/status/facilities/' + facilityId, {
          skipLocationChange: true,
        })
        .then(() => {
          this.facilitySelectDialogRef.close(true);
        })
    );
  }

  clearSearchForm() {
    this.searchTerm = '';
    this.searching = false;
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
    this._search$.next();
  }

  private _search(): Observable<any> {
    let foundFacilities: FacilitySummary[] = [];
    this.facilitySummary$.subscribe((data: FacilitySummary[]) => {
      if (data) {
        data.forEach((fs) => {
          foundFacilities.push(fs);
        });
      }
    });
    const { searchTerm } = this._state;
    foundFacilities = foundFacilities.filter((facilityOverview) => this.matches(facilityOverview.facility, searchTerm));
    const total = foundFacilities.length;
    return of({ foundFacilities, total });
  }

  private matches(facility: FacilityOverview, term: string): boolean {
    if (facility && facility.name && facility.id) {
      return (
        facility.name.toString().toLowerCase().includes(term.toLowerCase()) ||
        facility.id.toString().toLowerCase().includes(term.toLowerCase())
      );
    }
    return false;
  }
}
