<h5>{{ 'GRANT_PASSAGE.CONTROLLER_PASSAGE' | translate }}</h5>
<app-terminal-title [hidden]='hideElement' [showHeading]='false'
                    [show]='showTerminalTitle' [terminalOverview]='terminal'>
</app-terminal-title>
<p class='description'>
  {{ 'GRANT_PASSAGE.DESCRIPTION' | translate }}
</p>
<nb-alert *ngIf='passageResponseMessage && !passageCompleted' [status]=passageResponseStatus class='alert'>
  <nb-icon icon='alert-circle-outline' nbPrefix pack='eva'></nb-icon>
  {{passageResponseMessage}}
</nb-alert>
<div *ngIf='awaitTerminalActionComplete' class='spinner-container mb-3'>
  <div class='spinner-border mb-3' role='status'>
    <span class='visually-hidden'>Loading...</span>
  </div>
  <div class='spinner-label'>{{ 'GRANT_PASSAGE.IN_PROGRESS' | translate }}</div>
</div>
<form [formGroup]='grantPassageForm' [ngClass]='{awaitTerminalActionComplete: awaitTerminalActionComplete}'>
  <div class='p-section search'>
    <app-identification-search
      (searchCompleted)='onSearchCompleted()'
      (searchReset)='onSearchReset()'
      (searchStarted)='onSearchStarted()'
      (selectIdentificationId)='onSelectIdentificationId($event)'
      [action]='action'
      [passageCompleted]='passageCompleted'
      [terminalId]='terminalId'
    ></app-identification-search>
  </div>
  <div *ngIf='showFormEl'>
    <div class='p-section'>
      <div class='form-floating'>
        <select class='form-select' formControlName='reason'
                id='floatingSelect'>
          <option value=''>{{
            'GRANT_PASSAGE.SELECT_REASON' | translate
            }}*
          </option>
          <option value='0'>{{
            'GRANT_PASSAGE.SELECT_REASON_OPTIONS.OTHER' | translate
            }}</option>
          <option value='1'>{{
            'GRANT_PASSAGE.SELECT_REASON_OPTIONS.VISITOR' | translate
            }}</option>
          <option value='2'>{{
            'GRANT_PASSAGE.SELECT_REASON_OPTIONS.TERMINAL_OUT_OF_USE' | translate
            }}</option>
          <option value='3'>{{
            'GRANT_PASSAGE.SELECT_REASON_OPTIONS.CARDREADER_OUT_OF_USE' | translate
            }}</option>
          <option value='4'>{{
            'GRANT_PASSAGE.SELECT_REASON_OPTIONS.CARD_BROKEN' | translate
            }}</option>
          <option value='5'>{{
            'GRANT_PASSAGE.SELECT_REASON_OPTIONS.CARD_UNAVAILABLE' | translate
            }}</option>
        </select>
        <label for='floatingSelect'>{{ 'GRANT_PASSAGE.REASON' | translate }}</label>
      </div>
    </div>
    <div class='p-section'>
      <div *ngIf='action.type === 28 || action.type === 29' class='form-floating'>
      <textarea class='form-control' formControlName='remarks'
                id='remarks' placeholder=' '
                style='height: 100px'></textarea>
        <label for='remarks'>{{ 'GRANT_PASSAGE.REMARKS' | translate }}</label>
      </div>
    </div>
    <div class='p-section'>
      <div
        *ngIf='identificationHint && this.grantPassageForm && !this.grantPassageForm.valid'
        class='identification-hint'
      >
        <div>
          {{ 'GRANT_PASSAGE.IDENTIFICATION_HINT' | translate }}
        </div>
        <div>
          <nb-icon icon='alert-triangle-outline' nbPrefix pack='eva'></nb-icon>
        </div>
      </div>
      <div class='action-buttons'>
        <button
          (click)='submitIdentificationGrantPassage()'
          [disabled]='!grantPassageForm.valid'
          class='button'
          nbButton
          shape='semi-round'
          status='success'
          type='button'
        >
          {{ 'GRANT_PASSAGE.CONTROLLER_PASSAGE' | translate }}
        </button>
        <button
          (click)='switchAnonymousGrantPassage(showPassageHint)'
          *ngIf='showFormEl || showPassageHint'
          [ngClass]="showPassageHint ? 'margin-top' : 'transparent-button'"
          class='button'
          nbButton
          shape='semi-round'
          status="{{showPassageHint ? 'success' : 'primary'}}"
          type='button'
        >
          {{ 'GRANT_PASSAGE.ANONYMOUS_PASSAGE' | translate }}
        </button>
      </div>
    </div>
  </div>
  <ng-container *ngIf='showPassageHint' class='passage-hint'>
    <app-info-alert
      *ngIf='identificationType === 0'
      infoText="{{ 'GRANT_PASSAGE.CONTROLLER_LICENSEPLATE_PASSAGE_DENIED' | translate }}">
    </app-info-alert>
    <app-info-alert
      *ngIf='identificationType === 1'
      infoText="{{ 'GRANT_PASSAGE.CONTROLLER_CARD_PASSAGE_DENIED' | translate }}">
    </app-info-alert>
  </ng-container>
</form>
