import { ChangeDetectorRef, Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ComponentService } from '../../utils/component.service';
import { debounceTime } from 'rxjs/operators';
import { NbDialogRef, NbDialogService, NbIconConfig, NbLayoutRulerService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { ParkbaseStorageService } from '../../services/parkbase-storage.service';
import { SearchApiService } from 'src/app/@core/api/search-api.service';
import { TrackingService } from '../../services/tracking.service';
import { TranslateService } from '@ngx-translate/core';
import {
  SearchResult,
  SearchResultItem,
  SearchResultType2,
} from 'src/app/@core/domain/interfaces/search-result.interface';
import { IdentificationsApiService } from 'src/app/@core/api/identifications-api.service';
import { ParkersApiService } from 'src/app/@core/api/parkers-api.service';

export interface CardResult {
  searchResults: SearchResultItem[];
  placeholders: any[];
  loading: boolean;
  pageToLoadNext: number;
}

/**
 * Global search
 */
@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
})
export class GlobalSearchComponent implements OnInit {
  searchInput!: string;
  searchInputChanged = new Subject<string>();
  searchResult!: SearchResult[];
  recentSearches!: string[];
  searching = false;
  dialogOpen = false;
  loading = false;
  placeholder = '';
  change = false;
  searchResultType: any;
  searchResultData: any;

  currentTab!: string;

  scrollIcon = 'arrow-ios-downward-outline';

  licenseplatePageToLoadNext = 2;
  cardPageToLoadNext = 2;
  visitorPageToLoadNext = 2;
  subscriberPageToLoadNext = 2;

  dialog!: NbDialogRef<any>;

  AllIconConfig: NbIconConfig = { icon: 'folder-outline', pack: 'eva' };
  licenseIconConfig: NbIconConfig = { icon: 'car-outline', pack: 'eva' };
  cardNumberIconConfig: NbIconConfig = { icon: 'archive-outline', pack: 'eva' };
  subscriptionIconConfig: NbIconConfig = { icon: 'briefcase-outline', pack: 'eva' };
  transientIconConfig: NbIconConfig = { icon: 'person-outline', pack: 'eva' };

  @ViewChild('dummyInput') dummyInput: any;

  constructor(
    private searchApiService: SearchApiService,
    private translate: TranslateService,
    private dialogService: NbDialogService,
    private cdref: ChangeDetectorRef,
    private parkbaseStorageService: ParkbaseStorageService,
    private componentService: ComponentService,
    private trackingService: TrackingService,
    private identificationsApiService: IdentificationsApiService,
    private parkersApiService: ParkersApiService,
    private layoutRuleService: NbLayoutRulerService
  ) {
    this.searchInputChanged.pipe(debounceTime(300)).subscribe(() => {
      this.searchApiService.search(this.searchInput).subscribe((data) => {
        this.searchResult = data;
      });
    });
  }

  changed() {
    this.searching = true;
    this.searchInputChanged.next('');
    this.changeBack();
  }

  ngOnInit(): void {
    this.onResize();

    this.translate.onLangChange.subscribe((lang) => {
      if (lang) {
        this.getTranslations();
      }
    });
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.layoutRuleService.getDimensions().subscribe((data) => {
      if (data.clientWidth < 768) {
        this.placeholder = '';
      } else {
        this.getTranslations();
      }
    });
  }

  onScroll($event: any) {
    if ($event.target.offsetHeight + ($event.target.scrollTop + 5) >= $event.target.scrollHeight) {
      this.scrollIcon = 'arrow-ios-upward-outline';
    } else {
      this.scrollIcon = 'arrow-ios-downward-outline';
    }
  }

  open(dialog: TemplateRef<any>): void {
    this.dialogOpen = true;
    this.openDialog(dialog);
  }

  openFeedback($event: any): void {
    if ($event) {
      this.trackingService.eventEmitter('globalSearch', 'action', 'feedback', 'openDialog', 0);
      this.componentService.changeMessage('Open feedback from search component');
    }
  }

  clearSearchForm(): void {
    this.searchInput = '';
    this.searching = false;
    this.licenseplatePageToLoadNext = 2;
    this.cardPageToLoadNext = 2;
    this.visitorPageToLoadNext = 2;
    this.subscriberPageToLoadNext = 2;
    this.recentSearches = this.parkbaseStorageService.retrieveRecentSearches();
  }

  redoSearch(term: string): void {
    this.searching = true;
    this.searchInput = term;
    this.searchApiService.search(this.searchInput).subscribe((result) => {
      this.searchResult = result;
    });
  }

  removeRecentSearch(searchTerm: string): void {
    const recentSearches = this.parkbaseStorageService.retrieveRecentSearches();
    const index = recentSearches.findIndex((term) => term === searchTerm);
    recentSearches.splice(index, 1);
    this.parkbaseStorageService.storeRecentSearches(recentSearches);
    this.recentSearches = recentSearches;
  }

  closeSearchDialog(): void {
    this.licenseplatePageToLoadNext = 2;
    this.cardPageToLoadNext = 2;
    this.visitorPageToLoadNext = 2;
    this.subscriberPageToLoadNext = 2;
    this.searchResult = [];
    this.searchInput = '';
    this.onCloseSearchResult();
    this.dialog.close();
  }

  changeBack() {
    this.change = false;
  }

  onCloseSearchResult() {
    this.changeBack();
    this.searchResultType = null;
  }

  onChangeTab($event: any) {
    this.currentTab = $event.tabTitle;
  }

  navigateToSubscribers($event: any): void {
    this.searchResultType = $event.type.id;
    if (
      this.searchResultType === SearchResultType2.IdentityCard ||
      this.searchResultType === SearchResultType2.LicensePlate
    ) {
      this.identificationsApiService.searchIdentificationsbyId($event.result.id).subscribe((data) => {
        this.searchResultData = data;
      });
    }
    if (this.searchResultType === SearchResultType2.Visitor) {
      this.parkersApiService.searchParkerVisitor($event.result.id).subscribe((data) => {
        this.searchResultData = data;
      });
    }
    if (this.searchResultType === SearchResultType2.Subscriber) {
      this.parkersApiService.searchParkerSubscriber($event.result.id).subscribe((data) => {
        this.searchResultData = data;
      });
    }
    this.change = true;
  }

  protected openDialog(dialog: TemplateRef<any>): void {
    this.trackingService.eventEmitter('globalSearch', 'action', 'search', 'openDialog', 0);
    this.recentSearches = this.parkbaseStorageService.retrieveRecentSearches();
    this.searching = !!(this.searchInput && this.searchInput.length > 0);

    this.cdref.detectChanges();
    this.dialogService
      .open(dialog, {
        hasBackdrop: true,
        closeOnBackdropClick: true,
        closeOnEsc: true,
        context: '',
      })
      .onClose.subscribe(() => {
        this.dummyInput.nativeElement.blur();
      });
  }

  private getTranslations() {
    this.translate.get('GLOBAL_SEARCH.PLACEHOLDER').subscribe((res: string) => {
      this.placeholder = res;
    });
  }
}
