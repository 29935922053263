import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/app/@shared/services/config.service';
import { Parker } from '../domain/interfaces/shared/parker.interface';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root',
})
export class ParkersApiService extends BaseApiService {
  constructor(configService: ConfigService, httpClient: HttpClient) {
    super(configService, httpClient);
  }

  searchParkerSubscriber(id: number) {
    if (!id) {
      throw new Error('searchParkerSubscriber, id not provided');
    }
    const apiUrl = this.parkbaseBl9BaseUrl + '/parkers/subscriber/' + id;
    return this.http.get<Parker>(apiUrl, { headers: this.setHeaders() }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  searchParkerVisitor(id: number) {
    if (!id) {
      throw new Error('searchParkerVisitor, id not provided');
    }
    const apiUrl = this.parkbaseBl9BaseUrl + '/parkers/visitor/' + id;
    return this.http.get<Parker>(apiUrl, { headers: this.setHeaders() }).pipe(
      map((data) => {
        return data;
      })
    );
  }
}
