import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbPositionValues } from '@nebular/theme';
import { TerminalType } from 'src/app/@core/domain/enums/terminal-type.enum';
import { Notification, Ticket } from 'src/app/@core/domain/interfaces/notifications.interface';
import { ParkbaseStorageService } from 'src/app/@shared/services/parkbase-storage.service';
import { StatusPageService } from '../../../../pages/status-page/services/status-page.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input()
  notification: Notification | undefined;
  contextualActionPosition: NbPositionValues = 'left'; // no contextual actions on notification for now
  terminalType!: TerminalType;
  terminalTypeIcon = 'terminalType_0';
  preventClick = false;
  locale!: string;

  constructor(
    private parkbaseStorageService: ParkbaseStorageService,
    private statusPageService: StatusPageService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.notification && this.notification.terminal) {
      const terminalTypeEnumValue = Object.values(this.notification.terminal.type)[0];
      this.terminalTypeIcon = 'terminalType_' + terminalTypeEnumValue;
    }
    this.statusPageService.preventClick.subscribe((status) => {
      this.preventClick = status;
    });
    this.locale = this.statusPageService.getLocale();
  }

  getIconType(notification: any): string {
    if (notification && notification.terminal) {
      const terminalTypeEnumValue = Object.values(notification.terminal.type)[0];
      return 'terminalType_' + terminalTypeEnumValue;
    }
    return 'terminalType_0';
  }

  getBarcode(ticket: Ticket): string {
    if (ticket) {
      return ticket.hri + ' ' + ticket.type + ' ' + ticket.publisher;
    }
    return '---';
  }

  navigateToFacility(notification: Notification): void {
    this.router.navigate(['/status/facilities/' + notification.facility.id], {
      skipLocationChange: true,
    });
  }

  format(date: string, typeAlarm: boolean): string {
    const dateformatChoice = this.parkbaseStorageService.retrieveDateformatChoice();
    if (dateformatChoice === 'Exact' && typeAlarm) {
      return this.statusPageService.formatExactTime(date);
    } else {
      return this.statusPageService.formatRelativeTime(date);
    }
  }

  openDialog(notification: any): void {}
}
