import { BaseApiService } from './base-api.service';
import { FeedbackRequest, FeedbackResponse } from '../domain/interfaces/feedback.interface';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { ConfigService } from 'src/app/@shared/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackApiService extends BaseApiService {
  constructor(configService: ConfigService, httpClient: HttpClient) {
    super(configService, httpClient);
  }

  /**
   *  POST /api/gui/v1.0/feedback
   */
  sendFeedback(feedback: FeedbackRequest): Observable<FeedbackResponse> {
    return this.http
      .post<FeedbackResponse>(this.parkbaseBl9BaseUrl + '/feedback', feedback, {
        headers: this.setHeaders(),
      })
      .pipe(
        tap((response) => this.formatResponse(response)),
        catchError(this.doHandleError())
      );
  }

  private doHandleError() {
    return (error: HttpErrorResponse): Observable<any> => {
      return of(error);
    };
  }
}
