import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ParkbaseStorageService } from '../../services/parkbase-storage.service';
import { FacilitiesApiService } from '../../../@core/api/facilities-api.service';
import { OccupationPerHour } from '../../../@core/domain/interfaces/occupation.interface';
import { Subscription } from 'rxjs';
import dayjs from 'dayjs';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnInit, OnDestroy {
  @Input()
  facilityId!: number;

  @Input()
  style!: string;

  @Input()
  showNavigation = false;

  @Input()
  showDate = false;

  historyDataSubscription$!: Subscription;

  options: any;
  updateOptions = {};

  currentDate = dayjs();

  private data!: any[];
  private timer: any;

  constructor(
    private parkbaseStorageService: ParkbaseStorageService,
    private facilitiesApiService: FacilitiesApiService
  ) {}

  ngOnInit(): void {
    if (this.facilityId) {
      this.loadActivity();
    }
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  private loadActivity() {
    const lineData: any[] = [];
    this.historyDataSubscription$ = this.facilitiesApiService
      .loadActivity(this.facilityId, this.currentDate)
      .subscribe((data: OccupationPerHour[]) => {
        let max = 0;
        data.forEach((d) => {
          lineData.push({
            name: d.hour,
            metaData: d,
            value: d.occupationRatio.ratio,
          });
          max += d.occupationRatio.capacity;
        });
        this.getDefaultOptions(lineData, 0, max / data.length);
      });
  }

  private labelFormatter(params: any) {
    const metaData = params[0].data.metaData;
    return (
      metaData.hour +
      'hr: ' +
      metaData.occupationRatio.occupation +
      ' van ' +
      metaData.occupationRatio.capacity +
      ', ' +
      metaData.occupationRatio.freeSpaces +
      ' plaatsen vrij, bezetting ' +
      this.roundToPrecision(metaData.occupationRatio.ratio, 2) +
      '%'
    );
  }

  private roundToPrecision(value: number, precision: number) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  private getDefaultOptions(data: any, min: number, max: number) {
    this.options = {
      tooltip: {
        trigger: 'axis',
        formatter: (params: any) => {
          return this.labelFormatter(params);
        },
        axisPointer: {
          animation: true,
        },
      },
      xAxis: {
        type: 'category',
        splitLine: {
          show: false,
        },
        data: [
          '0hr',
          '1hr',
          '2hr',
          '3hr',
          '4hr',
          '5hr',
          '6hr',
          '7hr',
          '8hr',
          '9hr',
          '10hr',
          '11hr',
          '12hr',
          '13hr',
          '14hr',
          '15hr',
          '16hr',
          '17hr',
          '18hr',
          '19hr',
          '20hr',
          '21hr',
          '22hr',
          '23hr',
        ],
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '100%'],
        splitLine: {
          show: false,
        },
        splitNumber: 10,
        min: min,
        max: max,
      },
      series: [
        {
          name: 'Bezetting',
          type: 'line',
          showSymbol: false,
          emphasis: {
            line: false,
          },
          data: data,
        },
      ],
    };
  }
}
