<div *ngIf='showHeaderIcon' class='alarms-icon-handle'>
  <h5>
    <nb-icon class='emergency-alarm-bell' icon='bell' nbPrefix pack='eva'></nb-icon>
    <div *ngIf='this.alarmGoing' class='alarm-going'>
      <app-alarm-led [ledConfig]='{ label: false, tiny: true }'></app-alarm-led>
    </div>
    {{ 'ALARMS.FACILITY_TITLE' | translate }}
  </h5>
</div>
<div *ngIf='alarms$ | async as data' class='alarms'>
  <div *ngFor='let alarm of data'>
    <app-alarm-led *ngIf="alarm.enabled"
                   [ledConfig]='{ label: true, alarm: alarm }'
                   class='alarm'></app-alarm-led>
  </div>
</div>
