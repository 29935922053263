<div class='row'>
  <nb-form-field class='identification-input'>
    <nb-icon icon='search' nbPrefix pack='eva'></nb-icon>
    <input
      (input)='onIdChanged()' [(ngModel)]='id'
      [disabled]='disabled'
      [ngClass]="inValidTicketNumber ? 'ng-invalid' : 'reset-field'"
      autocomplete='off'
      class='appearance-outline status-basic nb-transition'
      fieldSize='large'
      fullWidth
      id='id'
      nbInput
      placeholder='{{ "IDENTIFICATIONS.PLACEHOLDER" | translate }}'
      required
      shape='semi-round'
      type='text'
    />
    <button
      (click)='clearSearchForm()'
      *ngIf='searching'
      class='clear-button'
      ghost
      nbButton
      nbSuffix
      style='margin-right:80px;'
      type='button'
    >
      {{ 'GLOBAL_SEARCH.CLEAR' | translate }}
    </button>
    <!--    <input (input)="onIdChanged()" [(ngModel)]="id" [disabled]="disabled" fullWidth nbInput-->
    <!--           placeholder="Type a license plate or ticket number" required type="text"/>-->

    <!-- <mat-icon *ngIf="!disabled" class="cursor-pointer" (click)="openBarcodeScanner()" matSuffix>camera_alt</mat-icon> -->
  </nb-form-field>
  <!-- <div fxLayout="column" *ngIf="!id" fxHide.gt-sm>
      <p class="bottom-margin-2 text-align-center">
          or
      </p>
      <button mat-flat-button color="primary" (click)="openBarcodeScanner()" i18n>
          <mat-icon matPrefix>linked_camera</mat-icon> SCAN BARCODE
      </button>
  </div> -->
</div>
