<div class='terminals-handle'>
  <h4>
    <div>{{ 'TERMINALS.TITLE' | translate }} {{facility.name}}</div>
    <div (click)='toggleTerminals()' class='toggle-terminals'>
      <nb-icon [icon]='icon' class='terminals-icon' nbPrefix pack='eva'></nb-icon>
    </div>
  </h4>
</div>
<app-bypass-alarms></app-bypass-alarms>
<nb-card class='terminals-card'>
  <nb-card-body>
    <div *ngIf='facilityTerminalsSummary$ | async as data'>
      <div *ngIf='data && data.length === 0' class='empty-list'>
        <div class='empty-list__image'>
          <img alt='' src='/assets/theme/empty_list_transparent.png' />
        </div>
        <div class='empty-list__text'>
          <h5>{{ 'TERMINALS.NOTHING_TO_SHOW_TITLE' | translate }}</h5>
          <p>{{ 'TERMINALS.NOTHING_TO_SHOW_DESCRIPTION' | translate }}</p>
        </div>
      </div>
      <ngb-accordion #acc='ngbAccordion' *ngIf='data.length > 0' [closeOthers]='false' class='terminals-accordion'>
        <ng-container *ngIf='loading'>
          <app-skeleton-loader *ngFor='let number of [0,1,2,3,4,5,6,7]; let i = index'
                               [styleListItem]='true'></app-skeleton-loader>
        </ng-container>
        <ngb-panel *ngFor='let summary of data; let i = index; trackBy: trackByFn'
                   [ngClass]="activePanel ? 'active' : 'inactive'"
                   id='static-{{ i }}'>
          <ng-template ngbPanelHeader>
            <button class='expand-panel-button' ngbPanelToggle>
              <app-terminal-type [summary]='summary' class='terminal-summary'></app-terminal-type>
              <nb-icon (click)='open(i)' icon='chevron-down' nbPrefix pack='eva'></nb-icon>
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <cdk-virtual-scroll-viewport [itemSize]='summary.counts.total'
                                         [style]='"height: " + summary.counts.total * 48 + "px"'
                                         class='viewport'>
              <div *cdkVirtualFor='let terminal of summary.terminals; let i = index; trackBy: trackByFn'
                   class='terminal-wrapper'>
                <app-terminal [terminal]='terminal' [useMobile]='useMobileComponents'
                              class='terminal'></app-terminal>
              </div>
            </cdk-virtual-scroll-viewport>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </nb-card-body>
</nb-card>
