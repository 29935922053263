import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationGroup, NotificationResponse } from 'src/app/@core/domain/interfaces/notifications.interface';
import { NotificationsApiService } from 'src/app/@core/api/notification-api.service';
import { FilterSet } from 'src/app/@core/domain/interfaces/definition.interface';
import dayjs from 'dayjs';

@Component({
  selector: 'app-notification-group',
  templateUrl: './notification-group.component.html',
  styleUrls: ['./notification-group.component.scss'],
})
export class NotificationGroupComponent implements OnInit, OnChanges {
  notificationResponse!: Observable<NotificationResponse>;

  @Input()
  accordionOpen!: boolean;
  @Input()
  notificationGroup!: NotificationGroup;
  @Input()
  filterSet!: FilterSet;
  itemCount = 0;

  constructor(private notificationsApiService: NotificationsApiService) {}

  ngOnInit(): void {
    this.accordionOpen = false;
    this.notificationResponse = this.notificationsApiService.divisionNotifications;

    this.notificationsApiService.loadDivisionsNotifications(
      1,
      15,
      [this.notificationGroup.type],
      [this.notificationGroup.level],
      [this.notificationGroup.terminal.id],
      this.filterSet.from ? this.filterSet.from : dayjs().subtract(30, 'day'),
      this.filterSet.to ? this.filterSet.to : dayjs().subtract(1, 'day')
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.itemCount = this.notificationGroup.count < 15 ? this.notificationGroup.count : 15;
  }
}
