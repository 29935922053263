import { OccupationEditMode, OccupationType2 } from './occupation.interface';
import { TerminalOverview } from './terminal-response.interface';

/**
 * Represents a single parking facility
 */
export interface FacilityName {
  id: number;
  name: string;
}

/**
 * Defines if a parking facility is open or closed
 */
export enum FacilityOpeningState {
  Open = 0,
  Closed = 1,
}

export interface OpeningState {
  id: FacilityOpeningState;
  name: string;
}

export interface FullStatus {
  id: number;
  name: string;
}

export enum FullStatus2 {
  NotFull,
  Full,
  PreFull,
}

/**
 * Simplified model of a facility
 */
export interface FacilityName {
  id: number;
  name: string;
}

export interface FacilityOverview {
  id: number;
  name: string;
  openingState: OpeningState;
  fullStatus: FullStatus;
}

/**
 * Summary of a terminals per facility.
 * selectedForFilter is used in GUI only.
 */
export interface FacilityTerminalSummary {
  facility: FacilityName;
  terminals: TerminalOverview[];
  selectedForFilter?: boolean;
}

/**
 * Used in GUI only
 */
export interface FacilityWithTerminals {
  id: number;
  name: string;
  availableActions: FacilityAction[];
  terminals?: TerminalOverview[];
  selectedForFilter?: boolean;
}

/**
 * Actions that can be performed on or with a facility.
 */
export interface FacilityAction {
  type: FacilityActionType;
  name: string;
}

/**
 * Result from API call to Adjust the number of occupants in a facility
 */
export interface FacilityActionResponse {
  status: ResponseStatus;
  message: string;
}

/**
 * Defines specific response status handling cases
 */
export enum ResponseStatus {
  Failure,
  Success,
  InputRequired,
  PartialFailure,
  BadRequestResponse = 400,
}

export interface AdjustOccupationFacilityAction {
  type: FacilityActionType;
  occupationAdjustments: OccupationAdjustment[];
  reservationOccupationAdjustments: ReservationOccupationAdjustment[];
}

export interface SyncOccupationTotalFacilityAction {
  type: FacilityActionType;
  occupationAdjustments: OccupationAdjustment[];
  reservationOccupationAdjustments: ReservationOccupationAdjustment[];
}

export interface ActivateBypassAlarm {
  type: FacilityActionType.BypassAlarm;
  activate: boolean;
}

export type AdjustFacilityAction =
  | AdjustOccupationFacilityAction
  | SyncOccupationTotalFacilityAction
  | ActivateBypassAlarm;

export interface OccupationAdjustment {
  type: OccupationType2;
  editMode: OccupationEditMode;
  value: number;
}

export interface ReservationOccupationAdjustment {
  type: OccupationType2;
  value: number;
}

/**
 * API Action, number of occupants in a facility
 */
export enum FacilityActionType {
  AdjustOccupation = 0,
  SyncOccupationTotal = 1,
  BypassAlarm = 2,
}
