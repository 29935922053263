import { Component, OnInit } from '@angular/core';
import { LogService } from 'src/app/@core';
import { ParkbaseStorageService } from '../../services/parkbase-storage.service';
import { FacilitiesLayoutType } from '../../../@core/domain/enums/facilitiesLayout-type.enum';

@Component({
  selector: 'app-facility-layout-selector',
  templateUrl: './facility-layout-selector.component.html',
  styleUrls: ['./facility-layout-selector.component.scss'],
})
export class FacilityLayoutSelectorComponent implements OnInit {
  selectedLayout!: string;
  layoutOptions: string[] = [FacilitiesLayoutType.Compact, FacilitiesLayoutType.Default, FacilitiesLayoutType.Extended];

  constructor(private parkbaseStorageService: ParkbaseStorageService, private logger: LogService) {
    this.selectedLayout = this.layoutOptions[0];
  }

  ngOnInit(): void {
    this.selectedLayout = this.parkbaseStorageService.retrieveStatusPageLayout();
  }

  setLayout(layout: string): void {
    this.logger.info('FacilityLayoutSelector setLayout ', layout);
    this.parkbaseStorageService.storeStatusPageLayout(layout);
    this.selectedLayout = layout;
  }
}
