import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FacilitySummary } from '../../../../../@core/domain/interfaces/facility-summary.interface';
import { Router } from '@angular/router';
import { StatusService } from '../../../../../@shared/services/status.service';
import { NbLayoutRulerService } from '@nebular/theme';

@Component({
  selector: 'app-facility-extended-full',
  templateUrl: './facility-extended-full.component.html',
  styleUrls: ['./facility-extended-full.component.scss'],
})
export class FacilityExtendedFullComponent implements OnInit, OnDestroy {
  @Input()
  facilitySummary!: FacilitySummary;

  showGraph = true;

  constructor(
    public statusService: StatusService,
    private router: Router,
    private layoutRulerService: NbLayoutRulerService
  ) {}

  @HostListener('window:resize', [])
  onResize(): void {
    this.getWindowSize();
  }

  ngOnInit(): void {
    this.getWindowSize();
  }

  gotoFacilityDetails(id: number): void {
    this.router.navigate(['/status/facilities/', id], { skipLocationChange: true });
  }

  ngOnDestroy(): void {}

  private getWindowSize() {
    this.layoutRulerService.getDimensions().subscribe((data) => {
      this.showGraph = data.clientWidth >= 768;
    });
  }
}
