import { Component, DoCheck, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TransactionsFilter } from '../../../../@core/domain/models/transactions.model';
import dayjs from 'dayjs';
import { ParkerType } from '../transactions-complete/transactions-complete.component';
import { Definition } from '../../../../@core/domain/interfaces/definition.interface';
import { TransactionsService } from '../../services/transactions.service';
import _ from 'lodash';

@Component({
  selector: 'app-transactions-filter-form',
  styleUrls: ['./transactions-filter-form.component.scss'],
  templateUrl: './transactions-filter-form.component.html',
})
export class TransactionsFilterFormComponent implements OnInit, DoCheck {
  @Input() filter!: TransactionsFilter;
  @Input() loadingTransactions!: boolean;
  @Input() resetFormAfterCancel = false;
  @Input() replaySearch = false;

  @Output() resetForm: EventEmitter<boolean> = new EventEmitter();
  @Output() fetchTransactions: EventEmitter<TransactionsFilter> = new EventEmitter();

  @ViewChild('searchForm') searchForm!: NgForm;
  maxEndDate: Date = new Date();
  errorMessage!: string;
  parkerTypes: ParkerType[] = [];
  selectedParkerTypeNames: string[] = [];
  resetSubscriptionModels = false;
  resetDateTimeSelector = false;
  isEveryModelSelected = false;

  constructor(private transactionsService: TransactionsService) {}

  ngOnInit() {
    this.filter.endDate = new Date();
    this.transactionsService.getParkerTypes().subscribe((result: Definition[]) => {
      result.forEach((d) => {
        this.parkerTypes.push({ type: d, selected: false });
      });
    });
  }

  ngDoCheck(): void {
    if (this.filter.startDate && !this.isFilterValid()) {
      this.errorMessage = 'The end date must be after the starting date';
      return;
    } else {
      this.errorMessage = '';
    }
    if (this.resetFormAfterCancel) {
      this.searchForm.form.markAsDirty({ onlySelf: false });
    }
    if (this.replaySearch && this.filter.parkerTypes) {
      this.filter.parkerTypes.forEach((p) => {
        this.toggle(p.selected, p);
      });
    }
  }

  toggle($event: boolean, parkerType: ParkerType) {
    const selectedParkerTypes: ParkerType[] = [];
    this.searchForm.form.markAsDirty({ onlySelf: false });
    this.selectedParkerTypeNames = [];
    this.parkerTypes.forEach((item) => {
      if (item.type.id === parkerType.type.id) {
        item.selected = $event;
      }
    });
    this.parkerTypes.forEach((p) => {
      if (p.selected) {
        selectedParkerTypes.push(p);
        this.selectedParkerTypeNames.push(p.type.name);
      }
    });
    this.filter.parkerTypes = selectedParkerTypes;
  }

  toggleSelectAll() {
    const selectedParkerTypes: ParkerType[] = [];
    if (this.isEveryModelSelected) {
      this.parkerTypes.forEach((p) => {
        p.selected = true;
        selectedParkerTypes.push(p);
        this.selectedParkerTypeNames.push(p.type.name);
      });
    } else {
      this.parkerTypes.forEach((p) => {
        p.selected = false;
        selectedParkerTypes.splice(0);
        this.selectedParkerTypeNames.splice(0);
      });
    }
    this.filter.parkerTypes = selectedParkerTypes;
  }

  searchOpenTransactions(): void {
    if (!this.isFilterValid()) {
      this.errorMessage = 'The end date must be after the starting date';
      return;
    }
    this.fetchTransactions.emit(this.filter);
    this.loadingTransactions = false;
  }

  resetSearch() {
    this.searchForm.form.reset();
    this.filter.startDate = undefined;
    this.filter.endDate = new Date();
    this.filter.subscriptionModels = [];
    this.resetSubscriptionModels = true;
    this.resetDateTimeSelector = true;
    this.isEveryModelSelected = false;
    this.loadingTransactions = false;
    this.errorMessage = '';
    this.parkerTypes.forEach((item) => {
      item.selected = false;
    });
    this.resetForm.emit(true);
    setTimeout(() => {
      this.resetSubscriptionModels = false;
      this.resetDateTimeSelector = false;
    }, 500);
  }

  isFilterValid(): boolean {
    return dayjs(this.filter.endDate).isAfter(this.filter.startDate);
  }

  toggleCheck() {
    if (_.find(this.parkerTypes, ['selected', false])) {
      this.isEveryModelSelected = false;
    }
  }
}
