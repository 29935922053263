import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
  TerminalStatus,
  TerminalStatus2,
  TerminalStatusColor,
  TerminalSummaryPerTerminalType,
} from 'src/app/@core/domain/interfaces/terminal.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terminal-type',
  templateUrl: './terminal-type.component.html',
  styleUrls: ['./terminal-type.component.scss'],
})
export class TerminalTypeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() summary!: TerminalSummaryPerTerminalType;

  terminalTypeIcon = 'terminalType_0';
  terminalStatus!: TerminalStatus;
  terminalStatusText = '';

  private counts: any;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    const terminalTypeEnumValue = Object.values(this.summary.type)[0];
    this.terminalTypeIcon = 'terminalType_' + terminalTypeEnumValue;
    this.counts = this.summary.counts;

    this.setTerminalStatusText();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.counts = this.summary.counts;
      this.setTerminalStatusText();
    }
  }

  ngOnDestroy(): void {
    this.terminalStatusText = '';
  }

  private setTerminalStatusText() {
    this.terminalStatusText = '';
    this.terminalStatus = { id: TerminalStatus2.Disabled, name: '-', color: TerminalStatusColor.Grey };

    this.translate
      .get(['TERMINALS.OFFLINE', 'TERMINALS.ONLINE', 'TERMINALS.WORD_ALL', 'TERMINALS.WORD_OF'])
      .subscribe((values) => {
        const t = Object.keys(values).map((key) => values[key].toLowerCase());

        if (this.counts) {
          if (this.summary.type.id === 1) {
            // console.log('this.counts', this.counts);
          }

          if (this.counts.offline >= 0) {
            this.terminalStatusText = this.counts.offline + ' ' + t[3] + ' ' + this.counts.total + ' ' + t[0];
            this.terminalStatus = { id: TerminalStatus2.OffLine, name: '-', color: TerminalStatusColor.Red };
          }

          if (this.counts.offline === this.counts.total) {
            this.terminalStatusText = t[2] + ' ' + this.counts.total + ' ' + t[0];
            this.terminalStatus = { id: TerminalStatus2.OffLine, name: '-', color: TerminalStatusColor.Red };
          }

          if (this.counts.offline === 0) {
            this.terminalStatusText = t[2] + ' ' + this.counts.total + ' ' + t[1];
            this.terminalStatus = { id: TerminalStatus2.InUse, name: '-', color: TerminalStatusColor.Green };
          }

          if (this.counts.offline < this.counts.total) {
            this.terminalStatusText =
              this.counts.total - this.counts.offline + ' ' + t[3] + ' ' + this.counts.total + ' ' + t[1];
            this.terminalStatus = { id: TerminalStatus2.InUse, name: '-', color: TerminalStatusColor.Green };
          }
        }

        // this.terminalStatusText += ' -' + this.summary.type.id;
      });
  }
}
