import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { slideInOutAnimation } from '../../@shared/animation/animation';
import { TerminalOverview } from '../../@core/domain/interfaces/terminal-response.interface';

export interface DialogCloseAction {
  type: number;
  action: {
    name: string;
  };
}

@Component({
  selector: 'app-anonymous-passage-mobile',
  templateUrl: './anonymous-passage-mobile.component.html',
  styleUrls: ['./anonymous-passage-mobile.component.scss'],
  animations: slideInOutAnimation,
})
export class AnonymousPassageMobileComponent implements OnInit {
  @Input()
  terminalOverview!: TerminalOverview;

  @Input()
  action!: any;

  showComponent = true;

  constructor(protected terminalActionsMobileDialogRef: NbDialogRef<AnonymousPassageMobileComponent>) {}

  ngOnInit(): void {}

  onChangeComponent($event: string) {
    setTimeout(() => {
      this.terminalActionsMobileDialogRef.close({ handleNavigate: { changeToSubscriber: true } });
    }, 250);
  }
}
