<div class='totals-handle'>
  <h5>
    <nb-icon class='totals-icon' icon='pie-chart' nbPrefix pack='eva'></nb-icon>
    {{ 'TOTALS.TITLE' | translate }}
  </h5>
</div>
<ng-container *ngIf='totals | async as occupation; else noData'>
  <ng-container *ngIf="occupation !== null && occupation.occupationRatios.length > 0; else noData">
    <nb-card>
      <nb-card-header class='edit-totals'>
        <div *ngIf='!editing'>
          <app-full-status-led [totals]='totals'></app-full-status-led>
        </div>
        <div>&nbsp;</div>
        <div>
          <app-button (click)='toggleEditMode()' *ngIf='!editing' icon='edit-2'
                      label="{{ 'BUTTONS.EDIT' | translate }}"></app-button>
          <app-button (click)='cancelEditMode()' *ngIf='editing' icon='edit-2'
                      label="{{ 'BUTTONS.CANCEL' | translate }}"></app-button>
          <app-button (click)='saveTotalsForm()' *ngIf='editing' [ngClass]='{ editing: editing }' icon='edit-2'
                      label="{{ 'BUTTONS.SAVE' | translate }}"></app-button>
        </div>
      </nb-card-header>
      <nb-card-body [nbSpinner]='savingForm' class='totals-card' nbSpinnerSize='large' nbSpinnerStatus='info'>
        <div *ngIf='totals | async as occupation' class='totals-card-body'>
          <div *ngIf="showChart">
            <app-totals-chart [editing]="editing" [occupation]="occupation"></app-totals-chart>
          </div>
          <div *ngIf='showTotalsForm' class='total-amounts'>
            <app-totals-form (formCanceled)="onFormCanceled($event)" (formSaved)="onFormSaved($event)"
                             (formSaving)="onFormSaving($event)" [editing]="editing"
                             [facilityId]="facilityId"
                             [occupation]="occupation"
                             [saveTotals]="saveTotals"></app-totals-form>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </ng-container>
</ng-container>
<ng-template #noData>
  <nb-card>
    <div class="no-data">
      <div>
        <h5>{{ 'GENERAL.NO_DATA' | translate }}</h5>
      </div>
    </div>
  </nb-card>
</ng-template>

