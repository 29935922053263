import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TransactionsService } from './services/transactions.service';
import { DeactivateGuardComponent } from '../../auth/guards/can-deactiovate.guard';
import {
  LAST_COMPLETED_STORAGE_KEY,
  TransactionsCompletionData,
  TransactionsFilter,
  TransactionsSummary,
} from '../../@core/domain/models/transactions.model';
import { TrackingService } from '../../@shared/services/tracking.service';
import { NbDialogService } from '@nebular/theme';
import { TransactionsFilterFormComponent } from './components/transactions-filter-form/transactions-filter-form.component';
import { TransactionsCompleteComponent } from './components/transactions-complete/transactions-complete.component';
import { NbAccessChecker } from '@nebular/security';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessageService } from '../../@shared/services/toast-message.service';

type Nullable<T> = T | null;

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
})
export class TransactionsComponent extends DeactivateGuardComponent implements OnInit {
  @ViewChild('transactionsFilterForm')
  filterComponent!: TransactionsFilterFormComponent;

  @ViewChild('summaryContainer')
  summaryContainer!: ElementRef;

  @Output() transactionsLoaded: EventEmitter<boolean> = new EventEmitter();

  loadingTransactions = false;
  summary!: Nullable<TransactionsSummary>;
  transactionsFilter: TransactionsFilter = new TransactionsFilter();
  lastCompletionData!: Nullable<TransactionsCompletionData>;
  accessBulkTransactionsClose = false;
  resetForm = false;
  initialState = true;
  replaySearch = false;

  constructor(
    private transactionsService: TransactionsService,
    private dialogService: NbDialogService,
    private accessChecker: NbAccessChecker,
    private trackingService: TrackingService,
    private toastMessageService: ToastMessageService,
    private translate: TranslateService
  ) {
    super();
    this.translate.get('DEACTIVATE_CONFIRMATION_MESSAGE').subscribe((message) => {
      super.setMessage(message);
    });

    this.accessChecker.isGranted('manage', 'bulkTransactionClose').subscribe((result: boolean) => {
      this.accessBulkTransactionsClose = result;
    });

    this.trackingService.eventEmitter('transactions', 'pageView', 'visit', '', 0);
  }

  ngOnInit() {
    this.lastCompletionData = this.getLastCompleted();
  }

  registerForm(): void {
    this.form = this.filterComponent.searchForm;
  }

  fetchTransactionsSummary(): void {
    this.loadingTransactions = true;
    this.summary = null;
    this.initialState = false;

    this.transactionsService
      .getTransactionsSummary(this.transactionsFilter)
      .subscribe((summary: TransactionsSummary) => {
        this.loadingTransactions = false;
        this.transactionsLoaded.emit(true);
        this.summary = summary;
        this.summaryContainer.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      });
  }

  completeTransactions() {
    this.dialogService
      .open(TransactionsCompleteComponent, {
        closeOnEsc: false,
        context: {
          transactionsFilter: this.transactionsFilter,
          transactionsSummary: this.summary,
        },
      })
      .onClose.subscribe((data) => {
        if (data && data.completed) {
          this.onDialogClose(true);
        } else {
          this.summary = null;
          this.resetForm = true;
        }
      });
  }

  onRepeatSearch(filter: TransactionsFilter): void {
    this.transactionsFilter = Object.assign({}, filter);
    this.fetchTransactionsSummary();
    this.replaySearch = true;
    this.trackingService.eventEmitter('repeat_search', 'open_transactions', 'onRepeatSearch');
  }

  onFormReset() {
    this.summary = null;
    this.initialState = true;
    this.replaySearch = false;
    this.transactionsLoaded.emit(false);
    this.lastCompletionData = null;
  }

  private onDialogClose(confirmed?: boolean): void {
    if (confirmed) {
      this.saveCompletionData();
      if (this.summary) {
        this.toastMessageService.showSuccess(
          `Completing ${this.summary.total} transactions, this operation may take a few minutes to finish.`
        );
      }
      this.summary = null;
    }
  }

  private getLastCompleted() {
    const lastCompleted = localStorage.getItem(LAST_COMPLETED_STORAGE_KEY);
    if (lastCompleted) {
      return JSON.parse(lastCompleted);
    }
  }

  private saveCompletionData(): void {
    if (this.summary) {
      this.lastCompletionData = {
        filter: this.transactionsFilter,
        summary: this.summary,
      };
      localStorage.setItem(LAST_COMPLETED_STORAGE_KEY, JSON.stringify(this.lastCompletionData));
      this.trackingService.eventEmitter(
        'completed',
        'open_transactions',
        this.lastCompletionData.summary.total.toString()
      );
    }
  }
}
