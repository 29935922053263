import { NbMenuItem } from '@nebular/theme';
import { NbAccessChecker } from '@nebular/security';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';

export interface SimpleMenuItem {
  title: string;
  link: string;
  data?: any;
}

@Component({
  selector: 'app-main-menu-config',
  template: ``,
})
export class MainMenuConfigComponent implements OnInit {
  // TODO Main menu does not work on desktop and on mobile correct
  mainMenuItems: NbMenuItem[] = [];

  loginMenuItems: NbMenuItem[] = [
    {
      title: 'Mijn Profiel',
      url: '/profile',
      data: { mobile: { useOverlay: true, use: 'profile-mobile' } },
      icon: { icon: 'person', pack: 'eva' },
      pathMatch: 'prefix',
      skipLocationChange: false,
      hidden: false,
    },
  ];
  items: SimpleMenuItem[] = [
    {
      title: 'Mijn Profiel',
      link: '/profile',
      data: { mobile: { useOverlay: true, use: 'profile-mobile' } },
    },
    {
      title: 'Uitloggen',
      link: '/account/logout',
      data: { mobile: { useOverlay: false } },
    },
  ];

  settingsItems: SimpleMenuItem[] = [
    {
      title: 'API Toegang',
      link: '/settings/api-access',
      data: { mobile: { useOverlay: false } },
    },
    {
      title: 'API Clients',
      link: '/settings/api-clients',
      data: { mobile: { useOverlay: false } },
    },
    {
      title: 'Kentekens',
      link: '/settings/license-plate-settings',
      data: { mobile: { useOverlay: false } },
    },
  ];
  private dashboard = {
    title: 'Status Pagina',
    icon: { icon: 'home', pack: 'eva' },
    link: '/status/facilities',
    data: { mobile: { useOverlay: false } },
    hidden: true,
  };

  private terminalDashboard = {
    title: 'Terminal overzicht',
    icon: { icon: 'monitor-outline', pack: 'eva' },
    link: '/terminals',
    data: { mobile: { useOverlay: false } },
    hidden: true,
  };

  private identifications = {
    title: 'Identifications',
    icon: { icon: 'ticket', pack: 'customIcons' },
    link: '/identification/validation',
    data: { mobile: { useOverlay: false } },
    hidden: true,
  };

  private transactions = {
    title: 'Transactions',
    icon: { icon: 'flip-2-outline', pack: 'eva' },
    link: '/transactions',
    data: { mobile: { useOverlay: false } },
    hidden: true,
  };

  private apiSettings = {
    title: 'Application Settings',
    icon: { icon: 'settings-outline', pack: 'eva' },
    link: '/settings',
    data: { mobile: { useOverlay: true, use: 'settings-mobile' } },
    hidden: true,
  };

  private translationA!: Subscription;
  private translationB!: Subscription;

  constructor(public accessChecker: NbAccessChecker, public translate: TranslateService) {}

  ngOnInit(): void {
    this.accessChecker.isGranted('manage', 'statusPage').subscribe((result: boolean) => {
      if (result) {
        this.mainMenuItems.push(this.dashboard);
        this.mainMenuItems.push(this.terminalDashboard);
      }
    });
    this.accessChecker.isGranted('manage', 'identificationValidation').subscribe((result: boolean) => {
      if (result) {
        this.mainMenuItems.push(this.identifications);
      }
    });
    this.accessChecker.isGranted('manage', 'bulkTransactionClose').subscribe((result: boolean) => {
      if (result) {
        this.mainMenuItems.push(this.transactions);
      }
    });
    this.accessChecker.isGranted('manage', 'apiSettings').subscribe((result: boolean) => {
      if (result) {
        this.mainMenuItems.push(this.apiSettings);
      }
    });

    this.mainMenuItems = this.mainMenuItems.concat(this.loginMenuItems);

    this.menuTranslation();
  }

  protected menuTranslation() {
    let index = 0;
    this.translationA = this.translate.getTranslation(this.translate.currentLang).subscribe((d) => {
      for (const item in d['GLOBAL_NAVIGATION']) {
        if (this.mainMenuItems[index]) {
          this.mainMenuItems[index].title = d['GLOBAL_NAVIGATION'][item];
          index++;
        }
      }
    });
    let index2 = 0;
    this.translationB = this.translate.getTranslation(this.translate.currentLang).subscribe((d) => {
      for (const item in d['ACCOUNT_NAVIGATION']) {
        if (this.loginMenuItems[index2]) {
          this.loginMenuItems[index2].title = d['ACCOUNT_NAVIGATION'][item];
          this.items[index2].title = d['ACCOUNT_NAVIGATION'][item];
          index2++;
        }
      }
    });
  }
}
