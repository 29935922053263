import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Occupation, OccupationType2 } from '../../../@core/domain/interfaces/occupation.interface';
import { Observable } from 'rxjs';
import { FullStatus, FullStatus2 } from '../../../@core/domain/interfaces/facility.interface';
import { FacilityStatusIcon } from '../../services/status.service';

@Component({
  selector: 'app-full-status-led',
  templateUrl: './full-status-led.component.html',
  styleUrls: ['./full-status-led.component.scss'],
})
export class FullStatusLedComponent implements OnInit, OnChanges {
  @Input()
  totals!: Observable<Occupation>;

  fullState!: FullStatus;

  label = 'test';
  color = '';

  size = 4;
  radius = 4;

  facilityStatusIcon!: FacilityStatusIcon;

  private translations: any;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.get(['STATUS.FACILITY_FREE', 'STATUS.FACILITY_PRE_FULL', 'STATUS.FACILITY_FULL']).subscribe((d) => {
      this.translations = d;
    });

    this.totals.subscribe((data) => {
      data.occupationRatios.forEach((o) => {
        if (o.type.id === OccupationType2.Total) {
          this.fullState = o.fullStatus;
          if (o.fullStatus.id === FullStatus2.Full) {
            this.label = this.translations['STATUS.FACILITY_FULL'];
            this.color = 'rgba(181,29,14,1.0)';
          } else if (o.fullStatus.id === FullStatus2.PreFull) {
            this.label = this.translations['STATUS.FACILITY_PRE_FULL'];
            this.color = 'rgba(249,200,14,1.0)';
          } else {
            this.label = this.translations['STATUS.FACILITY_FREE'];
            this.color = 'rgba(12,206,107,1.0)';
          }
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      // need to do something here or is subscription enough to see the change?
    }
  }
}
