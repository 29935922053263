import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '../../../@shared/services/config.service';
import { ParkbaseStorageService } from '../../../@shared/services/parkbase-storage.service';

@Injectable({
  providedIn: 'root',
})
export class GenericApiService {
  private parkbaseApiBaseUrl = '';
  private headers: any = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Ipp-Language': '0',
  };
  private ippLanguage: string;

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private parkbaseStorageService: ParkbaseStorageService
  ) {
    this.parkbaseApiBaseUrl = configService.configuration.server.resources + '/api/gui/v1.0/';
    this.ippLanguage = this.parkbaseStorageService.getLanguageForApi();
    this.headers['Ipp-Language'] = this.ippLanguage;
  }

  get baseUrl(): string {
    return this.parkbaseApiBaseUrl;
  }

  get identityServerApiUrl(): string {
    return this.configService.configuration.server.auth + '/api/v1/';
  }

  deleteResource(endPoint: string, fromIdentity: boolean = false): Observable<any | any[]> {
    const path = (fromIdentity ? this.identityServerApiUrl : this.baseUrl) + endPoint;
    const options = {
      headers: this.headers,
    };

    return this.http.delete(path, options).pipe(
      map((response: any) => {
        if (response && response['data']) {
          return response['data'];
        } else {
          return response;
        }
      })
    );
  }

  getResource(
    endPoint: string,
    query?: any,
    fromIdentity: boolean = false
  ): Observable<any | any[]> {
    const path = (fromIdentity ? this.identityServerApiUrl : this.baseUrl) + endPoint;
    const options = {
      headers: this.headers,
      params: query,
    };

    return this.http.get(path, options).pipe(
      map((response: any) => {
        if (response && response['data']) {
          return response['data'];
        } else {
          return response;
        }
      })
    );
  }

  postResource(
    endPoint: string,
    body?: { query?: any; data?: any } | any,
    fromIdentity: boolean = false
  ): Observable<any | any[]> {
    const path = (fromIdentity ? this.identityServerApiUrl : this.baseUrl) + endPoint;
    const options = { headers: this.headers };

    return this.http.post(path, body, options).pipe(
      map((response: any) => {
        if (response && response['data']) {
          return response['data'];
        } else {
          return response;
        }
      })
    );
  }

  updateResource(
    endPoint: string,
    body?: { query?: any; data?: any } | any,
    fromIdentity: boolean = false
  ): Observable<any | any[]> {
    const path = (fromIdentity ? this.identityServerApiUrl : this.baseUrl) + endPoint;
    const options = { headers: this.headers };

    return this.http.put(path, body, options).pipe(
      map((response: any) => {
        if (response && response['data']) {
          return response['data'];
        } else {
          return response;
        }
      })
    );
  }
}
