<ng-container *ngIf="data !== null">
  <div class="transactions-completed">
    <h5>{{ 'TRANSACTIONS.LATEST_COMPLETIONS' | translate }}</h5>
    <p>{{ 'TRANSACTIONS.LATEST_COMPLETIONS_EXPLANATION' | translate }}</p>

    <app-transactions-summary
      [hideIcons]="true"
      [summary]="data.summary"
      dateFormat="medium"
    ></app-transactions-summary>

    <p class="bottom-margin">
      <ng-container>{{ 'TRANSACTIONS.START_DATE' | translate }}</ng-container>
      : <strong>{{ data.filter.startDate | date : 'medium' }}</strong>
      <ng-container> {{ 'TRANSACTIONS.END_DATE' | translate }}</ng-container>
      : <strong>{{ data.filter.endDate | date : 'medium' }}</strong>
    </p>

    <p class="bottom-margin">
      {{ 'TRANSACTIONS.SUBSCRIPTION_MODELS' | translate }}:
      <strong *ngFor="let subscriptionModel of data.filter.subscriptionModels; let isLast = last">
        {{ subscriptionModel.name }}
        <ng-container *ngIf="!isLast">,</ng-container>
      </strong>
    </p>

    <p *ngIf="data.filter.parkerTypes && data.filter.parkerTypes.length > 0" class="bottom-margin">
      {{ 'TRANSACTIONS.PARKER_TYPES' | translate }}:
      <strong *ngFor="let parkerType of data.filter.parkerTypes; let isLast = last">
        {{ parkerType.type.name }}
        <ng-container *ngIf="!isLast">,</ng-container>
      </strong>
    </p>

    <p>
      <ng-container>{{ 'TRANSACTIONS.FACILITY' | translate }}</ng-container>
      : <strong>{{ getFacilityName(data.filter.facilityId!) | async }}</strong>
    </p>

    <button (click)="remove()" nbButton type="button">
      {{ 'TRANSACTIONS.DISCARD' | translate }}
      <nb-icon icon="close-outline" pack="eva"></nb-icon>
    </button>
    <button (click)="replayFilter()" class="margin-left" nbButton type="button">
      {{ 'TRANSACTIONS.REPEAT_SEARCH' | translate }}
      <nb-icon icon="repeat-outline" pack="eva"></nb-icon>
    </button>
  </div>
</ng-container>
<ng-container *ngIf="message !== null">
  <nb-card class="no-data">
    <div>
      <h5>{{ message }}</h5>
    </div>
  </nb-card>
</ng-container>
