import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { CellComponent } from './components/cell/cell.component';
import { AlarmBellComponent } from './components/alarm-bell/alarm-bell.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { ThemeSelectorComponent } from './components/theme-selector/theme-selector.component';
import { FacilityLayoutSelectorComponent } from './components/facility-layout-selector/facility-layout-selector.component';
import { PageComponent } from './components/page/page.component';
import { InfoComponent } from './components/info/info.component';
import { FacilitySelectComponent } from './components/facility-select/facility-select.component';
import { ParkBaseModule } from '../@parkbase/park-base.module';
import { ThemeModule } from '../@theme/theme.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { TrackingService } from './services/tracking.service';
import { CookieService } from 'ngx-cookie-service';
import { GeneralLedComponent } from './components/general-led/general-led.component';
import { TerminalTitleComponent } from './components/terminal-title/terminal-title.component';
import { StatusLedComponent } from './components/status-led/status-led.component';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { AlarmLedComponent } from './components/alarm-led/alarm-led.component';
import { ActivityChartComponent } from './components/activity-chart/activity-chart.component';
import { LicensePlateComponent } from './components/license-plate/license-plate.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FullStatusLedComponent } from './components/full-status-led/full-status-led.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { MainMenuConfigComponent } from './components/main-menu/main-menu.config.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { InfoAlertComponent } from './components/info-alert/info-alert.component';
import { ActionIconComponent } from './components/action-icon/action-icon.component';
import { LabelRequiredComponent } from './components/label-required/label-required.component';
import { RouterLinkWithHref } from '@angular/router';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ControlledPassageComponent } from './components/terminal-action-forms/controlled-passage/controlled-passage.component';
import { AnonymousPassageComponent } from './components/terminal-action-forms/anonymous-passage/anonymous-passage.component';
import { TerminalScreenComponent } from './components/terminal-action-forms/terminal-screen/terminal-screen.component';
import { IdentificationComponent } from './components/terminal-action-forms/identification/identification.component';
import { TerminalEventsComponent } from './components/terminal-action-forms/terminal-events/terminal-events.component';
import { SearchComponent } from './components/terminal-action-forms/identification/search/search.component';
import { SearchResultComponent } from './components/terminal-action-forms/identification/search-result/search-result.component';
import { LicensePlateResultComponent } from './components/search/license-plate-result/license-plate-result.component';
import { IdentityCardResultComponent } from './components/search/identity-card-result/identity-card-result.component';
import { ParkerResultComponent } from './components/search/parker-result/parker-result.component';
import { LoopAccessModeComponent } from './components/terminal-action-forms/loop-access-mode/loop-access-mode.component';
import { LostTicketComponent } from './components/terminal-action-forms/lost-ticket/lost-ticket.component';
import { TwoWayLaneComponent } from './components/terminal-action-forms/two-way-lane/two-way-lane.component';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { LicenseplateListComponent } from './components/global-search/licenseplate-list/licenseplate-list.component';
import { CardListComponent } from './components/global-search/card-list/card-list.component';
import { SubscriberListComponent } from './components/global-search/subscriber-list/subscriber-list.component';
import { VisitorListComponent } from './components/global-search/visitor-list/visitor-list.component';
import { ResultDetailsComponent } from './components/global-search/result-details/result-details.component';
import { DashboardFacilitiesSelectorComponent } from './components/dashboard-facilities-selector/dashboard-facilities-selector.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationsFilterComponent } from './components/notifications/notifications-filter/notifications-filter.component';
import { SortPipe } from './pipes/sort.pipe';
import { SortParamsDirective } from './pipes/sortParams.directive';
import { FilterNotificationPipe } from './pipes/filter-notificationType.pipe';
import { FilterNotificationNamePipe } from './pipes/filter-notificationName.pipe';
import { FilterIdenticalNotificationsPipe } from './pipes/filter-identicalNotifications.pipe';
import { HighlightSearchPipe } from './pipes/highlight-search.pipe';
import { FeedbackDialogComponent } from './components/feedback/feedback-dialog/feedback-dialog.component';
import { NotificationGroupComponent } from './components/notifications/notification-group/notification-group.component';
import { NotificationComponent } from './components/notifications/notification/notification.component';
import { NotificationActionsComponent } from './components/notifications/notification/notification-actions/notification-actions.component';
import { StatusService } from './services/status.service';
import { TwoWayLaneConfirmationComponent } from './components/terminal-action-forms/two-way-lane/two-way-lane-confirmation/two-way-lane-confirmation.component';
import { TimeFormatSelectorComponent } from './components/time-format-selector/time-format-selector.component';
import { SanitizeHtmlPipe } from './pipes/sanitize.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { ButtonComponent } from './components/button/button.component';
import { FacilityStatusIconComponent } from './components/facility-status-icon/facility-status-icon.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { TerminalSearchComponent } from './components/terminal-search/terminal-search.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import { TransactionsComponent } from './components/transactions/transactions.component';
import { NgxSliderModule } from "@angular-slider/ngx-slider";

@NgModule({
  declarations: [
    MainMenuComponent,
    MainMenuConfigComponent,
    NotificationsComponent,
    NotificationsFilterComponent,
    NotificationComponent,
    NotificationActionsComponent,
    NotificationGroupComponent,
    GlobalSearchComponent,
    LicenseplateListComponent,
    CardListComponent,
    SubscriberListComponent,
    VisitorListComponent,
    ResultDetailsComponent,
    FeedbackComponent,
    FeedbackDialogComponent,
    PageHeaderComponent,
    CellComponent,
    AlarmBellComponent,
    LanguageSelectorComponent,
    ThemeSelectorComponent,
    TimeFormatSelectorComponent,
    FacilityLayoutSelectorComponent,
    DashboardFacilitiesSelectorComponent,
    PageComponent,
    PageHeaderComponent,
    InfoComponent,
    FacilitySelectComponent,
    GeneralLedComponent,
    TerminalTitleComponent,
    StatusLedComponent,
    SkeletonLoaderComponent,
    AlarmLedComponent,
    ActivityChartComponent,
    LicensePlateComponent,
    FullStatusLedComponent,
    LineChartComponent,
    ConfirmationDialogComponent,
    InfoAlertComponent,
    ActionIconComponent,
    LabelRequiredComponent,
    TerminalScreenComponent,
    AnonymousPassageComponent,
    ControlledPassageComponent,
    IdentificationComponent,
    SearchComponent,
    SearchResultComponent,
    TerminalEventsComponent,
    LicensePlateResultComponent,
    IdentityCardResultComponent,
    ParkerResultComponent,
    LoopAccessModeComponent,
    LostTicketComponent,
    TwoWayLaneComponent,
    SortPipe,
    SanitizeHtmlPipe,
    SortParamsDirective,
    FilterNotificationPipe,
    FilterNotificationNamePipe,
    FilterIdenticalNotificationsPipe,
    HighlightSearchPipe,
    TwoWayLaneConfirmationComponent,
    ButtonComponent,
    FacilityStatusIconComponent,
    BarChartComponent,
    MultiSelectComponent,
    MultiSelectDropdownComponent,
    TerminalSearchComponent,
    SubscriptionComponent,
    TransactionsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ThemeModule,
    ParkBaseModule,
    ScrollingModule,
    RouterLinkWithHref,
    NgbModule,
    NgbAccordionModule,
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    TranslateModule.forChild({
      defaultLanguage: 'en-US',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxMaskModule,
    NgxCurrencyModule,
    NgxSliderModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    NgxEchartsModule,
    NgbAccordionModule,
    MainMenuComponent,
    MainMenuConfigComponent,
    NotificationsComponent,
    NotificationActionsComponent,
    NotificationsFilterComponent,
    NotificationComponent,
    NotificationGroupComponent,
    GlobalSearchComponent,
    LicenseplateListComponent,
    CardListComponent,
    SubscriberListComponent,
    VisitorListComponent,
    ResultDetailsComponent,
    FeedbackComponent,
    FeedbackDialogComponent,
    PageHeaderComponent,
    CellComponent,
    AlarmBellComponent,
    LanguageSelectorComponent,
    ThemeSelectorComponent,
    TimeFormatSelectorComponent,
    FacilityLayoutSelectorComponent,
    DashboardFacilitiesSelectorComponent,
    PageComponent,
    PageHeaderComponent,
    InfoComponent,
    SkeletonLoaderComponent,
    AlarmLedComponent,
    ActivityChartComponent,
    LicensePlateComponent,
    FullStatusLedComponent,
    LineChartComponent,
    ConfirmationDialogComponent,
    InfoAlertComponent,
    ActionIconComponent,
    LabelRequiredComponent,
    TerminalScreenComponent,
    AnonymousPassageComponent,
    ControlledPassageComponent,
    IdentificationComponent,
    SearchComponent,
    SearchResultComponent,
    TerminalEventsComponent,
    TerminalTitleComponent,
    GeneralLedComponent,
    LicensePlateResultComponent,
    IdentityCardResultComponent,
    ParkerResultComponent,
    LoopAccessModeComponent,
    LostTicketComponent,
    TwoWayLaneComponent,
    SortPipe,
    SanitizeHtmlPipe,
    SortParamsDirective,
    FilterNotificationPipe,
    FilterNotificationNamePipe,
    FilterIdenticalNotificationsPipe,
    HighlightSearchPipe,
    ButtonComponent,
    FacilityStatusIconComponent,
    BarChartComponent,
    MultiSelectComponent,
    MultiSelectDropdownComponent,
    TerminalSearchComponent,
    SubscriptionComponent,
  ],
  providers: [TrackingService, CookieService, StatusService],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [TrackingService, CookieService, StatusService],
    };
  }
}
