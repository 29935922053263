import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { GenericApiService } from '../../../@core/api/generic/generic-api.service';
import { Terminal } from '../../../@core/domain/models/transactions.model';
import { FacilityName } from '../../../@core/domain/interfaces/facility.interface';

@Injectable({
  providedIn: 'root',
})
export class FacilityService {
  getAll$!: Observable<FacilityName[]>;

  constructor(private apiService: GenericApiService) {}

  getAll(): Observable<FacilityName[]> {
    // debugger
    if (this.getAll$) {
      return this.getAll$;
    } else {
      return (this.getAll$ = this.apiService.getResource('facilities').pipe(
        shareReplay(1),
        tap((models: FacilityName[]) => {
          models.forEach((model: FacilityName) => {
            model.id = Number(model.id.toString());
          });
        })
      ));
    }
  }

  getExitTerminalsForFacility(facilityId: string): Observable<Terminal[]> {
    return this.apiService.getResource('facilities/' + facilityId + '/exit-terminals').pipe(
      tap((models: Terminal[]) => {
        models.forEach((model: Terminal) => {
          model.id = model.id.toString();
        });
      })
    );
  }

  getFacilityName(facilityId: string): Observable<string> {
    return this.getAll().pipe(
      map((facilities) => {
        // @ts-ignore
        return facilities.find((facility) => {
          // @ts-ignore
          return facility.id === facilityId;
        }).name;
      })
    );
  }
}
