import { Component, inject, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent implements OnInit {
  cookieService = inject(CookieService);

  isMobile = false;

  constructor() {}

  ngOnInit(): void {
    const cookieValue = this.cookieService.get('DI');
    this.isMobile = cookieValue === 'isMobile';
  }
}
