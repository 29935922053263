<nb-card>
  <nb-card-header>
    <h5>Confirmation required.</h5>
  </nb-card-header>
  <nb-card-body>
    <h4>Terminals are not connected to the same TSS!</h4>
    <p>{{message}}</p>
    <p>This event will be logged.</p>
    <div class='actions'>
      <button (click)='confirm()' fullWidth nbButton status='success'>Change Lane Status</button>
      <button (click)='close()' fullWidth nbButton status='basic'>Cancel</button>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <h6>Terminal: {{terminal.name}}</h6>
  </nb-card-footer>
</nb-card>
