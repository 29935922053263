<div *ngIf="showDate" class="bar-container-header">
  <span class="display-date">
    <input
      (ngModelChange)="onSelectDate($event)"
      [(ngModel)]="selectedDate"
      [nbDatepicker]="dateTimePicker"
      class="date-picker"
      nbInput
      placeholder="{{ currentDate.format() | date }}"
      shape="semi-round"
      status="control"
    />
    <nb-datepicker #dateTimePicker [max]="today"></nb-datepicker>
  </span>
</div>
<div class="bar-container-body">
  <div *ngIf="showNavigation" class="history-nav-left">
    <nb-icon (click)="goBack()" icon="arrow-ios-back-outline" id="nav-back" nbPrefix pack="eva"></nb-icon>
  </div>
  <div *ngIf="testEmitter$ | async as data">
    <div
      (chartInit)="onChartInit($event)"
      (optionsError)="onOptionsError($event)"
      *ngIf="data"
      [initOpts]="initOps"
      [merge]="data"
      [options]="options"
      class="bar-chart"
      echarts
      id="chart{{ facilityId }}"
    ></div>
  </div>
  <div *ngIf="showNavigation" [ngClass]="showForward ? 'history-nav-right' : 'disabled'">
    <nb-icon (click)="goForward()" icon="arrow-ios-forward-outline" id="nav-forward" nbPrefix pack="eva"></nb-icon>
  </div>
</div>
<ng-container *ngIf="debug">
  <h5>Facility id: {{ facilityId }}, max: {{ maximum }}</h5>
</ng-container>
