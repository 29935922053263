<div (click)='openModal()' class='terminal-status-name' id='{{ terminal?.status?.name }}'>
  <app-terminal-name *ngIf='terminal' [label]="terminal.name" [status]='terminal.status'></app-terminal-name>
  <button *ngIf='terminal' class='terminal-action-button'>
    <div class='contextual-actions-handle'>
      <nb-icon
        icon='chevron-right-outline'
        nbPrefix
        pack='eva'
      ></nb-icon>
    </div>
  </button>
</div>



