<nb-card class='confirmation-dialog'>
  <nb-card-header>{{ 'CONFIRM.TITLE' | translate }}</nb-card-header>
  <nb-card-body>
    <div class='action-buttons'>
      <button (click)='cancelSave()' fullWidth nbButton size='small'>{{ 'BUTTONS.CANCEL' | translate }}
        <nb-icon icon='close-outline' pack='eva'></nb-icon>
      </button>
      <button (click)='syncChanges()' fullWidth nbButton size='small' status='warning'>{{ 'BUTTONS.SYNC' | translate }}
        <nb-icon icon='sync-outline' pack='eva'></nb-icon>
      </button>
      <button (click)='saveChanges()' fullWidth nbButton size='small' status='success'>{{ 'BUTTONS.SAVE' | translate }}
        <nb-icon icon='edit-2'></nb-icon>
      </button>
    </div>
  </nb-card-body>
</nb-card>
