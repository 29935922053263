import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {
  @Input() facilityId!: number;

  constructor() {}

  ngOnInit(): void {
    if (!this.facilityId) {
      return;
    }
  }
}
