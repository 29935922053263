<h5>{{ 'GRANT_PASSAGE.ANONYMOUS_PASSAGE' | translate }}</h5>
<app-terminal-title [hidden]='hideElement' [showHeading]='false'
                    [show]='showTerminalTitle' [terminalOverview]='terminal'>
</app-terminal-title>
<p class='description'>
  {{ 'GRANT_PASSAGE.DESCRIPTION' | translate }}
</p>
<nb-alert *ngIf='passageResponseMessage' [status]=passageResponseStatus class='alert'>
  <nb-icon icon='alert-circle-outline' nbPrefix pack='eva'></nb-icon>
  {{passageResponseMessage}}
</nb-alert>
<div *ngIf='awaitTerminalActionComplete' class='spinner-container mb-3'>
  <div class='spinner-border mb-3' role='status'>
    <span class='visually-hidden'>Loading...</span>
  </div>
  <div class='spinner-label'>{{ 'GRANT_PASSAGE.IN_PROGRESS' | translate }}</div>
</div>
<form [formGroup]='grantPassageForm' [ngClass]='{awaitTerminalActionComplete: awaitTerminalActionComplete}'>
  <div class='p-section'>
    <div class='form-floating'>
      <select (change)='onSelect()' class='form-select' formControlName='reason'
              id='floatingSelect'>
        <option [selected]='grantPassageForm.pristine' value=''>{{
          'GRANT_PASSAGE.SELECT_REASON' | translate
          }}
          <ng-container *ngIf='action.type === 28'>*</ng-container>
        </option>
        <option value='0'>{{
          'GRANT_PASSAGE.SELECT_REASON_OPTIONS.OTHER' | translate
          }}</option>
        <option value='1'>{{
          'GRANT_PASSAGE.SELECT_REASON_OPTIONS.VISITOR' | translate
          }}</option>
        <option value='2'>{{
          'GRANT_PASSAGE.SELECT_REASON_OPTIONS.TERMINAL_OUT_OF_USE' | translate
          }}</option>
        <option value='3'>{{
          'GRANT_PASSAGE.SELECT_REASON_OPTIONS.CARDREADER_OUT_OF_USE' | translate
          }}</option>
        <option value='4'>{{
          'GRANT_PASSAGE.SELECT_REASON_OPTIONS.CARD_BROKEN' | translate
          }}</option>
        <option value='5'>{{
          'GRANT_PASSAGE.SELECT_REASON_OPTIONS.CARD_UNAVAILABLE' | translate
          }}</option>
      </select>
      <label for='floatingSelect'>{{ 'GRANT_PASSAGE.REASON' | translate }}</label>
    </div>
  </div>
  <div class='p-section'>
    <div class='form-floating'>
      <textarea (keyup)='onSelect()' class='form-control'
                formControlName='remarks'
                id='remarks' placeholder=' '
                style='height: 100px'></textarea>
      <label for='remarks'>{{ 'GRANT_PASSAGE.REMARKS' | translate }}</label>
    </div>
  </div>
  <div class='p-section'>
    <nb-alert *ngIf='errorMessage' class='alert' status='warning'>
      <nb-icon icon='alert-circle-outline' nbPrefix pack='eva'></nb-icon>
      {{errorMessage}}
    </nb-alert>
    <div>
      <button
        (click)='submitAnonymousGrantPassage()'
        *ngIf='action.type === 27'
        [disabled]='disableSubmit'
        fullWidth
        nbButton
        shape='semi-round'
        status='success'
        type='button'
      >
        {{ 'GRANT_PASSAGE.ANONYMOUS_PASSAGE' | translate }}
      </button>
      <button
        (click)='submitAnonymousGrantPassage()'
        *ngIf='action.type === 28'
        [disabled]='!grantPassageForm.valid'
        fullWidth
        nbButton
        shape='semi-round'
        status='success'
        type='button'
      >
        {{ 'GRANT_PASSAGE.ANONYMOUS_PASSAGE' | translate }}
      </button>
      <button
        (click)='switchControlledGrantPassage()'
        *ngIf='action.type === 27 || action.type === 28'
        class='transparent-button'
        fullWidth
        nbButton
        outline
        shape='semi-round'
        type='button'
      >
        {{ 'GRANT_PASSAGE.CONTROLLER_PASSAGE' | translate }}
      </button>
    </div>
  </div>
</form>
