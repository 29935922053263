import { Component, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { SubscriptionService } from '../../../subscriptions/services/subscriptions.service';
import { TransactionsFilter } from '../../../../@core/domain/models/transactions.model';
import { SubscriptionModelInterface } from '../../../../@core/domain/interfaces/subscription-model.interface';
import _ from 'lodash';

@Component({
  selector: 'app-subscription-model-selector',
  templateUrl: './subscription-model-selector.component.html',
  styleUrls: ['./subscription-model-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SubscriptionModelSelectorComponent,
      multi: true,
    },
  ],
})
export class SubscriptionModelSelectorComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input() hasMultiple = true;
  @Input() resetSelection = false;
  @Input() replaySearch = false;
  @Input() filter!: TransactionsFilter;

  @ViewChild('modelSelection') listTemplate!: TemplateRef<any>;

  isEveryModelSelected = false;
  subscriptionModels: SubscriptionModelInterface[] = [];
  selectedModels: SubscriptionModelInterface[] = [];
  preSelectedModels: SubscriptionModelInterface[] = [];
  dialogRef!: NbDialogRef<any>;

  private subscriptionModelSubscription$;

  constructor(private subscriptionService: SubscriptionService, private dialog: NbDialogService) {
    let xArray: SubscriptionModelInterface[] = [];
    const testArray: SubscriptionModelInterface[] = [];
    this.subscriptionModelSubscription$ = this.subscriptionService.getSubscriptionModels().subscribe((data) => {
      xArray = data;
      // for (let i = 0; i <= 600; i++) {
      //   testArray.push({ id: i, name: i + 'x', reference: '---', selected: false });
      // }
      this.subscriptionModels = xArray.concat(testArray);
    });
  }

  get selectedModel(): SubscriptionModelInterface {
    return this.selectedModels[0];
  }

  set selectedModel(model: SubscriptionModelInterface) {
    this.selectedModels = [model];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['resetSelection'] && changes['resetSelection'].currentValue === true) {
      this.selectedModels = [];
      this.isEveryModelSelected = false;
      this.subscriptionModels.forEach((m) => {
        m.selected = false;
      });
    }
    if (changes && changes['replaySearch'] && changes['replaySearch'].currentValue === true) {
      if (this.filter && this.filter.subscriptionModels) {
        this.subscriptionModels = [];
        this.filter.subscriptionModels.forEach((m) => {
          this.subscriptionModels.push({
            id: Number(m.id),
            name: m.name,
            reference: m.reference,
            selected: !!m.selected,
          });
        });
        this.isEveryModelSelected = !_.find(this.subscriptionModels, { selected: false });
      }
    }
  }

  onChange(something: any) {}

  registerOnTouched: any = (value: any) => {};

  confirm(): void {
    this.selectedModels = [];
    this.subscriptionModels.forEach((m) => {
      if (m.selected) {
        this.selectedModels.push(m);
      }
    });
    this.onChange(this.selectedModels);
    this.dialogRef.close();
  }

  discard(): void {
    this.selectedModels = this.preSelectedModels;
    this.dialogRef.close();
  }

  registerOnChange(fn: any): void {
    this.onChange = (value: any) => {
      fn(value);
    };
  }

  toggleModels(): void {
    this.dialogRef = this.dialog.open(this.listTemplate, { hasBackdrop: true });
    this.preSelectedModels = this.selectedModels ? this.selectedModels.slice(0) : [];
  }

  toggleSelectAll(): void {
    this.selectedModels = [];
    if (this.isEveryModelSelected) {
      this.subscriptionModels.forEach((m) => {
        m.selected = true;
      });
    } else {
      this.subscriptionModels.forEach((m) => {
        m.selected = false;
      });
    }
  }

  writeValue(value: SubscriptionModelInterface | SubscriptionModelInterface[]): void {
    this.selectedModels = [];
    this.subscriptionModels.forEach((m) => {
      if (m.selected) {
        this.selectedModels.push(m);
      }
    });
    if (!value) {
      return;
    }

    // if (value instanceof Array) {
    //   this.selectedModels = value;
    // } else {
    //   if (!value.id) {
    //     return;
    //   }
    //   if (this.subscriptionModels) {
    //     const model = this.subscriptionModels.find((model) => model.id === value.id);
    //     if (model) {
    //       this.selectedModel = model;
    //     }
    //   } else {
    //     this.selectedModel = value;
    //   }
    // }
  }

  storeSubscriptionsChoice($event: boolean, subscriptionModel: SubscriptionModelInterface) {
    subscriptionModel.selected = $event;
    if (!$event) {
      this.isEveryModelSelected = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptionModelSubscription$.unsubscribe();
  }
}
