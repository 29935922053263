import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { slideInOutAnimation } from '../../@shared/animation/animation';

@Component({
  selector: 'app-profile-mobile',
  templateUrl: './profile-mobile.component.html',
  styleUrls: ['./profile-mobile.component.scss'],
  animations: slideInOutAnimation,
})
export class ProfileMobileComponent implements OnInit {
  showComponent = true;

  constructor(protected profileMobileDialogRef: NbDialogRef<ProfileMobileComponent>) {}

  ngOnInit(): void {
    this.showComponent = true;
  }
}
