import { FullStatus } from './facility.interface';

export interface Occupation {
  occupationRatios: OccupationRatio[];
  reservationOccupationRatios: ReservationOccupationRatio[];
}

export interface OccupationRatio {
  type: OccupationType;
  occupation: number;
  capacity: number;
  ratio: number;
  editMode: OccupationEditMode;
  adjustable: boolean;
  fullStatus: FullStatus;
}

export interface ReservationOccupationRatio {
  type: OccupationType;
  occupation: number;
  allocation: number;
  ratio: number;
  adjustable: boolean;
  fullStatus: FullStatus;
}

export interface OccupationType {
  id: OccupationType2;
  name: string;
}

export interface OccupationPerHour {
  hour: number;
  occupationRatio: OccupationRatio;
}

export enum OccupationEditMode {
  ChangeOccupiedSpaces = 0,
  ChangeFreeSpaces = 1,
}

export enum OccupationType2 {
  Total = 0,
  Subscriber = 1,
  Transient = 2,
  Custom = 3,
}
