import { FilterSelected, FilterSet } from '../../../@core/domain/interfaces/definition.interface';
import * as dayjs from 'dayjs';
import { Dayjs } from 'dayjs';

export enum RangeSelection {
  Day = 1,
  Week = 2,
  Month = 3,
  Custom = 999,
}

export class NotificationFilterSet implements FilterSet {
  from: Dayjs;
  to: Dayjs;
  filtersSelected: FilterSelected[] = [];

  private currentDate = dayjs();

  constructor() {
    this.from = dayjs().subtract(29, 'day');
    this.to = dayjs().subtract(1, 'day');
    this.filtersSelected = [];
  }

  getDaysFrom(): number {
    return Math.round((new Date().getTime() - dayjs(this.from).toDate().getTime()) / (1000 * 3600 * 24));
  }

  getDaysTo(): number {
    return Math.round((new Date().getTime() - dayjs(this.to).toDate().getTime()) / (1000 * 3600 * 24));
  }

  getRangeSelection() {
    const now = new Date();
    const filterDate = dayjs(this.from).toDate();
    const days = Math.round((now.getTime() - filterDate.getTime()) / (1000 * 3600 * 24));

    if (days === 0 || days === 1) {
      return RangeSelection.Day.toString();
    } else if (days === 7) {
      return RangeSelection.Week.toString();
    } else if (days >= 28) {
      return RangeSelection.Month.toString();
    } else {
      return RangeSelection.Custom.toString();
    }
  }

  setRangeFromNumber(value: number) {
    if (value > 29) {
      value = 29;
    }
    this.from = this.currentDate.subtract(value, 'day');
    this.to = this.currentDate;
  }

  setFromTo(from: Dayjs, to: Dayjs) {
    this.from = from;
    this.to = to;
  }

  setData(data: FilterSet) {
    this.from = data.from;
    this.to = data.to;
    this.filtersSelected = data.filtersSelected;
  }
}
