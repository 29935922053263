import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnonymousPassageMobileComponent } from './anonymous-passage-mobile/anonymous-passage-mobile.component';
import { ControlledPassageMobileComponent } from './controlled-passage-mobile/controlled-passage-mobile.component';
import { FacilitySelectMobileComponent } from './facility-select-mobile/facility-select-mobile.component';
import { GlobalSearchMobileComponent } from './global-search-mobile/global-search-mobile.component';
import { GlobalSearchResultMobileComponent } from './global-search-result-mobile/global-search-result-mobile.component';
import { HeaderNavMobileComponent } from './header-nav-mobile/header-nav-mobile.component';
import { LoopAccessModeMobileComponent } from './loop-access-mode-mobile/loop-access-mode-mobile.component';
import { LostTicketMobileComponent } from './lost-ticket-mobile/lost-ticket-mobile.component';
import { MainMenuMobileComponent } from './main-menu-mobile/main-menu-mobile.component';
import { NotificationsMobileComponent } from './notifications-mobile/notifications-mobile.component';
import { PageHeaderMobileComponent } from './page-header-mobile/page-header-mobile.component';
import { ProfileMenuMobileComponent } from './profile-menu-mobile/profile-menu-mobile.component';
import { ProfileMobileComponent } from './profile-mobile/profile-mobile.component';
import { TerminalActionsMobileComponent } from './terminal-actions-mobile/terminal-actions-mobile.component';
import { TerminalScreenMobileComponent } from './terminal-screen-mobile/terminal-screen-mobile.component';
import { TerminalEventsMobileComponent } from './terminal-events-mobile/terminal-events-mobile.component';
import { TerminalSelectMobileComponent } from './terminal-select-mobile/terminal-select-mobile.component';
import { TwoWayLaneMobileComponent } from './two-way-lane-mobile/two-way-lane-mobile.component';
import { SharedModule } from '../@shared/shared.module';
import { MobileContainerComponent } from './mobile-container/mobile-container.component';
import { ParkBaseModule } from '../@parkbase/park-base.module';
import { ThemeModule } from '../@theme/theme.module';
import { SettingsMenuMobileComponent } from './settings-menu-mobile/settings-menu-mobile.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from '../app.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TerminalOverviewMobileComponent } from './terminal-overview-mobile/terminal-overview-mobile.component';

@NgModule({
  declarations: [
    AnonymousPassageMobileComponent,
    ControlledPassageMobileComponent,
    FacilitySelectMobileComponent,
    GlobalSearchMobileComponent,
    GlobalSearchResultMobileComponent,
    HeaderNavMobileComponent,
    LoopAccessModeMobileComponent,
    LostTicketMobileComponent,
    MainMenuMobileComponent,
    NotificationsMobileComponent,
    PageHeaderMobileComponent,
    ProfileMenuMobileComponent,
    ProfileMobileComponent,
    TerminalActionsMobileComponent,
    TerminalScreenMobileComponent,
    TerminalEventsMobileComponent,
    TerminalSelectMobileComponent,
    TwoWayLaneMobileComponent,
    MobileContainerComponent,
    SettingsMenuMobileComponent,
    TerminalOverviewMobileComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ThemeModule,
    SharedModule,
    ParkBaseModule,
    TranslateModule.forChild({
      defaultLanguage: 'en-US',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [
    AnonymousPassageMobileComponent,
    ControlledPassageMobileComponent,
    FacilitySelectMobileComponent,
    GlobalSearchMobileComponent,
    GlobalSearchResultMobileComponent,
    HeaderNavMobileComponent,
    LoopAccessModeMobileComponent,
    LostTicketMobileComponent,
    MainMenuMobileComponent,
    NotificationsMobileComponent,
    PageHeaderMobileComponent,
    ProfileMenuMobileComponent,
    ProfileMobileComponent,
    TerminalActionsMobileComponent,
    TerminalScreenMobileComponent,
    TerminalEventsMobileComponent,
    TerminalSelectMobileComponent,
    TwoWayLaneMobileComponent,
  ],
})
export class MobileModule {}
