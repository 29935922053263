<form [formGroup]='twoWayLaneForm' [ngClass]='{awaitTerminalActionComplete: awaitTerminalActionComplete}'>
  <div>
    <h5>{{ 'TWO_WAY_LANE.TITLE' | translate }}</h5>
    <app-terminal-title [hidden]='hideElement' [showHeading]='false'
                        [show]='showTerminalTitle' [terminalOverview]='terminal'>
    </app-terminal-title>
    <p class='description'>
      {{ 'TWO_WAY_LANE.DESCRIPTION' | translate }}
    </p>
    <div *ngIf='terminalActionError'>
      <app-info-alert
        [showIcon]='true'
        infoText='{{ terminalActionErrorMessage }}'
        type='warning'>
      </app-info-alert>
    </div>
    <div *ngIf='terminalActionSuccess'>
      <app-info-alert
        [showIcon]='true'
        infoText='{{ terminalActionSuccessMessage }}'
        type='success'>
      </app-info-alert>
    </div>
    <div *ngIf='terminal && terminal.twoWayLane' class='current-status'>
      <span
        *ngIf='terminal && terminal.twoWayLane'>{{ 'TWO_WAY_LANE.STATUS' | translate }} {{terminal.twoWayLane.name}}</span>
      <app-cell *ngIf='terminal && terminal.twoWayLane.entryTerminal' [showNav]='false'
                [terminalOverview]='entryTerminal'></app-cell>
      <app-cell *ngIf='terminal && terminal.twoWayLane.exitTerminal' [showNav]='false'
                [terminalOverview]='exitTerminal'></app-cell>
    </div>
    <div class='form-floating'>
      <select class='form-select' formControlName='newLaneStatus'
              id='floatingSelect'>
        <option value=''>---</option>
        <option *ngFor='let option of newStatusDefinitions' value='{{option.id}}'>{{option.name}}</option>
      </select>
      <label for='floatingSelect'>{{ 'TWO_WAY_LANE.SELECT_NEW_STATUS' | translate }}</label>
    </div>
  </div>
  <div class='form-actions'>
    <button
      (click)='changeTwoWayLane()'
      [disabled]='twoWayLaneForm.pristine'
      fullWidth
      nbButton
      shape='semi-round'
      size='large'
      status='success'
    >{{ 'TWO_WAY_LANE.CHANGE_STATUS' | translate }}</button>
  </div>
</form>
