<div>
  <h5>{{ "LOST_TICKET.TITLE" | translate }}</h5>
  <app-terminal-title [hidden]='hideElement' [showHeading]='false'
                      [show]='showTerminalTitle' [terminalOverview]='terminal'>
  </app-terminal-title>
  <p class='description'>
    {{ "LOST_TICKET.DESCRIPTION" | translate }}
  </p>
  <div class='current-status'>
    <span>{{ "LOST_TICKET.STATUS" | translate }}</span>
    <table class='data-table'>
      <tr *ngIf='feeSchedule'>
        <th>{{ "LOST_TICKET.AMOUNT_PROFILE" | translate }}</th>
        <td>{{feeSchedule.name}}</td>
      </tr>
      <tr *ngIf='payDeviceProfileForLostTicket'>
        <th>{{ "LOST_TICKET.PAY_STATION_PROFILE" | translate }}</th>
        <td>{{payDeviceProfileForLostTicket.name}}</td>
      </tr>
      <tr *ngIf='payDeviceProfileForLostTicket && payDeviceProfileForLostTicket.lostTicketMode'>
        <th>{{ "LOST_TICKET.PAY_STATION_MODE" | translate }}</th>
        <td>{{payDeviceProfileForLostTicket.lostTicketMode.name}}</td>
      </tr>
    </table>
  </div>
  <form #lostTicketForm='ngForm' (ngSubmit)='clickButton()' *ngIf='!terminalActionComplete'>
    <div class='form-floating'>
      <select
        (ngModelChange)='onSelectRateMethod($event)'
        [(ngModel)]='rateMethod'
        class='form-select'
        id='rateMethodSelect'
        name='rateMethodSelect'
      >
        <option *ngFor='let t of lostTicketFeeTypes' value='{{t.id}}'>{{t.name}}</option>
      </select>
      <label for='rateMethodSelect'>{{
        "LOST_TICKET.RATE_STATUS_OPTIONS_LABEL" | translate
        }}*</label>
    </div>
    <div *ngIf='rateMethod.toString() === "1"' class='form-floating'>
      <input [options]='inputOptions' class='form-control'
             currencyMask
             fieldSize='large'
             fullWidth
             id='amountInput'
             name='amountInput'
             nbInput
             ngModel
             required shape='semi-round'>
      <label for='amountInput'>{{
        "LOST_TICKET.VALUE_OPTIONS_LABEL" | translate
        }}* [n,nn]</label>
      <nb-icon (click)="clearInput('amountInput')" *ngIf="lostTicketForm.controls['amountInput']?.value"
               class='clear-button' icon='close-outline' pack='eva'></nb-icon>
    </div>
    <div *ngIf='rateMethod.toString() === "2"' class='form-floating'>
      <input [dropSpecialCharacters]='false' [showMaskTyped]='true' class='form-control' fieldSize='large'
             fullWidth
             id='durationInput'
             mask='d.Hh:m0'
             name='durationInput'
             nbInput
             ngModel
             required shape='semi-round'>
      <label for='durationInput'>{{
        "LOST_TICKET.VALUE_OPTIONS_LABEL" | translate
        }}* [d.hh:mm]</label>
      <nb-icon (click)="clearInput('durationInput')" *ngIf="lostTicketForm.controls['durationInput']?.value"
               class='clear-button' icon='close-outline' pack='eva'></nb-icon>
    </div>
    <div *ngIf='rateMethod.toString() === "3"' class='form-floating'>
      <input [dropSpecialCharacters]='false' [showMaskTyped]='true' class='form-control' fieldSize='large'
             fullWidth
             id='entryTimeInput'
             mask='Hh:m0'
             name='entryTimeInput'
             nbInput
             ngModel required
             shape='semi-round'>
      <label for='entryTimeInput'>{{
        "LOST_TICKET.VALUE_OPTIONS_LABEL" | translate
        }}* [hh:mm]</label>
      <nb-icon (click)="clearInput('entryTimeInput')" *ngIf="lostTicketForm.controls['entryTimeInput']?.value"
               class='clear-button' icon='close-outline' pack='eva'></nb-icon>
    </div>
    <div class='form-actions'>
      <button
        [disabled]='!lostTicketForm.valid'
        fullWidth
        nbButton
        shape='semi-round'
        size='large'
        status='success'
        type='submit'
      >
        {{ "LOST_TICKET.CHECK_ISSUE_TICKET" | translate }}
      </button>
    </div>
  </form>
  <div *ngIf='error'>
    <app-info-alert
      [showIcon]='true'
      infoText='{{ error.message }}'
      type='warning'>
    </app-info-alert>
    <button
      (click)='resetForm()'
      fullWidth
      nbButton
      shape='semi-round'
      size='large'
      status='success'
      type='button'
    >
      {{ "LOST_TICKET.ISSUE_ANOTHER_TICKET" | translate }}
    </button>
  </div>
  <div *ngIf='terminalActionComplete && finalTerminalActionComplete'>
    <div *ngIf='imageToShow$ | async as image; else placeholder' class='terminal-screen-container'>
      <img [src]='image' alt='terminal-screen' class='terminal-screen'>
    </div>
    <ng-template #placeholder>
      <div [nbSpinner]='loadingImage' class='terminal-screen-container'>
        <h6>{{ "LOST_TICKET.ISSUE_TICKET_SUCCESS" | translate }}</h6>
        <p>{{ticketIssued.message}}</p>
      </div>
    </ng-template>
    <button
      (click)='resetForm()'
      fullWidth
      nbButton
      shape='semi-round'
      size='large'
      status='success'
      type='button'
    >
      {{ "LOST_TICKET.ISSUE_ANOTHER_TICKET" | translate }}
    </button>
  </div>
</div>
