import { Component, Input, OnInit } from '@angular/core';
import { FacilitiesApiService } from '../../../../@core/api/facilities-api.service';
import { Observable } from 'rxjs';
import { Occupation } from '../../../../@core/domain/interfaces/occupation.interface';
import { FacilityStatusIcon, StatusService } from '../../../../@shared/services/status.service';

@Component({
  selector: 'app-capacity-count',
  templateUrl: './capacity-count.component.html',
  styleUrls: ['./capacity-count.component.scss'],
})
export class CapacityCountComponent implements OnInit {
  @Input()
  facilityId!: number;

  occupation$!: Observable<Occupation>;

  facilityStatusIcon!: FacilityStatusIcon;

  constructor(public statusService: StatusService, private facilitiesApiService: FacilitiesApiService) {}

  ngOnInit(): void {
    if (!this.facilityId) {
      return;
    }

    this.occupation$ = this.facilitiesApiService.occupationPerHourCurrent;
    this.facilitiesApiService.loadTotals(this.facilityId);
  }
}
