import { EnvironmentConfig } from './environment.config';

class Environment extends EnvironmentConfig {
  public production: boolean;
  public defaultLanguage;
  public supportedLanguages;
  public apiTimeout;
  public refreshInterval;
  public trackingId;

  constructor() {
    super();
    this.production = true;
    this.defaultLanguage = 'en-US';
    this.supportedLanguages = ['nl-NL', 'en-US', 'en-GB'];
    this.apiTimeout = 0;
    this.refreshInterval = 10000;
    this.trackingId = 'G-9L73S3P1Q1';
  }
}

export const environment = new Environment();
