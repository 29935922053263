<div *ngIf='showComponent' @slideIn @slideOut>
  <app-header-nav-mobile [componentRef]='terminalActionsMobileDialogRef'
                         [handleNavigate]='true' title="{{ 'MOBILE.TERMINAL_EVENTS' | translate }}">
  </app-header-nav-mobile>
  <div class='terminal-actions-mobile-content'>
    <app-cell [showNav]='false' [terminalOverview]='terminalOverview'></app-cell>
  </div>
  <div class='terminal-actions-mobile-forms'>
    <app-terminal-events *ngIf='terminalOverview' [showTerminalTitle]=false
                         [terminalId]='terminalOverview.id'></app-terminal-events>
  </div>
</div>


