import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import {
  Terminal,
  TerminalAction,
  TerminalActionType,
  TerminalStatus2,
} from 'src/app/@core/domain/interfaces/terminal.interface';
import { StatusPageService } from '../../../services/status-page.service';
import { NbDialogRef, NbLayoutRulerService } from '@nebular/theme';
import { TerminalsApiService } from '../../../../../@core/api/terminals-api.service';
import { LogService } from '../../../../../@core';
import { AnonymousPassageComponent } from '../../../../../@shared/components/terminal-action-forms/anonymous-passage/anonymous-passage.component';
import { ControlledPassageComponent } from '../../../../../@shared/components/terminal-action-forms/controlled-passage/controlled-passage.component';
import { LoopAccessModeComponent } from '../../../../../@shared/components/terminal-action-forms/loop-access-mode/loop-access-mode.component';
import { TerminalScreenComponent } from '../../../../../@shared/components/terminal-action-forms/terminal-screen/terminal-screen.component';
import { LostTicketComponent } from '../../../../../@shared/components/terminal-action-forms/lost-ticket/lost-ticket.component';
import { TwoWayLaneComponent } from '../../../../../@shared/components/terminal-action-forms/two-way-lane/two-way-lane.component';
import { Subscription } from 'rxjs';
import { TerminalOverview } from '../../../../../@core/domain/interfaces/terminal-response.interface';

@Component({
  selector: 'app-terminal-actions-dialog',
  templateUrl: './terminal-actions-dialog.component.html',
  styleUrls: ['./terminal-actions-dialog.component.scss'],
})
export class TerminalActionsDialogComponent implements OnInit, OnDestroy {
  @Input()
  terminalOverview!: TerminalOverview;

  @Input()
  terminal!: Terminal;

  terminalId!: number;
  useMobileComponents = false;
  showContextualActions = true;
  hideElement = false;
  showTerminalTitle = true;
  statusActions: TerminalAction[] = [];
  formActions: TerminalAction[] = [];
  activeComponent!: any;
  currentAction!: TerminalAction;
  disableToggleClick = false;
  disableToggleClickSubscription!: Subscription;
  refresh = false;
  terminalSubscription$!: Subscription;

  constructor(
    private statusPageService: StatusPageService,
    private terminalsApiService: TerminalsApiService,
    private layoutRuleService: NbLayoutRulerService,
    private logger: LogService,
    protected ref: NbDialogRef<TerminalActionsDialogComponent>
  ) {}

  @HostListener('window:resize', [])
  onResize(): void {
    this.getWindowSize();
  }

  ngOnInit(): void {
    if (this.terminal) {
      this.terminalId = this.terminal.id;
    } else if (this.terminalOverview) {
      this.terminalId = this.terminalOverview.id;
    }
    this.statusPageService.triggerRefreshNotifications(false);
    this.currentAction = { name: 'ShowTerminalEvents', type: TerminalActionType.ShowTerminalEvents };
    this.fetchTerminal();
    this.getWindowSize();

    this.disableToggleClickSubscription = this.statusPageService.preventClick.subscribe((result) => {
      this.disableToggleClick = result;
      if (result) {
        this.fetchTerminal();
      }
    });

    this.statusPageService.refreshTerminalSummary.subscribe((status) => {
      if (status) {
        this.fetchTerminal();
      }
    });
  }

  fetchTerminal() {
    this.terminalsApiService.loadTerminalById(this.terminalId).subscribe((data) => {
      if (data) {
        this.terminal = data;
        if (this.terminalOverview) {
          this.terminalOverview.status = data.status;
        }
        this.setFormActions();
        this.setStatusActions();
      }
    });
  }

  getWindowSize() {
    this.layoutRuleService.getDimensions().subscribe((data) => {
      this.useMobileComponents = data.clientWidth <= 768;
      if (this.useMobileComponents) {
        this.ref.close();
      }
    });
  }

  /**
   * In some cases there is no use showing the button because the terminal is already in that status
   * @param action
   * @param terminal
   */
  checkShowTerminalAction(action: TerminalAction, terminal: Terminal): boolean {
    const congestionMode =
      action.type === TerminalActionType.CongestionMode && terminal.status.id === TerminalStatus2.CongestionMode;
    const disabled = action.type === TerminalActionType.Disable && terminal.status.id === TerminalStatus2.Disabled;
    return !(congestionMode || disabled);
  }

  openTerminalAction(action: TerminalAction) {
    this.currentAction = action;
    switch (action.type) {
      case 27:
      case 28:
        this.activeComponent = AnonymousPassageComponent;
        this.logger.info('Open dialog grantAnonymousPassage', []);
        break;
      case 29:
        this.activeComponent = ControlledPassageComponent;
        this.logger.info('Open dialog grantControlledPassage', []);
        break;
      case 31:
        this.activeComponent = TerminalScreenComponent;
        this.logger.info('Open dialog showTerminalScreen', []);
        break;
      case 32:
        this.activeComponent = LoopAccessModeComponent;
        this.logger.info('Open dialog loopAccessMode', []);
        break;
      case 33:
        this.activeComponent = TwoWayLaneComponent;
        this.logger.info('Open dialog TwoWayLaneComponent', []);
        break;
      case 34:
        this.activeComponent = LostTicketComponent;
        this.logger.info('Open dialog LostTicketComponent', []);
        break;
    }
  }

  dismiss() {
    this.disableToggleClickSubscription.unsubscribe();
    this.statusPageService.triggerRefreshNotifications(true);
    this.ref.close();
  }

  ngOnDestroy(): void {
    this.disableToggleClickSubscription.unsubscribe();
  }

  onActionCompleted($event: boolean) {
    if ($event) {
      this.refresh = false;
      this.terminalSubscription$ = this.terminalsApiService.loadTerminalById(this.terminal.id).subscribe((data) => {
        this.terminal = data;
        this.refresh = true;
        this.setStatusActions();
      });
    }
  }

  onChangeComponent($event: any) {
    switch ($event) {
      case 'grantAnonymousPassage':
        this.openTerminalAction({ type: 27, name: 'grantAnonymousPassage' });
        break;
      case 'grantControlledPassage':
        this.openTerminalAction({ type: 29, name: 'grantControlledPassage' });
        break;
    }
  }

  private setStatusActions() {
    if (this.terminal.availableActions) {
      this.statusActions = [];
      this.terminal.availableActions.forEach((action: TerminalAction) => {
        switch (action.type) {
          case TerminalActionType.Enable:
          case TerminalActionType.Disable:
          case TerminalActionType.DisableAndOpen:
          case TerminalActionType.DisableAndClose:
          case TerminalActionType.CloseBarrier:
          case TerminalActionType.EnableTerminalWithOpen:
          case TerminalActionType.CongestionMode:
            this.statusActions.push(action);
            break;
        }
      });
    }
  }

  private setFormActions() {
    if (this.terminal.availableActions) {
      this.formActions = [];
      this.terminal.availableActions.forEach((action: TerminalAction) => {
        switch (action.type) {
          case TerminalActionType.GrantPassageToAnonymous:
          case TerminalActionType.GrantPassageToAnonymousWithReason:
          case TerminalActionType.GrantPassageToIdentification:
          case TerminalActionType.ScreenCapture:
          case TerminalActionType.LoopAccessMode:
          case TerminalActionType.TwoWayLaneStatus:
          case TerminalActionType.LostTicket:
          case TerminalActionType.LostTicketWithOperatorOverride:
            this.formActions.push(action);
            break;
        }
      });
    }
  }
}
