<div *ngIf='!useMobileComponents' class='terminal-action-dialog'>
  <nb-card [nbSpinner]='disableToggleClick'>
    <nb-card-body>
      <div class='terminal-actions'>
        <h4>Terminal
          <nb-icon (click)='openTerminalAction({type: 9999, name: "ShowTerminalEvents"})'
                   class='icon-terminal-events' icon='info-outline'></nb-icon>
        </h4>
        <app-terminal-title
          [hidden]='hideElement'
          [showHeading]='false' [show]='showTerminalTitle'
          [terminalOverview]='terminal'></app-terminal-title>
        <div class='form-action-buttons'>
          <button (click)='openTerminalAction(action)' *ngFor='let action of formActions'
                  class='menu-button'
                  fullWidth nbButton>{{action.name}}
            <nb-icon icon='arrow-ios-forward-outline'></nb-icon>
          </button>
        </div>
        <div *ngIf='showContextualActions' class='status-actions'>
          <p>
            {{ 'ACTIONS.TITLE' | translate }}
          </p>
          <div *ngFor='let action of statusActions'>
            <app-terminal-action *ngIf='checkShowTerminalAction(action, terminal)'
                                 [action]='action'
                                 [disableToggleClick]='disableToggleClick'
                                 [terminal]='terminal'>
            </app-terminal-action>

          </div>
          <div *ngIf='statusActions.length === 0'>
            <span><i>{{ 'ACTIONS.UNAVAILABLE' | translate }}</i></span>
          </div>
        </div>
      </div>
      <div class='terminal-forms'>
        <div class='terminal-forms-header'>
          <nb-icon
            (click)='dismiss()'
            class='close-icon'
            icon='close'
            nbPrefix
            pack='eva'
            status='control'
          ></nb-icon>
        </div>
        <div *ngIf='terminalId' class='terminal-forms-container'>
          <app-anonymous-passage (changeComponent)='onChangeComponent($event)'
                                 *ngIf='currentAction.type === 27 || currentAction.type === 28' [action]='currentAction'
                                 [terminalId]='terminalId'>
          </app-anonymous-passage>
          <app-controlled-passage (changeComponent)='onChangeComponent($event)'
                                  *ngIf='currentAction.type === 29' [action]='currentAction'
                                  [terminalId]='terminalId'>
          </app-controlled-passage>
          <app-terminal-screen *ngIf='currentAction.type === 31'
                               [terminalId]='terminalId'></app-terminal-screen>
          <app-loop-access-mode *ngIf='currentAction.type === 32'
                                [terminalId]='terminalId'></app-loop-access-mode>
          <app-lost-ticket *ngIf='currentAction.type === 34 || currentAction.type === 35'
                           [terminalId]='terminalId'></app-lost-ticket>
          <app-two-way-lane *ngIf='currentAction.type === 33' [terminalId]='terminalId'></app-two-way-lane>
          <app-terminal-events *ngIf='currentAction.type === 9999'
                               [terminalId]='terminalId'></app-terminal-events>
        </div>
      </div>
    </nb-card-body>
  </nb-card>
</div>
