import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import { Division } from 'src/app/@core/domain/interfaces/division.interface';
import { environment } from 'src/environments/environment';
import { FacilityName } from 'src/app/@core/domain/interfaces/facility.interface';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { StorageService } from 'ngx-webstorage-service';
import { User } from 'src/app/@core/domain/models/user.model';
import { Definition, DefinitionList } from 'src/app/@core/domain/interfaces/definition.interface';
import { SearchResultItem, SearchResultType } from '../../@core/domain/interfaces/search-result.interface';
import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { NotificationFilterSet } from '../components/notifications/notificationFilterSet';

export interface FacilitiesChoice {
  selected: boolean;
  facility: FacilityName;
}

const ENCRYPT = false;

const cookieOptions: CookieOptions = {
  expires: 312,
  path: '/',
  secure: true,
  sameSite: 'Lax',
};

export const encryptData = (data: string, salt: string) => {
  if (ENCRYPT) {
    CryptoJS.AES.encrypt(JSON.stringify(data), salt).toString();
  } else {
    return data;
  }
  return '';
};

export const decryptData = (ciphertext: string, salt: string) => {
  if (ENCRYPT) {
    const bytes = CryptoJS.AES.decrypt(ciphertext, salt);
    try {
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (err) {
      return null;
    }
  } else {
    return ciphertext;
  }
};

const SALT = '6f0ac317-93c9-4136-bd85-8a788147bb6b-1086861e-4130-40d1-a345-362f46809951';

export const PARKBASE_SERVICE_STORAGE = new InjectionToken<StorageService>('PARKBASE_SERVICE_STORAGE');

export interface NotificationTypeFilter {
  id: number;
  name: string;
  checked: boolean;
}

/**
 * Stores static information like definition lists, user data and user preferences in session storage
 * After being loaded no API calls for this information needed
 * Can be switched to use local storage in AppModule
 *
 */
@Injectable({
  providedIn: 'root',
})
export class ParkbaseStorageService {
  defaultFromDate = dayjs().set('hour', 23).set('minute', 59).set('second', 59).subtract(29, 'day');
  defaultToDate = dayjs();
  private userKey!: string;
  private firstUse = true;

  constructor(
    @Inject(PARKBASE_SERVICE_STORAGE) private storage: StorageService,
    private themeService: NbThemeService,
    private cookieService: CookieService
  ) {
    const STORAGE_KEY = 'PBStoreVersion';
    const appVersion = environment.version;
    if (!this.storage.has(STORAGE_KEY)) {
      this.storage.clear();
      this.storage.set(STORAGE_KEY, encryptData(appVersion, SALT));
    } else {
      const currentVersion = decryptData(this.storage.get(STORAGE_KEY), SALT);
      if (currentVersion !== appVersion) {
        this.storage.clear();
        this.storage.set(STORAGE_KEY, encryptData(appVersion, SALT));
      }
    }
  }

  /**
   * Get API version for inclusion in reporting and logs
   */
  retrieveApiVersion(): any {
    const STORAGE_KEY = 'PBApiVersion';
    const storeVersion = decryptData(this.storage.get('PBStoreVersion'), SALT);
    return storeVersion + '-' + decryptData(this.storage.get(STORAGE_KEY), SALT);
  }

  storeApiVersion(version: string): void {
    const STORAGE_KEY = 'PBApiVersion';
    this.storage.remove(STORAGE_KEY);
    this.storage.set(STORAGE_KEY, encryptData(version, SALT));
  }

  /**
   * Layout of the status page:
   * 0: n facilities <= 3
   * 1: n facilities > 3 && < 15 default
   * 2: n facilities >= 15
   */
  retrieveStatusPageLayout(): any {
    if (this.userKey) {
      const userStore = this.storage.get(this.userKey);
      return decryptData(userStore['PBFacilityLayout'], SALT);
    }
  }

  storeStatusPageLayout(layout: any): void {
    if (this.userKey) {
      const userStore = this.storage.get(this.userKey);
      userStore['PBFacilityLayout'] = encryptData(layout.toString(), SALT);
      this.storage.set(this.userKey, userStore);
    }
  }

  /**
   * Format of date time in alarm notifications
   * 1: Relative: 15 minutes ago, 12 days ago
   * 2: Exact: 12:03:45 10-01-2022
   */
  storeDateformatChoice(choice: string) {
    if (this.userKey) {
      const userStore = this.storage.get(this.userKey);
      userStore['PBDateformatChoice'] = encryptData(choice, SALT);
      this.storage.set(this.userKey, userStore);
    }
  }

  retrieveDateformatChoice() {
    if (this.userKey) {
      const userStore = this.storage.get(this.userKey);
      return decryptData(userStore['PBDateformatChoice'], SALT);
    }
  }

  disableFirstUse() {
    this.cookieService.delete('firstUse');
    this.cookieService.set('firstUse', 'false', cookieOptions);
    this.firstUse = false;
    return this.firstUse;
  }

  enableFirstUse() {
    this.cookieService.delete('firstUse');
    this.cookieService.set('firstUse', 'true', cookieOptions);
    this.firstUse = true;
    return this.firstUse;
  }

  handleFirstUseCookie(): boolean {
    const firstUseCookieExists = this.cookieService.check('firstUse');
    if (firstUseCookieExists) {
      return this.cookieService.get('firstUse') === 'true';
    } else {
      this.cookieService.set('firstUse', 'true', cookieOptions);
      this.firstUse = true;
      return this.firstUse;
    }
  }

  /**
   * Search history used in global search
   */
  storeRecentSearches(recentSearches: string[]): void {
    const STORAGE_KEY = 'PBRecentSearches';
    this.storage.remove(STORAGE_KEY);
    this.storage.set(STORAGE_KEY, encryptData(JSON.stringify(recentSearches), SALT));
  }

  /**
   * Search history used in global search
   */
  storeRecentSearch(searchTerm: string): void {
    const STORAGE_KEY = 'PBRecentSearches';
    let previousSearchTerms: string[] = this.retrieveRecentSearches();
    if (previousSearchTerms && previousSearchTerms.length > 0) {
      previousSearchTerms.unshift(searchTerm);
    } else {
      previousSearchTerms = [];
      previousSearchTerms.unshift(searchTerm);
    }
    previousSearchTerms = previousSearchTerms.filter((el, i, a) => i === a.indexOf(el));
    this.storage.remove(STORAGE_KEY);
    this.storage.set(STORAGE_KEY, encryptData(JSON.stringify(previousSearchTerms), SALT));
  }

  retrieveRecentSearches(): string[] {
    const STORAGE_KEY = 'PBRecentSearches';
    if (!this.storage.has(STORAGE_KEY)) {
      this.storage.set(STORAGE_KEY, encryptData(JSON.stringify([]), SALT));
    }
    const searchItems = JSON.parse(decryptData(this.storage.get(STORAGE_KEY), SALT));
    if (searchItems) {
      return searchItems;
    }
    return [];
  }

  /**
   * Store searchTerm used on terminals page for quick selection
   * @param searchTerm
   */
  storeAndReturnSearchTerm(searchTerm: string) {
    this.cookieService.set('searchTerm', searchTerm, cookieOptions);
    return this.cookieService.get('searchTerm');
  }

  retrieveSearchTerm() {
    return this.cookieService.get('searchTerm');
  }

  /**
   * The storage
   */
  getStorage(): StorageService<any> {
    return this.storage;
  }

  /** UserSessionId */
  storeUserSessionId(userProfile: User): void {
    const STORAGE_KEY = 'PBUserSessionId';
    this.userKey = userProfile.key;
    this.storage.remove(STORAGE_KEY);
    this.storage.set(STORAGE_KEY, encryptData(JSON.stringify(userProfile), SALT));
    this.setDefaultUserStore();
    // Load default theme or theme selection stored by user
    const selectedTheme = this.getTheme();
    this.themeService.changeTheme(selectedTheme);
  }

  setDefaultUserStore() {
    let userStore = this.storage.get(this.userKey);
    if (!userStore) {
      userStore = {
        PBDateformatChoice: encryptData('Relative', SALT),
        PBFacilityLayout: encryptData('Extended', SALT),
        PBTheme: encryptData('parkbaseDark', SALT),
        PBLang: encryptData('nl-NL', SALT),
      };
      this.storage.set(this.userKey, userStore);
    }
  }

  clearUserSessionId(): void {
    const STORAGE_KEY = 'PBUserSessionId';
    this.storage.remove(STORAGE_KEY);
  }

  retrieveUserSessionId(): User {
    const STORAGE_KEY = 'PBUserSessionId';
    if (!this.storage.has(STORAGE_KEY)) {
      this.storage.set(STORAGE_KEY, encryptData(JSON.stringify({}), SALT));
    }
    return JSON.parse(decryptData(this.storage.get(STORAGE_KEY), SALT));
  }

  /** Division */
  storeDivision(division: Division): void {
    const STORAGE_KEY = 'PBCurrentDivision';
    this.storage.remove(STORAGE_KEY);
    this.storage.set(STORAGE_KEY, encryptData(JSON.stringify(division), SALT));
  }

  retrieveDivision(): any {
    const STORAGE_KEY = 'PBCurrentDivision';
    const division = JSON.parse(decryptData(this.storage.get(STORAGE_KEY), SALT));
    return division.name + ' ' + division.city;
  }

  /** Facilities */
  storeFacilities(facilities: FacilityName[]): void {
    const STORAGE_KEY = 'PBFacilities';
    this.storage.remove(STORAGE_KEY);
    this.storage.set(STORAGE_KEY, encryptData(JSON.stringify(facilities), SALT));
    const choice = this.retrieveFacilitiesChoice();
    if (choice && choice.length === 0) {
      const facilitiesChoiceOptions: any[] = [];
      facilities.forEach((f) => {
        facilitiesChoiceOptions.push({ selected: true, facility: f });
      });
      this.storeFacilitiesChoice(facilitiesChoiceOptions);
    }
  }

  retrieveFacilities(): FacilityName[] {
    const STORAGE_KEY = 'PBFacilities';
    if (!this.storage.has(STORAGE_KEY)) {
      this.storage.set(STORAGE_KEY, encryptData(JSON.stringify([]), SALT));
    }
    return JSON.parse(decryptData(this.storage.get(STORAGE_KEY), SALT));
  }

  // FacilitySelection
  storeFacilitySelection(facility: FacilityName): void {
    const STORAGE_KEY = 'PBFacilitySelected';
    this.storage.set(STORAGE_KEY, encryptData(JSON.stringify(facility), SALT));
  }

  retrieveFacilitySelection(): FacilityName {
    const STORAGE_KEY = 'PBFacilitySelected';
    if (!this.storage.has(STORAGE_KEY)) {
      this.storage.set(STORAGE_KEY, encryptData(JSON.stringify({}), SALT));
    }
    return JSON.parse(decryptData(this.storage.get(STORAGE_KEY), SALT));
  }

  // Definitions
  storeDefinitionLists(data: DefinitionList[]): void {
    const STORAGE_KEY = 'PBDefinitionLists';
    this.storage.set(STORAGE_KEY, encryptData(JSON.stringify(data), SALT));
  }

  retrieveDefinitionList(type: number): Definition[] {
    const STORAGE_KEY = 'PBDefinitionLists';
    const definitionLists: DefinitionList[] = JSON.parse(decryptData(this.storage.get(STORAGE_KEY), SALT));
    return definitionLists[type].definitions;
  }

  // User NotificationFilterSet
  retrieveFilterSet(): NotificationFilterSet {
    const STORAGE_KEY = 'PBNotificationTypeFilterSet';
    if (this.storage.has(STORAGE_KEY)) {
      const filterSet: NotificationFilterSet = new NotificationFilterSet();
      filterSet.setData(JSON.parse(decryptData(this.storage.get(STORAGE_KEY), SALT)));
      return filterSet;
    } else {
      return new NotificationFilterSet();
    }
  }

  prepareNotificationTypeFilterSet(
    filterSet: NotificationFilterSet,
    definitions?: Definition[]
  ): NotificationFilterSet {
    console.log('prepareNotificationTypeFilterSet');
    const STORAGE_KEY = 'PBNotificationTypeFilterSet';
    if (!this.storage.has(STORAGE_KEY) && definitions) {
      definitions.forEach((d) => {
        filterSet.filtersSelected.push({
          id: d.id,
          name: d.name,
          checked: false,
        });
      });
      this.storage.set(STORAGE_KEY, encryptData(JSON.stringify(filterSet), SALT));
    } else {
      const data = decryptData(this.storage.get(STORAGE_KEY), SALT);
      if (data) {
        filterSet.setData(JSON.parse(data));
      }
    }
    return filterSet;
  }

  saveNotificationTypeFilterSet(filterSet: NotificationFilterSet): void {
    console.log('saveNotificationTypeFilterSet');
    if (filterSet.filtersSelected.length === 0) {
      console.log('filtersSelected', filterSet);
      return;
    }
    const STORAGE_KEY = 'PBNotificationTypeFilterSet';
    this.storage.set(STORAGE_KEY, encryptData(JSON.stringify(filterSet), SALT));
  }

  storeNotificationLevelFilterSet(levels: number[]): any {
    const STORAGE_KEY = 'PBNotificationLevelFilterSet';
    const levelFilterSet: number[] = [];
    levels.forEach((d) => {
      levelFilterSet.push(d);
    });
    this.storage.set(STORAGE_KEY, encryptData(JSON.stringify(levelFilterSet), SALT));
    return JSON.parse(decryptData(this.storage.get(STORAGE_KEY), SALT));
  }

  retrieveNotificationLevelFilterSet(): any {
    const STORAGE_KEY = 'PBNotificationLevelFilterSet';
    const f = decryptData(this.storage.get(STORAGE_KEY), SALT);
    if (f === undefined) {
      return this.storeNotificationLevelFilterSet([]);
    }
    return JSON.parse(f);
  }

  /** Set global styling theme */
  setTheme(theme: string): void {
    if (this.userKey) {
      const userStore = this.storage.get(this.userKey);
      userStore['PBTheme'] = encryptData(theme, SALT);
      this.storage.set(this.userKey, userStore);
    }
  }

  /** Get global styling theme */
  getTheme(): string {
    if (this.userKey) {
      const userStore = this.storage.get(this.userKey);
      return decryptData(userStore['PBTheme'], SALT);
    } else {
      return 'parkbaseDark';
    }
  }

  /**
   * Set language code
   */
  setLanguage(lang: string): void {
    this.cookieService.set('lang', lang, cookieOptions);
    if (this.userKey) {
      const userStore = this.storage.get(this.userKey);
      userStore['PBLang'] = encryptData(lang, SALT);
      this.storage.set(this.userKey, userStore);
    }
  }

  /**
   * Get language code
   */
  getLanguage(): string {
    const storedUser = this.retrieveUserSessionId();
    if (storedUser.key) {
      this.userKey = storedUser.key;
    }
    if (this.userKey) {
      const userStore = this.storage.get(this.userKey);
      const x = decryptData(userStore['PBLang'], SALT);
      return x;
    }
    const lang = this.cookieService.get('lang');
    if (lang) {
      return lang;
    }
    return 'en-US';
  }

  getCurrencyFormatForLang() {
    const currentLang = this.getLanguage();
    const defaultOptions = {
      align: 'left',
      precision: 2,
      allowNegative: false,
      prefix: '',
      max: 1000,
      inputMode: CurrencyMaskInputMode.NATURAL,
    };
    const commaFormat = {
      decimal: ',',
      thousands: '.',
    };
    const decimalPointFormat = {
      decimal: '.',
      thousands: ',',
    };
    let opts = {};

    switch (currentLang) {
      case 'en-GB':
      case 'en-US':
      case 'en-AU':
        opts = { ...defaultOptions, ...decimalPointFormat };
        break;
      case 'nl-NL':
      case 'de-DE':
      case 'fr-FR':
      case 'sv-SE':
      case 'sv-FI':
        opts = { ...defaultOptions, ...commaFormat };
        break;
    }

    return opts;
  }

  /**
   * Language code as numeric string for use in Api header Ipp-Language
   */
  getLanguageForApi(): string {
    const language = this.getLanguage();
    switch (language) {
      case 'en-GB':
        return '0';
      case 'en-US':
        return '1';
      case 'en-AU':
        return '2';
      case 'nl-NL':
        return '3';
      case 'de-DE':
        return '4';
      case 'fr-FR':
        return '5';
      case 'sv-SE':
        return '6';
      case 'sv-FI':
        return '7';
      default:
        return '3';
    }
  }

  storeSearchResult(searchResult: SearchResultItem, searchResultType: SearchResultType): void {
    const STORAGE_KEY = 'PBSearchResultItem';
    this.storage.set(STORAGE_KEY, encryptData(JSON.stringify({ searchResult, searchResultType }), SALT));
  }

  retrieveSearchResult(): SearchResult {
    const STORAGE_KEY = 'PBSearchResultItem';
    if (!this.storage.has(STORAGE_KEY)) {
      this.storage.set(STORAGE_KEY, encryptData(JSON.stringify({}), SALT));
    }
    return decryptData(this.storage.get(STORAGE_KEY), SALT);
  }

  storeFacilitiesChoice(facilitiesChoice: FacilitiesChoice[]) {
    const STORAGE_KEY = 'PBFacilitiesChoice';
    this.storage.set(STORAGE_KEY, encryptData(JSON.stringify({ facilitiesChoice }), SALT));
  }

  retrieveFacilitiesChoice(): FacilitiesChoice[] {
    const STORAGE_KEY = 'PBFacilitiesChoice';
    if (!this.storage.has(STORAGE_KEY)) {
      this.storage.set(STORAGE_KEY, encryptData(JSON.stringify({ facilitiesChoice: [] }), SALT));
    }
    const choice = JSON.parse(decryptData(this.storage.get(STORAGE_KEY), SALT));
    return choice.facilitiesChoice;
  }
}

type SearchResult = {
  searchResult: SearchResult;
  searchResultType: SearchResultType;
};
