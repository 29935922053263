<div class='terminal-card__icon'>
  <nb-icon
    *ngIf="terminalTypeIcon !== 'terminalType_0'"
    class='color-0-custom-icon__terminalType'
    icon='{{ terminalTypeIcon }}'
    nbPrefix
    pack='customIcons'
    status='control'
  ></nb-icon>
  <nb-icon
    *ngIf="terminalTypeIcon === 'terminalType_0'"
    class='color-0-custom-icon__terminalType small'
    icon='monitor-outline'
    nbPrefix
    pack='eva'
    status='control'
  ></nb-icon>
</div>
<div class='terminal-card__description'>
  <div class='terminal-title'>
    {{ summary.type.name }}
  </div>
  <div class='terminal-status'>
    <app-terminal-name [label]="terminalStatusText" [status]="terminalStatus"></app-terminal-name>
  </div>
</div>
