import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-parker-result',
  templateUrl: './parker-result.component.html',
  styleUrls: ['./parker-result.component.scss'],
})
export class ParkerResultComponent implements OnInit {
  @Input()
  data: any;

  @Input()
  type: any;

  tabName!: string;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.get('IDENTIFICATION').subscribe((items) => {
      if (this.type === 2) {
        this.tabName = items['VISITOR'];
      }
      if (this.type === 3) {
        this.tabName = items['SUBSCRIBER'];
      }
    });
  }

  fullName(data: any) {
    let fullName = '';
    if ((data && data.firstNames) || data.surname) {
      fullName = fullName + data.firstNames;
      if (data.surname) {
        fullName = fullName + ' ' + data.surname;
      }
    }
    if (data && data.middleName) {
      fullName = fullName + ' ' + data.middleName;
    }
    if (data && data.lastName) {
      fullName = fullName + ' ' + data.lastName;
    }
    return fullName;
  }
}
