import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { debounceTime } from 'rxjs';
import { SearchApiService } from 'src/app/@core/api/search-api.service';
import { SearchResult } from 'src/app/@core/domain/interfaces/search-result.interface';
import { CardResult } from '../global-search.component';

@Component({
  selector: 'app-subscriber-list',
  templateUrl: './subscriber-list.component.html',
  styleUrls: ['../shared.styles.scss'],
})
export class SubscriberListComponent implements OnInit, OnChanges {
  @Input()
  data!: SearchResult;

  @Output()
  navigate = new EventEmitter<any>();

  @Input()
  searchInput: any;

  firstCard: CardResult = {
    searchResults: [],
    placeholders: [],
    loading: false,
    pageToLoadNext: 2,
  };
  secondCard: CardResult = {
    searchResults: [],
    placeholders: [],
    loading: false,
    pageToLoadNext: 2,
  };

  searchType!: number;
  pageSize = 25;
  lastPageReached = false;

  scrollIcon = 'arrow-ios-downward-outline';

  constructor(private searchApiService: SearchApiService) {}

  ngOnInit(): void {
    this.firstCard.searchResults = this.data.results;
    this.searchType = this.data.type.id;
  }

  ngOnChanges() {
    if (this.data.results.length === 0) {
      this.firstCard.searchResults = [];
    }
  }

  loadNext(cardData: any) {
    if (cardData.loading) {
      return;
    }

    if (this.firstCard.searchResults.length <= this.data.count && !this.lastPageReached) {
      cardData.loading = true;
      cardData.placeholders = new Array(this.pageSize);
      this.searchApiService
        .searchByType(this.searchInput, this.searchType, cardData.pageToLoadNext)
        .pipe(debounceTime(500))
        .subscribe((result) => {
          if (result.results.length === 0) {
            this.lastPageReached = true;
          }
          cardData.placeholders = [];
          cardData.searchResults.push(...result.results);
          cardData.loading = false;
          cardData.pageToLoadNext++;
        });
    }
  }

  navigateToSubscribers(result: any, type: any) {
    this.navigate.emit({ result: result, type: type });
  }

  onScroll($event: any) {
    if ($event.target.offsetHeight + ($event.target.scrollTop + 5) >= $event.target.scrollHeight) {
      this.scrollIcon = 'arrow-ios-upward-outline';
    } else {
      this.scrollIcon = 'arrow-ios-downward-outline';
    }
  }
}
