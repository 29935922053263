import { Observable, Subject } from 'rxjs';
import { ParkbaseStorageService } from 'src/app/@shared/services/parkbase-storage.service';
import { StatusPageService } from '../../../pages/status-page/services/status-page.service';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NotificationGroup } from 'src/app/@core/domain/interfaces/notifications.interface';
import { Definition } from 'src/app/@core/domain/interfaces/definition.interface';
import { NotificationLevel } from 'src/app/@core/domain/enums/notification-type.enum';
import { NotificationsApiService } from 'src/app/@core/api/notification-api.service';
import { ActivatedRoute, Router } from '@angular/router';

import { FacilityName } from '../../../@core/domain/interfaces/facility.interface';
import { debounceTime } from 'rxjs/operators';
import { LogService } from 'src/app/@core';
import { environment } from 'src/environments/environment';
import { TrackingService } from 'src/app/@shared/services/tracking.service';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { NotificationFilterSet, RangeSelection } from './notificationFilterSet';
import { SelectedOutput } from './notifications-filter/notifications-filter.component';

export interface NotificationsCard {
  notificationGroups: NotificationGroup[];
  placeholders: string[];
  filterSet: NotificationFilterSet;
  levels: number[];
  types: number[];
  terminalIds: number[];
  definitions: Definition[];
  loading: boolean;
  pageToLoadNext: number;
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() facilitySelection = 'divisionsNotifications';
  @Input() facilityId!: number;
  @ViewChild('item') accordion: any;

  cardData$!: Observable<NotificationsCard>;

  firstNotificationCard: NotificationsCard = {
    notificationGroups: [],
    placeholders: [],
    filterSet: new NotificationFilterSet(),
    levels: [],
    types: [],
    terminalIds: [],
    definitions: [],
    loading: false,
    pageToLoadNext: 1,
  };
  pageSize = 25;
  levels: number[] = [];
  types: number[] = [];
  terminalIds: number[] = [];
  definitions!: Definition[];
  filterAlarms!: boolean;
  filterErrors!: boolean;
  filterWarnings!: boolean;
  filterInfo!: boolean;
  notificationLevel!: any;
  notifications!: any;
  placeholders!: any;
  interval;
  refreshNotifications = true;
  opened = false;
  locale!: string;
  onRegularPage = false;
  rangeSelection = '1';

  filterOpen = false;

  @ViewChildren(NgbAccordion) pageAccordions!: QueryList<NgbAccordion>;
  filterSelectedCount = 0;

  private buttonClicked = new Subject<string>();

  constructor(
    private notificationsApiService: NotificationsApiService,
    private parkbaseStorageService: ParkbaseStorageService,
    private statusPageService: StatusPageService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private routes: ActivatedRoute,
    private logger: LogService,
    private trackingService: TrackingService
  ) {
    this.interval = setInterval(() => {
      if (this.refreshNotifications) {
        this.refreshNotificationsFromApi();
      }
    }, environment.refreshInterval);

    if (this.statusPageService && this.statusPageService.refreshNotifications) {
      this.statusPageService.refreshNotifications.subscribe((status) => {
        this.refreshNotifications = status;
        if (status) {
          this.firstNotificationCard.notificationGroups = [];
          this.firstNotificationCard.pageToLoadNext = 1;

          this.refreshFilterSelection();
          this.refreshNotificationsFromApi();
        }
      });
    }

    const buttonClickedDebounced = this.buttonClicked.pipe(debounceTime(200));
    buttonClickedDebounced.subscribe((buttonClicked) => {
      this.filterNotifications(buttonClicked);
    });

    this.setRangeSelection();
  }

  ngOnInit(): void {
    if (this.parkbaseStorageService.getStorage().has('PBNotificationTypeFilterSet')) {
      this.firstNotificationCard.filterSet = this.parkbaseStorageService.retrieveFilterSet();
    }

    if (this.parkbaseStorageService.getStorage().has('PBFacilitySelected')) {
      const selection: FacilityName = this.parkbaseStorageService.retrieveFacilitySelection();
      if (selection && selection.id === 0) {
        this.onRegularPage = true;
      } else if (selection.id < 0) {
        this.onRegularPage = false;
      }
    }

    const currentRouteParts = this.router.url.split('/');
    if (currentRouteParts[1] !== 'status') {
      this.onRegularPage = true;
      this.facilitySelection = 'divisionsNotifications';
    }

    this.statusPageService.filterNotifications.subscribe((filter) => {
      if (filter) {
        this.terminalIds = filter.terminalIds;
        this.refreshFilterSelection();
        this.refreshNotificationsFromApi();
      }
    });

    this.locale = this.statusPageService.getLocale();

    this.cardData$ = this.notificationsApiService.cardData;
  }

  ngAfterViewInit(): void {
    this.pageAccordions.changes.subscribe((ac) => {
      ac.toArray().forEach((el: any) => {
        el.toggle();
        this.cdr.detectChanges();
      });
    });
  }

  counter(i: number): any[] {
    return new Array(i);
  }

  format(date: string, typeAlarm: boolean): string {
    const dateformatChoice = this.parkbaseStorageService.retrieveDateformatChoice();
    if (dateformatChoice === 'Exact' && typeAlarm) {
      return this.statusPageService.formatExactTime(date);
    } else {
      return this.statusPageService.formatRelativeTime(date);
    }
  }

  open(index: number): void {
    this.opened = !this.opened;
    this.closeAll(index);
  }

  closeAll(index: number) {
    const activeIds = 'static-' + index;
    this.pageAccordions.forEach((acc) => {
      if (acc.activeIds[0] !== activeIds) {
        acc.collapseAll();
      } else {
        acc.expand(activeIds);
      }
    });
  }

  getIconType(notificationGroup: NotificationGroup): string {
    if (notificationGroup && notificationGroup.terminal) {
      const terminalTypeEnumValue = Object.values(notificationGroup.terminal.type)[0];
      return 'terminalType_' + terminalTypeEnumValue;
    }
    return '';
  }

  navigateToFacility(notificationGroup: NotificationGroup): void {
    this.router.navigate(['/status/facilities/' + notificationGroup.facility.id], {
      skipLocationChange: true,
    });
  }

  loadPrevious() {}

  loadNext(cardData: any) {
    if (cardData.loading) {
      return;
    }

    cardData.loading = true;
    cardData.placeholders = new Array(this.pageSize);
    cardData.levels = this.levels;
    cardData.types = this.types;
    cardData.terminalIds = this.terminalIds;
    cardData.definitions = this.definitions;

    const selection: FacilityName = this.parkbaseStorageService.retrieveFacilitySelection();
    if (selection && selection.id && selection.id > 0 && this.facilityId) {
      this.facilityId = selection.id;
      this.facilitySelection = 'notificationsForFacility';
    }

    if (this.facilitySelection === 'divisionsNotifications') {
      this.notificationsApiService.loadAsyncWithCardData(cardData, this.pageSize, 'divisionsNotifications');
    }
    if (this.facilitySelection === 'notificationsForFacility') {
      this.notificationsApiService.loadAsyncWithCardData(
        cardData,
        this.pageSize,
        'notificationsForFacility',
        this.facilityId
      );
    }
  }

  refreshFilterSelection(): void {
    this.handlePBDefinitionLists();
    this.handlePBNotificationLevelFilterSet();
  }

  handlePBDefinitionLists(): void {
    this.types = [];
    let count = 0;
    if (this.parkbaseStorageService.getStorage().has('PBNotificationTypeFilterSet')) {
      this.firstNotificationCard.filterSet = this.parkbaseStorageService.retrieveFilterSet();
      this.firstNotificationCard.filterSet.filtersSelected.forEach((filter) => {
        if (filter.checked) {
          this.types.push(filter.id);
          count += 1;
        }
      });
      this.filterSelectedCount = count;
    }
  }

  handlePBNotificationLevelFilterSet(): void {
    if (this.parkbaseStorageService.getStorage().has('PBNotificationLevelFilterSet')) {
      this.levels = this.parkbaseStorageService.retrieveNotificationLevelFilterSet();
      this.levels.forEach((level) => {
        switch (level) {
          case 6:
            this.filterAlarms = true;
            break;
          case 2:
            this.filterErrors = true;
            break;
          case 1:
            this.filterWarnings = true;
            break;
          case 0:
            this.filterInfo = true;
            break;
        }
      });
      this.firstNotificationCard.levels = this.levels;
    }
  }

  onFiltersSelected(emittedValue: SelectedOutput): void {
    if (emittedValue.closeAccordion) {
      this.accordion.close();
    }
    this.filterOpen = false;
    this.types = [];
    if (emittedValue.filterSet) {
      emittedValue.filterSet.filtersSelected.forEach((filter: any) => {
        if (filter.checked) {
          this.types.push(filter.id);
        }
      });
      this.filterSelectedCount = emittedValue.filterSet.filtersSelected.length;
      this.firstNotificationCard.notificationGroups = [];
      this.firstNotificationCard.filterSet = emittedValue.filterSet;
    }
    this.firstNotificationCard.pageToLoadNext = 1;
    this.firstNotificationCard.types = this.types;
    this.setRangeSelection();
    this.refreshNotificationsFromApi();
  }

  clickFilterButton(button: string) {
    this.buttonClicked.next(button);
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  onSelectedChange($event: string) {
    const filterSet = this.firstNotificationCard.filterSet;
    switch ($event) {
      case RangeSelection.Day.toString():
        filterSet.setRangeFromNumber(1);
        break;
      case RangeSelection.Week.toString():
        filterSet.setRangeFromNumber(7);
        break;
      case RangeSelection.Month.toString():
        filterSet.setRangeFromNumber(29);
        break;
    }
    this.firstNotificationCard.filterSet = filterSet;
    this.parkbaseStorageService.saveNotificationTypeFilterSet(this.firstNotificationCard.filterSet);
    this.firstNotificationCard.notificationGroups = [];
    this.firstNotificationCard.pageToLoadNext = 1;
    if (this.facilitySelection === 'divisionsNotifications') {
      this.notificationsApiService.loadAsyncWithCardData(
        this.firstNotificationCard,
        this.pageSize,
        'divisionsNotifications'
      );
    }
    if (this.facilitySelection === 'notificationsForFacility') {
      this.notificationsApiService.loadAsyncWithCardData(
        this.firstNotificationCard,
        this.pageSize,
        'notificationsForFacility',
        this.facilityId
      );
    }
  }

  toggleFilter() {
    this.accordion.toggle();
    this.filterOpen = !this.filterOpen;
    this.setRangeSelection();
  }

  setRangeSelection() {
    this.rangeSelection = this.firstNotificationCard.filterSet.getRangeSelection();
  }

  onFiltersSelectedCountOutput($event: number) {
    this.filterSelectedCount = $event;
  }

  private filterNotifications(action: string) {
    switch (action) {
      case 'alarms':
        this.setFilterAlarms();
        this.trackingService.eventEmitter('notificationFilter', 'action', 'setFilterAlarms', 'alarms', 0);
        break;
      case 'errors':
        this.setFilterErrors();
        this.trackingService.eventEmitter('notificationFilter', 'action', 'setFilterErrors', 'errors', 0);
        break;
      case 'warnings':
        this.setFilterWarnings();
        this.trackingService.eventEmitter('notificationFilter', 'action', 'setFilterWarnings', 'warnings', 0);
        break;
      case 'info':
        this.setFilterInfo();
        this.trackingService.eventEmitter('notificationFilter', 'action', 'setFilterInfo', 'info', 0);
        break;
    }
  }

  private setFilterAlarms(): void {
    this.filterAlarms = !this.filterAlarms;
    this.notificationLevel = NotificationLevel.Alarm;
    if (this.filterAlarms) {
      this.levels.push(NotificationLevel.Alarm);
    } else {
      this.filterLevels(NotificationLevel.Alarm);
    }
    this.parkbaseStorageService.storeNotificationLevelFilterSet(this.levels);
    this.firstNotificationCard.notificationGroups = [];
    this.firstNotificationCard.pageToLoadNext = 1;
    this.firstNotificationCard.levels = this.levels;
    this.refreshNotificationsFromApi();
  }

  private setFilterErrors(): void {
    this.filterErrors = !this.filterErrors;
    this.notificationLevel = NotificationLevel.Error;
    if (this.filterErrors) {
      this.levels.push(NotificationLevel.Error);
    } else {
      this.filterLevels(NotificationLevel.Error);
    }
    this.parkbaseStorageService.storeNotificationLevelFilterSet(this.levels);
    this.firstNotificationCard.notificationGroups = [];
    this.firstNotificationCard.pageToLoadNext = 1;
    this.firstNotificationCard.levels = this.levels;
    this.refreshNotificationsFromApi();
  }

  private setFilterWarnings(): void {
    this.filterWarnings = !this.filterWarnings;
    this.notificationLevel = NotificationLevel.Warning;
    if (this.filterWarnings) {
      this.levels.push(NotificationLevel.Warning);
    } else {
      this.filterLevels(NotificationLevel.Warning);
    }
    this.parkbaseStorageService.storeNotificationLevelFilterSet(this.levels);
    this.firstNotificationCard.notificationGroups = [];
    this.firstNotificationCard.pageToLoadNext = 1;
    this.firstNotificationCard.levels = this.levels;
    this.refreshNotificationsFromApi();
  }

  private setFilterInfo(): void {
    this.filterInfo = !this.filterInfo;
    this.notificationLevel = NotificationLevel.Info;
    if (this.filterInfo) {
      this.levels.push(NotificationLevel.Info);
    } else {
      this.filterLevels(NotificationLevel.Info);
    }
    this.parkbaseStorageService.storeNotificationLevelFilterSet(this.levels);
    this.firstNotificationCard.notificationGroups = [];
    this.firstNotificationCard.pageToLoadNext = 1;
    this.firstNotificationCard.levels = this.levels;
    this.refreshNotificationsFromApi();
  }

  private filterLevels(level: number): void {
    this.levels = this.levels.filter((value, index, arr) => {
      return value !== level;
    });
  }

  private refreshNotificationsFromApi(): void {
    this.firstNotificationCard.filterSet = this.parkbaseStorageService.retrieveFilterSet();
    this.firstNotificationCard.types = this.types;
    this.firstNotificationCard.levels = this.levels;
    this.firstNotificationCard.terminalIds = this.terminalIds;

    if (this.facilitySelection === 'divisionsNotifications') {
      this.notificationsApiService.loadAsyncWithCardData(
        this.firstNotificationCard,
        this.pageSize,
        'divisionsNotifications'
      );
    }
    if (this.facilitySelection === 'notificationsForFacility') {
      this.notificationsApiService.loadAsyncWithCardData(
        this.firstNotificationCard,
        this.pageSize,
        'notificationsForFacility',
        this.facilityId
      );
    }
  }
}
