import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { slideInOutAnimation } from '../../@shared/animation/animation';
import { TerminalOverview } from '../../@core/domain/interfaces/terminal-response.interface';

@Component({
  selector: 'app-terminal-actions-screen-mobile',
  templateUrl: './terminal-screen-mobile.component.html',
  styleUrls: ['./terminal-screen-mobile.component.scss'],
  animations: slideInOutAnimation,
})
export class TerminalScreenMobileComponent implements OnInit {
  @Input()
  terminalOverview!: TerminalOverview;

  @Input()
  action!: any;

  showComponent = true;

  constructor(protected terminalActionsScreenMobileDialogRef: NbDialogRef<TerminalScreenMobileComponent>) {}

  ngOnInit(): void {}
}
