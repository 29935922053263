import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { LogService } from 'src/app/@core';
import { FacilitiesApiService } from 'src/app/@core/api/facilities-api.service';
import { FacilityName, FacilityOpeningState } from 'src/app/@core/domain/interfaces/facility.interface';
import { ParkbaseStorageService } from 'src/app/@shared/services/parkbase-storage.service';
import { TrackingService } from 'src/app/@shared/services/tracking.service';

@Component({
  selector: 'app-facility-select',
  templateUrl: './facility-select.component.html',
  styleUrls: ['./facility-select.component.scss'],
})
export class FacilitySelectComponent implements OnInit {
  facilities$!: Observable<FacilityName[]>;
  facilitiesForm!: UntypedFormGroup;
  currentFacility!: FacilityName;
  optionAll = { id: 0, name: '', openingState: FacilityOpeningState.Open, availableActions: [] };

  private subscription!: Subscription;

  constructor(
    private facilitiesApiService: FacilitiesApiService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private routes: ActivatedRoute,
    private translate: TranslateService,
    private parkbaseStorageService: ParkbaseStorageService,
    private trackingService: TrackingService,
    private logger: LogService
  ) {}

  get facility(): AbstractControl | null {
    return this.facilitiesForm.get('facilityName');
  }

  ngOnInit(): void {
    this.facilities$ = this.facilitiesApiService.facilities;
    this.facilitiesApiService.loadFacilities();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.getCurrentFacilityOption();
      }
    });

    this.subscription = this.translate.onLangChange.subscribe((lang) => {
      if (lang) {
        this.getTranslations();
      }
    });

    this.facilitiesForm = this.formBuilder.group({
      facilityName: [this.currentFacility],
    });

    this.getTranslations();
    this.getCurrentFacilityOption();
  }

  compareById(v1: FacilityName, v2: FacilityName): boolean {
    return Number(v1.id) === Number(v2.id);
  }

  changeFacility($e: FacilityName): void {
    this.findFacilityAndRedirect($e);
  }

  /**
   * Get the current facility option if on the /status/facilities route and optionAll in all other cases
   * @private
   */
  private getCurrentFacilityOption() {
    const currentRouteParts = this.router.url.split('/');
    if (currentRouteParts[1] === 'status') {
      if (currentRouteParts.length === 3 && currentRouteParts[2] === 'facilities') {
        this.currentFacility = this.optionAll;
      }
      if (currentRouteParts.length === 4 && currentRouteParts[2] === 'facilities') {
        const facilitiesFromStorage = this.parkbaseStorageService.retrieveFacilities();
        const found = facilitiesFromStorage.find((f: FacilityName) => {
          return String(currentRouteParts[3]) === String(f.id);
        });
        if (found) {
          this.parkbaseStorageService.storeFacilitySelection(found);
          this.currentFacility = found;
        }
      }
    } else {
      this.currentFacility = this.optionAll;
    }
  }

  /**
   * Redirect user to facility, works with DirectAccessGuard to prevent url navigation
   * @param facility Redirect
   */
  private findFacilityAndRedirect(facility: FacilityName): void {
    this.trackingService.eventEmitter('navigateToFacility', 'action', 'select facility', facility.toString(), 0);
    if (facility.name === this.optionAll.name) {
      this.currentFacility = this.optionAll;
      this.router
        .navigateByUrl('/', { skipLocationChange: false })
        .then(() => this.router.navigate(['/status/facilities']));
    } else {
      const facilitiesFromStorage = this.parkbaseStorageService.retrieveFacilities();
      const found = facilitiesFromStorage.find((f: FacilityName) => {
        return String(facility.name) === String(f.name);
      });
      if (found) {
        this.logger.info('Navigated to facility detail', []);
        this.currentFacility = found;
        this.parkbaseStorageService.storeFacilitySelection(found);
        this.router.navigateByUrl('/', { skipLocationChange: false }).then(() =>
          this.router.navigateByUrl('/status/facilities/' + found.id, {
            skipLocationChange: true,
          })
        );
      }
    }
  }

  private getTranslations() {
    this.translate.get('FACILITY_SELECT.OPTION_ALL').subscribe((res: string) => {
      this.optionAll.name = res;
    });
  }
}
