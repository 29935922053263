import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AlarmsApiService } from '../../../@core/api/alarms-api.service';
import { DivisionHubService } from '../../../@core/hubs/division-hub.service';
import { AuthService } from '../../../auth/services/auth.service';
import { FacilitiesApiService } from '../../../@core/api/facilities-api.service';

/**
 * Bell icon for emergency alarm status
 */
@Component({
  selector: 'app-alarm-bell',
  templateUrl: './alarm-bell.component.html',
  styleUrls: ['./alarm-bell.component.scss'],
})
export class AlarmBellComponent implements OnInit {
  color = '#f9430e';
  size = 6;
  radius = 4;
  divisionAlarmsGoingFromHub$!: Observable<boolean>;
  alarmsGoingFromApi$!: Observable<boolean>;
  alarmsGoingFromFacilitiesApi$!: Observable<boolean>;

  constructor(
    private alarmsApiService: AlarmsApiService,
    private facilitiesApiService: FacilitiesApiService,
    private divisionHubService: DivisionHubService,
    private authService: AuthService
  ) {}

  /**
   * There are 4 possible sources for alarms status
   * 1. division api call, never shows the bypass
   * 2. facility api call, does show the bypass
   * 3. SignalR hub but only when there is a change
   * 4. facilitySummary call on dashboard
   */
  ngOnInit(): void {
    this.alarmsGoingFromApi$ = this.alarmsApiService.alarmsGoingFromApi$;
    this.alarmsApiService.loadDivisionsEmergencyAlarms();

    this.alarmsGoingFromFacilitiesApi$ = this.facilitiesApiService.alarmFromFacilitySummary;
    this.facilitiesApiService.loadFacilitySummaryGroupedFromAPI();

    this.getEmergencyAlarmChanged();
  }

  private getEmergencyAlarmChanged() {
    this.authService.getUser('AlarmBellComponent getEmergencyAlarmChanged').subscribe((user) => {
      if (user) {
        this.divisionHubService.alarmGoing$.subscribe((r: any) => {
          if (r) {
            this.divisionAlarmsGoingFromHub$ = of(true);
          }
        });
      }
    });
  }
}
