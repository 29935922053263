import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TotalsHelper } from '../totals.helper';
import {
  AdjustFacilityAction,
  FacilityActionResponse,
  FacilityActionType,
  OccupationAdjustment,
  ReservationOccupationAdjustment,
  ResponseStatus,
} from '../../../../../@core/domain/interfaces/facility.interface';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { LogService } from '../../../../../@core';
import { ToastMessageService } from '../../../../../@shared/services/toast-message.service';
import { FacilitiesApiService } from '../../../../../@core/api/facilities-api.service';
import {
  Occupation,
  OccupationEditMode,
  OccupationRatio,
  ReservationOccupationRatio,
} from '../../../../../@core/domain/interfaces/occupation.interface';

interface Choice {
  cancel: boolean;
  save: boolean;
  sync: boolean;
  name: string;
}

@Component({
  selector: 'app-totals-form',
  templateUrl: './totals-form.component.html',
  styleUrls: ['./totals-form.component.scss'],
})
export class TotalsFormComponent implements OnInit, OnChanges {
  @Input()
  occupation!: Occupation;

  @Input()
  editing = false;

  @Input()
  saveTotals = false;

  @Input()
  facilityId!: number;

  @Output()
  formSaving = new EventEmitter<boolean>();

  @Output()
  formSaved = new EventEmitter<boolean>();

  @Output()
  formCanceled = new EventEmitter<boolean>();

  editMode!: OccupationEditMode;
  totalsForm!: UntypedFormGroup;
  savingForm!: boolean;

  private occupationAdjustments: OccupationAdjustment[] = [];

  private reservationOccupationAdjustments: ReservationOccupationAdjustment[] = [];

  constructor(
    private facilitiesApiService: FacilitiesApiService,
    private formBuilder: UntypedFormBuilder,
    private logger: LogService,
    private toastMessageService: ToastMessageService,
    private dialogService: NbDialogService
  ) {}

  ngOnInit(): void {
    this.resetTotalsForm();
    this.totalsForm.reset({});
    this.resetTotalsForm();
    this.buildForm(this.occupation);
    this.savingForm = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['editing']) {
      if (changes['editing'].currentValue === false && changes['editing'].previousValue === true) {
        this.totalsForm.reset({});
        this.resetTotalsForm();
        this.buildForm(this.occupation);
        return;
      }
    }
    if (changes) {
      if (this.occupation && this.occupation.occupationRatios.length > 0) {
        this.buildForm(this.occupation);
      }
      if (this.saveTotals) {
        this.saveTotalsForm();
      }
    }
  }

  getCategoryColor(o: OccupationRatio): string {
    return TotalsHelper.getCategoryColor(o);
  }

  getReservationCategoryColor(o: ReservationOccupationRatio) {
    return TotalsHelper.getReservationCategoryColor(o);
  }

  saveTotalsForm() {
    this.occupationAdjustments = [];
    this.reservationOccupationAdjustments = [];
    this.dialogService
      .open(ConfirmDialogComponent, {
        hasBackdrop: true,
        closeOnEsc: true,
        closeOnBackdropClick: false,
      })
      .onClose.subscribe((choice: Choice) => {
        if (choice && choice.save) {
          this.logger.info('Saving totals form with type AdjustOccupation', []);
          this.submitTotalsForm(FacilityActionType.AdjustOccupation);
        }
        if (choice && choice.sync) {
          this.logger.info('Syncing totals form with type SyncOccupationTotal', []);
          this.submitTotalsForm(FacilityActionType.SyncOccupationTotal);
        }
        if (choice && choice.cancel) {
          this.savingForm = false;
          this.resetTotalsForm();
          this.formCanceled.emit(true);
        }
      });
  }

  private submitTotalsForm(type: FacilityActionType): void {
    this.addToFacilityAction(0, this.totalsForm.get('ctrlOccupation0')?.value);

    this.addToFacilityAction(1, this.totalsForm.get('ctrlOccupation1')?.value);
    this.addToFacilityAction(2, this.totalsForm.get('ctrlOccupation2')?.value);
    this.addToFacilityAction(3, this.totalsForm.get('ctrlOccupation3')?.value);
    this.addToReservationFacilityAction(0, this.totalsForm.get('ctrlReservation0')?.value);

    this.addToReservationFacilityAction(1, this.totalsForm.get('ctrlReservation1')?.value);
    this.addToReservationFacilityAction(2, this.totalsForm.get('ctrlReservation2')?.value);
    this.addToReservationFacilityAction(3, this.totalsForm.get('ctrlReservation3')?.value);
    const adjustFacilityAction: AdjustFacilityAction = {
      type: type,
      occupationAdjustments: this.occupationAdjustments,
      reservationOccupationAdjustments: this.reservationOccupationAdjustments,
    };

    if (this.totalsForm.valid) {
      this.savingForm = true;
      this.formSaving.emit(true);
      this.facilitiesApiService
        .sendFacilityAction(this.facilityId, adjustFacilityAction)
        .subscribe((result: FacilityActionResponse) => {
          this.formSaving.emit(false);
          this.formSaved.emit(true);
          if (result.status === ResponseStatus.Success) {
            const logMessage =
              '-- Totals form saved with editMode ' +
              this.editMode.toString() +
              'on facility ' +
              this.facilityId.toString();
            this.logger.info(logMessage, []);
            this.toastMessageService.showSuccess(result.message);
          } else if (result.status === ResponseStatus.Failure) {
            this.toastMessageService.showFailure(result.message);
            this.logger.error(result.message, result);
          } else if (result.status === ResponseStatus.InputRequired) {
            this.toastMessageService.showInputRequired(result.message);
          } else if (result.status === ResponseStatus.PartialFailure) {
            this.toastMessageService.showPartialFailure(result.message);
          }

          this.facilitiesApiService.loadTotals(this.facilityId);
          this.resetTotalsForm();
        });
    }
  }

  private resetTotalsForm() {
    this.totalsForm = new UntypedFormGroup({});
    this.occupationAdjustments = [];
    this.reservationOccupationAdjustments = [];
    this.savingForm = false;
  }

  private buildForm(occupationData: Occupation) {
    if (occupationData && this.totalsForm) {
      occupationData.occupationRatios.forEach((o: any) => {
        if (o.adjustable) {
          if (o.editMode === 0) {
            this.editMode = 0;
            this.totalsForm.addControl(
              'ctrlOccupation' + o.type.id.toString(),
              new UntypedFormControl(o.occupation, [Validators.required, Validators.max(o.capacity)])
            );
          }
          if (o.editMode === 1) {
            this.editMode = 1;
            this.totalsForm.addControl(
              'ctrlOccupation' + o.type.id.toString(),
              new UntypedFormControl(o.capacity - o.occupation, [Validators.required, Validators.max(o.capacity)])
            );
          }
        }
      });
      occupationData.reservationOccupationRatios.forEach((o: any) => {
        if (o.adjustable) {
          this.totalsForm.addControl(
            'ctrlReservation' + o.type.id.toString(),
            new UntypedFormControl(o.occupation, [Validators.required, Validators.max(o.capacity)])
          );
        }
      });
    }
  }

  private addToFacilityAction(type: number, value: number) {
    if (value) {
      this.occupationAdjustments.push({ type: type, editMode: this.editMode, value: value });
    }
  }

  private addToReservationFacilityAction(type: number, value: number) {
    if (value) {
      this.reservationOccupationAdjustments.push({ type: type, value: value });
    }
  }
}
