export class Identification {
  constructor(
    public transactionId: number,
    public identification: string,
    public entryTime: Date,
    public hri?: string,
    public publisher?: string,
    public licensePlate?: string,
    public type?: IdentificationTypes
  ) {}

  static prepareFromApiForLicensePlate(identification: any): Identification {
    return {
      transactionId: identification.transactionId,
      entryTime: identification.localEntryTime,
      identification: identification.identification,
      hri: identification.hri,
      publisher: identification.publisher,
      licensePlate: identification.identification,
    };
  }

  static isValidTicketNumber(value: string) {
    return isAlphaNumericString(value) && hasValidLengthForTicketNumber(value);
  }
}

export enum IdentificationTypes {
  LicensePlate = 0,
  /// <summary>
  ///   HRI
  /// </summary>
  HRI,
  /// <summary>
  /// Barcode
  /// </summary>
  TicketBarcode,
  /// <summary>
  /// Mifare
  /// </summary>
  Mifare,
  /// <summary>
  /// Manual entered number by the customer. Combination of Identification type 0 and 1
  /// </summary>
  Manual,
}

function isAlphaNumericString(value: string): boolean {
  return /^\w+$/.test(value);
}

function hasValidLengthForTicketNumber(value: string): boolean {
  return value.length >= 6;
}
