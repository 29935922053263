<div *ngIf='showHeader' class='page__header'>
  <div class='t1 col-xl-3 col-md-4 col-xs-1 pe-3'>
    <div class='facility-select'>
      <app-facility-select id='facility-selector'></app-facility-select>
    </div>
  </div>
  <div class='t2 col-xl-9 col-md-8 col-xs-1 ps-0'>
    <div class='header-global-search'>
      <app-global-search id='global-search'></app-global-search>
      <div *ngIf='infoActive' [ngClass]='{active : active}' class='header-info'>
        <button (click)='handleClick()' [nbPopover]='infoComponent'
                class='header-button' icon='info'
                nbButton nbPopoverPlacement='bottom' nbPopoverTrigger='click'>
          <nb-icon
            icon='info' nbPrefix
            pack='eva'
            status='control'></nb-icon>

        </button>
      </div>
      <div *ngIf='notificationsActive' class='header-notifications'>
        <button *ngIf='notificationsClickable' [nbPopoverContext]="{text: 'Example context'}"
                [nbPopover]='notificationsComponent'
                class='header-button' nbButton nbPopoverPlacement='bottom' nbPopoverTrigger='click'>
          <app-alarm-bell></app-alarm-bell>
        </button>
        <button *ngIf='!notificationsClickable' class='header-button'
                nbButton nbTooltip='Notificaties worden al weergeveven' nbTooltipPlacement='bottom'>
          <app-alarm-bell></app-alarm-bell>
        </button>
      </div>
    </div>
  </div>
</div>
