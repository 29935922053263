import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

export interface ConfirmationData {
  title?: string;
  headerTitle?: string;
  message?: string;
  htmlMessage?: boolean;
  buttonCancel?: string;
  buttonConfirm?: string;
  showInfoAlert?: boolean;
  infoAlertText?: string;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  @Input()
  confirmationData!: ConfirmationData;

  constructor(protected dialogRef: NbDialogRef<ConfirmationDialogComponent>) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
