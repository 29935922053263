import { AfterViewInit, Directive, Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';

interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Directive()
export abstract class DeactivateGuardComponent implements CanComponentDeactivate, AfterViewInit {
  form!: NgForm;
  deactivateConfirmationMessage =
    'It looks like you have been editing something. If you leave before saving, your changes will be lost';

  setMessage(message: string) {
    this.deactivateConfirmationMessage = message;
  }

  ngAfterViewInit(): void {
    this.registerForm();
    if (!this.form) {
      console.warn('Form was not registered');
    }
  }

  canDeactivate(): boolean {
    if (this.form) {
      return this.form.dirty ? window.confirm(this.deactivateConfirmationMessage) : true;
    } else {
      return true;
    }
  }

  abstract registerForm(): void;
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  canDeactivate(component: any): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
