import { Component, OnInit } from '@angular/core';
import { NbAccessChecker } from '@nebular/security';
import { TranslateService } from '@ngx-translate/core';
import { NbDialogRef, NbDialogService, NbMenuItem } from '@nebular/theme';
import { Router } from '@angular/router';
import { MainMenuConfigComponent } from '../../@shared/components/main-menu/main-menu.config.component';
import { slideInOutAnimation } from '../../@shared/animation/animation';

@Component({
  selector: 'app-settings-menu-mobile',
  templateUrl: './settings-menu-mobile.component.html',
  styleUrls: ['./settings-menu-mobile.component.scss'],
  animations: slideInOutAnimation,
})
export class SettingsMenuMobileComponent extends MainMenuConfigComponent implements OnInit {
  showComponent = true;

  constructor(
    public override accessChecker: NbAccessChecker,
    public override translate: TranslateService,
    private dialogService: NbDialogService,
    private router: Router,
    protected settingsMobileMenuDialogRef: NbDialogRef<SettingsMenuMobileComponent>
  ) {
    super(accessChecker, translate);
  }

  override ngOnInit(): void {
    this.showComponent = true;
  }

  openMobile(menuItem: NbMenuItem) {
    if (menuItem.data && menuItem.data.mobile && menuItem.data.mobile.useOverlay) {
    } else {
      this.showComponent = false;
      this.settingsMobileMenuDialogRef.close(false);
      this.router.navigate([menuItem.link]);
    }
  }
}
