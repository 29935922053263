<div>
  <nb-form-field>
    <nb-select
      [(selected)]='selectedItems'
      fullWidth
      multiple
      optionsListClass='test2'
      optionsPanelClass='test'
      placeholder='{{placeholder}}'
      scrollStrategy='block'
      size='giant'>
      <nb-select-label *ngIf='selectedItems.length > 1'>{{"FORMS.MULTIPLE_SELECTED" | translate}}</nb-select-label>
      <nb-option *ngFor='let item of dropdownList' [value]='item.id' id='{{ item.id}}'>
        {{ item.label}}
      </nb-option>
    </nb-select>
  </nb-form-field>
</div>
