<nb-layout>
  <nb-layout-column>
    <div class='unauthorized-page'>
      <div class='unauthorized'>
        <img alt='Logo Parkbase' height='50' src='/assets/theme/Logo_ParkBase.png' width='225' />
        <h1>Unauthorized</h1>
        <p>We're sorry, the page you requested cannot be accessed.</p>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
