import { Component, OnInit, TemplateRef } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import {
  AdjustFacilityAction,
  FacilityActionType,
  FacilityName,
  ResponseStatus,
} from '../../../../@core/domain/interfaces/facility.interface';
import { FacilitiesApiService } from '../../../../@core/api/facilities-api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { StatusPageService } from '../../services/status-page.service';
import { ParkbaseStorageService } from '../../../../@shared/services/parkbase-storage.service';
import { Alarm, AlarmRule } from '../../../../@core/domain/interfaces/alarm.interface';
import { AlarmsApiService } from '../../../../@core/api/alarms-api.service';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from '../../../../@core';
import { ToastMessageService } from '../../../../@shared/services/toast-message.service';
import { FacilityHubService } from '../../../../@core/hubs/facility-hub.service';

@Component({
  selector: 'app-bypass-alarms',
  templateUrl: './bypass-alarms.component.html',
  styleUrls: ['./bypass-alarms.component.scss'],
})
export class BypassAlarmsComponent implements OnInit {
  facility!: FacilityName;
  canChangeBypassAlarm = false;
  bypassAlarmRules: AlarmRule[] = [];
  alarms$!: Observable<Alarm[]>;
  buttonDisabled = false;
  awaitActionComplete = false;
  successMessage!: string;
  errorMessage!: string;
  partialFailureMessage!: string | undefined;

  alarmStatusFromHub!: Observable<Alarm[]>;

  private _alarmStatusActive = new BehaviorSubject<Alarm>({
    enabled: false,
    going: true,
    name: 'byPass',
  });
  readonly bypassAlarmStatusActive = this._alarmStatusActive.asObservable();
  private dialogRef!: NbDialogRef<any>;

  constructor(
    private dialogService: NbDialogService,
    private facilitiesApiService: FacilitiesApiService,
    private facilityHubService: FacilityHubService,
    private translate: TranslateService,
    private statusPageService: StatusPageService,
    private parkbaseStorageService: ParkbaseStorageService,
    private toastMessageService: ToastMessageService,
    private alarmsApiService: AlarmsApiService,
    private logger: LogService
  ) {}

  ngOnInit(): void {
    this.facility = this.parkbaseStorageService.retrieveFacilitySelection();
    // const actionAvailable = _.find(this.facility.availableActions, { type: 2 });
    this.initAlarmRules();
    this.facilityHubService.facilityEmergencyAlarms$.subscribe((alarms) => {
      if (alarms) {
        if (alarms && alarms[3]) {
          this._alarmStatusActive.next(alarms[3]);
        }
      }
    });
    this.facilityHubService.callHub(this.facility.id);
  }

  controlBypassAlarms(dialog: TemplateRef<any>) {
    this.facility = this.parkbaseStorageService.retrieveFacilitySelection();
    this.statusPageService.triggerRefreshTerminalSummary(false);
    this.facilitiesApiService.loadTerminals(this.facility);
    this.dialogRef = this.dialogService.open(dialog, {
      context: '',
      dialogClass: 'modal-small',
      backdropClass: 'modal-backdrop-transparent',
      hasBackdrop: true,
      closeOnEsc: false,
      closeOnBackdropClick: false,
    });
  }

  activateBypassAlarm() {
    this.awaitActionComplete = true;
    this.buttonDisabled = true;
    const facilityAction: AdjustFacilityAction = {
      type: FacilityActionType.BypassAlarm,
      activate: true,
    };
    this.facilitiesApiService.sendFacilityAction(this.facility.id, facilityAction).subscribe((result) => {
      if (result && result.status === ResponseStatus.Success) {
        const alarm = { enabled: true, going: true, name: 'byPass' };
        this._alarmStatusActive.next(alarm);
        this.alarmsApiService.loadFacilityEmergencyAlarms(this.facility.id);
        this.logger.info('Bypass alarm activated', []);
        this.toastMessageService.showSuccess(result.message + ' - ' + this.successMessage);
        this.handleActionComplete();
      } else if (result && result.status === ResponseStatus.PartialFailure) {
        this.partialFailureMessage = result.message;
        this.toastMessageService.showPartialFailure(result.message);
        this.handleActionPartiallyComplete();
      } else {
        this.logger.error('Error activating bypass alarm', result.message);
        this.toastMessageService.showFailure(result.message);
        this.handleActionComplete();
      }
    });
  }

  deactivateBypassAlarm() {
    this.partialFailureMessage = undefined;
    this.awaitActionComplete = true;
    this.buttonDisabled = true;
    const facilityAction: AdjustFacilityAction = {
      type: FacilityActionType.BypassAlarm,
      activate: false,
    };
    this.facilitiesApiService.sendFacilityAction(this.facility.id, facilityAction).subscribe((result) => {
      if (result && result.status === ResponseStatus.Success) {
        const alarm = { enabled: true, going: false, name: 'byPass' };
        this._alarmStatusActive.next(alarm);
        this.alarmsApiService.loadFacilityEmergencyAlarms(this.facility.id);
        this.logger.info('Bypass alarm deactivated', []);
        this.toastMessageService.showSuccess(result.message + ' - ' + this.successMessage);
        this.handleActionComplete();
      } else if (result && result.status === ResponseStatus.PartialFailure) {
        this.partialFailureMessage = result.message;
        this.toastMessageService.showFailure(result.message + ' - ' + this.errorMessage);
        this.handleActionPartiallyComplete();
      } else {
        this.logger.error('Error deactivating bypass alarm', result.message);
        this.toastMessageService.showFailure(result.message + ' - ' + this.errorMessage);
        this.handleActionComplete();
      }
    });
  }

  private handleActionPartiallyComplete() {
    this.initAlarmRules();
    this.buttonDisabled = false;
    this.awaitActionComplete = false;
  }

  private handleActionComplete() {
    this.initAlarmRules();
    this.buttonDisabled = false;
    this.awaitActionComplete = false;
    if (this.dialogRef) {
      this.statusPageService.triggerRefreshTerminalSummary(false);
      this.dialogRef.close();
    }
  }

  private initAlarmRules() {
    this.alarms$ = this.alarmsApiService.alarms$;
    this.alarmsApiService.loadFacilityEmergencyAlarms(this.facility.id);
    this.alarmsApiService.loadFacilityBypassAlarmRules(this.facility.id).subscribe((alarmRules) => {
      if (alarmRules && alarmRules.length > 0) {
        this.canChangeBypassAlarm = true;
        this.bypassAlarmRules = alarmRules;
        this.alarms$.subscribe((alarms) => {
          if (alarms && alarms[3]) {
            this._alarmStatusActive.next(alarms[3]);
          }
        });
      }
    });
  }
}
