<div class='row status-page__body'>
  <div [class]="(toggleNotifications && showNotificationsContainer) ? 'col col-xs-1' : 'col'">
    <div class='row'>
      <h2>
        {{ 'FACILITIES_SUMMARY.TITLE' | translate
        }}
        <ng-container *ngIf='division$ | async as division'>
          {{ division.name }} {{ division.city }}</ng-container
        >
      </h2>
    </div>
    <div class='row'>
      <div class='facilities-grouped' id='facilities-grouped'>
        <ng-container *ngIf='facilitySummaryGrouped | async as data'>
          <div class='facilities-grouped danger'>
            <h5 class='facility-status-danger'>
              <nb-icon
                class='facility-status-danger'
                icon='alert-triangle'
                nbPrefix
                pack='eva'
                status=''
              ></nb-icon>
              {{ 'FACILITIES_SUMMARY.ALARMS' | translate }}
            </h5>
            <ng-container *ngIf='data && !data.facilitiesWithAlarm'>
              <app-skeleton-loader *ngFor='let number of [0, 1, 2]'></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf='data.facilitiesWithAlarm && data.facilitiesWithAlarm.length === 0'>
              <p class='no-warning'>{{ 'FACILITIES_SUMMARY.ALARMS_NO_WARNING' | translate }}</p>
            </ng-container>
            <div class='wrapper-a'>
              <ng-container
                *ngFor='
                      let facilitySummary of data.facilitiesWithAlarm;
                      let i = index;
                      trackBy: trackByFn
                    '
              >
                <app-facility
                  *ngIf="selectedLayout === 'Compact'"
                  [facilitySummary]='facilitySummary'
                  class='facility-item'
                ></app-facility>
                <app-facility-extended
                  *ngIf="selectedLayout === 'Default'"
                  [facilitySummary]='facilitySummary'
                  class='facility-item'
                ></app-facility-extended>
                <app-facility-extended-full
                  *ngIf="selectedLayout === 'Extended'"
                  [facilitySummary]='facilitySummary'
                  class='facility-item'
                ></app-facility-extended-full>
              </ng-container>
            </div>
          </div>
          <div class='facilities-grouped warning'>
            <h5 class='facility-status-warning'>
              <nb-icon
                class='facility-status-warning'
                icon='alert-triangle'
                nbPrefix
                pack='eva'
                status=''
              ></nb-icon
              >
              {{ 'FACILITIES_SUMMARY.ERRORS' | translate }}
            </h5>
            <ng-container *ngIf='data && !data.facilitiesWithWarning'>
              <app-skeleton-loader *ngFor='let number of [0, 1, 2]'></app-skeleton-loader>
            </ng-container>
            <ng-container
              *ngIf='data.facilitiesWithWarning && data.facilitiesWithWarning.length === 0'
            >
              <p class='no-warning'>{{ 'FACILITIES_SUMMARY.ERRORS_NO_WARNING' | translate }}</p>
            </ng-container>
            <div class='wrapper-a'>
              <ng-container
                *ngFor='
                      let facilitySummary of data.facilitiesWithWarning;
                      let i = index;
                      trackBy: trackByFn
                    '
              >
                <app-facility
                  *ngIf="selectedLayout === 'Compact'"
                  [facilitySummary]='facilitySummary'
                  class='facility-item'
                ></app-facility>
                <app-facility-extended
                  *ngIf="selectedLayout === 'Default'"
                  [facilitySummary]='facilitySummary'
                  class='facility-item'
                ></app-facility-extended>
                <app-facility-extended-full
                  *ngIf="selectedLayout === 'Extended'"
                  [facilitySummary]='facilitySummary'
                  class='facility-item'
                ></app-facility-extended-full>
              </ng-container>
            </div>
          </div>
          <div class='facilities-grouped statusOk'>
            <h5 class='facility-status-statusOk'>
              <nb-icon
                class='facility-status-statusOk'
                icon='checkmark-circle-2'
                nbPrefix
                pack='eva'
                status=''
              ></nb-icon>
              {{ 'FACILITIES_SUMMARY.STATUS_OK' | translate }}
            </h5>
            <ng-container *ngIf='data && !data.facilitiesStatusOk'>
              <app-skeleton-loader *ngFor='let number of [0, 1, 2]'></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf='data.facilitiesStatusOk && data.facilitiesStatusOk.length === 0'>
              <p class='no-warning'>{{ 'FACILITIES_SUMMARY.STATUS_OK_NO_WARNING' | translate }}</p>
            </ng-container>
            <div *ngIf='data && data.facilitiesStatusOk'
                 [class]="data.facilitiesStatusOk.length > 2 ? 'wrapper-b' : 'wrapper-a'">
              <app-facility
                *ngFor='let facilitySummary of data.facilitiesStatusOk'
                [facilitySummary]='facilitySummary'
                class='facility-item-statusOk'
              >
              </app-facility>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf='!disabled && showNotificationsContainer'
       [class]="toggleNotifications ? 'active-panel col' : 'inactive-panel'">
    <div (click)='showNotifications()' [class]="toggleNotifications ? 'shown' : 'hidden'" class='toggle'>
      <div [class]="toggleNotifications ? 'hidden' : 'shown'">
        <button class='toggle-button' nbButton>
          <app-alarm-bell *ngIf='!toggleNotifications' class='alarm-bell'></app-alarm-bell>
          <span class='expand-button-text'>{{ 'NOTIFICATIONS.NOTIFICATIONS' | translate }}</span>
          <nb-icon icon='expand-outline' pack='eva' status='control'></nb-icon>
        </button>
      </div>
      <div [class]="toggleNotifications ? 'show-notifications header' : 'hide-notifications'">
        <div class='header-alarm-bell'>
          <app-alarm-bell *ngIf='toggleNotifications' class='alarm-bell'></app-alarm-bell>
          <span class='header-title'>{{ 'NOTIFICATIONS.NOTIFICATIONS' | translate }}</span>
        </div>
        <div>
          <nb-icon icon='collapse-outline' pack='eva' status='control'></nb-icon>
        </div>
      </div>
    </div>
    <app-notifications
      *ngIf='toggleNotifications'
      [class]="toggleNotifications ? 'show-notifications notifications-panel' : 'hide-notifications'"
      [facilitySelection]='selection'
      class='notifications'
    ></app-notifications>
  </div>
</div>
