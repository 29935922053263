import { UUID } from 'angular2-uuid';
import { UserProfile } from './user-profile.model';

export class User implements UserProfile {
  key: string;
  lastLoginDate: string;
  preferred_username: string;
  email: string;
  role: string[];
  sessionId: string;

  constructor(key: string, lastLoginDate: string, preferred_username: string, email: string, role: string[]) {
    this.key = key;
    this.lastLoginDate = lastLoginDate;
    this.preferred_username = preferred_username;
    this.email = email;
    this.role = role;
    this.sessionId = UUID.UUID().split('-')[0];
  }
}
