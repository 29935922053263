import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { orderBy } from 'lodash';

export interface SelectItem {
  id: number;
  label: string;
  selectedForFilter?: boolean;
}

export enum ModelType {
  SubscriptionModel,
  ParkerType,
  TerminalType,
  Facility,
}

export enum SelectStyle {
  Default,
  Transparent,
}

/**
 * displayKey: Key in options array of objects to be used for display in label
 * searchEnabled: Should search field be enabled
 * multiple: Allow multiple items selected
 * model: Specific model to be used in select, used for translation
 */
export interface MultiSelectConfig {
  displayKey: string;
  searchEnabled: boolean;
  multiple: boolean;
  model: ModelType;
  style: SelectStyle;
}

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss'],
})
export class MultiSelectDropdownComponent implements OnInit {
  /**
   * Get the required inputs
   * */
  @Input() options: any;

  /**
   * Configuration options
   * */
  @Input() config!: MultiSelectConfig;

  /**
   * Value
   * */
  @Input() value: any;

  /**
   * Reset
   * */
  @Input() reset = false;

  /**
   * Event when value changes to update in the UI
   * */
  @Output() valueChange = new EventEmitter();

  toggleDropdown = false;
  availableItems: SelectItem[] = [];
  selectedItems: SelectItem[] = [];
  componentTitleText = '';
  selectPlaceholderText = '';
  searchTerm!: string;
  searching = false;
  allItemsSelected = false;
  cssClass!: string;

  searchInputPlaceholderText = '';

  private translations: any;
  private _reset = false;

  constructor(private translate: TranslateService) {}

  get doReset(): boolean {
    return this._reset;
  }

  @Input() set doReset(value: boolean) {
    this._reset = value;
  }

  @HostListener('document:click')
  closeDropdownPane() {
    if (this.toggleDropdown) {
      this.toggleDropdown = false;

      // restore original label if there are no items selected
      if (this.selectedItems.length === 0) {
        this.selectPlaceholderText = '';
      }
    }
  }

  ngOnInit(): void {
    if (typeof this.options === 'undefined' || this.options.length == 0 || typeof this.config === 'undefined') {
      // throw Error('multi-select-dropdown requires options amd config.');
    }
    if (this.config.style === SelectStyle.Default) {
      this.cssClass = 'default';
    }
    if (this.config.style === SelectStyle.Transparent) {
      this.cssClass = 'transparent';
    }
    this.translate.get(['MULTI_SELECT_DROPDOWN']).subscribe((t) => {
      this.translations = t['MULTI_SELECT_DROPDOWN'];
      this.populateAvailableItems();
      this.initDropdownValuesAndOptions();
      this.translateLabels();
    });
  }

  deselectItem(item: SelectItem, index: number, $event: Event) {
    this.selectedItems.splice(index, 1);
    this.availableItems.push(item);

    this.sortItems();
    this.valueChanged($event);
  }

  selectItem(item: SelectItem, index: number, $event: boolean) {
    if (!this.config.multiple) {
      if (this.selectedItems.length > 0) {
        this.availableItems.push(this.selectedItems[0]);
      }
      this.selectedItems = [];
    }
    this.availableItems.splice(index, 1);
    this.selectedItems.push(item);

    this.sortItems();
    this.valueChanged($event);
  }

  valueChanged($event: Event | boolean) {
    this.value = this.selectedItems;
    this.valueChange.emit(this.value);
    this.setSelectedDisplayText();
  }

  toggleSelectDropdown($event: Event) {
    this.toggleDropdown = !this.toggleDropdown;
    this.allItemsSelected = this.isEveryItemSelected();
    $event.stopPropagation();
  }

  search($event: Event) {
    this.availableItems = this.searchFilter();
    $event.stopPropagation();
  }

  clickHandler($event: Event) {
    $event.stopPropagation();
  }

  clearSearchTerm() {
    this.searchTerm = '';
    this.searching = false;
    this.populateAvailableItems();
  }

  toggleSelectAllItems() {
    if (this.isEveryItemSelected()) {
      this.populateAvailableItems();
      this.selectedItems = [];
    } else {
      this.populateAvailableItems();
      this.selectedItems = this.availableItems;
      this.availableItems = [];
    }
    this.allItemsSelected = !this.allItemsSelected;
    this.valueChanged(true);
  }

  private isEveryItemSelected(): boolean {
    return this.availableItems.length === 0 && this.selectedItems.length === this.options.length;
  }

  private sortItems() {
    this.selectedItems.sort();
    this.availableItems.sort();
    this.availableItems = orderBy(this.availableItems, ['id', 'label'], ['asc', 'asc']);
    this.allItemsSelected = this.isEveryItemSelected();
  }

  private populateAvailableItems() {
    this.availableItems = JSON.parse(JSON.stringify(this.options.sort()));
    this.availableItems = this.availableItems.map(({ id, label }, index) => ({ id, label, selectedForFilter: false }));
  }

  private initDropdownValuesAndOptions() {
    if (this.value !== '' && typeof this.value !== 'undefined') {
      this.selectedItems = this.value;
      this.value.forEach((item: any) => {
        this.availableItems.splice(this.availableItems.indexOf(item), 1);
      });
      // this.setSelectedDisplayText();
    }
  }

  private setSelectedDisplayText() {
    if (this.config.multiple && this.selectedItems.length > 0) {
      this.selectPlaceholderText = this.selectedItems.length + ' ' + this.translations['SELECTED'];
    }
    if (this.config.multiple && this.availableItems.length > 0) {
      this.selectPlaceholderText = this.selectedItems.length + ' ' + this.translations['SELECTED'];
    }
  }

  private searchFilter() {
    this.populateAvailableItems();
    const searchResults: SelectItem[] = [];
    this.availableItems?.forEach((t) => {
      if (this.matches(t, this.searchTerm)) {
        searchResults.push(t);
      }
    });
    if (this.searchTerm.length !== 0) {
      this.searching = true;
    } else {
      this.searching = false;
      this.populateAvailableItems();
      return this.availableItems;
    }
    return searchResults;
  }

  private matches(selectItem: SelectItem, searchTerm: string): boolean {
    if (searchTerm) {
      return (
        selectItem.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
        selectItem.label.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return false;
  }

  private translateLabels() {
    switch (this.config.model) {
      case ModelType.SubscriptionModel:
        this.componentTitleText = this.translations['MODEL_TYPE']['SUBSCRIPTION'];
        break;
      case ModelType.ParkerType:
        this.componentTitleText = this.translations['MODEL_TYPE']['PARKER'];
        break;
      case ModelType.TerminalType:
        this.componentTitleText = this.translations['MODEL_TYPE']['TERMINAL'];
        break;
      case ModelType.Facility:
        this.componentTitleText = this.translations['MODEL_TYPE']['FACILITY'];
        break;
    }

    this.searchInputPlaceholderText = this.translations['SEARCH'] + ' ' + this.componentTitleText.toLowerCase();
  }
}
