import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterNotificationName',
})
export class FilterNotificationNamePipe implements PipeTransform {
  transform(items: any[], searchTerm: string): any {
    const filteredList: any[] = [];
    if (searchTerm) {
      // @ts-ignore
      const newSearchTerm: string = !isNaN(searchTerm)
        ? searchTerm.toString()
        : searchTerm.toString().toUpperCase();
      // @ts-ignore
      return items.filter((item) => {
        if (item.name.toUpperCase().indexOf(newSearchTerm) > -1) {
          filteredList.push(item);
          return filteredList;
        }
      });
    } else {
      return items;
    }
  }
}
