<div *ngIf='showComponent' @slideIn @slideOut id="animated-container">
  <app-header-nav-mobile [componentRef]='facilitySelectDialogRef'
                         [handleNavigate]='true' title="{{ 'MOBILE.FACILITIES' | translate }}">
  </app-header-nav-mobile>
  <div class='facility-select-mobile-content'>
    <div class='facility-search'>
      <nb-form-field>
        <nb-icon icon='search' nbPrefix pack='eva'></nb-icon>
        <input [(ngModel)]='searchTerm' fullWidth nbInput placeholder="{{ 'FACILITY_SEARCH.PLACEHOLDER' | translate }}"
               shape='semi-round'
               size='large' status='basic'
               type='text'>
        <button
          (click)='clearSearchForm()'
          *ngIf='searching'
          class='clear-button'
          ghost
          nbButton
          nbSuffix
          type='button'
        >
          {{ 'GLOBAL_SEARCH.CLEAR' | translate }}
        </button>
      </nb-form-field>
    </div>
    <div *ngIf='facilities$ | async as facilitySummary' class='facilities-list'>
      <app-cell (click)='openFacilityMobile(f.facility.id)' *ngFor='let f of facilitySummary'
                [data]='f'></app-cell>
    </div>
    <div *ngIf='noSearchResults'>
      <span>No facilities found</span>
    </div>
  </div>
</div>

