<ng-container>
  <div class="contextual-action">
    <span *ngIf="action">{{ action['name'] }}</span>
    <nb-toggle
      (click)="setAction(terminal.id, action['type'])"
      *ngIf="action"
      [(checked)]="actionInProgress"
      disabled="{{ disableToggleClick }}"
    ></nb-toggle>
  </div>
</ng-container>
