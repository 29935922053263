<div *ngIf='showComponent' @slideIn @slideOut>
  <app-header-nav-mobile [componentRef]='profileMobileDialogRef'
                         [handleNavigate]='true' title="{{ 'MOBILE.PROFILE' | translate }}">
  </app-header-nav-mobile>
  <div class='profile-mobile-content'>
    <!--    <app-user-profile></app-user-profile>-->
    <app-language-selector></app-language-selector>
    <app-theme-selector></app-theme-selector>
    <app-facility-layout-selector></app-facility-layout-selector>
    <!--    <app-tour-selector></app-tour-selector>-->
    <app-time-format-selector></app-time-format-selector>
    <app-dashboard-facilities-selector></app-dashboard-facilities-selector>
  </div>
</div>

