import { BaseApiService } from './base-api.service';
import { ConfigService } from 'src/app/@shared/services/config.service';
import { HttpClient } from '@angular/common/http';
import {
  IdentificationCard,
  LicensePlate,
} from '../domain/interfaces/identification-result.interface';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SearchResult } from '../domain/interfaces/search-result.interface';

@Injectable({
  providedIn: 'root',
})
export class IdentificationsApiService extends BaseApiService {
  constructor(configService: ConfigService, httpClient: HttpClient) {
    super(configService, httpClient);
  }

  /**
   * ParkBaseApi v.42
   * @param id
   * @returns
   */
  // GET /v1.0/identifications/card/{id}
  searchIdentificationCard(id: number) {
    if (!id) {
      throw new Error('searchIdentificationCard, id not provided');
    }
    const apiUrl = this.parkbaseBl9BaseUrl + '/identifications/card/' + id;
    return this.http.get<IdentificationCard>(apiUrl, { headers: this.setHeaders() }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * ParkBaseApi v.42
   * @param id
   * @returns
   */
  // GET /v1.0/identifications/license-plate/{id}
  searchIdentificationLicensePlate(id: number) {
    if (!id) {
      throw new Error('searchIdentificationLicensePlate, id not provided');
    }
    const apiUrl = this.parkbaseBl9BaseUrl + '/identifications/license-plate/' + id;
    return this.http.get<LicensePlate>(apiUrl, { headers: this.setHeaders() }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  /**
   * ParkbaseApiV9 v.43
   * @param id
   * @returns
   */
  // GET /v1.0/identifications/{id}
  searchIdentificationsbyId(id: number) {
    if (!id) {
      throw new Error('searchIdentificationsbyId, id not provided');
    }
    const apiUrl = this.parkbaseBl9BaseUrl + '/identifications/' + id;
    return this.http.get<any>(apiUrl, { headers: this.setHeaders() }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  // POST /v1.0/terminals/{{terminal}}/identifications/search
  searchIdentificationsFromTerminal(
    searchTerm: string,
    terminalId: number
  ): Observable<SearchResult[]> {
    if (!searchTerm) {
      throw new Error('searchTerm not provided');
    }

    const apiUrl = this.parkbaseBl9BaseUrl + '/terminals/' + terminalId + '/identifications/search';
    const body = {
      term: searchTerm,
    };
    return this.http.post<SearchResult[]>(apiUrl, body, { headers: this.setHeaders() }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  // POST /v1.0/identifications/search
  searchIdentifications(searchTerm: string): Observable<SearchResult[]> {
    if (!searchTerm) {
      throw new Error('searchTerm not provided');
    }

    const apiUrl = this.parkbaseBl9BaseUrl + '/identifications/search';
    const body = {
      term: searchTerm,
    };
    return this.http.post<SearchResult[]>(apiUrl, body, { headers: this.setHeaders() }).pipe(
      map((data) => {
        return data;
      })
    );
  }

  // POST /api/gui/v1.0/identifications/validation

  // POST /api/gui/v1.0/identifications/validation/execute
}
