import { Component } from '@angular/core';
import { TrackingService } from '../../@shared/services/tracking.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent {
  constructor(private trackingService: TrackingService) {
    this.trackingService.eventEmitter('subscriptions', 'pageView', 'visit', '', 0);
  }
}
