import { Component, OnInit, VERSION } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Router } from '@angular/router';
import { ParkbaseStorageService } from 'src/app/@shared/services/parkbase-storage.service';
import { FeedbackApiService } from 'src/app/@core/api/feedback-api.service';
import { FeedbackRequest } from 'src/app/@core/domain/interfaces/feedback.interface';
import { LogService } from 'src/app/@core';
import { environment } from 'src/environments/environment';
import { TrackingService } from 'src/app/@shared/services/tracking.service';

export const atLeastOne =
  (validator: ValidatorFn) =>
  (group: UntypedFormGroup): ValidationErrors | null => {
    const hasAtLeastOne =
      group && group.controls && Object.keys(group.controls).some((k) => !validator(group.controls[k]));

    return hasAtLeastOne ? null : { atLeastOne: true };
  };

@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent implements OnInit {
  title!: string;
  icon = 'star-outline';
  deviceInfo!: DeviceInfo;
  disabled = true;
  feedbackSent = false;
  feedbackForm!: any;
  selectedItem: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private deviceService: DeviceDetectorService,
    protected ref: NbDialogRef<any>,
    private parkbaseStorageService: ParkbaseStorageService,
    private trackingService: TrackingService,
    private router: Router,
    private feedbackApiService: FeedbackApiService,
    private logger: LogService
  ) {}

  ngOnInit(): void {
    this.title = this.title = 'ParkbaseGui 9 Beta - ' + environment.version + ' - Angular v' + VERSION.full;
    this.feedbackForm = this.formBuilder.group({
      ratingCtrl: new UntypedFormControl(null, Validators.max(5)),
      feedback: this.formBuilder.group(
        {
          suggestionsCtrl: new UntypedFormControl(null, Validators.maxLength(500)),
          errorsCtrl: new UntypedFormControl(null, Validators.maxLength(500)),
          complimentsCtrl: new UntypedFormControl(null, Validators.maxLength(500)),
        },
        { validator: atLeastOne(Validators.required) }
      ),
    });
  }

  closeDialog(): void {
    this.ref.close();
  }

  onSubmit(): void {
    let remark = '';
    if (!this.feedbackForm.value.ratingCtrl) {
      this.feedbackForm.value.ratingCtrl = 1;
      remark = '(No rating given by user, setting 1 as default.)';
    }
    if (this.feedbackForm.valid) {
      this.disabled = false;
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.trackingService.eventEmitter('feedbackComponent', 'action', 'feedback', 'onSubmit', 0);
      const payLoad: FeedbackRequest = {
        suggestion: this.feedbackForm.controls.feedback.value.suggestionsCtrl + ' - ' + remark,
        compliment: this.feedbackForm.controls.feedback.value.complimentsCtrl,
        errors: this.feedbackForm.controls.feedback.value.errorsCtrl,
        rating: this.feedbackForm.value.ratingCtrl,
        version: this.parkbaseStorageService.retrieveApiVersion(),
        division: this.parkbaseStorageService.retrieveDivision(),
        provider: '---',
        page: this.router.url,
        deviceInfo: {
          userAgent: this.deviceInfo.userAgent,
          operatingSystem: this.deviceInfo.os,
          operatingSystemVersion: this.deviceInfo.os_version,
          browser: this.deviceInfo.browser,
          browserVersion: this.deviceInfo.browser_version,
          device: this.deviceInfo.device,
          deviceType: this.deviceInfo.deviceType,
          orientatiion: this.deviceInfo.orientation,
          isMobile: this.deviceService.isMobile(),
          isTablet: this.deviceService.isTablet(),
        },
      };

      this.feedbackSent = true;
      this.feedbackApiService.sendFeedback(payLoad).subscribe((result) => {
        if (result.success) {
          this.logger.info('-- Feedback was saved by API', []);
          setTimeout(() => {
            this.closeDialog();
          }, 5000);
        }
      });
    }
  }

  toggle(): void {
    if (this.feedbackForm.disabled) {
      this.feedbackForm.enable();
    } else {
      this.feedbackForm.disable();
    }
  }
}
