import { Component, HostListener, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbLayoutRulerService, NbMenuItem } from '@nebular/theme';
import { Observable } from 'rxjs';
import { FacilitySelectMobileComponent } from '../facility-select-mobile/facility-select-mobile.component';
import { MainMenuConfigComponent } from '../../@shared/components/main-menu/main-menu.config.component';
import { NbAccessChecker } from '@nebular/security';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { FacilitiesApiService } from '../../@core/api/facilities-api.service';
import { FacilitySummary } from '../../@core/domain/interfaces/facility-summary.interface';
import { slideInAnimation } from '../../@shared/animation/animation';
import { ProfileMenuMobileComponent } from '../profile-menu-mobile/profile-menu-mobile.component';
import { SettingsMenuMobileComponent } from '../settings-menu-mobile/settings-menu-mobile.component';
import { ParkbaseStorageService } from '../../@shared/services/parkbase-storage.service';
import { TerminalOverviewMobileComponent } from '../terminal-overview-mobile/terminal-overview-mobile.component';
import { StatusPageService } from '../../pages/status-page/services/status-page.service';

@Component({
  selector: 'app-main-menu-mobile',
  templateUrl: './main-menu-mobile.component.html',
  styleUrls: ['./main-menu-mobile.component.scss'],
  animations: slideInAnimation,
})
export class MainMenuMobileComponent extends MainMenuConfigComponent implements OnInit {
  facilitySummary$!: Observable<FacilitySummary[]>;
  mainMenuItemsForMobile: NbMenuItem[] = [];

  constructor(
    public override accessChecker: NbAccessChecker,
    public override translate: TranslateService,
    protected dialogRef: NbDialogRef<MainMenuMobileComponent>,
    private facilitiesApiService: FacilitiesApiService,
    private parkbaseStorageService: ParkbaseStorageService,
    private statusPageService: StatusPageService,
    private dialogService: NbDialogService,
    private router: Router,
    private layoutRulerService: NbLayoutRulerService
  ) {
    super(accessChecker, translate);
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.getWindowSize();
  }

  /**
   * TODO: introduce store for this data as it is shared by multiple components
   */
  override ngOnInit(): void {
    super.ngOnInit();

    this.mainMenuItems.forEach((m) => {
      if (m.link !== '/terminals') {
        this.mainMenuItemsForMobile.push(m);
      }
    });

    this.facilitySummary$ = this.facilitiesApiService.facilitySummary;
    this.facilitiesApiService.loadFacilitySummary();

    this.statusPageService.refreshFacilitiesSummary.subscribe((refresh) => {
      if (refresh) {
        this.facilitiesApiService.loadFacilitySummary();
      }
    });

    this.getWindowSize();

    this.facilitySummary$.subscribe((d) => {});
  }

  close() {
    this.dialogRef.close(false);
  }

  openFacilitySelectMobile() {
    this.dialogService
      .open(FacilitySelectMobileComponent, {
        context: {
          facilitySummary$: this.facilitySummary$,
        },
      })
      .onClose.subscribe((res) => {
        if (res && res.handleNavigate && res.handleNavigate.changeBack) {
          // do nothing
          // this.dialogRef.close();
        } else {
          this.dialogRef.close();
        }
      });
  }

  openTerminalSelectMobile() {
    this.dialogService
      .open(TerminalOverviewMobileComponent, {
        context: {},
      })
      .onClose.subscribe((res) => {
        if (res && res.handleNavigate && res.handleNavigate.changeBack) {
          // do nothing
          // this.dialogRef.close();
        } else {
          this.dialogRef.close();
        }
      });
  }

  openMobile(menuItem: NbMenuItem) {
    if (menuItem && menuItem.data.mobile.useOverlay) {
      if (menuItem.data.mobile.use === 'profile-mobile') {
        this.dialogService.open(ProfileMenuMobileComponent).onClose.subscribe((res) => {});
      }
      if (menuItem.data.mobile.use === 'settings-mobile') {
        this.dialogService.open(SettingsMenuMobileComponent).onClose.subscribe((res) => {
          this.dialogRef.close();
        });
      }
    } else {
      if (menuItem.link) {
        this.dialogRef.close();
        this.router.navigateByUrl(menuItem.link).then(() => {});
      }
    }
  }

  private getWindowSize() {
    this.layoutRulerService.getDimensions().subscribe((data) => {
      if (data.clientWidth > 576) {
        // this.dialogRef.close();
      }
    });
  }
}
