<div class="terminals-page__body">
  <div class="page-container">
    <div class="heading">
      <h2>
        {{ 'TERMINALS.TITLE' | translate }}
        <ng-container *ngIf="division$ | async as division"> {{ division.name }} {{ division.city }}</ng-container>
      </h2>
    </div>
    <app-terminal-search></app-terminal-search>
  </div>
</div>
