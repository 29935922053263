import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  NbAccordionModule,
  NbActionsModule,
  NbAlertModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbCheckboxModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbOptionModule,
  NbPopoverModule,
  NbProgressBarModule,
  NbRadioModule,
  NbRouteTabsetModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbStepperModule,
  NbTabsetModule,
  NbTimepickerModule,
  NbToggleModule,
  NbTooltipModule,
  NbUserModule,
} from '@nebular/theme';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NbLayoutModule,
    NbCardModule,
    NbToggleModule,
    NbSidebarModule,
    NbTabsetModule,
    NbRouteTabsetModule,
    NbButtonModule,
    NbInputModule,
    NbFormFieldModule,
    NbRadioModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbTooltipModule,
    NbIconModule,
    NbSelectModule,
    NbOptionModule,
    NbButtonModule,
    NbPopoverModule,
    NbEvaIconsModule,
    NbSelectModule,
    NbOptionModule,
    NbSpinnerModule,
    NbAccordionModule,
    NbBadgeModule,
    NbListModule,
    NbActionsModule,
    NbUserModule,
    NbSecurityModule,
    NbAlertModule,
    NbTimepickerModule,
    NbDatepickerModule,
    NbMenuModule,
    NbProgressBarModule,
  ],
  exports: [
    CommonModule,
    NbLayoutModule,
    NbCardModule,
    NbLayoutModule,
    NbCardModule,
    NbToggleModule,
    NbSidebarModule,
    NbTabsetModule,
    NbRouteTabsetModule,
    NbButtonModule,
    NbInputModule,
    NbFormFieldModule,
    NbRadioModule,
    NbCheckboxModule,
    NbContextMenuModule,
    NbTooltipModule,
    NbIconModule,
    NbSelectModule,
    NbOptionModule,
    NbButtonModule,
    NbPopoverModule,
    NbEvaIconsModule,
    NbSelectModule,
    NbOptionModule,
    NbSpinnerModule,
    NbAccordionModule,
    NbBadgeModule,
    NbListModule,
    NbActionsModule,
    NbUserModule,
    NbSecurityModule,
    NbAlertModule,
    NbStepperModule,
    NbTimepickerModule,
    NbDatepickerModule,
    NbMenuModule,
    NbProgressBarModule,
  ],
  providers: [],
})
export class ThemeModule {}
