import { Component, OnInit, TemplateRef } from '@angular/core';
import { NbAccessChecker } from '@nebular/security';
import { User } from 'src/app/@core/domain/models/user.model';
import { FacilitiesChoice, ParkbaseStorageService } from 'src/app/@shared/services/parkbase-storage.service';
import { AuthService } from '../../auth/services/auth.service';
import { FacilityName } from '../../@core/domain/interfaces/facility.interface';
import { NbDialogService } from '@nebular/theme';
import _ from 'lodash';
import { LogService } from '../../@core';
import { TranslateService } from '@ngx-translate/core';

interface FacilitySelectionCount {
  selected: boolean;
  count: number;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  userProfile!: User;
  dateFormatChoiceOptions = [
    { value: 'Exact', label: '' },
    { value: 'Relative', label: '' },
  ];
  dateFormatChoice!: string;
  facilityOptions!: FacilityName[];
  facilitiesChoiceOptions!: any[];
  allFacilitiesSelected = false;
  tourChoice: any;
  tourChoiceOptions = [
    { value: 'true', label: '' },
    { value: 'false', label: '' },
  ];
  collapse: any;
  isCollapsed = true;

  constructor(
    public accessChecker: NbAccessChecker,
    private parkbaseStorageService: ParkbaseStorageService,
    private authService: AuthService,
    private dialogService: NbDialogService,
    private logger: LogService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.tourChoice = this.parkbaseStorageService.handleFirstUseCookie().toString();
    this.userProfile = this.parkbaseStorageService.retrieveUserSessionId();
    this.dateFormatChoice = this.parkbaseStorageService.retrieveDateformatChoice();
    this.facilityOptions = this.parkbaseStorageService.retrieveFacilities();
    const storedOptions = this.parkbaseStorageService.retrieveFacilitiesChoice();
    if (storedOptions && storedOptions.length > 0) {
      this.facilitiesChoiceOptions = storedOptions;
      const result = _(this.facilitiesChoiceOptions)
        .groupBy('selected')
        .map((item: any, itemId) => {
          return {
            selected: itemId,
            count: item.length,
          };
        })
        .value();
      if (result[0].selected === 'false') {
        /* empty */
      } else if (result[0].selected === 'true') {
        this.allFacilitiesSelected = this.facilitiesChoiceOptions.length === result[0]['count'];
      } else if (result[1].selected === 'true') {
        this.allFacilitiesSelected = this.facilitiesChoiceOptions.length === result[1]['count'];
      }
    } else {
      this.allFacilitiesSelected = false;
    }

    this.translateLabels();
    this.translate.onLangChange.subscribe((lang) => {
      if (lang) {
        this.translateLabels();
      }
    });
  }

  translateLabels() {
    this.translate.get('PROFILE.TOUR.SHOW').subscribe((d) => {
      this.tourChoiceOptions[0].label = d;
    });
    this.translate.get('PROFILE.TOUR.HIDE').subscribe((d) => {
      this.tourChoiceOptions[1].label = d;
    });
    this.translate.get('PROFILE.DATEFORMAT.EXACT').subscribe((d) => {
      this.dateFormatChoiceOptions[0].label = d;
    });
    this.translate.get('PROFILE.DATEFORMAT.RELATIVE').subscribe((d) => {
      this.dateFormatChoiceOptions[1].label = d;
    });
  }

  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: 'this is some additional data passed to dialog' });
  }

  storeDateformatChoice(dateFormatChoice: any) {
    this.parkbaseStorageService.storeDateformatChoice(dateFormatChoice);
    this.logger.info('Stored notifications date format choice', dateFormatChoice);
  }

  storeFacilitiesChoice(status: boolean, choice: FacilitiesChoice) {
    this.allFacilitiesSelected = false;
    this.facilitiesChoiceOptions.forEach((c) => {
      if (c.facility.id === choice.facility.id) {
        c.selected = status;
      }
    });
    this.parkbaseStorageService.storeFacilitiesChoice(this.facilitiesChoiceOptions);
    this.logger.info('Stored status dashboard facilities choice', []);
  }

  storeAllFacilitiesChoice(event$: any) {
    this.allFacilitiesSelected = !this.allFacilitiesSelected;
    this.facilitiesChoiceOptions.forEach((c) => {
      c.selected = this.allFacilitiesSelected;
    });
    this.parkbaseStorageService.storeFacilitiesChoice(this.facilitiesChoiceOptions);
  }

  storeTourChoice($event: any) {
    if ($event == 'true') {
      this.parkbaseStorageService.enableFirstUse();
      this.logger.info('Enabled firstUse tour', []);
    } else {
      this.parkbaseStorageService.disableFirstUse();
      this.logger.info('Disabled firstUse tour', []);
    }
  }
}
