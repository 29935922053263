<div *ngIf='showComponent' @slideIn @slideOut>
  <app-header-nav-mobile [componentRef]='globalSearchResultMobileDialogRef'
                         [handleNavigate]='true' title="{{ 'MOBILE.SEARCH' | translate }}">
  </app-header-nav-mobile>
  <div class='global-search-mobile-content'>
    <div *ngIf='searchResult' class='search-result-container'>
      <div *ngIf='searchResultType === 0'>
        <h5>License plate</h5>
        <table>
          <tr>
            <td>Type</td>
            <td>{{searchResult.type?.name}}</td>
          </tr>
          <tr>
            <td>Country</td>
            <td>{{searchResult.country}}</td>
          </tr>
          <tr>
            <td>Licenseplate</td>
            <td>{{searchResult.license}}</td>
          </tr>
          <tr>
            <td>Firstname</td>
            <td>{{searchResult.owner?.firstNames}}</td>
          </tr>
          <tr>
            <td>Middlename</td>
            <td>{{searchResult.owner?.middleName}}</td>
          </tr>
          <tr>
            <td>Lastname</td>
            <td>{{searchResult.owner?.lastName}}</td>
          </tr>
        </table>
      </div>
      <div *ngIf='searchResultType === 1'>
        <h5>Identity card</h5>
        <table>
          <tr>
            <td>Type</td>
            <td>{{searchResult.type?.name}}</td>
          </tr>
          <tr>
            <td>Publisher</td>
            <td>{{searchResult.publisher?.name}}</td>
          </tr>
          <tr>
            <td>Firstname</td>
            <td>{{searchResult.owner?.firstNames}}</td>
          </tr>
          <tr>
            <td>Middlename</td>
            <td>{{searchResult.owner?.middleName}}</td>
          </tr>
          <tr>
            <td>Lastname</td>
            <td>{{searchResult.owner?.lastName}}</td>
          </tr>
        </table>
      </div>
      <div *ngIf='searchResultType === 2'>
        <h5>Visitor</h5>
        <table>
          <tr>
            <td>Firstname</td>
            <td>{{searchResult.owner?.firstNames}}</td>
          </tr>
          <tr>
            <td>Middlename</td>
            <td>{{searchResult.owner?.middleName}}</td>
          </tr>
          <tr>
            <td>Lastname</td>
            <td>{{searchResult.owner?.lastName}}</td>
          </tr>
        </table>
      </div>
      <div *ngIf='searchResultType === 3'>
        <h5>Subscriber</h5>
        <table>
          <tr>
            <td>Firstname</td>
            <td>{{searchResult.owner?.firstNames}}</td>
          </tr>
          <tr>
            <td>Middlename</td>
            <td>{{searchResult.owner?.middleName}}</td>
          </tr>
          <tr>
            <td>Lastname</td>
            <td>{{searchResult.owner?.lastName}}</td>
          </tr>
        </table>
      </div>
      <!--      <ng-container-->
      <!--        *ngIf='searchResult.availableActions && searchResult.availableActions.length > 0; else noActions'>-->
      <!--        <app-cell (click)='openAvailableActionMobile(action)' *ngFor='let action of searchResult.availableActions'-->
      <!--                  [showNav]='true'-->
      <!--                  title='{{action.name}}'></app-cell>-->
      <!--      </ng-container>-->
      <ng-template #noActions>
        <p class='no-actions'>No actions available for search result.</p>
      </ng-template>
    </div>
  </div>
</div>
