import { Injectable } from '@angular/core';
import { AuthenticatedResult, LoginResponse, OidcSecurityService, UserDataResult } from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { User } from 'src/app/@core/domain/models/user.model';
import { ParkbaseStorageService } from '../../@shared/services/parkbase-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private user!: User;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private parkbaseStorageService: ParkbaseStorageService
  ) {
    // console.log('AuthService constructor', this.user);
  }

  /**
   * TODO there is a problem with the tokens expiring and the user still logged in
   * @param calledFrom
   */
  getUser(calledFrom: string): Observable<any> {
    // this.oidcSecurityService.forceRefreshSession().subscribe((data) => {
    //   console.log('forceRefreshSession', data);
    // });
    this.oidcSecurityService.isAuthenticated().subscribe((result) => {
      // console.log('calledFrom', calledFrom, 'isAuthenticated?', result);
    });
    return this.oidcSecurityService.getUserData();
  }

  clearCredentials(): void {
    this.oidcSecurityService.revokeAccessToken();
  }

  revokeAccessToken() {
    this.oidcSecurityService.revokeAccessToken().subscribe((result) => console.log(result));
  }

  setCredentials() {
    this.oidcSecurityService.isAuthenticated().subscribe((result) => {
      // console.log('this.oidcSecurityService.isAuthenticated', result);
      if (result) {
        this.oidcSecurityService.getUserData().subscribe((userData) => {
          if (result && userData && !this.user) {
            let storageKey = '';
            const u = userData;
            const existingUserData = this.parkbaseStorageService.retrieveUserSessionId();
            if (existingUserData && existingUserData.key && existingUserData.key.length > 0) {
              storageKey = existingUserData.key;
            }
            this.user = new User(
              storageKey.length > 0 ? storageKey : u.sub.split('-')[0],
              u.lastlogindate,
              u.preferred_username,
              u.email,
              u.role
            );
            this.parkbaseStorageService.storeUserSessionId(this.user);
          }
        });
      }
    });
  }
}
