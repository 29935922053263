<nb-card>
  <nb-card-header>
    <div class="header-text">{{ 'FEEDBACK.DIALOG.FEEDBACK_1' | translate }}</div>
    <div class="header-icon">
      <nb-icon (click)="closeDialog()" class="close-dialog" icon="close-outline" pack="eva">
      </nb-icon>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div *ngIf="!feedbackSent">
      <h4>{{ 'FEEDBACK.DIALOG.FEEDBACK_2' | translate }}</h4>
      <form (ngSubmit)="onSubmit()" [formGroup]="feedbackForm">
        <div class="star-rating">
          <h6>{{ 'FEEDBACK.DIALOG.FEEDBACK_3' | translate }}</h6>
          <ng-template #t let-fill="fill">
            <span [class.full]="fill === 100" class="star">
              <span [style.width.%]="fill" class="half">&#9733;</span>&#9733;
            </span>
          </ng-template>
          <ngb-rating
            [max]="5"
            [readonly]="false"
            [starTemplate]="t"
            class="rating"
            formControlName="ratingCtrl"
          ></ngb-rating>
        </div>
        <div class="textual-feedback" formGroupName="feedback">
          <h6>{{ 'FEEDBACK.DIALOG.FEEDBACK_7' | translate }}</h6>
          <div>
            <textarea
              class="textarea-feedback"
              formControlName="suggestionsCtrl"
              fullWidth
              nbInput
              placeholder="{{ 'FEEDBACK.DIALOG.FEEDBACK_8a' | translate }}"
              shape="semi-round"
            ></textarea>
            <textarea
              class="textarea-feedback"
              formControlName="errorsCtrl"
              fullWidth
              nbInput
              placeholder="{{ 'FEEDBACK.DIALOG.FEEDBACK_9a' | translate }}"
              shape="semi-round"
            ></textarea>
            <textarea
              class="textarea-feedback"
              formControlName="complimentsCtrl"
              fullWidth
              nbInput
              placeholder="{{ 'FEEDBACK.DIALOG.FEEDBACK_10a' | translate }}"
              shape="semi-round"
            ></textarea>
            <div class="feedback-actions">
              <button [disabled]="!feedbackForm.valid" nbButton shape="semi-round" status="success">
                {{ 'FEEDBACK.DIALOG.FEEDBACK_11' | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="feedbackSent">
      <h4>{{ 'FEEDBACK.DIALOG.FEEDBACK_12' | translate }}</h4>
      <p>{{ 'FEEDBACK.DIALOG.FEEDBACK_13' | translate }}</p>
    </div>
  </nb-card-body>
  <nb-card-footer>{{ title }}</nb-card-footer>
</nb-card>
