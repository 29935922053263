import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from 'src/app/@shared/services/config.service';
import { Alarm, AlarmRule } from '../domain/interfaces/alarm.interface';
import { BaseApiService } from './base-api.service';
import { ParkbaseStorageService } from '../../@shared/services/parkbase-storage.service';

/**
 * Load emergency alarms from external API
 */
@Injectable({
  providedIn: 'root',
})
export class AlarmsApiService extends BaseApiService {
  private _alarms = new BehaviorSubject<Alarm[]>([]);
  readonly alarms$ = this._alarms.asObservable();
  private _alarmsGoingFromApi = new BehaviorSubject<boolean>(false);
  readonly alarmsGoingFromApi$ = this._alarmsGoingFromApi.asObservable();

  private _dataStore: {
    alarms: Alarm[];
  } = {
    alarms: [],
  };

  /**
   * Set api header and url
   * @param configService
   * @param httpClient HttpClient
   * @param parkbaseStorageService
   */
  constructor(configService: ConfigService, httpClient: HttpClient, parkbaseStorageService: ParkbaseStorageService) {
    super(configService, httpClient);
    super.setStorage(parkbaseStorageService);
  }

  /**
   * GET /v1.0/divisions/emergency-alarms
   * See Alarm in domain/interfaces
   */
  loadDivisionsEmergencyAlarms(): void {
    this.http
      .get<Alarm[]>(this.parkbaseBl9BaseUrl + '/divisions/emergency-alarms', {
        headers: this.setHeaders(),
      })
      .subscribe(
        (data) => {
          this._dataStore.alarms = data;
          this._alarms.next(Object.assign({}, this._dataStore).alarms);
          const status: any[] = [];
          data.forEach((a) => {
            if (a.going) {
              status.push(a);
            }
          });
          if (status.length > 0) {
            this._alarmsGoingFromApi.next(true);
          } else {
            this._alarmsGoingFromApi.next(false);
          }
        },
        (error) => this.handleError('Could not load division emergency-alarms.', error)
      );
  }

  /**
   * GET /api/gui/v1.0/facilities/{id}/emergency-alarms
   * See Alarm in domain/interfaces
   */
  loadFacilityEmergencyAlarms(facilityId: number): void {
    this.http
      .get<Alarm[]>(this.parkbaseBl9BaseUrl + '/facilities/' + facilityId + '/emergency-alarms', {
        headers: this.setHeaders(),
      })
      .subscribe(
        (data) => {
          if (data) {
            this._dataStore.alarms = data;
            this._alarms.next(Object.assign({}, this._dataStore).alarms);
          }
        },
        (error) => this.handleError('Could not load facility emergency-alarms.', error)
      );
  }

  loadFacilityBypassAlarmRules(facilityId: number) {
    return this.http.get<AlarmRule[]>(this.parkbaseBl9BaseUrl + '/facilities/' + facilityId + '/alarm-rules/bypass', {
      headers: this.setHeaders(),
    });
  }
}
