import { Component, Input } from '@angular/core';
import { Occupation } from '../../../../../../@core/domain/interfaces/occupation.interface';

@Component({
  selector: 'app-total-occupation-ratio',
  templateUrl: './total-occupation-ratio.component.html',
  styleUrls: ['./total-occupation-ratio.component.scss'],
})
export class TotalOccupationRatioComponent {
  @Input()
  occupation!: Occupation;

  constructor() {}
}
