import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { slideInOutAnimation } from '../../@shared/animation/animation';
import { TerminalOverview } from '../../@core/domain/interfaces/terminal-response.interface';

@Component({
  selector: 'app-controlled-passage-mobile',
  templateUrl: './controlled-passage-mobile.component.html',
  styleUrls: ['./controlled-passage-mobile.component.scss'],
  animations: slideInOutAnimation,
})
export class ControlledPassageMobileComponent implements OnInit {
  @Input()
  terminalOverview!: TerminalOverview;

  @Input()
  action!: any;
  showComponent = true;

  constructor(protected terminalActionsMobileDialogRef: NbDialogRef<ControlledPassageMobileComponent>) {}

  ngOnInit(): void {}

  onChangeComponent($event: string) {
    setTimeout(() => {
      this.terminalActionsMobileDialogRef.close({ handleNavigate: { changeToAnonymous: true } });
    }, 250);
  }
}
