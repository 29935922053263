import { Component, Input, OnInit } from '@angular/core';
import { Terminal, TerminalActionType } from '../../../../@core/domain/interfaces/terminal.interface';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { TerminalsApiService } from '../../../../@core/api/terminals-api.service';
import { StatusPageService } from '../../../../pages/status-page/services/status-page.service';
import { ResponseStatus } from '../../../../@core/domain/interfaces/facility.interface';
import { TwoWayLaneConfirmationComponent } from './two-way-lane-confirmation/two-way-lane-confirmation.component';
import { ToastMessageService } from '../../../services/toast-message.service';
import { ParkbaseStorageService } from '../../../services/parkbase-storage.service';

@Component({
  selector: 'app-two-way-lane',
  templateUrl: './two-way-lane.component.html',
  styleUrls: ['./two-way-lane.component.scss'],
})
export class TwoWayLaneComponent implements OnInit {
  @Input()
  terminalId!: number;

  @Input()
  showTerminalTitle = true;

  terminal!: Terminal;
  newStatusDefinitions!: any[];
  awaitTerminalActionComplete = false;
  entryTerminal!: Terminal;
  exitTerminal!: Terminal;
  twoWayLaneForm!: UntypedFormGroup;
  hideElement = false;
  terminalActionError = false;
  terminalActionErrorMessage = '';
  terminalActionSuccess = false;
  terminalActionSuccessMessage = '';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private terminalsApiService: TerminalsApiService,
    private statusPageService: StatusPageService,
    private toastMessageService: ToastMessageService,
    private parkBaseStorageService: ParkbaseStorageService,
    private dialogService: NbDialogService
  ) {}

  ngOnInit(): void {
    this.buildForm();

    this.terminalsApiService.loadTerminalById(this.terminalId).subscribe((data) => {
      this.terminal = data;

      this.buildForm();
      this.setLaneTerminals();

      this.newStatusDefinitions = this.parkBaseStorageService.retrieveDefinitionList(6);
    });
  }

  changeTwoWayLane() {
    const twoWayLaneChange = {
      type: TerminalActionType.TwoWayLaneStatus,
      twoWayLaneStatus: Number(this.twoWayLaneForm.get('newLaneStatus')?.value),
      multipleTssWarningConfirmed: false,
    };
    this.terminalsApiService.sendTerminalAction(this.terminalId, twoWayLaneChange).subscribe((result) => {
      this.statusPageService.triggerRefreshTerminalSummary(true);
      if (result.status === ResponseStatus.Success) {
        this.handleSuccess(result);
      } else if (result.status === ResponseStatus.Failure) {
        this.handleFailure(result);
      } else if (result.status === ResponseStatus.InputRequired) {
        this.terminalActionSuccess = false;
        this.terminalActionSuccessMessage = '';
        this.terminalActionError = true;
        this.terminalActionErrorMessage = result.message;
        this.dialogService
          .open(TwoWayLaneConfirmationComponent, {
            context: { message: result.message, terminal: this.terminal },
          })
          .onClose.subscribe((res) => {
            if (res.confirmed && res.confirmed === true) {
              const twoWayLaneChangeWithConfirmation = {
                type: TerminalActionType.TwoWayLaneStatus,
                twoWayLaneStatus: Number(this.twoWayLaneForm.get('newLaneStatus')?.value),
                multipleTssWarningConfirmed: true,
              };
              this.statusPageService.triggerRefreshTerminalSummary(true);
              this.terminalsApiService
                .sendTerminalAction(this.terminal.id, twoWayLaneChangeWithConfirmation)
                .subscribe((result) => {
                  if (result.status === ResponseStatus.Success) {
                    this.handleSuccess(result);
                  }
                  if (result.status === ResponseStatus.Failure) {
                    this.handleFailure(result);
                  }
                });
            } else {
              this.twoWayLaneForm.reset();
            }
          });
      }
    });
  }

  private buildForm() {
    this.twoWayLaneForm = this.formBuilder.group({
      newLaneStatus: ['', [Validators.required]],
    });
  }

  private handleSuccess(result: any) {
    this.terminalActionSuccess = true;
    this.toastMessageService.showSuccess(result.message);
    this.terminalActionSuccessMessage = result.message;
    this.awaitTerminalActionComplete = false;
    this.terminalActionError = false;
    this.terminalActionErrorMessage = '';
    this.statusPageService.triggerRefreshTerminalSummary(true);
    this.terminalsApiService.loadTerminalById(this.terminal.id).subscribe((result) => {
      if (result) {
        this.terminal = result;
        this.buildForm();
        this.setLaneTerminals(result);
        this.terminalActionSuccess = true;
      }
    });
  }

  private handleFailure(result: any) {
    this.toastMessageService.showFailure('Error' + ' - ' + result.message);
    this.terminalActionError = true;
    this.terminalActionErrorMessage = result.message;
    this.terminalActionSuccess = false;
    this.terminalActionSuccessMessage = '';
  }

  private setLaneTerminals(terminal?: Terminal) {
    if (terminal) {
      this.buildLanes(terminal);
    } else {
      this.buildLanes(this.terminal);
    }
  }

  private buildLanes(terminal: Terminal) {
    if (terminal && terminal.twoWayLane) {
      this.entryTerminal = {
        id: terminal.twoWayLane.entryTerminal.id,
        name: terminal.twoWayLane.entryTerminal.name,
        type: terminal.twoWayLane.entryTerminal.type,
        status: terminal.twoWayLane.entryTerminal.status,
        availableActions: [],
      };
      this.exitTerminal = {
        id: terminal.twoWayLane.exitTerminal.id,
        name: terminal.twoWayLane.exitTerminal.name,
        type: terminal.twoWayLane.exitTerminal.type,
        status: terminal.twoWayLane.exitTerminal.status,
        availableActions: [],
      };
    }
  }
}
