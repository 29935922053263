import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ParkbaseStorageService } from '../../services/parkbase-storage.service';
import { FacilitiesApiService } from '../../../@core/api/facilities-api.service';
import { OccupationPerHour } from '../../../@core/domain/interfaces/occupation.interface';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

enum BarType {
  Occupation,
  Ratio,
}

interface Bar {
  value: number;
  metaData: any;
  itemStyle: any;
}

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  facilityId!: number;

  @Input()
  showDual = false;

  @Input()
  showNavigation = true;

  @Input()
  showDate = false;

  @Input()
  debug = false;

  currentDate = dayjs();
  today = this.currentDate.toDate();
  selectedDate = this.currentDate.toDate();
  showForward = false;
  options: any;
  updateOptions: any;
  echartsInstance: any;
  interval: any;

  colorDark = '#4a5b65';
  colorLight = '#e8f1f2';

  initOps: any = {
    renderer: 'svg',
  };

  colorOne = 'rgba(12,206,107,1.0)';
  colorTwo = 'rgba(249,200,14,1.0)';
  colorThree = 'rgba(255,69,14,1.0)';

  colors = [this.colorOne, this.colorTwo, this.colorThree];

  maximum!: number | undefined;

  showInput = false;

  testEmitter$ = new BehaviorSubject<any>({});

  private historyDataSubscription$!: Subscription;
  private translations!: any;

  constructor(
    private translate: TranslateService,
    private parkbaseStorageService: ParkbaseStorageService,
    private facilitiesApiService: FacilitiesApiService
  ) {}

  ngOnInit(): void {
    if (!this.facilityId) {
      throw new Error('Facility ID not provided!');
    }

    const defaultSeriesData: any[] = [];
    for (let i = 0; i < 12; i++) {
      defaultSeriesData.push({
        hour: i,
        occupationRatio: {
          occupation: this.randomIntFromInterval(1, 100),
        },
      });
    }

    this.options = this.getDefaultChartOptions(this.formatXAxis(), defaultSeriesData);

    this.translate.get('ACTIVITY').subscribe((res: string) => {
      this.translations = res;
    });
  }

  ngAfterViewInit() {
    this.getHistoryData(this.currentDate);
  }

  onChartInit(e: any) {
    this.echartsInstance = e;
    if (this.echartsInstance) {
      this.interval = setInterval(() => {
        this.getHistoryData(dayjs());
      }, 240000);
    }
  }

  onOptionsError($event: Error) {
    console.log('Options error in chart!', $event);
  }

  ngOnDestroy(): void {
    if (this.historyDataSubscription$) {
      this.historyDataSubscription$.unsubscribe();
    }
    this.interval = null;
    this.options = [];
    this.updateOptions = [];
    this.echartsInstance = null;
  }

  goBack(): void {
    this.currentDate = this.currentDate.subtract(1, 'day');
    this.selectedDate = this.currentDate.toDate();
    this.getHistoryData(this.currentDate);
  }

  goForward(): void {
    if (this.showForward) {
      this.currentDate = this.currentDate.add(1, 'day');
      this.selectedDate = this.currentDate.toDate();
      this.getHistoryData(this.currentDate);
    }
  }

  onSelectDate($event: any) {
    this.showInput = false;
    console.log('this.selectedDate', this.selectedDate);
    this.currentDate = dayjs($event);
    this.getHistoryData(dayjs($event));
  }

  private getHistoryData(utcDate: Dayjs) {
    const xAxisData: string[] = [];
    const occupation: Bar[] = [];
    const capacity: Bar[] = [];
    const backgroundStyle = this.getBackGroundStyle();

    this.showForward = utcDate.format('DD/MM/YYYY') !== dayjs().format('DD/MM/YYYY');
    this.historyDataSubscription$ = this.facilitiesApiService
      .loadActivity(this.facilityId, utcDate)
      .subscribe((data: OccupationPerHour[]) => {
        const dataPart = _.takeRight(data, 12);
        const length = dataPart.length;
        if (length < 12) {
          const toAdd = 12 - length;
          for (let i = 1; i < toAdd; i++) {
            xAxisData.push(i + 'Hr');
          }
        }
        const max = _.maxBy(data, 'occupationRatio.capacity');
        this.maximum = max?.occupationRatio.capacity;
        if (dataPart && this.maximum) {
          dataPart.forEach((occupationPerHour: OccupationPerHour) => {
            xAxisData.push(occupationPerHour.hour + 'Hr');
            occupation.push(this.getBarPart(occupationPerHour, BarType.Occupation));

            if (this.showDual) {
              capacity.push(this.getBarPart(occupationPerHour, BarType.Ratio));
            }
          });
          this.updateOptions = this.getDefaultChartOptions(xAxisData, []);

          this.updateOptions.tooltip = {
            trigger: 'axis',
            formatter: (params: any) => {
              return this.labelFormatter(params);
            },
          };
          this.updateOptions.series[0] = this.getChartOptionDataConfig(occupation);
          this.updateOptions.series[0]['backgroundStyle'] = backgroundStyle;

          if (this.showDual) {
            this.updateOptions.series[0] = this.getChartOptionDataConfig(capacity);
            this.updateOptions.series[1]['backgroundStyle'] = backgroundStyle;
          }
          this.testEmitter$.next(this.updateOptions);
        } else {
          this.updateOptions = this.getDefaultChartOptions(xAxisData, []);
          this.testEmitter$.next(this.updateOptions);
        }
      });
  }

  private randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  private getBarPart(occupationPerHour: OccupationPerHour, barType: BarType): Bar {
    let barValue = 0;
    if (barType === BarType.Occupation) {
      barValue = occupationPerHour.occupationRatio.occupation;
    }
    if (barType === BarType.Ratio) {
      barValue = occupationPerHour.occupationRatio.ratio;
    }
    // barValue = this.randomIntFromInterval(1, occupationPerHour.occupationRatio.capacity);
    return {
      value: Math.round(barValue),
      metaData: occupationPerHour,
      itemStyle: {
        color: this.getRatioColorNum(occupationPerHour),
        shadowColor: this.getRatioColorNum(occupationPerHour),
        shadowBlur: 0,
        shadowOffsetX: 0,
        shadowOffsetY: 0,
      },
    };
  }

  private getRatioColorNum(o: OccupationPerHour): string {
    const percentage = (100 * o.occupationRatio.occupation) / o.occupationRatio.capacity;
    if (percentage < 50) {
      return this.colors[0];
    } else if (percentage >= 50 && percentage < 80) {
      return this.colors[1];
    } else if (percentage >= 80) {
      return this.colors[2];
    } else {
      return 'grey';
    }
  }

  private getChartOptionDataConfig(data: any[]): any {
    return {
      type: 'bar',
      data: data,
      color: this.colorDark,
      itemStyle: {
        borderRadius: [4, 4, 0, 0],
      },
      backgroundStyle: {
        color: '#293a47',
      },
      showBackground: true,
    };
  }

  private getBackGroundStyle() {
    const theme = this.parkbaseStorageService.getTheme();
    let backgroundStyle;
    if (theme === 'parkbaseDark') {
      backgroundStyle = {
        color: 'rgba(12, 206, 107, 0.16)',
        borderColor: '#142735',
        borderWidth: 10,
        borderType: 'solid',
      };
    } else {
      this.colorLight = '#142735';
      backgroundStyle = {
        color: '#dde2e4',
        borderColor: '#f2f6f8',
        borderWidth: 10,
        borderType: 'solid',
      };
    }

    return backgroundStyle;
  }

  private roundToPrecision(value: number, precision: number) {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  private labelFormatter(params: any) {
    const metaData = params[0].data.metaData;
    let labelPart = '';
    if (metaData.occupationRatio.checkCapacity) {
      labelPart = this.translations['ON'];
    } else {
      labelPart = this.translations['OFF'];
    }
    return (
      metaData.hour +
      'hr ' +
      this.roundToPrecision(metaData.occupationRatio.ratio, 2) +
      '%, ' +
      this.translations['CHECK_CAPACITY'] +
      '&nbsp' +
      labelPart +
      ', ' +
      this.translations['PRE_FULL_SIGNAL_CAPACITY'] +
      '&nbsp' +
      metaData.occupationRatio.preFullSignalCapacity
    );
  }

  private getDefaultChartOptions(xAxisData: any, series: any) {
    return {
      grid: {
        left: '0px',
        top: '0px',
        bottom: '0px',
        right: '0px',
        height: 'auto',
        width: 'auto',
        containLabel: 'false',
      },
      lazyUpdate: true,
      xAxis: {
        show: true,
        axisTick: {
          show: false,
        },
        axisLine: {
          symbol: 'none',
          lineStyle: {
            type: 'solid',
            width: '0px',
          },
        },
        data: xAxisData,
        silent: false,
        splitLine: {
          show: false,
        },
      },
      responsive: true,
      yAxis: {
        show: false,
        type: 'value',
        max: this.maximum,
        containLabel: 'false',
      },
      label: {
        show: false,
      },
      tooltip: {
        enable: true,
      },
      series: [
        {
          data: series,
          type: 'bar',
          color: '#4a5b65',
          showBackground: true,
          backgroundStyle: {
            color: '#293a47',
          },
        },
      ],
      animationEasing: 'elasticOut',
    };
  }

  private formatXAxis() {
    const xAxisData: string[] = [];
    for (let i = 0; i < 12; i++) {
      xAxisData.push(i + 'Hr');
    }
    return xAxisData;
  }
}
