import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { ReplaySubject } from 'rxjs';
import { Alarm, EmergencyAlarm } from 'src/app/@core/domain/interfaces/alarm.interface';
import { ConfigService } from 'src/app/@shared/services/config.service';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Config } from '../domain/interfaces/config.interface';
import { LogService } from '../logging/log.service';
import { ParkbaseStorageService } from '../../@shared/services/parkbase-storage.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root',
})
export class FacilityHubService {
  private hubUrl!: string;
  private hubConnection!: HubConnection;
  private _facilityEmergencyAlarmsSource = new ReplaySubject<Alarm[]>();
  readonly facilityEmergencyAlarms$ = this._facilityEmergencyAlarmsSource.asObservable();

  constructor(
    private configService: ConfigService,
    private authService: AuthService,
    private parkbaseStorageService: ParkbaseStorageService,
    private oidcSecurityService: OidcSecurityService,
    private logger: LogService
  ) {
    // this.connectWithConfiguration(); called from statusPage
  }

  callHub(facilityId: number) {
    try {
      if (this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {
        this.hubConnection.invoke('RegisterAsync', facilityId);
        this.hubConnection.on('FacilityEmergencyAlarmsChanged', (alarms: EmergencyAlarm[]) => {
          if (alarms && alarms.length > 0) {
            this._facilityEmergencyAlarmsSource.next(alarms);
            this.logger.info('FacilityHub for facility ' + facilityId + ' callHub received new alarms', alarms);
          }
        });
      }
    } catch (err) {
      console.error(err);
      this.logger.error('Error in FacilityHub callHub', err);
    }
  }

  reconnect() {
    this.stopConnection();
    this.connectWithConfiguration();
  }

  stop() {
    this.stopConnection();
  }

  connectWithConfiguration() {
    if (!this.hubConnection) {
      this.configService.loadConfiguration().then((result: Config) => {
        this.hubUrl = result.server.resourcesBL9;
        const user = this.authService.getUser('FacilityHubService connectWithConfiguration');
        const ippLanguage = this.parkbaseStorageService.getLanguageForApi();
        const headers = { 'Ipp-Language': ippLanguage };
        if (user) {
          this.oidcSecurityService.getAccessToken().subscribe((token) => {
            this.hubConnection = new HubConnectionBuilder()
              .withUrl(this.hubUrl + '/hub/facility?ipp-Language=' + ippLanguage, {
                headers: headers,
                accessTokenFactory: () => token,
              })
              .configureLogging(LogLevel.Error)
              .withAutomaticReconnect()
              .build();
            this.startConnection();
          });
        }
      });
    }
  }

  private startConnection() {
    this.startHub(this.hubConnection).then((result: boolean) => {
      if (result) {
        this.hubConnection.onreconnecting((error: any) => {
          this.logger.warning('FacilityHub error on reconnecting', error);
        });
        this.hubConnection.on('Error', (message) => {
          this.logger.error('Error in FacilityHub startHub', message);
          throw new Error(message);
        });
      }
    });
  }

  private startHub(connection: HubConnection): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (connection && connection.state === HubConnectionState.Disconnected) {
        connection.start().then(() => {
          this.logger.info('FacilityHub connection started', []);
          resolve(true);
        });
      }
    });
  }

  private stopConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop().catch((error: any) => {
        this.logger.error('Error stopping hubConnection in FacilityHub', error);
      });
    }
  }
}
