import { Injectable } from '@angular/core';
import { IdentificationsApiService } from 'src/app/@core/api/identifications-api.service';
import { Observable, of } from 'rxjs';
import { LicensePlate } from 'src/app/@core/domain/interfaces/shared/licenseplate.interface';
import { IdentificationCard } from 'src/app/@core/domain/interfaces/shared/identification-card.interface';
import { Parker } from 'src/app/@core/domain/interfaces/shared/parker.interface';
import { ParkersApiService } from 'src/app/@core/api/parkers-api.service';

@Injectable({
  providedIn: 'root',
})
export class IdentificationSearchService {
  constructor(
    private identificationsApiService: IdentificationsApiService,
    private parkersApiService: ParkersApiService
  ) {}

  searchIdentificationByType(
    id: number,
    type: number
  ): Observable<LicensePlate | IdentificationCard | Parker | any> {
    switch (type) {
      case 0:
      case 1:
        return this.identificationsApiService.searchIdentificationsbyId(id);
      case 2:
        return this.parkersApiService.searchParkerVisitor(id);
      case 3:
        return this.parkersApiService.searchParkerVisitor(id);
      default:
        return of([]);
    }
  }
}
