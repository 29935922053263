import { Observable, of } from 'rxjs';
import { LogEntry, LogLevel } from './log.service';
import { LogApiService } from '../api/log-api.service';

export abstract class LogPublisher {
  location!: string;

  abstract log(record: LogEntry): Observable<boolean>;

  abstract clear(): Observable<boolean>;

  abstract setLocation(location: string): void;
}

export class LogToApi extends LogPublisher {
  constructor(private logApiService: LogApiService) {
    super();
    this.location = 'api';
  }

  log(logEntry: LogEntry): Observable<boolean> {
    const message = logEntry.buildLogString();
    const type = LogLevel[logEntry.level].toString();
    if (this.location === 'api') {
      this.logApiService.storeLogMessage(message, type);
    } else if (this.location === 'console') {
      console.log('DEV LOG, CONSOLE ONLY:', message, logEntry);
    }
    return of(true);
  }

  clear(): Observable<boolean> {
    return of(true);
  }

  setLocation(location: string): void {
    this.location = location;
  }
}
