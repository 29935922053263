<div (click)="clickHandler($event)" class="multi-select-dropdown">
  <button (click)="toggleSelectDropdown($event)" [ngClass]="toggleDropdown ? 'active' : ''" class="{{cssClass}}"
          fullWidth
          id="multiSelectButton" nbButton nbSuffix>
    <span [ngClass]="selectPlaceholderText.length > 0 ? 'items-selected' : ''"
          id="componentTitle">{{componentTitleText}}</span>
    <span [ngClass]="selectPlaceholderText.length > 0 ? 'selection-count' : ''">{{selectPlaceholderText}}</span>
    <nb-icon *ngIf="toggleDropdown" icon='arrow-up' nbSuffix pack='eva'></nb-icon>
    <nb-icon *ngIf="!toggleDropdown" icon='arrow-down' nbSuffix pack='eva'></nb-icon>
  </button>
  <div *ngIf="toggleDropdown" class="multi-select-dropdown-list-container">
    <div *ngIf="config.searchEnabled" class="search-container">
      <div class="form-floating">
        <input (keyup)='search($event)' [(ngModel)]="searchTerm" class="form-control" fullWidth id="searchTermInput"
               nbInput placeholder="{{searchInputPlaceholderText}}"
               shape="semi-round" type="text">
        <label for="searchTermInput">
          <nb-icon icon='search' nbPrefix pack='eva'></nb-icon>
          {{searchInputPlaceholderText}}</label>
        <nb-icon (click)="clearSearchTerm()" *ngIf="searching" class="close-icon" icon='close' nbSuffix
                 pack='eva'></nb-icon>
      </div>
    </div>
    <nb-checkbox (checkedChange)="toggleSelectAllItems()" *ngIf="config.searchEnabled" [checked]="allItemsSelected">
      <span class="option-select-all">{{'MULTI_SELECT_DROPDOWN.OPTION_ALL' | translate}}</span>
    </nb-checkbox>
    <div [ngClass]="{'allItemsSelected': allItemsSelected }" class="selected-items">
      <nb-checkbox (click)="deselectItem(selected,i,$event)" *ngFor="let selected of selectedItems;let i = index"
                   checked=true>{{selected.label}}
      </nb-checkbox>
    </div>
    <hr *ngIf="selectedItems.length > 0 && availableItems.length > 0"/>
    <span *ngIf="searching">{{availableItems.length}} items found</span>
    <div *ngIf="!allItemsSelected" class="available-items">
      <nb-checkbox (checkedChange)="selectItem(item,i,$event)"
                   *ngFor="let item of availableItems;let i = index"><span
        [innerHTML]="item.label | highlightSearch: searchTerm"></span></nb-checkbox>
    </div>
  </div>
</div>
