import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { slideInOutAnimation } from '../../@shared/animation/animation';
import { TerminalOverview } from '../../@core/domain/interfaces/terminal-response.interface';

@Component({
  selector: 'app-terminal-events-mobile',
  templateUrl: './terminal-events-mobile.component.html',
  styleUrls: ['./terminal-events-mobile.component.scss'],
  animations: slideInOutAnimation,
})
export class TerminalEventsMobileComponent implements OnInit {
  @Input()
  terminalOverview!: TerminalOverview;

  @Input()
  action!: any;

  showComponent = true;

  constructor(protected terminalActionsMobileDialogRef: NbDialogRef<TerminalEventsMobileComponent>) {}

  ngOnInit(): void {}

  back() {
    this.showComponent = false;
    setTimeout(() => {
      this.terminalActionsMobileDialogRef.close({ handleNavigate: { changeBack: true } });
    }, 250);
  }

  close() {
    this.terminalActionsMobileDialogRef.close(true);
  }
}
