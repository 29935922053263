<ng-template #dialog let-data let-ref="dialogRef">
  <div class="dialog-container">
    <nb-card class="global-search-card">
      <nb-card-header>
        <div class="search-header">
          <div>
            <h5>{{ placeholder }}</h5>
          </div>
          <div class="close-button">
            <nb-icon (click)="ref.close()" icon="close-outline" nbPrefix pack="eva"></nb-icon>
          </div>
        </div>
      </nb-card-header>
      <nb-card-body>
        <div class="form-group search">
          <nb-form-field>
            <nb-icon icon="search" nbPrefix pack="eva"></nb-icon>
            <input
              (ngModelChange)="changed()"
              [(ngModel)]="searchInput"
              autocomplete="off"
              class="global-search appearance-outline status-primary nb-transition"
              fieldSize="giant"
              fullWidth
              nbInput
              placeholder="{{ 'GLOBAL_SEARCH.PLACEHOLDER' | translate }}"
              shape="semi-round"
              type="text"
            />
            <button
              (click)="clearSearchForm()"
              *ngIf="searching"
              class="clear-button"
              ghost
              nbButton
              nbSuffix
              type="button"
            >
              {{ 'GLOBAL_SEARCH.CLEAR' | translate }}
            </button>
          </nb-form-field>
        </div>
        <div [ngClass]="change ? 'collapsed-search' : 'show-search'">
          <div class="search-results-content">
            <div *ngIf="!searching">
              <div class="search-results-tab-container">
                <ul class="search-result-list">
                  <h6>{{ 'GLOBAL_SEARCH.RECENT_SEARCHES' | translate }}</h6>
                  <li *ngFor="let searchTerm of recentSearches" class="recent-searches">
                    <div (click)="redoSearch(searchTerm)" class="recent-search-result">
                      <nb-icon icon="clock" nbPrefix pack="eva"></nb-icon>
                      <span>{{ searchTerm }}</span>
                    </div>
                    <div>
                      <nb-icon
                        (click)="removeRecentSearch(searchTerm)"
                        class="icon-close"
                        icon="close-circle-outline"
                        nbSuffix
                        pack="eva"
                      ></nb-icon>
                    </div>
                  </li>
                  <div *ngIf="recentSearches && recentSearches.length === 0" class="no-search-results">
                    {{ 'GLOBAL_SEARCH.NO_RECENT_SEARCHES' | translate }}
                  </div>
                </ul>
              </div>
            </div>
            <div *ngIf="searching && !searchResult" [nbSpinner]="searching" class="spinner">
              <div>
                <p>{{ 'GLOBAL_SEARCH.LOADING' | translate }} '{{ searchInput }}'</p>
              </div>
            </div>
            <div *ngIf="searching && searchResult as results">
              <nb-tabset (changeTab)="onChangeTab($event)" *ngIf="results && results[0]">
                <nb-tab [tabIcon]="AllIconConfig" tabTitle="{{ 'GLOBAL_SEARCH.TABS.ALL' | translate }}">
                  <div (scroll)="onScroll($event)" class="search-results-tab-container">
                    <ul class="search-result-list">
                      <h6>
                        {{ 'GLOBAL_SEARCH.TABS.LICENSE_PLATES' | translate
                        }}<span class="badge badge-light">{{ results[0].count }}</span>
                      </h6>
                      <li
                        (click)="navigateToSubscribers({ result: result, type: results[0].type })"
                        *ngFor="let result of results[0].results"
                        class="y"
                      >
                        <div>
                          <nb-icon icon="car-outline" nbPrefix pack="eva"></nb-icon>
                          <span class="result-label">{{ 'IDENTIFICATION.OWNER' | translate }}:</span>
                          <span class="no-search-results">{{
                            result.owner ? result.owner['lastName'] : ('GLOBAL_SEARCH.NO_RESULT' | translate)
                          }}</span>
                        </div>
                        <div>
                          <app-license-plate
                            *ngIf="result && result.country && result.license"
                            [country]="result.country"
                            [licence]="result.license"
                          ></app-license-plate>
                        </div>
                      </li>
                      <div *ngIf="results[0].results.length === 0" class="no-search-results">
                        {{ 'GLOBAL_SEARCH.NO_SEARCH_RESULTS' | translate }}
                      </div>
                      <h6>
                        {{ 'GLOBAL_SEARCH.TABS.CARD_NUMBERS' | translate
                        }}<span class="badge badge-light">{{ results[1].count }}</span>
                      </h6>
                      <li
                        (click)="navigateToSubscribers({ result: result, type: results[1].type })"
                        *ngFor="let result of results[1].results"
                      >
                        <nb-icon icon="archive-outline" nbPrefix pack="eva"></nb-icon>
                        {{ 'SUBSCRIPTION.HRI' | translate }}:&nbsp; {{ result.hri }}&nbsp;
                        {{ 'SUBSCRIPTION.PUBLISHER' | translate }}:&nbsp; {{ result.publisher?.name }}&nbsp;
                        {{ 'SUBSCRIPTION.TYPE' | translate }}:&nbsp; {{ result.type?.name }}&nbsp;
                        <span class="result-label">{{ 'SUBSCRIPTION.OWNER' | translate }}:</span>
                        <span class="no-search-results">{{
                          result.owner ? result.owner['lastName'] : ('GLOBAL_SEARCH.NO_RESULT' | translate)
                        }}</span>
                      </li>
                      <div *ngIf="results[1].results.length === 0" class="no-search-results">
                        {{ 'GLOBAL_SEARCH.NO_SEARCH_RESULTS' | translate }}
                      </div>
                      <h6>
                        {{ 'GLOBAL_SEARCH.TABS.SUBSCRIPTION_NUMBERS' | translate
                        }}<span class="badge badge-light">{{ results[2].count }}</span>
                      </h6>
                      <li
                        (click)="navigateToSubscribers({ result: result, type: results[2].type })"
                        *ngFor="let result of results[2].results"
                      >
                        <nb-icon icon="briefcase-outline" nbPrefix pack="eva"></nb-icon>
                        {{ 'PERSON.FIRSTNAME' | translate }}:&nbsp; {{ result.firstNames }}&nbsp;
                        {{ 'PERSON.MIDDLENAME' | translate }}:&nbsp; {{ result.middleName }}&nbsp;
                        {{ 'PERSON.LASTNAME' | translate }}:&nbsp; {{ result.lastName }}&nbsp;
                      </li>
                      <div *ngIf="results[2].results.length === 0" class="no-search-results">
                        {{ 'GLOBAL_SEARCH.NO_SEARCH_RESULTS' | translate }}
                      </div>
                      <h6>
                        {{ 'GLOBAL_SEARCH.TABS.TRANSIENTS' | translate
                        }}<span class="badge badge-light">{{ results[3].count }}</span>
                      </h6>
                      <li
                        (click)="navigateToSubscribers({ result: result, type: results[3].type })"
                        *ngFor="let result of results[3].results"
                      >
                        <nb-icon icon="person-outline" nbPrefix pack="eva"></nb-icon>
                        {{ 'PERSON.FIRSTNAME' | translate }}:&nbsp; {{ result.firstNames }}&nbsp;
                        {{ 'PERSON.MIDDLENAME' | translate }}:&nbsp; {{ result.middleName }}&nbsp;
                        {{ 'PERSON.LASTNAME' | translate }}:&nbsp; {{ result.lastName }}&nbsp;
                      </li>
                      <div *ngIf="results[3].results.length === 0" class="no-search-results">
                        {{ 'GLOBAL_SEARCH.NO_SEARCH_RESULTS' | translate }}
                      </div>
                    </ul>
                  </div>
                  <div
                    *ngIf="results"
                    [ngClass]="
                      results[0].count > 5 || results[1].count > 5 || results[2].count > 5 || results[3].count > 5
                        ? 'showMoreIcon'
                        : 'hideMoreIcon'
                    "
                    class="z"
                  >
                    <nb-icon class="icon-navigate" icon="{{ scrollIcon }}" nbSuffix pack="eva"></nb-icon>
                  </div>
                </nb-tab>
                <nb-tab
                  [tabIcon]="licenseIconConfig"
                  badgeText="{{ results[0].count }}"
                  tabTitle="{{ 'GLOBAL_SEARCH.TABS.LICENSE_PLATES' | translate }}"
                >
                  <app-licenseplate-list
                    (navigate)="navigateToSubscribers($event)"
                    *ngIf="currentTab === 'Kentekens' || currentTab === 'License plates'"
                    [data]="results[0]"
                    [searchInput]="searchInput"
                  ></app-licenseplate-list>
                </nb-tab>
                <nb-tab
                  [tabIcon]="cardNumberIconConfig"
                  badgeText="{{ results[1].count }}"
                  tabTitle="{{ 'GLOBAL_SEARCH.TABS.CARD_NUMBERS' | translate }}"
                >
                  <app-card-list
                    (navigate)="navigateToSubscribers($event)"
                    *ngIf="currentTab === 'Kaartnummers' || currentTab === 'Card numbers'"
                    [data]="results[1]"
                    [searchInput]="searchInput"
                  ></app-card-list>
                </nb-tab>
                <nb-tab
                  [tabIcon]="subscriptionIconConfig"
                  badgeText="{{ results[2].count }}"
                  tabTitle="{{ 'GLOBAL_SEARCH.TABS.SUBSCRIPTION_NUMBERS' | translate }}"
                >
                  <app-subscriber-list
                    (navigate)="navigateToSubscribers($event)"
                    *ngIf="currentTab === 'Abonnees' || currentTab === 'Subscription numbers'"
                    [data]="results[2]"
                    [searchInput]="searchInput"
                  ></app-subscriber-list>
                </nb-tab>
                <nb-tab
                  [tabIcon]="transientIconConfig"
                  badgeText="{{ results[3].count }}"
                  tabTitle="{{ 'GLOBAL_SEARCH.TABS.TRANSIENTS' | translate }}"
                >
                  <app-visitor-list
                    (navigate)="navigateToSubscribers($event)"
                    *ngIf="currentTab === 'Bezoekers' || currentTab === 'Visitors'"
                    [data]="results[3]"
                    [searchInput]="searchInput"
                  ></app-visitor-list>
                </nb-tab>
              </nb-tabset>
            </div>
          </div>
        </div>
        <app-result-details
          (closeSearchResult)="onCloseSearchResult()"
          [ngClass]="change ? 'show-search' : 'collapsed-search'"
          [searchResultData]="searchResultData"
          [searchResultType]="searchResultType"
        ></app-result-details>
      </nb-card-body>
      <nb-card-footer>
        <div>{{ 'GLOBAL_SEARCH.FEEDBACK.HINT' | translate }}</div>
        <div>
          <span (click)="openFeedback($event)" class="feedback-link">{{
            'GLOBAL_SEARCH.FEEDBACK.CTA' | translate
          }}</span
          ><span>!</span>
        </div>
      </nb-card-footer>
    </nb-card>
  </div>
</ng-template>
<div class="search-dummy">
  <nb-icon (click)="open(dialog)" icon="search" nbPrefix pack="eva"></nb-icon>
  <input
    #dummyInput
    (click)="open(dialog)"
    (keydown)="open(dialog)"
    [placeholder]="placeholder"
    autocomplete="off"
    class="appearance-outline status-primary nb-transition dummy-input-global-search"
    fullWidth
    name="dummy"
    nbInput
    shape="semi-round"
    size="large"
    type="text"
  />
</div>
