<div *ngIf='showComponent' @slideIn @slideOut>
  <app-header-nav-mobile [componentRef]='terminalActionsMobileDialogRef'
                         [handleNavigate]='true'
                         title="{{ 'MOBILE.ANONYMOUS_PASSAGE' | translate }}"></app-header-nav-mobile>
  <div class='terminal-actions-mobile-content'>
    <app-cell [showNav]='false' [terminalOverview]='terminalOverview'></app-cell>
  </div>
  <div class='terminal-actions-mobile-forms'>
    <app-anonymous-passage (changeComponent)='onChangeComponent($event)'
                           [action]='action'
                           [showTerminalTitle]=false
                           [terminalId]='terminalOverview.id'
    ></app-anonymous-passage>
  </div>
</div>


