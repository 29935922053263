import { Component, Input, OnInit } from '@angular/core';
import {
  Occupation,
  OccupationRatio,
} from '../../../../../../@core/domain/interfaces/occupation.interface';

@Component({
  selector: 'app-other-occupation-ratio',
  templateUrl: './other-occupation-ratio.component.html',
  styleUrls: ['./other-occupation-ratio.component.scss'],
})
export class OtherOccupationRatioComponent implements OnInit {
  @Input()
  occupation!: Occupation;

  @Input()
  showBorder!: boolean;

  transientOccupation!: OccupationRatio;
  subscriberOccupation!: OccupationRatio;
  customOccupation!: OccupationRatio;

  constructor() {}

  ngOnInit(): void {
    this.occupation.occupationRatios.forEach((o) => {
      switch (o.type.id) {
        case 1:
          this.subscriberOccupation = o;
          break;
        case 2:
          this.transientOccupation = o;
          break;
        case 3:
          this.customOccupation = o;
          break;
      }
    });
  }
}
