<form [formGroup]="totalsForm" class="totals-form">
  <div *ngFor="let ratios of occupation.occupationRatios; let i = index">
    <div class="occupation-number-row">
      <div class="alarm-led">
        <app-alarm-led
          [ledConfig]="{
            label: false,
            color: getCategoryColor(occupation.occupationRatios[i]),
            tiny: false
          }"
          class="alarm-led"
        ></app-alarm-led>
      </div>
      <div class="box-element">
        <span *ngIf="(!editing || !occupation.occupationRatios[i].adjustable) && editMode === 0" class="occupation"
          >{{ occupation.occupationRatios[i].occupation }}&nbsp;</span
        >
        <span *ngIf="(!editing || !occupation.occupationRatios[i].adjustable) && editMode === 1" class="occupation"
          >{{ occupation.occupationRatios[i].capacity - occupation.occupationRatios[i].occupation }}&nbsp;</span
        >
        <input
          *ngIf="editing && occupation.occupationRatios[i].adjustable"
          autoSizeInput
          class="input-totals"
          fieldSize="small"
          formControlName="ctrlOccupation{{ occupation.occupationRatios[i].type.id }}"
          min="0"
          name="occupation"
          nbInput
          type="number"
        />
        <span class="label">
          {{ 'TOTALS.OUT_OFF' | translate }} {{ occupation.occupationRatios[i].capacity }}
          <span *ngIf="occupation.occupationRatios[i].editMode === 0">{{ 'TOTALS.EDIT_MODE_0' | translate }}</span>
          <span *ngIf="occupation.occupationRatios[i].editMode === 1">{{ 'TOTALS.EDIT_MODE_1' | translate }}</span>
          {{ occupation.occupationRatios[i].type.name }}</span
        >
      </div>
    </div>
    <div class="occupation-number-row">
      <div *ngIf="occupation.reservationOccupationRatios[i]" class="alarm-led">
        <app-alarm-led
          [ledConfig]="{
            label: false,
            color: getReservationCategoryColor(occupation.reservationOccupationRatios[i]),
            tiny: false
          }"
          class="alarm-led"
        ></app-alarm-led>
      </div>
      <div *ngIf="occupation.reservationOccupationRatios[i]" class="box-element">
        <span *ngIf="!editing || !occupation.reservationOccupationRatios[i].adjustable" class="occupation"
          >{{ occupation.reservationOccupationRatios[i].occupation }}&nbsp;</span
        >
        <input
          *ngIf="editing && occupation.reservationOccupationRatios[i].adjustable"
          autoSizeInput
          class="input-totals"
          fieldSize="medium"
          formControlName="ctrlReservation{{ occupation.reservationOccupationRatios[i].type.id }}"
          min="0"
          name="occupation"
          nbInput
          type="number"
        />
        <span class="label"
          >{{ 'TOTALS.RESERVED' | translate }} {{ 'TOTALS.OUT_OFF' | translate }}
          {{ occupation.reservationOccupationRatios[i].allocation }} ({{
            occupation.reservationOccupationRatios[i].type.name
          }})</span
        >
      </div>
    </div>
  </div>
</form>
