import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';
import { ToastMessageService } from '../../@shared/services/toast-message.service';
import { Router } from '@angular/router';

/**
 * Adds a default error handler to all requests.
 * Note: ngx-toastr allows use of html, nebular toastr does not
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  private messageCount = 0;

  constructor(private toastMessageService: ToastMessageService, private router: Router) {}

  /**
   * Intercept http requests catching and handling errors
   * @param request HttpRequest
   * @param next HttpHandler
   * @returns Observable<HttpEvent<any>>
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      debounceTime(500),
      catchError((response) => {
        if (
          response &&
          response.error &&
          response.error.url &&
          response.error.url.contains('/facilities/') &&
          response.error.status === 404
        ) {
          return this.errorHandler(response);
        }
        if (response && response.status === 0 && response.error instanceof ProgressEvent) {
          console.error('Connection time out or No internet connection! Check configuration.');
          this.messageCount += 1;
          if (this.messageCount <= 3) {
            this.toastMessageService.showFailure('Error fetching data from API - NO CONNECTION ERROR');
          }
        }
        return this.errorHandler(response);
      })
    );
  }

  /**
   * Customize the default error handler here if needed
   * @param response incoming response from API
   * @private
   */
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    this.showToastMessage(response);
    throw response;
  }

  private showToastMessage(response: any) {
    let message = '';
    if (response.status && (response.status === 404 || response.status === 400)) {
      return;
    }
    if (response.status && (response.status === 401 || response.status === 403)) {
      // this.toastMessageService.showFailure('Error fetching data from API - 401 ERROR');
      this.router.navigateByUrl('/unauthorized').then((r) => {
        console.log('Error fetching data from API - 401 ERROR');
      });
    }
    if (response.status && response.status === 200 && response.ok === false) {
      console.log('200 OK FALSE', response);
      // this.toastMessageService.showFailure('Error fetching data from API - 200 OK false ERROR');
    }
    if (response.status && response.status === 500) {
      if (response.error && response.error['Message']) {
        message = response.error['Message'] + response.message;
      } else {
        message = response.message;
      }
      this.toastMessageService.showFailure('500 ERROR - ' + message);
    }
  }
}
