<div class='layout-selector'>
  <h6>{{ "PROFILE.LAYOUTCHOICE" | translate }}</h6>
  <nb-form-field>
    <nb-icon icon='cube' nbPrefix pack='eva'></nb-icon>
    <nb-select [(selected)]='selectedLayout'
               class='appearance-outline status-basic nb-transition'
               fullWidth
               placeholder='Select layout'
               shape='semi-round' size='large'>
      <nb-option
        (click)='setLayout(layout)'
        *ngFor='let layout of layoutOptions'
        value='{{ layout }}'
      >{{ layout }}</nb-option>
    </nb-select>
  </nb-form-field>
</div>
