import { Component, Input, OnInit } from '@angular/core';
import { Terminal, TerminalActionType } from '../../../../@core/domain/interfaces/terminal.interface';
import { DefinitionsApiService } from '../../../../@core/api/definitions-api.service';
import { TerminalsApiService } from '../../../../@core/api/terminals-api.service';
import dayjs from 'dayjs';
import { StatusPageService } from '../../../../pages/status-page/services/status-page.service';
import { ResponseStatus } from '../../../../@core/domain/interfaces/facility.interface';
import { ToastMessageService } from '../../../services/toast-message.service';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NbDateService } from '@nebular/theme';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();

@Component({
  selector: 'app-loop-access-mode',
  templateUrl: './loop-access-mode.component.html',
  styleUrls: ['./loop-access-mode.component.scss'],
})
export class LoopAccessModeComponent implements OnInit {
  @Input()
  terminalId!: number;

  @Input()
  action!: any;

  @Input()
  showTerminalTitle = true;

  terminal!: Terminal;
  selectedLoopAccessDate: Date | undefined = new Date();
  selectedLoopAccessTime: Date | undefined = new Date();
  loopAccessModes!: any;
  loopAccessMode!: any;
  selectedLoopAccessMode = 999;
  reason: any;
  hideElement = false;
  temporaryStatusEndTime: string | undefined;
  submitDisabled = true;
  timeZoneOffset: any;

  min!: Date;
  max!: Date;

  private buttonClicked = new Subject<boolean>();
  private interval: any;

  constructor(
    private definitionsApiService: DefinitionsApiService,
    private terminalsApiService: TerminalsApiService,
    private statusPageService: StatusPageService,
    private toastMessageService: ToastMessageService,
    protected dateService: NbDateService<Date>
  ) {
    this.min = this.dateService.today();
    this.max = this.dateService.addMonth(this.dateService.today(), 1);
  }

  ngOnInit(): void {
    this.loadTerminal();

    this.timeZoneOffset = new Date().getTimezoneOffset();

    this.statusPageService.refreshTerminalSummary.subscribe((refresh) => {
      if (refresh) {
        this.terminalsApiService.loadTerminalById(this.terminalId).subscribe((t) => {
          this.terminal = t;
        });
      }
    });

    this.interval = setInterval(() => {
      if (this.temporaryStatusEndTime) {
        if (dayjs().isAfter(dayjs(this.temporaryStatusEndTime))) {
          this.loopAccessMode = undefined;
          this.loadTerminal();
        }
      }
    }, 10000);

    this.definitionsApiService.loadDefinitionsById(5).subscribe((definitions) => {
      this.loopAccessModes = definitions;
    });

    const buttonClickedDebounced = this.buttonClicked.pipe(debounceTime(200));
    buttonClickedDebounced.subscribe(() => {
      this.setLoopAccessMode();
    });
  }

  onChange($event: any) {
    this.selectedLoopAccessMode = Number($event);
    this.submitDisabled = !($event && (this.selectedLoopAccessMode === 0 || this.selectedLoopAccessMode === 1));
  }

  clearDatePicker() {
    this.selectedLoopAccessDate = undefined;
  }

  clearTimePicker() {
    this.selectedLoopAccessTime = undefined;
  }

  clickButton() {
    this.buttonClicked.next(true);
  }

  /**
   * YYYY-MM-DD HH:mm
   */
  private setLoopAccessMode() {
    const loopAccessModeTerminalAction = {
      type: TerminalActionType.LoopAccessMode,
      mode: Number(this.selectedLoopAccessMode),
      date: dayjs(this.selectedLoopAccessDate).format('YYYY-MM-DD'),
      time: dayjs(this.selectedLoopAccessTime).format('HH:mm'),
    };
    this.terminalsApiService.sendTerminalAction(this.terminalId, loopAccessModeTerminalAction).subscribe((result) => {
      if (result.status === ResponseStatus.Success) {
        this.loopAccessMode = result;
        this.statusPageService.triggerRefreshTerminalSummary(true);
        this.toastMessageService.showSuccess(result.message);
        this.loadTerminal();
      } else if (result.status === ResponseStatus.Failure) {
        this.toastMessageService.showFailure(result.message);
      } else if (result.status === ResponseStatus.InputRequired) {
        this.toastMessageService.showInputRequired(result.message);
      } else if (result.status === ResponseStatus.PartialFailure) {
        this.toastMessageService.showPartialFailure(result.message);
      }
    });
  }

  private loadTerminal() {
    this.terminalsApiService.loadTerminalById(this.terminalId).subscribe((t) => {
      this.terminal = t;
      if (t.temporaryStatusUtcEndTime) {
        this.temporaryStatusEndTime = this.utcDate(t.temporaryStatusUtcEndTime);
      } else {
        this.statusPageService.triggerRefreshTerminalSummary(true);
        this.temporaryStatusEndTime = undefined;
      }
    });
  }

  private utcDate(datTime: string): string {
    return dayjs.utc(datTime.substring(0, 23)).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm').toString();
  }
}
