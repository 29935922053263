import { Component, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { GlobalSearchMobileComponent } from '../global-search-mobile/global-search-mobile.component';
import { NotificationsMobileComponent } from '../notifications-mobile/notifications-mobile.component';
import { MobileContainerComponent } from '../mobile-container/mobile-container.component';

@Component({
  selector: 'app-page-header-mobile',
  templateUrl: './page-header-mobile.component.html',
  styleUrls: ['./page-header-mobile.component.scss'],
})
export class PageHeaderMobileComponent implements OnInit {
  constructor(private dialogService: NbDialogService) {}

  ngOnInit(): void {}

  openMobileNav() {
    this.dialogService.open(MobileContainerComponent, {
      context: {
        title: 'MainMenu',
      },
    });
  }

  openMobileSearch() {
    this.dialogService.open(GlobalSearchMobileComponent, {
      context: {},
    });
  }

  openNotificationsMobile() {
    this.dialogService.open(NotificationsMobileComponent, {
      context: {},
    });
  }
}
