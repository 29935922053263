import { Component, OnInit } from '@angular/core';
import { Terminal } from '../../@core/domain/interfaces/terminal.interface';
import { ToastMessageService } from '../../@shared/services/toast-message.service';
import { FacilitiesApiService } from '../../@core/api/facilities-api.service';
import { FacilityName, FacilityTerminalSummary } from '../../@core/domain/interfaces/facility.interface';
import { ParkbaseStorageService } from '../../@shared/services/parkbase-storage.service';
import {
  ModelType,
  MultiSelectConfig,
  SelectItem,
  SelectStyle,
} from '../../@shared/components/multi-select-dropdown/multi-select-dropdown.component';

// 231 = ok terminal for screen
// 43 = ok for two way lane

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.scss'],
})
export class TestPageComponent implements OnInit {
  terminal: Terminal = {
    id: 50,
    name: 'RAAM Kassa 510',
    type: { id: 6, name: 'Kassa' },
    status: { id: 1, name: 'In bedrijf', color: 1 },
    availableActions: [
      { type: 2, name: 'Zet buiten bedrijf' },
      {
        type: 31,
        name: 'Schermopname van terminal bekijken',
      },
      { type: 32, name: 'Verander de lus toegangsmodus' },
      { type: 35, name: 'Verloren kaart uitgeven' },
    ],
    twoWayLane: undefined,
    payDeviceProfile: {
      id: 15,
      name: 'ITS1',
      lostTicketMode: { id: 3, name: 'Portier, aanpassing toegestaan' },
    },
    feeSchedules: [{ id: 1, name: 'Parkeerder', type: 0 }],
  };

  toastMessageInput = {
    status: 'success',
    title: 'title',
    message: 'message',
  };

  data: any = '&#39;';
  facilityWithTerminals!: FacilityTerminalSummary[];
  loading = false;

  facilities!: FacilityName[];

  itemList: SelectItem[] = [];

  multiSelect: any = [];
  config: MultiSelectConfig = {
    displayKey: 'label',
    searchEnabled: true,
    multiple: true,
    model: ModelType.ParkerType,
    style: SelectStyle.Transparent,
  };
  theValues: any;

  constructor(
    private toastMessageService: ToastMessageService,
    private facilitiesApiService: FacilitiesApiService,
    private parkBaseStorageService: ParkbaseStorageService
  ) {}

  ngOnInit(): void {
    // this.toastMessageService.showFailure(
    //   'sdfgsdfgsdfgsdfgs',
    //   'sdfgsdf sdfgsdfgsdfgsdfgs sdfgsdfgsdfgsdfgs sdfgsdfgsdfgsdfgs sdfgsdfgsdfgsdfgsgsdf'
    // );

    this.facilities = this.parkBaseStorageService.retrieveFacilities();

    this.loading = true;
    this.facilitiesApiService.loadFacilityWithTerminals().subscribe((r) => {
      this.loading = false;
      this.facilityWithTerminals = r;
    });

    for (let i = 0; i <= 600; i++) {
      this.itemList.push({ id: i, label: i + '-Item ' + this.makeString() });
    }
  }

  makeString(): string {
    let outString = '';
    const inOptions = 'abcdefghijklmnopqrstuvwxyz0123456789';

    for (let i = 0; i < 32; i++) {
      outString += inOptions.charAt(Math.floor(Math.random() * inOptions.length));
    }

    return outString;
  }

  openTerminal(t: any) {}

  changeValue($event: Event) {
    this.theValues = $event;
  }
}
