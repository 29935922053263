<nb-icon
  *ngIf="facilityStatusIcon.icon === 'facility'"
  class="{{ facilityStatusIcon.alertClass }}"
  icon="facility"
  nbPrefix
  nbTooltip="{{ facilityStatusIcon.openingState }}"
  nbTooltipPlacement="top"
  pack="customIcons"
  status="control"
></nb-icon>
<nb-icon
  *ngIf="facilityStatusIcon.icon === 'facility_closed'"
  class="{{ facilityStatusIcon.alertClass }}"
  icon="facility_closed"
  nbPrefix
  nbTooltip="{{ facilityStatusIcon.openingState }}"
  nbTooltipPlacement="top"
  pack="customIcons"
  status="control"
></nb-icon>
