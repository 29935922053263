import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EChartsOption } from 'echarts';
import {
  Occupation,
  OccupationEditMode,
  OccupationRatio,
} from '../../../../../@core/domain/interfaces/occupation.interface';
import { TotalsHelper } from '../totals.helper';
import { formatNumber } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ParkbaseStorageService } from '../../../../../@shared/services/parkbase-storage.service';
import { round } from 'lodash';

@Component({
  selector: 'app-totals-chart',
  templateUrl: './totals-chart.component.html',
  styleUrls: ['./totals-chart.component.scss'],
})
export class TotalsChartComponent implements OnInit, OnChanges {
  @Input()
  editing!: boolean;

  @Input()
  occupation!: Occupation;

  chartOption: EChartsOption = {
    series: [{}],
  };
  initOps: object = {
    renderer: 'svg',
  };
  totalCapacity = 0;
  totalOccupation = 0;
  totalFree = 0;
  labelRatio = 0;

  private loaded = false;
  private editMode!: OccupationEditMode;
  private full = false;
  private theme: any;
  private translations!: any;

  constructor(private translate: TranslateService, private parkbaseStorageService: ParkbaseStorageService) {}

  ngOnInit(): void {
    this.theme = this.parkbaseStorageService.getTheme();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.totalOccupation = 0;
      this.theme = this.parkbaseStorageService.getTheme();
      this.translate.get('TOTALS').subscribe((res) => {
        this.translations = res;
        this.setChartData(this.occupation);
      });
    }
  }

  /**
   * TODO: the calculation is completely bonkers, try fixing it so anyone can understand
   * @param occupation
   * @private
   */
  private setChartData(occupation: Occupation) {
    if (occupation.occupationRatios.length > 0) {
      this.setChartOptions();

      this.loaded = true;

      this.editMode = occupation.occupationRatios[0].editMode;
      this.totalCapacity = occupation.occupationRatios[0].capacity;
      this.totalOccupation = occupation.occupationRatios[0].occupation;

      this.occupation.occupationRatios.forEach((value: OccupationRatio, i: number) => {
        if (i === 0) {
          this.labelRatio = value.ratio;
          this.full = this.labelRatio === 100;
        }
        if (i >= 1) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.chartOption.series[1].data.push({
            value: value.occupation,
            name: this.translations['COUNTING'] + ' ' + value.type.name.toLowerCase(),
            itemStyle: {
              color: TotalsHelper.getCategoryColor(value),
            },
            label: {
              show: false,
            },
            tooltip: {
              trigger: 'item',
              position: ['25%', '25%'],
              extraCssText: 'z-index: 2000, position: absolute, background: yellow',
              formatter: (params: any) => this.formatPartLabel(params),
            },
          });
        }
      });

      // center part

      this.totalFree = this.totalCapacity - this.totalOccupation;

      let percentage = 0;
      if (this.totalFree > 0) {
        percentage = this.totalCapacity >= this.totalFree ? (this.totalFree / this.totalCapacity) * 100 : 0;
      }

      let prefixWord = '';
      const labelWord = ' ' + this.translations['OF'] + ' ';

      if (this.editMode === 0) {
        prefixWord = this.translations['TOTAL_OCCUPIED'];
      } else if (this.editMode === 1) {
        prefixWord = this.translations['TOTAL_FREE'];
      }

      let prefixNumber = this.totalOccupation;
      if (this.editMode === 1) {
        prefixNumber = this.totalCapacity - this.totalOccupation;
      }

      const prefix = prefixWord;
      const number = formatNumber(prefixNumber, this.translate.currentLang);
      const postfix = labelWord + formatNumber(this.totalCapacity, this.translate.currentLang);

      const centerPart = {
        value: percentage,
        name: 'name',
        itemStyle: {
          color: 'transparent',
        },
        label: {
          show: true,
          formatter: [`{word|${prefix}}`, `{occupation|${number}}`, `{word|${postfix}}`].join('\n'),
          rich: {
            word: {
              fontSize: 18,
              fontFamily: 'InterRegular',
              color: 'rgb(130, 144, 151)',
              padding: 5,
            },
            occupation: {
              color: this.theme === 'parkbaseDark' ? '#e8f1f2' : '#142735',
              fontFamily: 'InterRegularSemiBold',
              fontSize: 48,
              fontWeight: 'bold',
            },
          },
        },
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.chartOption.series[1].data.push(centerPart);
    }
  }

  private formatPartLabel(params: any) {
    const x = this.translations['CATEGORY'];
    return `${x} ${params.seriesName}<br />
                    ${params.name}: ${round(params.data.value, 2)} (${round(params.percent, 0)}%)<br />`;
  }

  private setChartOptions(): void {
    this.chartOption = {
      tooltip: {
        trigger: 'item',
        position: ['25%', '25%'],
        name: '',
      },
      series: [
        {
          name: 'totals',
          type: 'pie',
          selectedMode: 'single',
          clockwise: false,
          radius: ['65%', '85%'],
          center: ['50%', '50%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: false,
              fontFamily: 'InterRegularSemiBold',
              fontSize: '22',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            {
              value: 180,
              itemStyle: {
                color: 'rgba(128,255,253,.24)',
              },
            },
            {
              value: 180,
              itemStyle: {
                color: 'rgba(12,206,107,.16)',
              },
            },
          ],
        },
        {
          name: 'totals',
          type: 'pie',
          animation: true,
          clockwise: true,
          radius: ['65%', '85%'],
          center: ['50%', '50%'],
          avoidLabelOverlap: true,
          label: {
            show: true,
            position: 'center',
            fontFamily: 'InterRegularSemiBold',
            fontSize: '40',
            fontWeight: 'bold',
            color: this.theme === 'parkbaseDark' ? '#e8f1f2' : '#142735',
            borderWidth: 0,
          },
          data: [],
        },
      ],
    };
  }
}
