import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { StatusService } from '../../services/status.service';
import { Terminal, TerminalStatus2 } from '../../../@core/domain/interfaces/terminal.interface';
import { TerminalOverview } from '../../../@core/domain/interfaces/terminal-response.interface';
import dayjs from 'dayjs';
import { TerminalsApiService } from '../../../@core/api/terminals-api.service';
import { StatusPageService } from '../../../pages/status-page/services/status-page.service';

@Component({
  selector: 'app-terminal-title',
  templateUrl: './terminal-title.component.html',
  styleUrls: ['./terminal-title.component.scss'],
})
export class TerminalTitleComponent implements OnInit, OnChanges {
  @Input()
  terminalOverview!: Terminal | TerminalOverview;

  @Input()
  show!: boolean;

  @Input()
  showHeading = true;

  @Input()
  terminalType = true;

  loopAccessEndTime: string | undefined;

  constructor(
    public statusService: StatusService,
    private terminalsApiService: TerminalsApiService,
    private statusPageService: StatusPageService
  ) {}

  ngOnInit(): void {
    this.statusPageService.refreshTerminalSummary.subscribe((status) => {
      if (status) {
        this.loadTerminal();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (
        this.terminalOverview &&
        this.terminalOverview.status &&
        this.terminalOverview.status.id === TerminalStatus2.VehicleLoopAccess
      ) {
        this.loadTerminal();
      } else {
        this.loopAccessEndTime = undefined;
      }
    }
  }

  private loadTerminal() {
    if (this.terminalOverview) {
      this.terminalsApiService.loadTerminalById(this.terminalOverview.id).subscribe((t) => {
        this.terminalOverview = t;
        if (t.temporaryStatusUtcEndTime) {
          this.loopAccessEndTime = this.utcDate(t.temporaryStatusUtcEndTime);
        } else {
          this.loopAccessEndTime = undefined;
        }
      });
    }
  }

  private utcDate(datTime: string): string {
    return dayjs.utc(datTime.substring(0, 23)).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm').toString();
  }
}
