<div class="notification-filter">
  <div class="notification-filter__header">
    <div class="search-filter">
      <nb-form-field>
        <nb-icon icon="search" nbPrefix pack="eva"></nb-icon>
        <input
          (keyup)="filtering = true"
          [(ngModel)]="searchTerm"
          class="input-notification-filter"
          fieldSize="giant"
          fullWidth
          nbInput
          placeholder="{{ 'NOTIFICATIONS.FILTER.TITLE' | translate }}"
          shape="semi-round"
          type="text"
        />
        <button (mouseup)="clearFilterForm()" *ngIf="filtering" class="clear-button" ghost nbButton nbSuffix>
          <nb-icon [icon]="filtering ? 'close-outline' : ''" pack="eva"></nb-icon>
        </button>
      </nb-form-field>
      <!--      <div class="range-select">-->
      <!--        <div class="custom-slider">-->
      <!--          <ngx-slider-->
      <!--            (userChangeEnd)="setEndRange($event)"-->
      <!--            [(highValue)]="maxValue"-->
      <!--            [(value)]="minValue"-->
      <!--            [options]="options"-->
      <!--          ></ngx-slider>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
  <div class="filter-types">
    <nb-list *ngIf="filterSet && filterSet.filtersSelected" class="definition-list">
      <nb-list-item
        *ngFor="let definition of filterSet.filtersSelected | filterNotificationName : searchTerm"
        class="definition-row"
      >
        <nb-checkbox (checkedChange)="toggle($event, definition.id)" [checked]="definition.checked"
          ><span
            [innerHTML]="definition.name | highlightSearch : searchTerm"
            [ngClass]="{ selected: definition.checked }"
            class="definition"
          ></span
        ></nb-checkbox>
      </nb-list-item>
    </nb-list>
  </div>
  <div class="filter-actions">
    <button (click)="resetFilterSet()" class="button-reset" nbButton shape="semi-round">
      {{ 'BUTTONS.RESET' | translate }}
    </button>
    <button (click)="cancelFilterSet()" class="button-cancel" nbButton shape="semi-round">
      {{ 'BUTTONS.CANCEL' | translate }}
      <nb-icon icon="close-outline" pack="eva"></nb-icon>
    </button>
    <!--    <button (click)="applyFilterSet()" class="button-apply" nbButton shape="semi-round">-->
    <!--      {{ 'BUTTONS.APPLY' | translate }}-->
    <!--      <nb-icon icon="checkmark-outline" pack="eva"></nb-icon>-->
    <!--    </button>-->
    <button (click)="saveFilterSet()" class="button-save" nbButton shape="semi-round">
      {{ 'BUTTONS.SAVE' | translate }}
      <nb-icon icon="edit-2"></nb-icon>
    </button>
  </div>
</div>
