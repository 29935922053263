<div class="terminals-filter-container">
  <div class="terminal-search">
    <nb-form-field>
      <nb-icon icon="search" nbPrefix pack="eva"></nb-icon>
      <input
        (keyup)="onSearchFilter()"
        [(ngModel)]="searchTerm"
        fullWidth
        nbInput
        placeholder="{{ 'TERMINAL_SEARCH.PLACEHOLDER' | translate }}"
        shape="semi-round"
        size="large"
        status="basic"
        type="text"
      />
      <button (click)="clearSearchForm()" *ngIf="searching" class="clear-button" ghost nbButton nbSuffix type="button">
        {{ 'GLOBAL_SEARCH.CLEAR' | translate }}
      </button>
    </nb-form-field>
    <button
      [nbPopover]="templateRef"
      class="filter-button"
      nbButton
      nbPopoverPlacement="bottom"
      outline
      shape="semi-round"
      size="large"
      status="basic"
    >
      <nb-icon icon="funnel-outline"></nb-icon>
    </button>
  </div>
</div>
<ng-template #templateRef>
  <div class="terminals-filter">
    <nb-card>
      <div class="filter-list">
        <h6>Terminal type</h6>
        <div class="toggle">
          <nb-toggle (change)="toggleSelectAll()" [(ngModel)]="isEveryModelSelected" name="modelSelection">
            <span *ngIf="!isEveryModelSelected">
              {{ 'BUTTONS.SELECT_ALL' | translate }}
            </span>
            <span *ngIf="isEveryModelSelected">
              {{ 'BUTTONS.UNSELECT_ALL' | translate }}
            </span>
          </nb-toggle>
        </div>
        <ul>
          <li *ngFor="let d of filterTerminalTypes">
            <nb-checkbox (checkedChange)="toggle($event, d)" [checked]="d.selected">{{ d.name }}</nb-checkbox>
          </li>
        </ul>
      </div>
    </nb-card>
  </div>
</ng-template>
<div class="row terminals">
  <div class="terminals-grouped" id="terminals-grouped">
    <div [nbSpinner]="loadingTerminals" class="terminals-test" nbSpinnerSize="giant" nbSpinnerStatus="primary">
      <ng-container *ngFor="let f of facilityWithTerminals; let i = index">
        <div *ngIf="terminalsToShow(f)" class="facility-block">
          <h5>{{ f.facility.name }}</h5>
          <div *ngIf="f.terminals && f.terminals.length > 0" class="terminals-list">
            <ng-container *ngFor="let t of f.terminals">
              <app-cell
                (click)="openTerminal(t)"
                [ngClass]="{ visible: t.selectedForFilter }"
                [terminalOverview]="t"
                class="diminished"
              ></app-cell>
            </ng-container>
          </div>
        </div>
        <!--            <div *ngIf='!terminalsToShow(f)' class='facility-block'>-->
        <!--              <h5>{{f.facility.name}}</h5>-->
        <!--              <p>{{ 'TERMINAL_SEARCH.NO_RESULTS' | translate }}</p>-->
        <!--            </div>-->
      </ng-container>
    </div>
  </div>
</div>
