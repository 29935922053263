<nb-card *ngIf="!confirmationMode">
  <nb-card-header>
    <h5>{{ 'TRANSACTIONS.COMPLETION' | translate }}</h5>
  </nb-card-header>
  <nb-card-body>
    <nb-alert *ngIf="errorMessage" class="alert" id="alert" status="danger">
      <nb-icon icon="alert-circle-outline" nbPrefix pack="eva"></nb-icon>
      {{ errorMessage }}
    </nb-alert>
    <form #form="ngForm">
      <app-info-alert *ngIf="dateType === DateType.UNDEFINED" [showIcon]="true" [title]="infoTitle"></app-info-alert>
      <div class="form-group">
        <button
          (click)="setDateTypeSpecific()"
          class="margin-bottom-1"
          nbButton
          nbTooltip="{{ 'TRANSACTIONS.SPECIFIC_DATE_HINT' | translate }}"
          status="{{ dateTypeSpecificSelected ? 'info' : 'basic' }}"
          value="absolute"
        >
          {{ 'TRANSACTIONS.SPECIFIC_DATE' | translate }}
        </button>
        <button
          (click)="setDateTypeRelative()"
          class="margin-bottom-1"
          nbButton
          nbTooltip="{{ 'TRANSACTIONS.TIME_IN_MINUTES_HINT' | translate }}"
          status="{{ dateTypeRelativeSelected ? 'info' : 'basic' }}"
          value="relative"
        >
          {{ 'TRANSACTIONS.TIME_IN_MINUTES' | translate }}
        </button>
      </div>
      <div>
        <app-date-time-selector
          *ngIf="dateType === 0"
          [(ngModel)]="completionDate"
          [maxDate]="maxCompletionDate"
          [minDate]="minCompletionDate"
          class="margin-bottom-1"
          name="completionDate"
          nbTooltip="{{ 'TRANSACTIONS.DEPARTURE_DATE' | translate }}"
          required
        >
        </app-date-time-selector>
        <div *ngIf="dateType === 1" class="margin-bottom-1">
          <label>{{ 'TRANSACTIONS.DURATION_IN_MINUTES' | translate }}</label>
          <nb-form-field>
            <nb-icon icon="clock-outline" nbPrefix pack="eva"></nb-icon>
            <input
              [(ngModel)]="duration"
              fullWidth
              max="4320"
              min="0"
              name="duration"
              nbInput
              pattern="\d*"
              placeholder="{{ 'TRANSACTIONS.TIME_IN_MINUTES' | translate }}"
              required
              shape="semi-round"
              size="large"
              step="1"
              type="number"
            />
          </nb-form-field>
        </div>

        <nb-spinner *ngIf="!exitTerminals"></nb-spinner>

        <div *ngIf="exitTerminals" class="margin-bottom-1">
          <label>{{ 'TRANSACTIONS.SELECT_EXIT_TERMINAL' | translate }}</label>
          <nb-form-field>
            <nb-icon icon="monitor-outline" nbPrefix pack="eva"></nb-icon>
            <nb-select
              [(ngModel)]="selectedExitTerminalId"
              fullWidth
              name="exitTerminalId"
              placeholder="{{ 'TRANSACTIONS.EXIT_TERMINAL' | translate }}"
              required
              shape="semi-round"
              size="large"
            >
              <nb-option *ngFor="let terminal of exitTerminals" [value]="terminal.id">{{ terminal.name }}</nb-option>
            </nb-select>
          </nb-form-field>
        </div>

        <nb-spinner *ngIf="!completionReasons"></nb-spinner>

        <div class="margin-bottom-1">
          <label>{{ 'TRANSACTIONS.SELECT_EXIT_REASON' | translate }}</label>
          <nb-form-field>
            <nb-icon icon="sync-outline" nbPrefix pack="eva"></nb-icon>
            <nb-select
              [(ngModel)]="reason"
              fullWidth
              name="reason"
              placeholder="{{ 'TRANSACTIONS.EXIT_REASON' | translate }}"
              required
              shape="semi-round"
              size="large"
            >
              <nb-option *ngFor="let reason of completionReasons" [value]="reason.id">{{ reason.name }}</nb-option>
            </nb-select>
          </nb-form-field>
        </div>

        <div class="action-form-buttons">
          <button (click)="cancel()" class="margin-bottom-1" nbButton type="button">
            {{ 'TRANSACTIONS.CANCEL' | translate }}
            <nb-icon icon="close-outline" pack="eva"></nb-icon>
          </button>
          <button
            (click)="completeTransactionsResume()"
            [disabled]="!form.valid"
            class="margin-bottom-1"
            nbButton
            nbTooltip="{{ 'TRANSACTIONS.COMPLETION_NEXT_HINT' | translate }}"
            status="success"
            type="button"
          >
            {{ 'TRANSACTIONS.CONTINUE' | translate }}
          </button>
        </div>
      </div>
    </form>
  </nb-card-body>
</nb-card>
<nb-card *ngIf="confirmationMode">
  <nb-card-header>
    <h5>{{ 'TRANSACTIONS.COMPLETION_INFORMATION' | translate }}</h5>
  </nb-card-header>
  <nb-card-body>
    <app-transactions-summary [summary]="transactionsSummary"></app-transactions-summary>
    <div class="transaction-summary">
      <p *ngIf="completionDate">
        <nb-icon icon="calendar-outline" pack="eva">event</nb-icon>
        <ng-container>{{ 'TRANSACTIONS.DEPARTURE_DATE' | translate }}</ng-container>
        : <span class="text-bold">{{ completionDate | date : 'medium' }}</span>
      </p>

      <p *ngIf="duration">
        <nb-icon icon="clock-outline" pack="eva"></nb-icon>
        <ng-container>{{ 'TRANSACTIONS.DURATION' | translate }}</ng-container>
        : <span class="text-bold">{{ duration }} {{ 'TRANSACTIONS.MINUTES' | translate }}</span>
      </p>

      <p>
        <nb-icon icon="monitor-outline" pack="eva"></nb-icon>
        <ng-container>{{ 'TRANSACTIONS.EXIT_TERMINAL' | translate }}</ng-container>
        : <span class="text-bold">{{ getExitTerminalName(selectedExitTerminalId) }}</span>
      </p>
      <p>
        <nb-icon icon="sync-outline" pack="eva"></nb-icon>
        <ng-container>{{ 'TRANSACTIONS.COMPLETION_REASON' | translate }}</ng-container>
        : <span class="text-bold">{{ getManualCompletionReason(reason).name }}</span>
      </p>
      <p>
        <nb-icon icon="car-outline" pack="eva"></nb-icon>
        <ng-container>{{ 'TRANSACTIONS.PARKER_TYPE' | translate }}</ng-container>
        : <span class="text-bold">{{ getParkerTypes() }}</span>
      </p>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button (click)="completeTransactions()" class="margin-right-1" nbButton status="success" type="button">
      {{ 'TRANSACTIONS.CONFIRM_COMPLETION' | translate }}
      <nb-icon icon="checkmark-outline" pack="eva"></nb-icon>
    </button>
    <button (click)="return()" nbButton type="button">
      {{ 'TRANSACTIONS.RETURN' | translate }}
      <nb-icon icon="corner-down-left-outline" pack="eva"></nb-icon>
    </button>
  </nb-card-footer>
</nb-card>
