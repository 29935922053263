import _ from 'lodash';
import { BaseApiService } from './base-api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  AdjustFacilityAction,
  FacilityActionResponse,
  FacilityName,
  FacilityTerminalSummary,
} from '../domain/interfaces/facility.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Occupation, OccupationPerHour } from '../domain/interfaces/occupation.interface';
import { TerminalSummaryPerTerminalType } from '../domain/interfaces/terminal.interface';
import { FacilitySummary, FacilitySummaryGrouped } from '../domain/interfaces/facility-summary.interface';
import { ConfigService } from 'src/app/@shared/services/config.service';
import { ParkbaseStorageService } from 'src/app/@shared/services/parkbase-storage.service';
import { FilteredTerminals, TerminalResponse } from '../domain/interfaces/terminal-response.interface';
import { NotificationLevel } from '../domain/enums/notification-type.enum';
import { Alarm } from '../domain/interfaces/alarm.interface';

@Injectable({
  providedIn: 'root',
})
export class FacilitiesApiService extends BaseApiService {
  private _facilities = new BehaviorSubject<FacilityName[]>([]);
  readonly facilities = this._facilities.asObservable();
  private _terminals = new BehaviorSubject<FilteredTerminals>({
    entry: { count: 0, label: 'Inritten' },
    exit: { count: 0, label: 'Uitritten' },
    barrier: { count: 0, label: 'Slagbomen' },
  });
  readonly terminals = this._terminals.asObservable();
  private _facilitySummary = new BehaviorSubject<FacilitySummary[]>([]);
  readonly facilitySummary = this._facilitySummary.asObservable();
  private _facilitySummaryGrouped = new BehaviorSubject<FacilitySummaryGrouped>({});
  readonly facilitySummaryGrouped = this._facilitySummaryGrouped.asObservable();
  private _facilityTerminalsSummary = new BehaviorSubject<TerminalSummaryPerTerminalType[]>([]);
  readonly facilityTerminalsSummary = this._facilityTerminalsSummary.asObservable();
  private _alarmFromFacilitySummary = new BehaviorSubject<boolean>(false);
  readonly alarmFromFacilitySummary = this._alarmFromFacilitySummary.asObservable();
  private _occupationPerHourCurrent = new BehaviorSubject<Occupation>({
    occupationRatios: [],
    reservationOccupationRatios: [],
  });
  readonly occupationPerHourCurrent = this._occupationPerHourCurrent.asObservable();

  private _dataStore: {
    facilities: FacilityName[];
    terminals: any;
    facilitySummary: FacilitySummary[];
    facilitySummaryGrouped: FacilitySummaryGrouped;
    facilityTerminalsSummary: TerminalSummaryPerTerminalType[];
    occupationPerHourCurrent: Occupation;
  } = {
    facilities: [],
    terminals: {},
    facilitySummary: [],
    facilitySummaryGrouped: {},
    facilityTerminalsSummary: [],
    occupationPerHourCurrent: { occupationRatios: [], reservationOccupationRatios: [] },
  };

  constructor(configService: ConfigService, httpClient: HttpClient, parkbaseStorageService: ParkbaseStorageService) {
    super(configService, httpClient);
    super.setStorage(parkbaseStorageService);
    // this.loadFacilities();
  }

  /**
   * GET /api/gui/v1.0/facilities
   */
  loadFacilities(): void {
    this.http
      .get<FacilityName[]>(this.parkbaseBl9BaseUrl + '/facilities', {
        headers: this.setHeaders(),
      })
      .subscribe(
        (data) => {
          this._dataStore.facilities = data;
          this._facilities.next(Object.assign({}, this._dataStore).facilities);
          this.parkbaseStorageService.storeFacilities(data);
        },
        (error) => this.handleError('Could not load facilities.', error)
      );
  }

  /**
   * POST /api/gui/v1.0/facilities/{id}/action
   */
  sendFacilityAction(id: number, facilityAction: AdjustFacilityAction): Observable<FacilityActionResponse> {
    if (!id) {
      throw new Error('sendFacilityAction, id not provided');
    }
    const apiUrl = this.parkbaseBl9BaseUrl + '/facilities/' + id + '/action';
    // this.logger.info('-- sendFacilityAction', []);
    return this.http.post<FacilityActionResponse>(apiUrl, facilityAction, {
      headers: this.setHeaders(),
    });
  }

  getFacilityTerminals(id: number): Observable<TerminalSummaryPerTerminalType[]> {
    if (!id) {
      throw new Error('loadFacilityTerminalsSummary, id not provided');
    }
    return this.http.get<TerminalSummaryPerTerminalType[]>(
      this.parkbaseBl9BaseUrl + '/facilities/' + id + '/terminals/summary',
      {
        headers: this.setHeaders(),
      }
    );
  }

  /**
   * GET /api/gui/v1.0/facilities/{id}/terminals/summary
   */
  loadFacilityTerminalsSummary(id: number): void {
    if (!id) {
      throw new Error('loadFacilityTerminalsSummary, id not provided');
    }
    this.http
      .get<TerminalSummaryPerTerminalType[]>(this.parkbaseBl9BaseUrl + '/facilities/' + id + '/terminals/summary', {
        headers: this.setHeaders(),
      })
      .subscribe({
        next: (data) => {
          setTimeout(() => {
            this._dataStore.facilityTerminalsSummary = data;
            this._facilityTerminalsSummary.next(Object.assign({}, this._dataStore).facilityTerminalsSummary);
          }, environment.apiTimeout);
        },
        error: (error) => {
          if (error.status === 404) {
            this._dataStore.facilityTerminalsSummary = [];
            this._facilityTerminalsSummary.next(Object.assign({}, this._dataStore).facilityTerminalsSummary);
          } else {
            this.handleError('Could not load facilities terminals summary.', error);
          }
        },
      });
  }

  /**
   * GET /api/gui/v1.0/facilities/{id}/occupation-ratio/current
   */
  loadTotals(id: number): void {
    if (!id) {
      throw new Error('loadTotals, id not provided');
    }
    this.http
      .get<Occupation>(this.parkbaseBl9BaseUrl + '/facilities/' + id + '/occupation-ratio/current', {
        headers: this.setHeaders(),
      })
      .subscribe(
        (data) => {
          this._dataStore.occupationPerHourCurrent = data;
          this._occupationPerHourCurrent.next(Object.assign({}, this._dataStore).occupationPerHourCurrent);
        },
        (error) => this.handleError('Could not load occupationRatio.', error)
      );
  }

  /**
   * POST /api/gui/v1.0/facilities/{id}/occupation-ratio/history
   */
  loadActivity(id: number, utcDate: any): Observable<OccupationPerHour[]> {
    if (!id) {
      throw new Error('loadActivity, id not provided');
    }
    const body = {
      utcDate,
    };
    return this.http.post<OccupationPerHour[]>(
      this.parkbaseBl9BaseUrl + '/facilities/' + id + '/occupation-ratio/history',
      body,
      {
        headers: this.setHeaders(),
      }
    );
  }

  /**
   * GET /api/gui/v1.0/divisions/summary
   */
  loadFacilitySummary(): void {
    const facilities: FacilitySummary[] = [];
    const facilitiesForDashboard = this.parkbaseStorageService.retrieveFacilitiesChoice();
    this.http
      .get<FacilitySummary[]>(this.parkbaseBl9BaseUrl + '/divisions/summary', {
        headers: this.setHeaders(),
      })
      .subscribe((data) => {
        facilitiesForDashboard.forEach((facilityChoice) => {
          if (facilityChoice.selected) {
            data.forEach((f) => {
              if (f.facility.id === facilityChoice.facility.id) {
                facilities.push(f);
              }
            });
          }
        });
        this._dataStore.facilitySummary = facilities;
        this._facilitySummary.next(Object.assign({}, this._dataStore).facilitySummary);
      });
  }

  loadFacilitySummaryGroupedFromAPI() {
    const facilitiesForDashboard = this.parkbaseStorageService.retrieveFacilitiesChoice();
    return this.http
      .get<FacilitySummary[]>(this.parkbaseBl9BaseUrl + '/divisions/summary', {
        headers: this.setHeaders(),
      })
      .subscribe((data) => {
        const facilitySummaryGrouped: FacilitySummaryGrouped = {
          facilitiesWithAlarm: [],
          facilitiesWithWarning: [],
          facilitiesStatusOk: [],
        };
        data = _.orderBy(data, ['notificationLevel'], ['desc']);
        data.forEach((f) => {
          if (this.alarmGoing(f.emergencyAlarms)) {
            this._alarmFromFacilitySummary.next(true);
          }
        });
        facilitiesForDashboard.forEach((facilityChoice) => {
          if (facilityChoice.selected) {
            data.forEach((f) => {
              if (f.facility.id === facilityChoice.facility.id) {
                if (this.alarmGoing(f.emergencyAlarms)) {
                  facilitySummaryGrouped.facilitiesWithAlarm?.push(f);
                } else {
                  if (
                    f.notificationLevel === NotificationLevel.Alarm ||
                    f.notificationLevel === NotificationLevel.Error ||
                    f.notificationLevel === NotificationLevel.Warning
                  ) {
                    facilitySummaryGrouped.facilitiesWithWarning?.push(f);
                  }
                  if (f.notificationLevel === NotificationLevel.Info) {
                    facilitySummaryGrouped.facilitiesStatusOk?.push(f);
                  }
                }
              }
            });
          }
        });

        this._dataStore.facilitySummaryGrouped = facilitySummaryGrouped;
        this._facilitySummaryGrouped.next(Object.assign({}, this._dataStore).facilitySummaryGrouped);
      });
  }

  loadTerminals(facility: FacilityName): void {
    const body = {
      query: {
        page: 1,
        pageSize: 25,
      },
    };
    const filteredTerminals: FilteredTerminals = {
      entry: { count: 0, label: 'Inritten' },
      exit: { count: 0, label: 'Uitritten' },
      barrier: { count: 0, label: 'Slagbomen' },
    };
    this.http
      .post<TerminalResponse>(this.parkbaseBl9BaseUrl + '/facilities/' + facility.id + '/terminals', body, {
        headers: this.setHeaders(),
      })
      .subscribe((result) => {
        result?.terminals?.forEach((t) => {
          switch (t.type.id) {
            case 1:
              filteredTerminals.entry.count += 1;
              break;
            case 2:
              filteredTerminals.exit.count += 1;
              break;
            case 3:
              filteredTerminals.barrier.count += 1;
              break;
            case 11:
              filteredTerminals.entry.count += 1;
              filteredTerminals.exit.count += 1;
              break;
          }
        });
        this._dataStore.terminals = filteredTerminals;
        this._terminals.next(Object.assign({}, this._dataStore).terminals);
      });
  }

  loadFacilityWithTerminals() {
    const facilitiesWithTerminals: FacilityTerminalSummary[] = [];
    const facilities = this.parkbaseStorageService.retrieveFacilitiesChoice();
    const selectedFacilities = _.filter(facilities, { selected: true });
    return this.http
      .get<FacilityTerminalSummary[]>(this.parkbaseBl9BaseUrl + '/facilities/terminals', {
        headers: this.setHeaders(),
      })
      .pipe(
        map((data) => {
          selectedFacilities.forEach((f) => {
            data.forEach((d) => {
              if (f.facility.id === d.facility.id) {
                facilitiesWithTerminals.push(d);
              }
            });
          });
          return facilitiesWithTerminals;
        })
      );
  }

  // async loadFacilityWithTerminals(): Promise<FacilityWithTerminals[]> {
  //   const facilitiesWithTerminals: FacilityWithTerminals[] = [];
  //   const facilities = this.parkbaseStorageService.retrieveFacilitiesChoice();
  //   const selectedFacilities = _.filter(facilities, { selected: true });
  //   for (const f of selectedFacilities) {
  //     const response: TerminalResponse | undefined = await this.loadFacilityTerminals(f.facility);
  //     if (response && response.terminals && response.terminals.length > 0) {
  //       facilitiesWithTerminals.push({
  //         id: f.facility.id,
  //         name: f.facility.name,
  //         availableActions: f.facility.availableActions,
  //         terminals: response.terminals.map((t) => ({ ...t, selectedForFilter: true })),
  //       });
  //     }
  //   }
  //   return facilitiesWithTerminals;
  // }

  private alarmGoing(alarms: Alarm[]): boolean {
    let alarmGoing = false;
    alarms.forEach((a) => {
      if (a.going) {
        alarmGoing = true;
      }
    });
    return alarmGoing;
  }

  private loadFacilityTerminals(f: FacilityName) {
    const body = {
      query: {
        page: 1,
        pageSize: 25,
      },
    };
    return this.http
      .post<TerminalResponse>(this.parkbaseBl9BaseUrl + '/facilities/' + f.id + '/terminals', body, {
        headers: this.setHeaders(),
      })
      .toPromise();
  }
}
