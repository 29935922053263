<div class='theme-selector'>
  <h6>{{ "PROFILE.THEMECHOICE" | translate }}</h6>
  <nb-form-field>
    <nb-icon icon='color-palette' nbPrefix pack='eva'></nb-icon>
    <nb-select [(selected)]='selectedTheme'
               class='appearance-outline status-basic nb-transition'
               fullWidth
               placeholder='Select theme'
               shape='semi-round' size='large'>
      <nb-option
        (click)='setTheme(theme)'
        *ngFor='let theme of themeOptions'
        value='{{ theme }}'
      >{{ theme }}</nb-option
      >
    </nb-select>
  </nb-form-field>
</div>
