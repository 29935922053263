<div class='facilities-selector'>
  <div class='form-group'>
    <h6>{{ 'PROFILE.FACILITIESCHOICE' | translate }}</h6>
    <ul class='list-unstyled card-columns-4 facilities-list'>
      <li>
        <nb-checkbox (checkedChange)='storeAllFacilitiesChoice($event)'
                     [checked]='allFacilitiesSelected'>{{ 'PROFILE.ALL_FACILITIES' | translate }}
        </nb-checkbox>
      </li>
      <li *ngFor='let choice of facilitiesChoiceOptions'>
        <nb-checkbox (checkedChange)='storeFacilitiesChoice($event, choice)'
                     [checked]='choice.selected'>{{choice.facility.name}}</nb-checkbox>
      </li>
    </ul>
  </div>
</div>
