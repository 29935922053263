export enum NotificationType {
  Undefined = 0,
  BarrierOpenTooLong = 1,
  HardwareError = 2,
  SoftwareError = 3,
  LoopOccupiedTooLong = 5,
  BarrierNotOpened = 6,
}

export enum NotificationLevel {
  Info = 0,
  Warning = 1,
  Error = 2,
  Alarm = 6,
}
