import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { fadeAnimation } from 'src/app/@shared/animation/animation';
import { AuthService } from '../../auth/services/auth.service';
import { FacilityHubService } from '../../@core/hubs/facility-hub.service';
import { LogService } from '../../@core';
import { DivisionHubService } from '../../@core/hubs/division-hub.service';
import { PageComponent } from '../../@shared/components/page/page.component';
import { ParkbaseStorageService } from '../../@shared/services/parkbase-storage.service';
import { AppTourService } from '../../@shared/services/app-tour.service';

@Component({
  selector: 'app-status-page',
  templateUrl: './status-page.component.html',
  styleUrls: ['./status-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation],
})
export class StatusPageComponent extends PageComponent implements OnInit {
  connected = false;

  constructor(
    public appTourService: AppTourService,
    private authService: AuthService,
    private facilityHubService: FacilityHubService,
    private divisionHubService: DivisionHubService,
    private parkbaseStorageService: ParkbaseStorageService,
    private logger: LogService
  ) {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
    localStorage.removeItem('divisionsNotifications');
    const user = this.authService.getUser('StatusPageComponent ngOnInit');
    if (user && !this.connected) {
      this.divisionHubService.connectWithConfiguration();
      this.facilityHubService.connectWithConfiguration();
      this.connected = true;
    }
    this.onboardingTour();
  }

  private onboardingTour() {
    const firstUse = this.parkbaseStorageService.handleFirstUseCookie();

    if (firstUse) {
      this.appTourService.startTour();
      this.logger.info('Started onboarding tour', []);
    }
  }
}
