<div class='search-result'>
  <div>
    <h5>{{ 'IDENTIFICATION.IDENTITY_CARD' | translate }}</h5>
    <table class='table table-borderless data-table'>
      <tbody>
      <tr>
        <th scope='row'>{{ 'IDENTIFICATION.NAME' | translate }}</th>
        <td>
        <span *ngIf='data && data.parker' id='name'>
          {{ data.parker?.firstNames }} &nbsp;{{ data.parker?.middleName }} &nbsp;{{
          data.parker?.surname
          }}</span
        >
          <span *ngIf='data && !data.parker'>-</span>
        </td>
        <th scope='row'>{{ 'IDENTIFICATION.PRESENCE' | translate }}</th>
        <td>
          <span *ngIf='data && data.presence'>{{ data.presence.name }}</span>
          <span *ngIf='data && !data.presence'>-</span>
        </td>
      </tr>
      <tr>
        <th scope='row'>{{ 'IDENTIFICATION.POSTALCODE' | translate }}</th>
        <td>
          <span *ngIf='data && data.parker'>{{ data.parker?.address?.postalCode }}</span>
          <span *ngIf='data && !data.parker?.address'>-</span>
        </td>
        <th scope='row'>{{ 'IDENTIFICATION.VALIDITY_FROM' | translate }}</th>
        <td>
          <span *ngIf='data && data.validity'>{{ data.validity?.from | date }}</span>
          <span *ngIf='data && !data.validity?.from'>-</span>
        </td>
      </tr>
      <tr>
        <th scope='row'>{{ 'IDENTIFICATION.CITY' | translate }}</th>
        <td>
          <span *ngIf='data && data.parker'>{{ data.parker?.address?.city }}</span>
          <span *ngIf='data && !data.parker?.address'>-</span>
        </td>
        <th scope='row'>{{ 'IDENTIFICATION.VALIDITY_TO' | translate }}</th>
        <td>
          <span *ngIf='data && data.validity'>{{ data.validity?.to | date }}</span>
          <span *ngIf='data && !data.validity?.to'>-</span>
        </td>
      </tr>
      <tr>
        <th scope='row'>{{ 'IDENTIFICATION.STATUS' | translate }}</th>
        <td>
          <span *ngIf='data && data.status'>{{ data.status.name }}</span>
          <span *ngIf='data && !data.status'>-</span>
        </td>
        <th scope='row'>{{ 'IDENTIFICATION.FIRSTUSE' | translate }}</th>
        <td>
          <span *ngIf='data && data.firstUse'>{{ 'IDENTIFICATION.LABEL_TRUE' | translate }}</span>
          <span *ngIf='data && !data.firstUse'>{{ 'IDENTIFICATION.LABEL_FALSE' | translate }}</span>
        </td>
      </tr>
      <tr>
        <th scope='row'>{{ 'IDENTIFICATION.BIRTHDATE' | translate }}</th>
        <td>
          <span *ngIf='data && data.parker'>{{ data.parker?.birthDate | date }}</span>
          <span *ngIf='data && !data.parker?.birthDate'>-</span>
        </td>
        <th scope='row'>{{ 'IDENTIFICATION.COMPANY_NAME' | translate }}</th>
        <td>
          <span *ngIf='data && data.parker'>{{ data.parker?.company.name }}</span>
          <span *ngIf='data && data.parker && !data.parker.company'>-</span>
        </td>
      </tr>
      <tr>
        <th scope='row'>{{ 'IDENTIFICATION.LICENSEPLATES' | translate }}</th>
        <td>
          <ng-container *ngIf='data && data.parker?.licensePlates'>
            <div *ngFor='let licensePlate of data.parker?.licensePlates'>
              {{ licensePlate.country }} {{ licensePlate.license }}
            </div>
          </ng-container>
          <span *ngIf='data && !data.parker?.licensePlates'>-</span>
        </td>
        <th scope='row'>{{ 'IDENTIFICATION.TYPE' | translate }}</th>
        <td>
          <span *ngIf='data && data.type'>{{ data.type.name }}</span>
        </td>
      </tr>
      <tr>
        <th scope='row'>{{ 'IDENTIFICATION.CARDS' | translate }}</th>
        <td>
          <ng-container *ngIf='data && data.parker && data.parker.cards'>
            <div *ngFor='let card of data.parker?.cards'>{{ card }}</div>
          </ng-container>
          <span *ngIf='data && !data.parker?.cards'>-</span>
        </td>
        <th scope='row'>{{ 'IDENTIFICATION.HRI' | translate }}</th>
        <td><span *ngIf='data && data.hri'>{{ data.hri }}</span></td>
      </tr>
      <tr>
        <th scope='row'>{{ 'IDENTIFICATION.SPECIALCARDTYPE' | translate }}</th>
        <td>
          <span *ngIf='data && data.specialCardType'>{{ data.specialCardType.name }}</span>
          <span *ngIf='data && !data.specialCardType'>-</span>
        </td>
        <th scope='row'>{{ 'IDENTIFICATION.PUBLISHER' | translate }}</th>
        <td>
          <span *ngIf='data && data.publisher'>{{ data.publisher.name }}</span>
        </td>
      </tr>
      <tr>
        <th scope='row'>{{ 'IDENTIFICATION.OWNERTYPE' | translate }}</th>
        <td>
          <span *ngIf='data && data.ownerType'>{{ data.ownerType.name }}</span>
          <span *ngIf='data && !data.ownerType'>-</span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
