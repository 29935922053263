import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IdentificationScannerComponent } from '../identification-scanner/identification-scanner.component';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-identification-input',
  templateUrl: './identification-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => IdentificationInputComponent),
    },
  ],
})
export class IdentificationInputComponent {
  @Input()
  disabled = false;

  @Input()
  inValidTicketNumber = false;

  @Output()
  barcodeScanned: EventEmitter<any> = new EventEmitter();

  @Output()
  searchFormCleared: EventEmitter<any> = new EventEmitter();

  id!: string;
  identificationScannerDialog: any;
  isTypingInput = true;
  isScanning = false;
  onIdChanged!: () => void;
  onIdTouched!: () => void;
  searching = false;

  constructor(private dialog: NbDialogService) {}

  writeValue(id: string): void {
    this.id = id;
  }

  registerOnChange(fn: (id: string) => {}): void {
    this.onIdChanged = () => {
      this.searching = true;
      fn(this.id);
    };
  }

  registerOnTouched(fn: () => {}): void {
    this.onIdTouched = fn;
  }

  openBarcodeScanner(): void {
    this.identificationScannerDialog = this.dialog.open(IdentificationScannerComponent);
    this.identificationScannerDialog.afterClosed().subscribe((code: any) => {
      if (code) {
        this.id = code;
        this.barcodeScanned.emit(this.id);
      }
    });
  }

  clearSearchForm() {
    this.id = '';
    this.searching = false;
    this.searchFormCleared.emit(true);
  }
}
