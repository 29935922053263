<nb-layout>
  <nb-layout-column>
  <div class="page-not-found">
    <div class="not-found">
      <img src="/assets/theme/Logo_ParkBase.png" alt="Logo Parkbase" width="225" height="50" />
      <h1>Page not found</h1>
      <p>We're sorry, the page you requested cannot be found.</p>
    </div>
  </div>
  </nb-layout-column>
</nb-layout>
