<nb-card class='help-content-card'>
  <nb-card-header>
    <h4 [innerHTML]='currentHelpFileTitle'></h4>
  </nb-card-header>
  <nb-card-body>
    <div [innerHTML]='currentHelpFileText' class='content-container' id='content'>
    </div>
  </nb-card-body>
</nb-card>


