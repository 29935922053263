/**
 * Contains translated strings of definitions in specific categories.
 */
export enum DefinitionType {
  TerminalTypes = 0,
  ManualCorrectionReasons = 1,
  ManualGrantPassageReasons = 2,
  NotificationLevels = 3,
  NotificationTypes = 4,
  LoopAccessModes = 5,
  TwoWayLaneStatuses = 6,
  LostTicketFeeTypes = 7,
}
