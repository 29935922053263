import { CommonModule } from '@angular/common';
import { FacilitiesComponent } from './components/facilities/facilities.component';
import { FacilityDetailComponent } from './components/facility-detail/facility-detail.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StatusPageComponent } from './status-page.component';
import { DirectAccessGuard } from '../../auth/guards/direct-access.guard';

const statusPageRoutes: Routes = [
  {
    path: '',
    component: StatusPageComponent,
    children: [
      {
        path: 'facilities',
        component: FacilitiesComponent,
      },
      {
        path: 'facilities/:id',
        canActivate: [DirectAccessGuard],
        component: FacilityDetailComponent,
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(statusPageRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class StatusPageRoutingModule {}
