import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Terminal, TerminalAction } from '../../../../@core/domain/interfaces/terminal.interface';
import { TrackingService } from '../../../services/tracking.service';
import { TerminalsApiService } from '../../../../@core/api/terminals-api.service';
import { LogService } from '../../../../@core';
import { NbLayoutRulerService } from '@nebular/theme';
import { StatusPageService } from '../../../../pages/status-page/services/status-page.service';
import _ from 'lodash';
import { ResponseStatus } from '../../../../@core/domain/interfaces/facility.interface';
import { ToastMessageService } from '../../../services/toast-message.service';
import { SimpleAction } from '../anonymous-passage/anonymous-passage.component';

@Component({
  selector: 'app-controlled-passage',
  templateUrl: './controlled-passage.component.html',
  styleUrls: ['./controlled-passage.component.scss'],
})
export class ControlledPassageComponent implements OnInit {
  @Input()
  terminalId!: number;

  @Input()
  action!: TerminalAction | SimpleAction;

  @Input()
  showTerminalTitle = true;

  @Output()
  changeComponent = new EventEmitter<string>();

  terminal!: Terminal;
  index = 0;
  grantPassageForm!: UntypedFormGroup;
  identificationHint = true;
  identification!: any;
  searchResultType: any;
  showSearchElement = true;
  showSearchResultElement = false;
  showFormEl = true;
  selectedItem: any;
  awaitTerminalActionComplete = false;
  toastrMessage = '';
  passageResponseMessage!: string;
  passageResponseStatus = 'success';
  passageCompleted = false;
  showPassageHint = false;
  ownerType!: string;
  identificationType!: number;
  hideElement = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private trackingService: TrackingService,
    private terminalsApiService: TerminalsApiService,
    private layoutRuleService: NbLayoutRulerService,
    private statusPageService: StatusPageService,
    private toastMessageService: ToastMessageService,
    private logger: LogService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.terminalsApiService.loadTerminalById(this.terminalId).subscribe((t) => {
      this.terminal = t;
      this.showSearchResultElement = false;
      this.selectedItem = '';
      this.buildForm();
      this.getWindowSize();
    });
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.getWindowSize();
  }

  getWindowSize() {
    this.layoutRuleService.getDimensions().subscribe((data) => {});
  }

  buildForm() {
    this.grantPassageForm = this.formBuilder.group({
      reason: ['', [Validators.required]],
      remarks: ['', [Validators.required]],
      identificationId: ['', [Validators.required]],
    });
  }

  onSelectIdentificationId($event: any) {
    if ($event && $event.identification && $event.type) {
      this.identification = $event.identification;
      this.identificationType = $event.type.id;
      if (this.identification.owner && this.identification.owner.type) {
        this.ownerType = this.identification.owner.type.name;
      }
      if (this.identification.availableActions && this.identification.availableActions.length === 0) {
        this.showPassageHint = true;
        this.showSearchElement = false;
        this.showFormEl = false;
        this.logger.info('Insufficient available actions to allow controlled passage', []);
      } else {
        const allowGrantPassage = _.find(this.identification.availableActions, { type: 0 });
        if (allowGrantPassage) {
          this.grantPassageForm.controls['identificationId'].setValue(this.identification.id, {
            onlySelf: true,
          });
          this.showPassageHint = false;
          this.showSearchElement = false;
          this.showFormEl = true;
        }
      }
    }
  }

  submitIdentificationGrantPassage(): void {
    this.trackingService.eventEmitter('passageComponent', 'action', '', 'identificationGrantPassage', 0);
    if (this.grantPassageForm.valid) {
      const terminalAction = {
        reason: +this.grantPassageForm.value.reason,
        remarks: this.grantPassageForm.value.remarks,
        type: 29,
        identificationId: this.identification.id,
      };
      this.awaitTerminalActionComplete = true;
      this.terminalsApiService.sendTerminalAction(this.terminal.id, terminalAction).subscribe((result) => {
        if (result.status === ResponseStatus.Success) {
          this.passageResponseStatus = 'success';
          this.awaitTerminalActionComplete = false;
          this.logger.info('Terminal action GrantPassageToIdentification performed on terminal' + this.terminal.id, []);
          this.passageResponseMessage = result.message;
          this.passageCompleted = true;
          this.identification = null;
          this.showSearchResultElement = false;
          this.grantPassageForm.reset();
          this.onDoResetSearch('yes');
          this.toastMessageService.showSuccess(result.message);
          return;
        } else if (result.status === ResponseStatus.Failure) {
          this.awaitTerminalActionComplete = false;
          this.identificationHint = true;
          this.logger.info('Terminal action GrantPassageToIdentification failed on terminal' + this.terminal.id, []);
          this.passageResponseStatus = 'danger';
          this.passageResponseMessage = result.message;
          this.toastMessageService.showFailure(result.message);
        } else if (result.status === ResponseStatus.InputRequired) {
          this.toastMessageService.showInputRequired(result.message);
        } else if (result.status === ResponseStatus.PartialFailure) {
          this.toastMessageService.showPartialFailure(result.message);
        }
      });
    } else {
      this.identificationHint = true;
    }
  }

  switchAnonymousGrantPassage(showPassageHint: boolean) {
    this.changeComponent.emit('grantAnonymousPassage');
  }

  onSearchStarted() {
    this.showFormEl = false;
  }

  onSearchCompleted() {
    this.showSearchResultElement = true;
    this.showPassageHint = false;
  }

  onSearchReset() {
    this.showFormEl = true;
    this.showSearchElement = true;
    this.showSearchResultElement = false;
    this.showPassageHint = false;
  }

  onDoResetSearch($event: any) {
    if ($event) {
      this.showFormEl = true;
      this.showSearchElement = true;
      this.showSearchResultElement = false;
    }
  }
}
