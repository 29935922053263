import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgModel,
  ValidationErrors,
} from '@angular/forms';
import dayjs from 'dayjs';

type Nullable<T> = T | null;

@Component({
  selector: 'app-date-time-selector',
  styleUrls: ['./date-time-selector.component.scss'],
  templateUrl: './date-time-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeSelectorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateTimeSelectorComponent),
      multi: true,
    },
  ],
})
export class DateTimeSelectorComponent implements ControlValueAccessor, OnChanges {
  @Input() dateLabel = '';
  @Input() timeLabel = '';
  @Input() maxDate!: Date;
  @Input() minDate!: Date;
  @Input() name!: string;
  @Input() reset = false;

  @ViewChild('datePickerModel', { static: true }) datePicker!: NgModel;

  localDate!: Nullable<Date>;
  onChange: any;
  selectedTime!: Nullable<Date>;

  constructor() {}

  set time(date: Date) {
    this.selectedTime = date;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['reset'] && changes['reset'].currentValue === true) {
      this.localDate = null;
      const midNight = new Date();
      midNight.setHours(0, 0, 0, 0);
      this.selectedTime = midNight;
    }
  }

  writeValue(date: Nullable<Date>): void {
    let dateType: Date;
    if (date) {
      dateType = new Date(date);
      this.localDate = dateType;
      this.time = this.localDate;
    } else {
      this.localDate = null;
      this.selectedTime = null;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {}

  onDateChange($event: any): void {
    if ($event) {
      this.localDate = $event;
      this.updateTimeForDate();
    }
  }

  onTimeChange(): void {
    this.updateTimeForDate();
    this.handleInputChange();
  }

  handleInputChange() {
    if (this.localDate) {
      this.writeValue(this.localDate);
    }
  }

  validate(): ValidationErrors | null {
    if (!this.datePicker) {
      return null;
    }
    const errors = Object.assign({}, this.datePicker.control.errors);
    if (errors['required'] && this.localDate) {
      delete errors['required'];
    }

    return Object.keys(errors).length ? errors : null;
  }

  handleKeyUpForDate($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.handleInputChange();
    }
  }

  handleKeyUpForTime($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.onTimeChange();
    }
  }

  private updateTimeForDate(): void {
    if (this.localDate) {
      if (!this.selectedTime) {
        this.selectedTime = this.localDate;
        this.onChange(this.localDate);
      } else {
        this.setTimeOnLocalDate();
        this.onChange(this.localDate);
      }
    }
  }

  private setTimeOnLocalDate(): void {
    if (this.selectedTime && this.localDate) {
      const hour: number = dayjs(this.selectedTime).hour();
      const minute: number = dayjs(this.selectedTime).minute();
      this.localDate = dayjs(this.localDate).hour(hour).minute(minute).toDate();
    }
  }
}
