import { Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ReplaySubject } from 'rxjs';
import { ConfigService } from 'src/app/@shared/services/config.service';
import { Alarm, EmergencyAlarm } from '../domain/interfaces/alarm.interface';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Config } from '../domain/interfaces/config.interface';
import { LogService } from '../logging/log.service';
import { ParkbaseStorageService } from '../../@shared/services/parkbase-storage.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root',
})
export class DivisionHubService {
  private hubUrl!: string;
  private hubConnection!: HubConnection;
  private _divisionEmergencyAlarmsSource = new ReplaySubject<Alarm[]>();
  readonly divisionEmergencyAlarms$ = this._divisionEmergencyAlarmsSource.asObservable();
  private _alarmGoing = new ReplaySubject<boolean>();
  readonly alarmGoing$ = this._alarmGoing.asObservable();

  constructor(
    private configService: ConfigService,
    private authService: AuthService,
    private parkbaseStorageService: ParkbaseStorageService,
    private oidcSecurityService: OidcSecurityService,
    private logger: LogService
  ) {}

  reconnect() {
    this.stopHubConnection();
    this.connectWithConfiguration();
  }

  stop() {
    this.stopHubConnection();
    this.startConnection();
  }

  connectWithConfiguration() {
    if (!this.hubConnection) {
      this.configService.loadConfiguration().then((result: Config) => {
        this.hubUrl = result.server.resourcesBL9;
        const user = this.authService.getUser('DivisionHubService connectWithConfiguration');
        const ippLanguage = this.parkbaseStorageService.getLanguageForApi();
        const connecting = true;
        const headers = { 'Ipp-Language': ippLanguage };
        if (user && connecting) {
          this.oidcSecurityService.getAccessToken().subscribe((token) => {
            this.hubConnection = new HubConnectionBuilder()
              .withUrl(this.hubUrl + '/hub/division?ipp-Language=' + ippLanguage, {
                headers: headers,
                accessTokenFactory: () => token,
              })
              .configureLogging(LogLevel.Error)
              .withAutomaticReconnect()
              .build();

            this.startConnection();
          });
        }
      });
    }
  }

  private startConnection() {
    this.hubConnection
      .start()
      .then(() => {
        this.logger.info('DivisionHub connection started', []);
      })
      .catch((error) => {
        this.logger.error('Error in Division hubConnection', error);
        throw error;
      });

    this.hubConnection.on('Error', (message) => {
      this.logger.error('Error in Division hubConnection', message);
    });

    this.hubConnection.on('DivisionEmergencyAlarmsChanged', (alarms: EmergencyAlarm[]) => {
      if (alarms && alarms.length > 0) {
        this._divisionEmergencyAlarmsSource.next(alarms);
        const status: any[] = [];
        alarms.forEach((a) => {
          if (a.enabled && a.going) {
            status.push(a);
          }
        });
        if (status.length > 0) {
          this._alarmGoing.next(true);
        } else {
          this._alarmGoing.next(false);
        }
        this.logger.info('DivisionHub divisionEmergencyAlarmsChanged', alarms);
      }
    });
  }

  private stopHubConnection() {
    if (this.hubConnection) {
      this.hubConnection.stop().catch((error) => {
        this.logger.error('Error in Division stopHubConnection', error);
      });
    }
  }
}
