import { Component, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FacilityName } from '../../../../@core/domain/interfaces/facility.interface';
import { ParkbaseStorageService } from '../../../../@shared/services/parkbase-storage.service';

@Component({
  selector: 'app-facility-selector',
  styleUrls: ['./facility-selector.component.scss'],
  templateUrl: './facility-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FacilitySelectorComponent,
      multi: true,
    },
  ],
})
export class FacilitySelectorComponent implements OnInit, ControlValueAccessor {
  allFacilities: FacilityName[] = [];
  selectedFacilityId!: string;
  onChange: any;

  constructor(private parkbaseStorageService: ParkbaseStorageService) {}

  ngOnInit() {
    this.allFacilities = this.parkbaseStorageService.retrieveFacilities();
  }

  writeValue(selectedFacilityId: string): void {
    this.selectedFacilityId = selectedFacilityId;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(): void {
    return;
  }
}
