<nb-alert *ngIf="errorMessage && errorMessage.length > 0" class="alert" status="danger">
  <nb-icon icon="alert-circle-outline" nbPrefix pack="eva"></nb-icon>
  {{ errorMessage }}
</nb-alert>
<nb-spinner *ngIf="loadingTransactions" class=""></nb-spinner>
<form #searchForm="ngForm">
  <div class="transactions-filter-form-container">
    <div class="form-group">
      <h6>{{ 'TRANSACTIONS.DATE_TIME_SELECTION' | translate }} *</h6>
      <div class="transaction-filter-date">
        <app-date-time-selector
          *ngIf="filter.endDate"
          [(ngModel)]="filter.startDate"
          [reset]="resetDateTimeSelector"
          dateLabel="{{ 'TRANSACTIONS.START_DATE' | translate }}"
          name="startDate"
          timeLabel="Time"
        >
        </app-date-time-selector>
        <app-date-time-selector
          *ngIf="maxEndDate"
          [(ngModel)]="filter.endDate"
          dateLabel="{{ 'TRANSACTIONS.END_DATE' | translate }}"
          name="endDate"
          timeLabel="Time"
        >
        </app-date-time-selector>
      </div>
    </div>
    <div class="form-group">
      <h6>{{ 'TRANSACTIONS.FACILITY_SUBSCRIPTION' | translate }} *</h6>
      <div class="transaction-filter-options">
        <app-facility-selector [(ngModel)]="filter.facilityId" name="facilityId" required> </app-facility-selector>
        <app-subscription-model-selector
          [(ngModel)]="filter.subscriptionModels"
          [filter]="filter"
          [replaySearch]="replaySearch"
          [resetSelection]="resetSubscriptionModels"
          name="subscriptionModels"
          required
        >
        </app-subscription-model-selector>
      </div>
    </div>
  </div>
  <div class="form-group">
    <h6>{{ 'TRANSACTIONS.PARKER_TYPE' | translate }}</h6>
    <div>
      <div class="toggle">
        <nb-toggle (change)="toggleSelectAll()" [(ngModel)]="isEveryModelSelected" name="modelSelection">
          <span *ngIf="!isEveryModelSelected">
            {{ 'BUTTONS.SELECT_ALL' | translate }}
          </span>
          <span *ngIf="isEveryModelSelected">
            {{ 'BUTTONS.UNSELECT_ALL' | translate }}
          </span>
        </nb-toggle>
      </div>
      <div class="radio-group-container">
        <ul *ngFor="let parkerType of parkerTypes" class="checkbox-item">
          <nb-checkbox
            (change)="toggleCheck()"
            (checkedChange)="toggle($event, parkerType)"
            [checked]="parkerType.selected"
            >{{ parkerType.type.name }}</nb-checkbox
          >
        </ul>
      </div>
    </div>
  </div>
  <button
    (click)="searchOpenTransactions()"
    [disabled]="
      loadingTransactions ||
      searchForm.pristine ||
      !isFilterValid() ||
      (filter.subscriptionModels && filter.subscriptionModels.length === 0)
    "
    class="button"
    nbButton
    shape="semi-round"
    status="success"
  >
    {{ 'TRANSACTIONS.SEARCH_OPEN_TRANSACTIONS' | translate }}
    <nb-icon icon="search-outline" pack="eva"></nb-icon>
  </button>
  <button
    (click)="resetSearch()"
    [disabled]="searchForm.pristine"
    class="button"
    nbButton
    shape="semi-round"
    status="default"
  >
    {{ 'TRANSACTIONS.RESET' | translate }}
    <nb-icon icon="close-outline" pack="eva"></nb-icon>
  </button>
</form>
