import { Component, Input, OnInit } from '@angular/core';

export interface ButtonConfig {
  label: string;
  icon: string;
  class?: string;
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input()
  label!: string;

  @Input()
  icon!: string;

  class: string;

  constructor() {
    this.class = 'edit-button';
  }

  ngOnInit(): void {}
}
