<form [formGroup]='facilitiesForm' novalidate>
  <nb-form-field>
    <nb-select
      (selectedChange)='changeFacility($event)'
      [compareWith]='compareById'
      [selected]='currentFacility'
      class='appearance-outline status-primary nb-transition'
      fullWidth
      shape='semi-round'
    >
      <nb-select-label class='facility-label'>{{
        'FACILITY_SELECT.LABEL' | translate
        }}</nb-select-label>
      <nb-select-label
        [innerHtml]='currentFacility.name'
        class='facility-selected'
      ></nb-select-label>
      <nb-option [value]="{ id: 0, name: 'FACILITY_SELECT.OPTION_ALL' | translate }" id='0'>{{
        'FACILITY_SELECT.OPTION_ALL' | translate
        }}
      </nb-option>
      <nb-option
        *ngFor='let facility of facilities$ | async'
        [value]='facility'
        id='{{ facility.id }}'
      >{{ facility.name }}</nb-option
      >
    </nb-select>
  </nb-form-field>
</form>
