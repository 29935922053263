import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { slideInOutAnimation } from '../../@shared/animation/animation';
import { TerminalOverview } from '../../@core/domain/interfaces/terminal-response.interface';

@Component({
  selector: 'app-lost-ticket-mobile',
  templateUrl: './lost-ticket-mobile.component.html',
  styleUrls: ['./lost-ticket-mobile.component.scss'],
  animations: slideInOutAnimation,
})
export class LostTicketMobileComponent implements OnInit {
  @Input()
  terminalOverview!: TerminalOverview;

  @Input()
  action!: any;

  showComponent = true;

  constructor(protected terminalActionsMobileDialogRef: NbDialogRef<LostTicketMobileComponent>) {}

  ngOnInit(): void {}
}
