import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-nav-mobile',
  templateUrl: './header-nav-mobile.component.html',
  styleUrls: ['./header-nav-mobile.component.scss'],
})
export class HeaderNavMobileComponent implements OnInit {
  @Input()
  title!: string;

  @Input()
  componentRef!: any;

  @Input()
  handleNavigate = false;

  private showComponent = false;

  constructor() {}

  ngOnInit(): void {}

  back() {
    this.showComponent = false;
    if (this.handleNavigate) {
      // console.log('back with handleNavigate in header-nav', this.handleNavigate);
      setTimeout(() => {
        // console.log('this.componentRef', this.componentRef);
        // TODO: how to let the back animation kick in
        this.componentRef.close({ handleNavigate: { changeBack: true } });
      }, 250);
    } else {
      // console.log('back --without-- handleNavigate in header-nav');
      setTimeout(() => {
        this.componentRef.close(false);
      }, 250);
    }
  }

  close() {
    this.componentRef.close(true);
  }
}
