import { Injectable } from '@angular/core';
import { IdentificationTypes } from '../domain/models/identification.model';
import { catchError, Observable, of, tap } from 'rxjs';
import { FeedbackResponse } from '../domain/interfaces/feedback.interface';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ParkbaseStorageService } from '../../@shared/services/parkbase-storage.service';
import { ConfigService } from '../../@shared/services/config.service';
import { LogService } from '../logging/log.service';

@Injectable({
  providedIn: 'root',
})
export class IdentificationValidationApiService {
  parkbaseApiUrl = '';

  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private logger: LogService,
    private parkbaseStorageService: ParkbaseStorageService
  ) {
    this.configService.loadConfiguration().then((data) => {
      this.parkbaseApiUrl = data.server.resources + '/api/v1.0/';
    });
  }

  getValidationOptions(identification: string, identificationType: IdentificationTypes) {
    return this.http
      .post<FeedbackResponse>(
        this.parkbaseApiUrl + 'identifications/validation',
        {
          identification,
          identificationType,
        },
        {
          headers: this.setHeaders(),
        }
      )
      .pipe(
        tap((response) => this.formatResponse(response)),
        catchError(this.handleError())
      );
  }

  confirmTransactionValidation(identification: string, identificationType: IdentificationTypes, actionId: number) {
    return this.http
      .post<FeedbackResponse>(
        this.parkbaseApiUrl + 'identifications/validation/execute',
        {
          identification,
          identificationType,
          actionId,
        },
        {
          headers: this.setHeaders(),
        }
      )
      .pipe(
        tap((response) => this.formatResponse(response)),
        catchError(this.handleError())
      );
  }

  setHeaders(): HttpHeaders {
    return new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Ipp-Language', this.parkbaseStorageService.getLanguageForApi())
      .append('Ipp-Host-Address', '*');
  }

  formatResponse(response: any) {
    const obj = {
      status: 200,
      statusText: 'OK',
      response: response.id,
    };
    return (response['response'] = obj);
  }

  handleError(msg?: string, errorMsg?: Error) {
    return (error: HttpErrorResponse): Observable<HttpErrorResponse> => {
      return of(error);
    };
  }
}
