import { Injectable, NgModule } from '@angular/core';
import { RouterModule, RouterStateSnapshot, Routes, TitleStrategy } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AccountComponent } from './pages/account/account.component';
import { ShellService } from './shell/services/shell.service';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TemplateComponent } from './pages/template/template.component';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';
import { IdentificationValidationComponent } from './pages/identification/identification-validation/identification-validation.component';
import { TestPageComponent } from './pages/test-page/test-page.component';
import { TerminalsPageComponent } from './pages/terminals-page/terminals-page.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`ParkBase 9 - ${title}`);
    }
  }
}

export const routes: Routes = [
  { path: '', redirectTo: 'account', pathMatch: 'full' },
  { path: 'account', component: AccountComponent, title: 'account' },
  { path: 'account/logout', component: AccountComponent, title: 'account' },
  { path: 'status', redirectTo: 'status/facilities', pathMatch: 'full' },
  ShellService.childRoutes([
    {
      path: 'status',
      title: 'Status page',
      loadChildren: () => import('./pages/status-page/status-page.module').then((m) => m.StatusPageModule),
    },
  ]),
  ShellService.childRoutes([
    {
      path: 'identification/validation',
      component: IdentificationValidationComponent,
      title: 'identification validation',
    },
  ]),
  ShellService.childRoutes([
    {
      path: 'transactions',
      title: 'transactions',
      loadChildren: () => import('./pages/transactions/transactions.module').then((m) => m.TransactionsModule),
    },
  ]),
  ShellService.childRoutes([
    {
      path: 'settings',
      title: 'Application Settings',
      loadChildren: () => import('./pages/settings/settings.module').then((m) => m.ApiSettingsModule),
    },
  ]),
  ShellService.childRoutes([
    { path: 'terminals', component: TerminalsPageComponent, title: 'Terminals' },
    { path: 'terminals/:id', component: TerminalsPageComponent, title: 'Terminals' },
  ]),
  ShellService.childRoutes([{ path: 'profile', component: ProfileComponent, title: 'Profile' }]),
  ShellService.childRoutes([{ path: 'testpage', component: TestPageComponent, title: 'Test Page' }]),
  ShellService.childRoutes([{ path: 'dashboard', component: DashboardComponent, title: 'Dashboard' }]),
  ShellService.childRoutes([{ path: 'subscriptions', component: SubscriptionsComponent, title: 'subscriptions' }]),
  { path: 'account', component: AccountComponent, title: 'account' },
  { path: 'template', component: TemplateComponent, title: 'template' },
  { path: 'account/logout', component: AccountComponent, title: 'account' },
  { path: 'error', component: ErrorPageComponent, title: 'error' },
  { path: 'forbidden', component: ForbiddenComponent, title: 'forbidden' },
  { path: 'unauthorized', component: UnauthorizedComponent, title: 'unauthorized' },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy,
    },
  ],
})
export class AppRoutingModule {}
