<nb-layout>
  <nb-layout-column>
    <div class="error-page">
      <div class="error">
        <img alt="Logo Parkbase" height="50" src="/assets/theme/Logo_ParkBase.png" width="225"/>
        <h1>Unable to contact API.</h1>
        <p>We're sorry, API is not accessible at this time, check configuration.</p>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
