import { Component, OnInit } from '@angular/core';
import { MainMenuConfigComponent } from '../../@shared/components/main-menu/main-menu.config.component';
import { NbAccessChecker } from '@nebular/security';
import { TranslateService } from '@ngx-translate/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { debounceTime } from 'rxjs/operators';
import { SearchApiService } from '../../@core/api/search-api.service';
import { Subject } from 'rxjs';
import { SearchResult, SearchResultItem } from '../../@core/domain/interfaces/search-result.interface';
import { GlobalSearchResultMobileComponent } from '../global-search-result-mobile/global-search-result-mobile.component';
import { slideInOutAnimation } from '../../@shared/animation/animation';
import { GenericInfo } from '../../@shared/components/cell/cell.component';
import { SearchResultType } from '../../@core/domain/enums/search-result-type';

type Nullable<T> = T | null;

@Component({
  selector: 'app-global-search-mobile',
  templateUrl: './global-search-mobile.component.html',
  styleUrls: ['./global-search-mobile.component.scss'],
  animations: slideInOutAnimation,
})
export class GlobalSearchMobileComponent extends MainMenuConfigComponent implements OnInit {
  searchInput!: string;
  searchInputChanged = new Subject<string>();
  searchResult!: Nullable<SearchResult[]>;
  searching = false;
  showComponent = true;
  SearchResultType: any = SearchResultType;

  constructor(
    public override accessChecker: NbAccessChecker,
    public override translate: TranslateService,
    private searchApiService: SearchApiService,
    private dialogService: NbDialogService,
    protected globalSearchMobileDialogRef: NbDialogRef<GlobalSearchMobileComponent>
  ) {
    super(accessChecker, translate);

    this.searchInputChanged.pipe(debounceTime(300)).subscribe(() => {
      this.searchApiService.search(this.searchInput).subscribe((data) => {
        this.searchResult = data;
      });
    });
  }

  changed() {
    this.searching = true;
    this.searchInputChanged.next('');
  }

  override ngOnInit(): void {}

  clearSearchForm(): void {
    this.searchInput = '';
    this.searching = false;
    this.searchResult = [];
  }

  openSearchResult(result: SearchResultItem, type: number) {
    this.dialogService
      .open(GlobalSearchResultMobileComponent, {
        context: {
          searchResult: result,
          searchResultType: type,
        },
      })
      .onClose.subscribe((res) => {
        if (res && res.handleNavigate && res.handleNavigate.changeBack) {
          // do nothing
          // this.globalSearchMobileDialogRef.close();
        } else {
          this.globalSearchMobileDialogRef.close();
        }
      });
  }

  formatSearchResult(x: SearchResultItem, searchResultType: SearchResultType): GenericInfo {
    let info: GenericInfo = { title: '', message: '' };
    switch (searchResultType) {
      case 0:
        info = {
          title: x.license + ' ' + x.country + ' ' + this.formatString(x.type?.name),
          message:
            this.formatString(x.owner?.firstNames) +
            ' ' +
            this.formatString(x.owner?.middleName) +
            ' ' +
            this.formatString(x.owner?.lastName),
        };
        break;
      case 1:
        info = {
          title: x.publisher?.name + ' ' + x.type?.name,
          message:
            this.formatString(x.owner?.firstNames) +
            ' ' +
            this.formatString(x.owner?.middleName) +
            ' ' +
            this.formatString(x.owner?.lastName) +
            ' ' +
            this.formatString(x.type?.name),
        };
        break;
      case 2:
      case 3:
        info = {
          title: x.firstNames + ' ' + x.middleName + ' ' + x.lastName + ' ' + this.formatString(x.company?.name),
        };
        break;
    }

    return info;
  }

  private formatString(input: any): string {
    if (input) {
      return input;
    } else {
      return '';
    }
  }
}
