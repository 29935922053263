import { Injectable, VERSION } from '@angular/core';
import { LogPublisher } from './log-publishers.service';
import { LogPublishersService } from './log-publisher.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { LogApiService } from '../api/log-api.service';
import { environment } from '../../../environments/environment';
import { filter, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import dayjs from 'dayjs';
import { ParkbaseStorageService } from '../../@shared/services/parkbase-storage.service';

/**
 * INFO: service was started or stopped, resource was created, accessed, updated, or deleted in the database, The state of an operation
 */
export enum LogLevel {
  All = 0,
  Debug = 1,
  Trace = 2,
  Info = 3,
  Warning = 4,
  Error = 5,
}

enum LoggerEvents {
  Flush = 1,
}

interface LogConfig {
  enabled: boolean;
  logLevel: string;
}

type Nullable<T> = T | null;

export class LogEntry {
  id = environment.version + '-A' + VERSION.full;
  timestamp = dayjs().format('YYYY-M-D:mm-ss');
  key = '';
  message = '';
  level!: number;
  extraInfo: any[] = [];

  constructor(key: string) {
    this.key = key;
  }

  private static formatParams(params: any[]): string {
    let ret: string = params.join(',');
    if (params.some((p) => typeof p === 'object')) {
      ret = '';
      for (const item of params) {
        ret += JSON.stringify(item) + ',';
      }
    }
    return ret;
  }

  buildLogString(): string {
    let ret = '';
    ret += this.id + '|' + this.timestamp + '|' + this.key + '|GUI-' + LogLevel[this.level] + ': ' + this.message;
    if (this.extraInfo.length) {
      ret += '|' + LogEntry.formatParams(this.extraInfo);
    }
    return ret.toString();
  }
}

@Injectable()
export class LogService {
  publishers!: LogPublisher[];
  level!: Nullable<LogLevel>;
  enabled = false;
  key!: string;

  private buffer: LogEntry[] = [];
  private flush = new Subject<LoggerEvents>();

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private parkbaseStorageService: ParkbaseStorageService,
    private logApiService: LogApiService
  ) {
    this.oidcSecurityService.userData$.subscribe((d) => {
      if (d.userData) {
        this.key = d.userData.id.slice(0, d.userData.id.indexOf('-'));
      }
    });
    this.flush
      .pipe(
        debounceTime(150),
        filter((event) => event === LoggerEvents.Flush)
      )
      .subscribe(() => this.flushBuffer());
  }

  config(logConfig: LogConfig) {
    this.enabled = logConfig.enabled;
    this.level = this.setLogLevel(logConfig.logLevel);

    const logPublishersService = new LogPublishersService(this.logApiService);
    this.publishers = logPublishersService.publishers;
  }

  info(msg: string, ...optionalParams: any[]): void {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warning(msg: string, ...optionalParams: any[]): void {
    this.writeToLog(msg, LogLevel.Warning, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]): void {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  debug(msg: string, ...optionalParams: any[]): void {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  trace(msg: string, ...optionalParams: any[]): void {
    this.writeToLog(msg, LogLevel.Trace, optionalParams);
  }

  setLogLevel(level: string): LogLevel {
    switch (level.toLowerCase()) {
      case 'all':
        return LogLevel.All;
      case 'debug':
        return LogLevel.Debug;
      case 'info':
        return LogLevel.Info;
      case 'warning':
        return LogLevel.Warning;
      case 'error':
        return LogLevel.Error;
      case 'trace':
        return LogLevel.Trace;
      default:
        return LogLevel.Info;
    }
  }

  private flushBuffer() {
    const data = this.buffer.splice(0);

    if (data.length === 0) {
      return;
    }

    if (environment.production) {
      this.publishers.forEach((d) => d.setLocation('api'));
    } else {
      this.publishers.forEach((d) => d.setLocation('console'));
    }

    for (const logger of this.publishers) {
      data.forEach((d) => {
        logger.log(d);
      });
    }
  }

  private writeToLog(msg: string, level: LogLevel, params: any[]): void {
    if (this.shouldLog(level)) {
      const entry: LogEntry = new LogEntry(this.key);
      entry.message += msg;
      entry.level = level;
      entry.extraInfo = params;
      this.buffer.push(entry);
      this.flush.next(LoggerEvents.Flush);
    }
  }

  private shouldLog(level: LogLevel): boolean {
    let result = false;
    if ((this.level && level >= this.level && this.enabled) || (this.level === LogLevel.All && this.enabled)) {
      result = true;
      return result;
    } else {
      return result;
    }
  }
}
