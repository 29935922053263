import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { IdentificationsApiService } from 'src/app/@core/api/identifications-api.service';
import { SearchResult } from 'src/app/@core/domain/interfaces/search-result.interface';
import { TerminalAction } from 'src/app/@core/domain/interfaces/terminal.interface';
import { Owner } from '../../../../../@core/domain/interfaces/shared/owner.interface';
import { SimpleAction } from '../../anonymous-passage/anonymous-passage.component';

@Component({
  selector: 'app-identification-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent {
  @Input()
  action!: TerminalAction | SimpleAction;

  @Input()
  passageCompleted = false;

  @Input()
  terminalId!: number;

  @Output()
  selectIdentificationId = new EventEmitter<any>();

  @Output()
  searchCompleted = new EventEmitter<any>();

  @Output()
  searchReset = new EventEmitter<any>();

  @Output()
  searchStarted = new EventEmitter<any>();

  searchInput!: string;
  searchInputChanged = new Subject<string>();
  searchResult$!: Observable<SearchResult | undefined>;
  searching = false;
  awaitSearchResult = false;
  subscriberSelected = false;
  subscriber: any;
  searchResultType: any;
  resultRow!: string;

  constructor(private identificationsApiService: IdentificationsApiService) {
    this.searchInputChanged.pipe(debounceTime(300)).subscribe(() => {
      this.searching = true;
      this.awaitSearchResult = false;
      this.searchStarted.emit(true);

      // TODO: try do all this with composable streams
      this.searchResult$ = this.identificationsApiService
        .searchIdentificationsFromTerminal(this.searchInput, this.terminalId)
        .pipe(
          map((result) => {
            if (result) {
              this.searchCompleted.emit(true);
              const searchResult: SearchResult = {
                results: [],
                type: { id: 0, name: '' },
                count: 0,
              };
              this.searching = true;
              this.awaitSearchResult = false;
              if (result[0] && result[0].count > 0) {
                searchResult.results = result[0].results;
                searchResult.type = result[0].type;
              }
              if (result[1] && result[1].count > 0) {
                searchResult.results = result[1].results;
                searchResult.type = result[1].type;
              }
              if (result[2] && result[2].count > 0) {
                searchResult.results = result[2].results;
                searchResult.type = result[2].type;
              }
              if (result[3] && result[3].count > 0) {
                searchResult.results = result[3].results;
                searchResult.type = result[3].type;
              }

              return searchResult;
            }
            return;
          })
        );
    });
  }

  clearSearchform(): void {
    this.searchInput = '';
    this.searching = false;
    this.subscriberSelected = false;
    this.subscriber = null;
    this.searchReset.emit(true);
    this.passageCompleted = false;
    this.setIdentificationvalue();
  }

  changed() {
    this.searching = true;
    this.awaitSearchResult = true;
    this.searchInputChanged.next('');
  }

  selectSubscriber(result: any, type: any, i: number): void {
    this.subscriberSelected = true;
    this.subscriber = result;
    this.resultRow = 'result-' + i;
    this.searchResultType = type;
    this.setIdentificationvalue(result, type);
  }

  formatOwner(owner: Owner | undefined) {
    let ownerName = '';
    if (!owner) {
      ownerName = 'Owner information not available';
    } else {
      if (owner.firstnames) {
        ownerName += owner.firstnames;
      }
      if (owner.middleName) {
        ownerName += owner.middleName;
      }
      if (owner.lastName) {
        ownerName += owner.lastName;
      }
    }

    return ownerName;
  }

  private setIdentificationvalue(identification?: object, type?: number) {
    this.selectIdentificationId.emit({ identification, type });
  }
}
