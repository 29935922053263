// Terminal should not be on this file
import { SubscriptionModel } from './subscription.model';
import { ParkerType } from '../../../pages/transactions/components/transactions-complete/transactions-complete.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();

export class Terminal {
  constructor(public id: string, public name: string) {}
}

class Transaction {
  arrivalDate!: Date;
  entryTerminal!: string;
}

export class TransactionsSummary {
  constructor(
    public total: number = 0,
    public first: Transaction = new Transaction(),
    public last: Transaction = new Transaction()
  ) {}
}

export class TransactionsFilter {
  constructor(
    public startDate?: Date,
    public endDate?: Date,
    public subscriptionModels?: SubscriptionModel[],
    public parkerTypes?: ParkerType[],
    public facilityId?: string
  ) {
    this.subscriptionModels = [];
    this.parkerTypes = [];
  }

  static prepareForApi(filter: TransactionsFilter): any {
    if (filter && filter.subscriptionModels) {
      const modelIds: number[] = [];
      const parkerTypeIds: number[] = [];
      filter.subscriptionModels.forEach((m) => {
        if (m.selected) {
          modelIds.push(Number(m.id));
        }
      });
      if (filter.parkerTypes) {
        filter.parkerTypes.forEach((p) => {
          if (p.selected) {
            parkerTypeIds.push(Number(p.type.id));
          }
        });
      }
      const preparedFilter = {
        startDate: dayjs(filter.startDate).utc(false)?.toISOString(),
        endDate: dayjs(filter.endDate).utc(false)?.toISOString(),
        facilityId: filter.facilityId,
        subscriptionModelsId: modelIds,
        parkerTypes: parkerTypeIds,
      };

      return preparedFilter;
    }
    return;
  }
}

export class ManualCompletionReason {
  id: string;
  name: string;

  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class TransactionsCompletionData {
  filter!: TransactionsFilter;
  summary!: TransactionsSummary;
}

export const LAST_COMPLETED_STORAGE_KEY = 'last-completed-transactions';
