import { Component, OnDestroy } from '@angular/core';
import { Identification, IdentificationTypes } from '../../../@core/domain/models/identification.model';
import { ValidationAction } from '../../../@core/domain/models/identification-validation.model';
import { IdentificationValidationApiService } from '../../../@core/api/identification-validation-api.service';
import { NbAccessChecker } from '@nebular/security';
import { TrackingService } from '../../../@shared/services/tracking.service';
import { LogService } from '../../../@core';
import { ToastMessageService } from '../../../@shared/services/toast-message.service';

type Nullable<T> = T | null;

@Component({
  selector: 'app-identification-validation',
  templateUrl: './identification-validation.component.html',
  styleUrls: ['./identification-validation.component.scss'],
})
export class IdentificationValidationComponent implements OnDestroy {
  validationActions!: Nullable<ValidationAction[]>;
  validationMessages!: Nullable<string[]>;
  identification!: any;
  inValidIdentificationInput = false;
  isLoading = false;
  validationCompleted = false;
  identificationNotFoundMessage!: string;
  accessIdentificationValidation = false;
  initialState = true;

  private identificationType!: IdentificationTypes;
  private aclSubscription$;
  private searching = false;

  constructor(
    private identificationValidationApiService: IdentificationValidationApiService,
    private toastMessageService: ToastMessageService,
    private accessChecker: NbAccessChecker,
    private trackingService: TrackingService,
    private logger: LogService
  ) {
    this.aclSubscription$ = this.accessChecker
      .isGranted('manage', 'identificationValidation')
      .subscribe((result: boolean) => {
        this.accessIdentificationValidation = result;
      });

    this.trackingService.eventEmitter('identificationValidation', 'pageView', 'visit', '', 0);
  }

  checkForIdentification(id: string): void {
    this.initialState = false;
    if (Identification.isValidTicketNumber(id)) {
      this.getValidationActions(id);
      //this.inValidIdentificationInput = false;
    } else {
      this.getValidationActions(id);
      //this.inValidIdentificationInput = true;
    }
  }

  handleKeyUp($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.initialState = false;
      this.checkForIdentification(this.identification);
    }
  }

  onBarcodeScanned(id: string): void {
    this.initialState = false;
    this.getValidationActions(id, IdentificationTypes.TicketBarcode);
  }

  executeValidationAction(action: any): void {
    this.isLoading = true;
    this.identificationValidationApiService
      .confirmTransactionValidation(this.identification, this.identificationType, action.id)
      .subscribe({
        next: this.onExecuteValidationConfirmed.bind(this),
        error: this.handleHttpErrorForConfirm.bind(this),
      });
  }

  reset(): void {
    this.identification = null;
    this.validationActions = null;
    this.validationMessages = null;
    this.inValidIdentificationInput = false;
  }

  dummyModalTap(): void {
    return;
  }

  ngOnDestroy(): void {
    this.aclSubscription$.unsubscribe();
  }

  onSearchFormCleared($event: any) {
    this.searching = false;
    this.identification = null;
    this.validationActions = null;
    this.identificationNotFoundMessage = '';
    this.inValidIdentificationInput = false;
  }

  private onExecuteValidationConfirmed(response: any): void {
    this.isLoading = false;
    this.validationActions = [];
    this.validationMessages = [];

    if (response.displayText) {
      this.validationMessages = response.displayText;
    }

    this.logger.info('Validation action completed', []);
    this.validationCompleted = true;
  }

  private handleHttpErrorForConfirm(): void {
    this.isLoading = false;
    this.toastMessageService.showFailure('Error' + ' - ' + 'You are not authorized to perform this action');
  }

  private getValidationActions(
    identification: string,
    identificationType: IdentificationTypes = IdentificationTypes.Manual
  ): void {
    this.isLoading = true;
    this.searching = true;
    this.identificationType = identificationType;
    this.identificationNotFoundMessage = '';
    this.identificationValidationApiService.getValidationOptions(identification, identificationType).subscribe({
      next: this.onGetValidationActionsSuccess.bind(this),
      error: this.onGetValidationActionsError.bind(this),
    });
  }

  private onGetValidationActionsSuccess(validationActions: any): void {
    if (validationActions.status === 404) {
      this.onGetNotFoundValidationActionsMessage();
      this.inValidIdentificationInput = !Identification.isValidTicketNumber(this.identification);
      return;
    }
    if (validationActions.defaultAction) {
      this.executeValidationAction(ValidationAction.prepareFromApi(validationActions.defaultAction));
      return;
    }
    this.isLoading = false;

    if (validationActions.displayText) {
      this.validationMessages = validationActions.displayText;
    }

    if (validationActions.actions) {
      this.validationActions = validationActions.actions.map((action: any) => ValidationAction.prepareFromApi(action));
    }
  }

  private onGetNotFoundValidationActionsMessage() {
    this.isLoading = false;
    this.identificationNotFoundMessage = 'Identification ' + this.identification + ' was not found';
    this.logger.info(this.identificationNotFoundMessage, []);
  }

  private onGetValidationActionsError(error: any) {
    this.isLoading = false;
    let message: string;
    switch (error.status) {
      case 403: {
        message = 'You are not authorized to access the requested data';
        if (error.error.Message) {
          message += ' ' + error.error.Message;
        }
        break;
      }
      case 404: {
        message = 'Identification not found';
        break;
      }
      default: {
        message = 'Server error, please try again';
        break;
      }
    }
    this.logger.info('Validation action error', message);
    this.toastMessageService.showFailure('Validation error' + ' - ' + message);
  }
}
