import { ActivatedRoute, Params } from '@angular/router';
import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { ParkbaseStorageService } from 'src/app/@shared/services/parkbase-storage.service';
import { FacilityName } from 'src/app/@core/domain/interfaces/facility.interface';
import { NbLayoutRulerService } from '@nebular/theme';

@Component({
  selector: 'app-facility-detail',
  templateUrl: './facility-detail.component.html',
  styleUrls: ['./facility-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilityDetailComponent implements OnInit {
  selection = 'notificationsForFacility';
  currentFacility!: FacilityName;
  showNotifications = true;

  constructor(
    private route: ActivatedRoute,
    private parkbaseStorageService: ParkbaseStorageService,
    private layoutRuleService: NbLayoutRulerService
  ) {
    this.getWindowSizeForNotifications();
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: Params) => {
      this.getCurrentFacility();
    });
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.getWindowSizeForNotifications();
  }

  private getWindowSizeForNotifications() {
    this.layoutRuleService.getDimensions().subscribe((data) => {
      this.showNotifications = data.clientWidth >= 1024;
    });
  }

  private getCurrentFacility(): FacilityName | void {
    this.route.params.subscribe((params: Params) => {
      this.currentFacility = {
        id: params['id'],
        name: '',
      };
      const terminalId = 1;
      console.log('params', params);
      const facilitiesFromLocalStorage = this.parkbaseStorageService.retrieveFacilities();
      if (facilitiesFromLocalStorage) {
        facilitiesFromLocalStorage.forEach((f: FacilityName) => {
          if (f.id.toString() === params['id']) {
            this.currentFacility.name = f.name;
            this.parkbaseStorageService.storeFacilitySelection(f);
          }
        });
      }
    });
  }
}
