import { Injectable } from '@angular/core';
import { ShepherdService } from 'angular-shepherd';
import { TranslateService } from '@ngx-translate/core';
import { ParkbaseStorageService } from './parkbase-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AppTourService {
  confirmCancelMessage = '';
  dialogMessages!: string[];
  dialogActions!: string[];

  constructor(
    public shepherdService: ShepherdService,
    private parkbaseStorageService: ParkbaseStorageService,
    private translate: TranslateService
  ) {}

  translations() {
    this.translate
      .get(['START', 'NEXT', 'PREVIOUS', 'FINISH', 'CONFIRM_CANCEL_MESSAGE', 'SKIP'])
      .subscribe((values) => {
        this.dialogActions = Object.keys(values).map((key) => values[key]);
        this.confirmCancelMessage = this.dialogActions[4];
      });
  }

  startTour() {
    this.translations();

    this.translate
      .get([
        'T1',
        'T2',
        'T3',
        'T4',
        'T5',
        'T6',
        'T7',
        'T8',
        'T9',
        'T10',
        'T11',
        'T12',
        'T13',
        'T14',
        'T15',
        'T16',
        'T17',
        'T18',
        'T19',
        'T20',
        'T21',
        'T22',
        'T23',
        'T24',
        'T25',
        'T26',
        'T27',
        'T28',
        'T29',
        'T30',
        'T31',
        'T32',
        'T33',
        'T34',
        'T35',
        'T36',
        'T37',
        'T38',
      ])
      .subscribe((values) => {
        this.dialogMessages = Object.keys(values).map((key) => values[key]);
        this.getTranslationKeys();
      });
  }

  private getTranslationKeys() {
    if (!this.shepherdService.isActive) {
      const defaultSteps = {
        classes: 'shepherd-theme-arrows',
        scrollTo: false,
        cancelIcon: {
          enabled: true,
        },
      };
      this.shepherdService.modal = true;
      this.shepherdService.confirmCancel = true;
      this.shepherdService.confirmCancelMessage = this.confirmCancelMessage;
      this.shepherdService.defaultStepOptions = defaultSteps;
      this.shepherdService.addSteps([
        {
          title: this.dialogMessages[0],
          text: this.dialogMessages[1],
          classes:
            'start-tour shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',
          buttons: [
            {
              action() {
                return this.cancel();
              },
              text: this.dialogActions[5],
              classes: 'skip',
            },
            {
              action() {
                return this.next();
              },
              text: this.dialogActions[0],
              classes: 'next',
            },
          ],
          id: 'tour-1',
        },
        {
          title: this.dialogMessages[2],
          text: this.dialogMessages[3],
          classes:
            'tour shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',
          attachTo: {
            element: '.menu-bar',
            on: 'right',
          },
          buttons: [
            {
              action() {
                return this.next();
              },
              text: this.dialogActions[1],
            },
          ],
        },
        {
          title: this.dialogMessages[4],
          text: this.dialogMessages[5],
          attachTo: {
            element: '#facility-selector',
            on: 'bottom',
          },
          classes:
            'tour shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',
          buttons: [
            {
              action() {
                return this.next();
              },
              text: this.dialogActions[1],
            },
          ],
          id: 'tour-2',
        },
        {
          title: this.dialogMessages[6],
          text: this.dialogMessages[7],
          attachTo: {
            element: '#global-search',
            on: 'bottom',
          },
          classes: 'tour shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',
          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: this.dialogActions[2],
            },
            {
              action() {
                return this.next();
              },
              text: this.dialogActions[1],
            },
          ],
          id: 'tour-3',
        },
        {
          title: this.dialogMessages[8],
          text: this.dialogMessages[9],
          attachTo: {
            element: '#facilities-grouped',
            on: 'auto',
          },
          classes: 'tour shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',
          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: this.dialogActions[2],
            },
            {
              action() {
                return this.next();
              },
              text: this.dialogActions[1],
            },
          ],
          id: 'tour-4',
        },
        {
          title: this.dialogMessages[10],
          text: this.dialogMessages[11],
          attachTo: {
            element: '.facility-item',
            on: 'bottom',
          },
          classes: 'tour shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text',
          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: this.dialogActions[2],
            },
            {
              action() {
                return this.next();
              },
              text: this.dialogActions[1],
            },
          ],
          id: 'tour-5',
        },
        {
          title: this.dialogMessages[12],
          text: this.dialogMessages[13],
          attachTo: {
            element: '.facility-card',
            on: 'top',
          },
          classes:
            'tour shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',
          advanceOn: { selector: '.facility-card', event: 'click' },
          id: 'tour-6',
        },
        {
          title: this.dialogMessages[14],
          text: this.dialogMessages[15],
          classes:
            'tour shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',
          buttons: [
            {
              action() {
                return this.next();
              },
              text: this.dialogActions[0],
            },
          ],
          id: 'tour-7',
        },
        {
          title: this.dialogMessages[16],
          text: this.dialogMessages[17],
          attachTo: {
            element: '#terminals',
            on: 'right',
          },
          classes:
            'tour shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',
          buttons: [
            {
              action() {
                return this.next();
              },
              text: this.dialogActions[1],
            },
          ],
          id: 'tour-8',
        },
        {
          title: this.dialogMessages[18],
          text: this.dialogMessages[19],
          attachTo: {
            element: '#totals',
            on: 'bottom',
          },
          classes:
            'tour shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',
          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: this.dialogActions[2],
            },
            {
              action() {
                return this.next();
              },
              text: this.dialogActions[1],
            },
          ],
          scrollTo: true,
          id: 'tour-11',
        },
        {
          title: this.dialogMessages[20],
          text: this.dialogMessages[21],
          attachTo: {
            element: '#activity',
            on: 'top',
          },
          classes:
            'tour shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',
          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: this.dialogActions[2],
            },
            {
              action() {
                return this.next();
              },
              text: this.dialogActions[1],
            },
          ],
          scrollTo: true,
          id: 'tour-12',
        },
        {
          title: this.dialogMessages[22],
          text: this.dialogMessages[23],
          attachTo: {
            element: '#emergency-alarms',
            on: 'bottom',
          },
          classes:
            'tour shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',
          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: this.dialogActions[2],
            },
            {
              action() {
                return this.next();
              },
              text: this.dialogActions[1],
            },
          ],
          scrollTo: true,
          id: 'tour-13',
        },
        {
          title: this.dialogMessages[24],
          text: this.dialogMessages[25],
          attachTo: {
            element: '#notifications',
            on: 'left',
          },
          classes:
            'tour shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',
          buttons: [
            {
              action() {
                return this.back();
              },
              classes: 'shepherd-button-secondary',
              text: this.dialogActions[2],
            },
            {
              action() {
                return this.next();
              },
              text: this.dialogActions[1],
            },
          ],
          scrollTo: true,
          id: 'tour-14',
        },
        {
          title: this.dialogMessages[26],
          text: this.dialogMessages[27],
          classes:
            'tour shepherd shepherd-open shepherd-theme-arrows shepherd-transparent-text shepherd-step-highlight',
          buttons: [
            {
              action() {
                return this.complete();
              },
              text: this.dialogActions[3],
            },
          ],
          id: 'tour-15',
        },
      ]);
      this.shepherdService.modal = true;
      this.shepherdService.confirmCancel = false;
      this.shepherdService.start();

      this.shepherdService.tourObject.on('cancel', (event: any) => {
        this.parkbaseStorageService.disableFirstUse();
      });

      this.shepherdService.tourObject.on('complete', (event: any) => {
        this.parkbaseStorageService.disableFirstUse();
      });
    }
  }
}
