<div *ngIf='showComponent' @slideIn @slideOut class='terminal-actions-mobile-container'>
  <app-header-nav-mobile [componentRef]='terminalActionsMobileDialogRef'
                         [handleNavigate]='true' title="{{ 'MOBILE.LOST_TICKET' | translate }}">
  </app-header-nav-mobile>
  <div class='terminal-actions-mobile-content'>
    <app-cell [showNav]='false' [terminalOverview]='terminalOverview'></app-cell>
  </div>
  <div class='terminal-actions-mobile-forms'>
    <app-lost-ticket [showTerminalTitle]=false [terminalId]='terminalOverview.id'></app-lost-ticket>
  </div>
</div>


