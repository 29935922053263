<ng-container *ngIf="canChangeBypassAlarm">
  <div *ngIf="bypassAlarmStatusActive | async as alarmStatus">
    <nb-card [class]="alarmStatus.going ? 'bypass-alarms-active-card' : 'bypass-alarms-card'" class="bypass-alarms">
      <nb-card-body (click)="controlBypassAlarms(dialog)" class="bypass-alarms-card-body">
        <div [class]="alarmStatus.going ? 'alert-icon-active' : 'alert-icon'">
          <nb-icon icon="alert-circle-outline" nbPrefix pack="eva"></nb-icon>
        </div>
        <div class="text-label">
          <h6>{{ 'BYPASS_ALARMS.TITLE' | translate }}</h6>
          <div *ngIf="!alarmStatus.going">{{ 'BYPASS_ALARMS.INACTIVE' | translate }}</div>
          <div *ngIf="alarmStatus.going">{{ 'BYPASS_ALARMS.ACTIVE' | translate }}</div>
        </div>
      </nb-card-body>
    </nb-card>
    <ng-template #dialog id="dialog-ref" let-data let-ref="dialogRef">
      <nb-card class="bypass-alarms-dialog-card">
        <nb-card-header>
          <h5>
            {{ 'BYPASS_ALARMS.TITLE' | translate }}
            <span *ngIf="!alarmStatus.going">{{ 'BYPASS_ALARMS.ACTIVATE' | translate }}</span>
            <span *ngIf="alarmStatus.going">{{ 'BYPASS_ALARMS.DEACTIVATE' | translate }}</span
            >?
          </h5>
        </nb-card-header>
        <nb-card-body [nbSpinner]="awaitActionComplete" nbSpinnerSize="large" nbSpinnerStatus="info">
          <app-info-alert *ngIf="partialFailureMessage" [showIcon]="true" infoText="{{ partialFailureMessage }}">
          </app-info-alert>
          <p>
            {{ 'BYPASS_ALARMS.EXPLANATION_1' | translate }}
            <span *ngIf="!alarmStatus.going">{{ 'BYPASS_ALARMS.ACTIVATE' | translate }}</span>
            <span *ngIf="alarmStatus.going">{{ 'BYPASS_ALARMS.DEACTIVATE' | translate }}</span>
            {{ 'BYPASS_ALARMS.EXPLANATION_2' | translate }} <strong>"{{ facility.name }} "</strong>? <br />{{
              'BYPASS_ALARMS.EXPLANATION_3' | translate
            }}
          </p>
          <div *ngFor="let rule of bypassAlarmRules" class="terminal-type">
            <div>
              <h3>{{ rule.terminalType.name }}</h3>
              <p>
                <span>{{ rule.action.name }}</span>
              </p>
            </div>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="bypass-alarms-buttons">
            <button (click)="ref.close()" [disabled]="buttonDisabled" fullWidth nbButton shape="semi-round">
              {{ 'BYPASS_ALARMS.CANCEL' | translate }}
              <nb-icon icon="close-outline" pack="eva"></nb-icon>
            </button>
            <button
              (click)="activateBypassAlarm()"
              *ngIf="!alarmStatus.going"
              [disabled]="buttonDisabled"
              class="activate-bypass-alarms-button"
              fullWidth
              nbButton
              shape="semi-round"
            >
              {{ 'BYPASS_ALARMS.ACTIVATE_ALARM' | translate }}
              <nb-icon class="alarm-bell-icon" icon="bell-outline" nbPrefix pack="eva" status="control"></nb-icon>
            </button>
            <button
              (click)="deactivateBypassAlarm()"
              *ngIf="alarmStatus.going"
              [disabled]="buttonDisabled"
              class="activate-bypass-alarms-button"
              fullWidth
              nbButton
              shape="semi-round"
            >
              {{ 'BYPASS_ALARMS.DEACTIVATE_ALARM' | translate }}
              <nb-icon class="alarm-bell-icon" icon="bell-off-outline" nbPrefix pack="eva" status="control"></nb-icon>
            </button>
          </div>
        </nb-card-footer>
      </nb-card>
    </ng-template>
  </div>
</ng-container>
