import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-alert',
  templateUrl: './info-alert.component.html',
  styleUrls: ['./info-alert.component.scss'],
})
export class InfoAlertComponent implements OnInit {
  @Input()
  title = '';

  @Input()
  infoText = '';

  @Input()
  type = 'info';

  @Input()
  showIcon = false;

  @Input()
  class = 'alert-diminished';

  constructor() {}

  ngOnInit(): void {
    if (this.type === 'warning') {
      this.class = 'alert-warning';
    }
    if (this.type === 'info') {
      this.class = 'alert-info';
    }
    if (this.type === 'success') {
      this.class = 'alert-success';
    }
  }
}
