import { Component, HostListener, Input, OnInit } from '@angular/core';
import { slideInOutAnimation } from '../../@shared/animation/animation';
import { NbDialogRef, NbLayoutRulerService } from '@nebular/theme';

@Component({
  selector: 'app-mobile-container',
  templateUrl: './mobile-container.component.html',
  styleUrls: ['./mobile-container.component.scss'],
  animations: slideInOutAnimation,
})
export class MobileContainerComponent implements OnInit {
  @Input()
  componentRef: any;

  @Input()
  title!: string;

  showComponent = true;
  handleNavigate: any;

  constructor(
    protected mobileContainerComponentRef: NbDialogRef<MobileContainerComponent>,
    private layoutRuleService: NbLayoutRulerService
  ) {}

  @HostListener('window:resize', [])
  onResize(): void {
    this.getWindowSize();
  }

  ngOnInit(): void {
    this.showComponent = true;
    this.getWindowSize();
  }

  back() {
    this.showComponent = false;
    if (this.handleNavigate) {
      setTimeout(() => {
        this.mobileContainerComponentRef.close({ handleNavigate: { changeBack: true } });
      }, 250);
    } else {
      setTimeout(() => {
        this.mobileContainerComponentRef.close(false);
      }, 250);
    }
  }

  close() {
    this.mobileContainerComponentRef.close(true);
  }

  getWindowSize() {
    this.layoutRuleService.getDimensions().subscribe((data) => {
      if (data.clientWidth > 768) {
        this.close();
      }
    });
  }
}
