import { BehaviorSubject, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ParkbaseStorageService } from 'src/app/@shared/services/parkbase-storage.service';
import { Terminal } from 'src/app/@core/domain/interfaces/terminal.interface';

import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import * as updateLocale from 'dayjs/plugin/updateLocale';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as nl from 'dayjs/locale/nl';
import * as en from 'dayjs/locale/en';

dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);

// import { SimpleAction } from '../components/contextual-actions/contextual-actions.component';

class NotificationFilter {
  types: number[];
  levels: number[];
  terminalIds: number[];

  constructor(types: number[], levels: number[], terminalIds: number[]) {
    this.types = types;
    this.levels = levels;
    this.terminalIds = terminalIds;
  }
}

@Injectable({
  providedIn: 'root',
})
export class StatusPageService {
  private _refreshNotifications: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  refreshNotifications = this._refreshNotifications.asObservable();

  private _refreshFacilitiesSummary: Subject<boolean> = new Subject<boolean>();
  refreshFacilitiesSummary = this._refreshFacilitiesSummary.asObservable();

  private _refreshTerminalSummary: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  refreshTerminalSummary = this._refreshTerminalSummary.asObservable();

  private _refreshTerminal: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  refreshTerminal = this._refreshTerminal.asObservable();

  private _filterNotifications: BehaviorSubject<NotificationFilter> = new BehaviorSubject<NotificationFilter>({
    types: [],
    levels: [],
    terminalIds: [],
  });
  filterNotifications = this._filterNotifications.asObservable();

  private _preventClick: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  preventClick = this._preventClick.asObservable();

  constructor(private parkbaseStorageService: ParkbaseStorageService) {
    this._refreshNotifications.next(true);
  }

  // If more translations come available, split switch and/or add locales
  getLocale(): string {
    const lang = this.parkbaseStorageService.getLanguage();
    switch (lang) {
      case 'en-GB':
      case 'en-US':
      case 'en-AU':
        dayjs.locale(en);
        return 'en';
      case 'nl-NL':
        dayjs.locale(nl);
        return 'nl';
      case 'de-DE':
        return 'de';
      case 'fr-FR':
        return 'fr';
      case 'sv-SE':
        return 'se';
      case 'sv-FI':
        return 'fi';
      default:
        return 'nl';
    }
  }

  formatRelativeTime(date: string): string {
    this.getLocale();
    return dayjs(date).fromNow();
  }

  formatExactTime(date: string): string {
    this.getLocale();
    return dayjs(date).format('LLL');
  }

  triggerRefreshNotifications(status: boolean): void {
    this._refreshNotifications.next(status);
  }

  triggerRefreshFacilitiesSummary(status: boolean): void {
    this._refreshFacilitiesSummary.next(status);
  }

  triggerRefreshTerminalSummary(status: boolean): void {
    this._refreshTerminalSummary.next(status);
  }

  triggerRefreshTerminal(status: boolean): void {
    this._refreshTerminal.next(status);
  }

  triggerPreventClick(status: boolean): void {
    this._preventClick.next(status);
  }

  filterNotificationsForTerminal(terminal: Terminal): void {
    this._filterNotifications.next({ types: [], levels: [], terminalIds: [terminal.id] });
  }
}
