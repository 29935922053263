import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastMessageService {
  private config = {
    enableHtml: false,
    extendedTimeOut: 2500,
    newestOnTop: true,
    timeOut: 10000,
    tapToDismiss: true,
    preventDuplicates: true,
    countDuplicates: true,
  };

  constructor(private toastr: ToastrService) {}

  // ActionResponseType
  // 0 = Failure
  // 1 = Success
  // 2 = InputRequired
  // 3 = PartialFailure

  setConfig() {}

  /**
   * Show toast for Failure scenario
   * @param title
   * @param message
   */
  showFailure(message: string) {
    this.toastr.error(message, '', {
      ...this.config,
      payload: { type: 'failure' },
    });
  }

  /**
   * Show toast for Success scenario
   * @param title
   * @param message
   */
  showSuccess(message: string) {
    this.toastr.success(message, '', {
      ...this.config,
      payload: { type: 'success' },
    });
  }

  /**
   * Show toast for user input required scenario
   * @param title
   * @param message
   */
  showInputRequired(message: string) {
    this.toastr.info(message, '', {
      ...this.config,
      payload: { type: 'input-required' },
    });
  }

  /**
   * Show toast for partial backend error scenario
   * @param title
   * @param message
   */
  showPartialFailure(message: string) {
    this.toastr.warning(message, '', {
      ...this.config,
      payload: { type: 'partial-failure' },
    });
  }
}
