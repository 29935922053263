<div class='contextual-actions-handle'>
  <button (click)='open()' class='open-dialog-button'>
    <nb-icon
      [nbPopoverPlacement]='direction'
      [nbPopover]='popoverTemplateRef'
      icon='more-vertical'
      nbPrefix
      pack='eva'
    ></nb-icon>
  </button>
</div>
<ng-template #popoverTemplateRef>
  <div class='contextual-actions-popover'>
    <div class='contextual-actions-popover__title'>
      <span class='action-title'>
        <nb-icon
          *ngIf='notification && notification.level'
          class='icon color-{{ notification.level }}-custom-icon__terminalType'
          icon='{{ getIconType(notification) }}'
          nbPrefix
          pack='customIcons'
        ></nb-icon>
        {{ 'ACTIONS.TITLE' | translate }}
      </span>
      <!-- <nb-icon icon="close-circle" pack="eva" class="icon icon-close" (click)="close()"></nb-icon> -->
    </div>
    <div class='contextual-actions-popover__message'>
      <h5>{{ notification.facility.name }}</h5>
      <p *ngIf='notification.terminal'>
        Terminal: {{ notification.terminal.name }}<br />
        Message: {{ notification.title }}
      </p>
    </div>
    <div
      *ngIf='notification.availableActions.length > 0 && !notificationHandled'
      class='confirm-action'
    >
      <ng-container *ngFor='let action of notification.availableActions'>
        <div class='confirm'>
          <nb-icon icon='alert-circle' pack='eva'></nb-icon>
          {{ action.name }}
        </div>
        <div>
          <button
            (click)='handleNotificationAction(notification.id, action.type)'
            class='notification-action-button'
            nbButton
          >
            {{ 'NOTIFICATIONS.CONFIRM' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
    <div>&nbsp;</div>
  </div>
</ng-template>
