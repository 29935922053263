<div *ngIf='showComponent' @slideIn @slideOut>
  <app-header-nav-mobile [componentRef]='terminalActionsMobileDialogRef'
                         [handleNavigate]='true' title="{{ 'MOBILE.TERMINAL_ACTIONS' | translate }}">
  </app-header-nav-mobile>
  <div class='terminal-actions-mobile-content'>
    <app-cell (click)='openTerminalEvents()' [showNav]='true' [terminalOverview]='terminalOverview'
              navIcon='activity-outline'></app-cell>
    <div class='terminal-actions-mobile-content__actions'>
      <ul class='terminal-actions-mobile-list'>
        <li (click)='openMobile(a)' *ngFor='let a of formActions; let i = index;' class='menu-item'>
          <div class='menu-item-title'>
            <div class='menu-item-title__icon'>
            </div>
            <div>{{a.name}}</div>
          </div>
          <div *ngIf='actionWithIcon(a)'>
            <nb-icon
              icon='arrow-ios-forward-outline' nbButton
              nbPrefix
              pack='eva'
              status='control'></nb-icon>
          </div>
        </li>
      </ul>
    </div>
    <div class='terminal-actions-mobile-content__actions'>
      <ul class='terminal-actions-mobile-list'>
        <li class='menu-item'>
          <div class='menu-item-caption'>
            <div>{{ 'TERMINAL_ACTIONS.MANAGE_STATUS' | translate }}</div>
          </div>
        </li>
        <li (click)='openMobile(a)' *ngFor='let a of statusActions; let i = index;' class='action-menu-item'>
          <div class='action-menu-item-title'>
            <div class='action-menu-item-title__icon'>
            </div>
            <div>{{a.name}}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

