import { ActivatedRoute, Params, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ParkbaseStorageService } from 'src/app/@shared/services/parkbase-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { FacilitySummaryGrouped } from 'src/app/@core/domain/interfaces/facility-summary.interface';
import { Division } from 'src/app/@core/domain/interfaces/division.interface';
import { DivisionsApiService } from 'src/app/@core/api/divisions-api.service';
import { FacilitiesApiService } from 'src/app/@core/api/facilities-api.service';
import { LogService } from 'src/app/@core';
import { StatusPageService } from '../../services/status-page.service';
import { environment } from '../../../../../environments/environment';
import { FacilityOpeningState } from '../../../../@core/domain/interfaces/facility.interface';
import { NbLayoutRulerService } from '@nebular/theme';

@Component({
  selector: 'app-facilities',
  templateUrl: './facilities.component.html',
  styleUrls: ['./facilities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilitiesComponent implements OnInit, OnDestroy {
  @Input() facilitySelection!: string;
  @Input() facilityId!: number;

  selection = 'divisionsNotifications';
  selectedLayout = '';
  division$: Observable<Division>;
  facilitySummaryGrouped!: Observable<FacilitySummaryGrouped>;
  interval = 50000;
  toggleNotifications = false;
  showNotificationsContainer = true;
  disabled = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private divisionsApiService: DivisionsApiService,
    private facilitiesApiService: FacilitiesApiService,
    private statusPageService: StatusPageService,
    private parkbaseStorageService: ParkbaseStorageService,
    private layoutRuleService: NbLayoutRulerService,
    private translate: TranslateService,
    private logger: LogService
  ) {
    this.interval = setInterval(() => {
      this.refreshData();
    }, environment.refreshInterval);
    this.division$ = this.divisionsApiService.division$;
    this.divisionsApiService.loadDivisions();

    this.facilitySelection = this.selection;

    this.getWindowSize();
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.getWindowSize();
  }

  getWindowSize() {
    this.layoutRuleService.getDimensions().subscribe((data: { clientWidth: number }) => {
      this.showNotificationsContainer = data.clientWidth >= 1440;
    });
  }

  ngOnInit(): void {
    this.facilitySummaryGrouped = this.facilitiesApiService.facilitySummaryGrouped;
    this.refreshData();
    this.selectedLayout = this.parkbaseStorageService.retrieveStatusPageLayout();

    this.statusPageService.refreshFacilitiesSummary.subscribe((status) => {
      if (status) {
        this.refreshData();
      }
    });

    this.route.params.subscribe((params: Params) => {
      if (Object.keys(params).length === 0) {
        const facilityOptionAll = { id: 0, name: '', openingState: FacilityOpeningState.Open, availableActions: [] };
        this.translate.get('FACILITY_SELECT.OPTION_ALL').subscribe((res: string) => {
          if (res) {
            facilityOptionAll.name = res;
          }
        });
        this.parkbaseStorageService.storeFacilitySelection(facilityOptionAll);
      }
    });
  }

  refreshData(): void {
    this.facilitiesApiService.loadFacilitySummaryGroupedFromAPI();
  }

  showNotifications() {
    this.toggleNotifications = !this.toggleNotifications;
    this.logger.info('Notifications window on status page opened', []);
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  trackByFn(index: number): number {
    return index;
  }
}
