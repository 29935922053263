<nb-spinner *ngIf='!subscriptionModels' class=''></nb-spinner>
<button (click)='toggleModels()' *ngIf='subscriptionModels' class='subscription-model-button' fullWidth nbButton
        size='large' type='button'>
  <nb-icon icon='layers-outline' nbPrefix pack='eva'></nb-icon>
  <span [class.not-empty]='selectedModels.length > 0' class='label'>
    <ng-container *ngIf='hasMultiple'> {{ 'TRANSACTIONS.SUBSCRIPTION_MODELS' | translate }} *</ng-container>
    <ng-container *ngIf='!hasMultiple'> {{ 'TRANSACTIONS.SUBSCRIPTION_MODEL' | translate }} *</ng-container>
  </span>
  <span *ngIf='selectedModels && !isEveryModelSelected' class='selected-models'>
    <ng-container *ngFor='let model of selectedModels; let i = index; let isLast = last;'>
      <span *ngIf='i <= 4'>{{ model.name | titlecase  }}
        <span *ngIf='!isLast' class='separator'>, </span>
      </span>
    </ng-container>
  </span>
  <span *ngIf='selectedModels && isEveryModelSelected' class='selected-models'>
    {{ 'TRANSACTIONS.ALL_SUBSCRIPTION_MODELS' | translate }}
  </span>
</button>
<ng-template #modelSelection>
  <nb-card class='subscription-model-container'>
    <nb-card-header>
      <nb-toggle (change)='toggleSelectAll()'
                 [(ngModel)]='isEveryModelSelected'>
        <span *ngIf='!isEveryModelSelected'>
            {{ 'BUTTONS.SELECT_ALL' | translate }}
          </span>
        <span *ngIf='isEveryModelSelected'>
            {{ 'BUTTONS.UNSELECT_ALL' | translate }}
          </span>
      </nb-toggle>
    </nb-card-header>
    <nb-card-body>
      <div class='radio-group-container'>
        <ul *ngFor='let subscriptionModel of subscriptionModels'>
          <nb-checkbox (checkedChange)='storeSubscriptionsChoice($event, subscriptionModel)'
                       [checked]='subscriptionModel.selected'>{{subscriptionModel.name}}
          </nb-checkbox>
        </ul>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button (click)='discard()' class='button' nbButton
              status='basic'>{{ 'TRANSACTIONS.CANCEL' | translate }}</button>
      <button (click)='confirm()' class='button' nbButton
              status='success'>{{ 'TRANSACTIONS.CONFIRM' | translate }}</button>
    </nb-card-footer>
  </nb-card>
</ng-template>
