import { Component, Input } from '@angular/core';
import { TransactionsSummary } from '../../../../@core/domain/models/transactions.model';

@Component({
  selector: 'app-transactions-summary',
  templateUrl: './transactions-summary.component.html',
  styleUrls: ['./transactions-summary.component.scss'],
})
export class TransactionsSummaryComponent {
  @Input() summary!: TransactionsSummary;
  @Input() title!: string;
  @Input() hideIcons = false;
  @Input() dateFormat = 'medium';

  constructor() {}
}
