import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-license-plate',
  templateUrl: './license-plate.component.html',
  styleUrls: ['./license-plate.component.scss'],
})
export class LicensePlateComponent {
  @Input()
  country = '';

  @Input()
  licence = '';

  constructor() {}
}
