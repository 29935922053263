import _ from 'lodash';
import { Component, Input, OnInit } from '@angular/core';
import { Terminal, TerminalAction } from '../../@core/domain/interfaces/terminal.interface';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { TerminalScreenMobileComponent } from '../terminal-screen-mobile/terminal-screen-mobile.component';
import { AnonymousPassageMobileComponent } from '../anonymous-passage-mobile/anonymous-passage-mobile.component';
import { ControlledPassageMobileComponent } from '../controlled-passage-mobile/controlled-passage-mobile.component';
import { TerminalEventsMobileComponent } from '../terminal-events-mobile/terminal-events-mobile.component';
import { TerminalsApiService } from '../../@core/api/terminals-api.service';
import { StatusPageService } from '../../pages/status-page/services/status-page.service';
import { TwoWayLaneMobileComponent } from '../two-way-lane-mobile/two-way-lane-mobile.component';
import { LostTicketMobileComponent } from '../lost-ticket-mobile/lost-ticket-mobile.component';
import { LoopAccessModeMobileComponent } from '../loop-access-mode-mobile/loop-access-mode-mobile.component';
import { TranslateService } from '@ngx-translate/core';
import { slideInOutAnimation } from '../../@shared/animation/animation';
import { ToastMessageService } from '../../@shared/services/toast-message.service';
import { TerminalOverview } from '../../@core/domain/interfaces/terminal-response.interface';
import { ResponseStatus } from '../../@core/domain/interfaces/facility.interface';

@Component({
  selector: 'app-terminal-actions-mobile',
  templateUrl: './terminal-actions-mobile.component.html',
  styleUrls: ['./terminal-actions-mobile.component.scss'],
  animations: slideInOutAnimation,
})
export class TerminalActionsMobileComponent implements OnInit {
  @Input()
  terminalOverview!: TerminalOverview;

  terminal!: Terminal;
  showComponent = true;
  statusActions: TerminalAction[] = [];
  formActions: TerminalAction[] = [];
  awaitTerminalActionComplete = true;

  constructor(
    protected terminalActionsMobileDialogRef: NbDialogRef<TerminalActionsMobileComponent>,
    private terminalsApiService: TerminalsApiService,
    private statusPageService: StatusPageService,
    private toastMessageService: ToastMessageService,
    private dialogService: NbDialogService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.terminalsApiService.loadTerminalById(this.terminalOverview.id).subscribe((t) => {
      this.terminal = t;
      this.statusPageService.triggerRefreshTerminalSummary(false);
      this.terminal.availableActions.forEach((a) => {
        switch (a.type) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 21:
          case 23:
          case 30:
            this.statusActions.push(a);
            break;
          case 27:
          case 28:
          case 29:
          case 31:
          case 32:
          case 33:
          case 34:
          case 35:
            this.formActions.push(a);
            break;
        }
      });
    });
  }

  openMobile(menuAction: any) {
    switch (menuAction.type) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 21:
      case 23:
      case 30:
        this.sendTerminalAction(menuAction);
        break;
      case 27:
      case 28:
      case 29:
      case 31:
      case 32:
      case 33:
      case 34:
      case 35:
        this.openTerminalActionDialog(menuAction);
        break;
    }
  }

  openTerminalEvents() {
    this.dialogService
      .open(TerminalEventsMobileComponent, {
        context: {
          terminalOverview: this.terminalOverview,
        },
      })
      .onClose.subscribe((res) => {
        if (res.handleNavigate && res.handleNavigate.changeBack) {
          setTimeout(() => {
            this.terminalActionsMobileDialogRef.close({ handleNavigate: { changeBack: true } });
          }, 250);
        } else {
          setTimeout(() => {
            this.terminalActionsMobileDialogRef.close(false);
          }, 250);
        }
      });
  }

  actionWithIcon(a: TerminalAction) {
    const statusActions = [1, 2, 3, 4, 21, 23, 30];
    const noIcon = _.find(statusActions, function (n) {
      return a.type === n;
    });
    return !noIcon;
  }

  private sendTerminalAction(menuAction: any) {
    this.awaitTerminalActionComplete = true;
    this.terminalsApiService.sendTerminalAction(this.terminal.id, { type: menuAction.type }).subscribe((result) => {
      if (result.status === ResponseStatus.Success) {
        this.awaitTerminalActionComplete = false;
        this.toastMessageService.showSuccess(this.terminal.name + ' ' + result.message);
        this.statusPageService.triggerRefreshTerminalSummary(true);
      } else if (result.status === ResponseStatus.Failure) {
        this.awaitTerminalActionComplete = false;
        this.toastMessageService.showFailure(this.terminal.name + ' ' + result.message);
      } else if (result.status === ResponseStatus.InputRequired) {
        this.awaitTerminalActionComplete = false;
        this.toastMessageService.showInputRequired(this.terminal.name + ' ' + result.message);
      } else if (result.status === ResponseStatus.PartialFailure) {
        this.awaitTerminalActionComplete = false;
        this.toastMessageService.showPartialFailure(this.terminal.name + ' ' + result.message);
      }
    });
  }

  private openTerminalActionDialog(menuAction: any) {
    let component: any;
    switch (menuAction.type) {
      case 27:
      case 28:
        component = AnonymousPassageMobileComponent;
        break;
      case 29:
        component = ControlledPassageMobileComponent;
        break;
      case 31:
        component = TerminalScreenMobileComponent;
        break;
      case 32:
        component = LoopAccessModeMobileComponent;
        break;
      case 33:
        component = TwoWayLaneMobileComponent;
        break;
      case 34:
        component = LostTicketMobileComponent;
        break;
      case 35:
        component = LostTicketMobileComponent;
        break;
    }
    this.dialogService
      .open(component, {
        context: {
          action: menuAction,
          terminalOverview: this.terminal,
        },
      })
      .onClose.subscribe((res) => {
        if (res && res.handleNavigate && res.handleNavigate.changeToSubscriber) {
          this.openTerminalActionDialog({ name: 'test', type: 29 });
        } else if (res && res.handleNavigate && res.handleNavigate.changeToAnonymous) {
          this.openTerminalActionDialog({ name: 'test', type: 28 });
        } else if (res && res.handleNavigate && res.handleNavigate.changeBack) {
          // do nothing
          // this.terminalActionsMobileDialogRef.close();
        } else {
          this.terminalActionsMobileDialogRef.close();
        }
      });
  }
}
