import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit {
  currentUrlContext!: string;
  currentHelpFileTitle!: string;
  currentHelpFileText!: string;
  private currentLang!: string;
  private currentFacility!: { availableActions: any[]; name: string; id: any };

  constructor(
    private router: Router,
    private routes: ActivatedRoute,
    private httpClient: HttpClient,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.currentUrlContext = this.router.url;

    this.routes.params.subscribe((params: Params) => {
      if (params && params['id']) {
        this.currentFacility = { id: params['id'], name: '', availableActions: [] };
      }
    });

    if (this.currentFacility === undefined) {
    }

    this.getHelpFile();
  }

  private getHelpFile(): void {
    const urlParts = this.currentUrlContext.split('/');
    let filename: string;
    if (urlParts.length === 4 && urlParts[2] === 'facilities') {
      filename = 'help_' + urlParts[1] + '_' + urlParts[2] + '_detail.json';
    } else {
      filename = 'help' + this.currentUrlContext.replace(/\//g, '_') + '.json';
    }
    this.httpClient
      .get('./assets/help/html/' + this.currentLang + '/' + filename, { responseType: 'json' })
      .subscribe((result: any) => {
        this.currentHelpFileTitle = result.title;
        this.currentHelpFileText = result.text;
      });
  }
}
