<div class='terminal-icon-container'>
  <nb-icon
    *ngIf="terminalTypeIcon === 'terminalType_0'"
    class='color-0-custom-icon__terminalType'
    icon='monitor-outline'
    nbPrefix
    pack='eva'
    status='control'
  ></nb-icon>
  <nb-icon
    *ngIf="terminalTypeIcon !== 'terminalType_0'"
    class='color-0-custom-icon__terminalType'
    icon='{{ terminalTypeIcon }}'
    nbPrefix
    pack='customIcons'
    status='control'
  ></nb-icon>
  <div class='terminal-status-icon'>
    <svg
      [attr.fill]='statusService.terminalStatusColor(terminalOverview.status)'
      class='alarm-svg'
      height='16'
      viewBox='0 0 16 16'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='3' cy='3' r='3'></circle>
    </svg>
  </div>
