import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../domain/interfaces/config.interface';
import { environment } from '../../../environments/environment';

export interface LogApiResponse {
  code: number;
  description: string;
}

/**
 * Send log information to backend to store
 */
@Injectable({
  providedIn: 'root',
})
export class LogApiService {
  private logBaseUrl = '';

  /**
   * Set api header and url
   * @param http HttpClient
   */
  constructor(private http: HttpClient) {
    this.init();
  }

  init() {
    this.http.get<Config>('assets/app.config.json').subscribe((data) => {
      if (data) {
        this.logBaseUrl = data.server.log + '/log';
      }
    });
  }

  /**
   * Log messages to NLog with log level: Debug, Info, Warn, Error
   * @param message string
   * @param type string
   */
  storeLogMessage(message: string, type: string): void {
    const apiUrl = this.logBaseUrl + '/' + type.toLowerCase();
    this.http
      .post<LogApiResponse>(
        apiUrl,
        { message: message },
        { headers: this.setHeaders(), observe: 'response' }
      )
      .subscribe((response) => {
        if (!environment.production) {
          console.log('-- Response from log api', response);
        }
      });
  }

  /**
   * Set headers specific to API
   * @returns HttpHeaders
   */
  private setHeaders(): HttpHeaders {
    return new HttpHeaders().append('Content-Type', 'application/json');
  }
}
