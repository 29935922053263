<div class='date-time-selector-container'>
  <div>
    <nb-form-field>
      <nb-icon icon='calendar-outline' nbPrefix pack='eva'></nb-icon>
      <input (keyup)='handleKeyUpForDate($event)'
             [(ngModel)]='localDate'
             [name]='name'
             [nbDatepicker]='dateTimePicker'
             fieldSize='large'
             fullWidth
             nbInput
             onkeydown='return false'
             placeholder='{{dateLabel}}'
             shape='semi-round'>
      <nb-datepicker #dateTimePicker (dateChange)='onDateChange($event)'
                     [max]='maxDate'></nb-datepicker>
    </nb-form-field>
  </div>
  <div>
    <nb-form-field>
      <nb-icon icon='clock-outline' nbPrefix pack='eva'></nb-icon>
      <input
        (keyup)='handleKeyUpForTime($event)'
        [(ngModel)]='selectedTime'
        [disabled]='!localDate'
        [nbTimepicker]='timepicker'
        fieldSize='large'
        fullWidth
        nbInput
        onkeydown='return false'
        placeholder='{{timeLabel}}'
        shape='semi-round' />
      <nb-timepicker #timepicker (onSelectTime)='onTimeChange()'></nb-timepicker>
    </nb-form-field>
  </div>
</div>

