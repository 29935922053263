<div class='cell-content'>
  <div class='content-container'>
    <div *ngIf='data' class='content-container__icon'>
      <app-facility-status-icon [fullStatus]="data.facility.fullStatus"
                                [openingState]="data.facility.openingState"></app-facility-status-icon>
    </div>
    <div *ngIf='data === undefined && title' class='content-container__icon'>
      <nb-icon
        class='custom-icon__facility'
        icon='facility'
        nbPrefix
        pack='customIcons'
        status='control'
      ></nb-icon>
    </div>
    <div *ngIf='data'>
      <div class='content-container__title'>{{data.facility.name}}</div>
      <div class='content-container__status'>
        <app-general-led [color]='statusService.getLedColor(data)'></app-general-led>
        {{statusService.getFacilityStatus(data)}}
      </div>
    </div>
    <div *ngIf='title'>
      <div class='content-container__title'>{{title}}</div>
      <div class='content-container__status'></div>
    </div>
    <div *ngIf='genericInfo'>
      <div class='content-container__title'>{{genericInfo.title}}</div>
      <div class='content-container__status'>{{genericInfo.message}}</div>
    </div>
    <div *ngIf='terminalOverview'>
      <app-terminal-title [showHeading]='false' [show]='true'
                          [terminalOverview]='terminalOverview'></app-terminal-title>

    </div>
  </div>
  <div class='content-container__nav'>
    <nb-icon
      *ngIf='showNav'
      icon="{{navIcon}}" nbButton
      nbPrefix
      pack='eva'
      status='control'></nb-icon>
  </div>
</div>
