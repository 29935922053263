<div *ngIf='action && action.type === 29' class='subscriber-title'>
  <div *ngIf='subscriberSelected'>
    <div (click)='clearSearchform()' class='reset-search'>
      <div>{{ 'GRANT_PASSAGE.RESET_SEARCH' | translate }}
        <nb-icon icon='close-outline' nbPrefix pack='eva'></nb-icon>
      </div>
    </div>
    <div [ngClass]="passageCompleted ? 'complete' : ''" class='identification-item-person-data'>
      <div class='search-result-item-data'>
        <div class='item-person-icon'>
          <nb-icon icon='person' nbPrefix pack='eva'></nb-icon>
        </div>
        <div class='item-person-data'>
          <ng-container *ngIf='searchResultType.id === 0'>
            <div class='owner'>{{formatOwner(subscriber.owner)}}</div>
            <div class='info'>{{searchResultType.name}} {{subscriber.country}} {{subscriber.license}}</div>
          </ng-container>
          <ng-container *ngIf='searchResultType.id === 1'>
            <div class='owner'>{{formatOwner(subscriber.owner)}}</div>
            <div
              class='info'>{{searchResultType?.name}} {{subscriber.hri}} {{subscriber.publisher?.name}} {{subscriber.type?.name}}</div>
          </ng-container>
          <ng-container *ngIf='searchResultType.id === 2 || searchResultType.id === 3'>
            <div class='owner'>{{formatOwner(subscriber.owner)}}</div>
            <div class='info'>{{subscriber.company?.name}}</div>
          </ng-container>
        </div>
      </div>
      <div>
        <app-license-plate
          *ngIf='subscriber.country && subscriber.license'
          [country]='subscriber.country'
          [licence]='subscriber.license'
          class='license-plate'
        ></app-license-plate>
      </div>
      <div>
        <nb-icon icon='attach-2-outline' pack='eva'></nb-icon>
      </div>
    </div>
  </div>
  <div *ngIf='!subscriberSelected'>
    <div class='form-floating'>
      <input #searchbar (ngModelChange)='changed()' [(ngModel)]='searchInput' autocomplete='off' class='form-control'
             id='subscriber' placeholder="{{ 'IDENTIFICATION_SEARCH.PLACEHOLDER' | translate }}" type='text'>
      <label for='subscriber'>{{ 'IDENTIFICATION_SEARCH.PLACEHOLDER' | translate }}</label>
      <button
        (click)='clearSearchform()'
        *ngIf='searching'

        class='suffix-button'
        ghost
        nbButton
        nbSuffix
      >
        <nb-icon [icon]="searching ? 'close-outline' : ''" pack='eva'></nb-icon>
      </button>
    </div>
  </div>
</div>
<ng-container *ngIf='!subscriberSelected && searching && (searchResult$ | async) as searchData'>
  <div [nbSpinner]='awaitSearchResult' class='search-results-container' nbSpinnerSize='giant'
       nbSpinnerStatus='primary'>
    <div class='search-results'>
      <div *ngIf='searchData.results && searchData.results.length === 0'>
        {{ 'GRANT_PASSAGE.NO_SEARCH_RESULTS' | translate }}
      </div>
      <div
        (click)='selectSubscriber(result, searchData.type, i)'
        *ngFor='let result of searchData.results; let i = index'
        [ngClass]="{ selected: subscriberSelected && resultRow === 'result-' + i }"
        class='search-result-item'
      >
        <div class='search-result-item-data'>
          <div class='item-person-icon'>
            <nb-icon icon='person' nbPrefix pack='eva'></nb-icon>
          </div>
          <div class='item-person-data'>
            <ng-container *ngIf='searchData.type.id === 0'>
              <div class='owner'>{{formatOwner(result.owner)}}</div>
              <div class='info'>{{result.country}} {{result.license}}</div>
            </ng-container>
            <ng-container *ngIf='searchData.type.id === 1'>
              <div class='owner'>{{formatOwner(result.owner)}}</div>
              <div
                class='info'>{{searchData.type.name}} {{result.hri}} {{result.publisher?.name}} {{result.type?.name}}</div>
            </ng-container>
            <ng-container *ngIf='searchData.type.id === 2 || searchData.type.id === 3'>
              <div class='owner'>{{formatOwner(result.owner)}}</div>
              <div class='info'>{{result.company?.name}}</div>
            </ng-container>
          </div>
        </div>
        <div>
          <app-license-plate
            *ngIf='result.country && result.license'
            [country]='result.country'
            [licence]='result.license'
            class='license-plate'
          ></app-license-plate>
        </div>
        <div>
          <nb-icon icon='attach-outline' pack='eva'></nb-icon>
        </div>
      </div>
    </div>
  </div>
</ng-container>
