import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfigService } from 'src/app/@shared/services/config.service';
import { DefinitionType } from '../domain/enums/definition-type.enum';
import { Definition, DefinitionList } from '../domain/interfaces/definition.interface';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root',
})
export class DefinitionsApiService extends BaseApiService {
  constructor(configService: ConfigService, httpClient: HttpClient) {
    super(configService, httpClient);
  }

  storeDefinitions(): void {
    const apiUrl = this.parkbaseBl9BaseUrl + '/definitions';
    const body = {
      types: [
        DefinitionType.TerminalTypes,
        DefinitionType.ManualCorrectionReasons,
        DefinitionType.ManualGrantPassageReasons,
        DefinitionType.NotificationLevels,
        DefinitionType.NotificationTypes,
        DefinitionType.LoopAccessModes,
        DefinitionType.TwoWayLaneStatuses,
        DefinitionType.LostTicketFeeTypes,
      ],
    };
    this.http.post<DefinitionList[]>(apiUrl, body, { headers: this.setHeaders() }).subscribe((result) => {
      this.parkbaseStorageService.storeDefinitionLists(result);
    });
  }

  // POST /api/gui/v1.0/definitions
  loadDefinitions(): Observable<DefinitionList[]> {
    const apiUrl = this.parkbaseBl9BaseUrl + '/definitions';
    const body = {
      types: [
        DefinitionType.TerminalTypes,
        DefinitionType.ManualCorrectionReasons,
        DefinitionType.ManualGrantPassageReasons,
        DefinitionType.NotificationLevels,
        DefinitionType.NotificationTypes,
        DefinitionType.LoopAccessModes,
        DefinitionType.TwoWayLaneStatuses,
      ],
    };
    return this.http.post<DefinitionList[]>(apiUrl, body, { headers: this.setHeaders() }).pipe(
      tap((data) => {
        this.parkbaseStorageService.storeDefinitionLists(data);
      })
    );
  }

  // GET /api/gui/v1.0/definitions/{id}
  loadDefinitionsById(id: number): Observable<Definition[]> {
    const apiUrl = this.parkbaseBl9BaseUrl + '/definitions/' + id;
    return this.http.get<Definition[]>(apiUrl, { headers: this.setHeaders() });
  }

  // POST /api/gui/v1.0/definitions/{id}/search
  searchDefinitionsById(): void {}
}
