<div>
  <h5>{{ 'LOOP_ACCESS_MODE.TITLE' | translate }}</h5>
  <app-terminal-title [hidden]='hideElement' [showHeading]='false'
                      [show]='showTerminalTitle' [terminalOverview]='terminal'>
  </app-terminal-title>
  <p class='description'>
    {{ 'LOOP_ACCESS_MODE.DESCRIPTION' | translate }}
  </p>
  <div class='current-status'>
    <div *ngIf='temporaryStatusEndTime' class='current-status-display'>
      <span><nb-icon icon='clock-outline' nbPrefix pack='eva'></nb-icon></span>
      <span>
        {{ 'LOOP_ACCESS_MODE.END_TIME' | translate }}: <br>
        {{temporaryStatusEndTime}}</span>
    </div>
    <div *ngIf='loopAccessMode && !loopAccessMode.success && loopAccessMode.message'>
      <app-info-alert
        [showIcon]='true'
        infoText='{{ loopAccessMode.message }}'>
      </app-info-alert>
    </div>
  </div>
  <div *ngIf='loopAccessMode && loopAccessMode.success && loopAccessMode.message'>
    <app-info-alert
      [showIcon]='true'
      infoText='{{ loopAccessMode.message }}'
      type='success'>
    </app-info-alert>
  </div>
  <div class='form-floating'>
    <select (ngModelChange)='onChange($event)'
            [ngModel]='selectedLoopAccessMode'
            class='form-select'
            id='loopAccessModeSelect'>
      <option value='999'>{{
        'LOOP_ACCESS_MODE.OPTION_SELECT' | translate
        }}*
      </option>
      <option *ngFor='let mode of loopAccessModes' value='{{mode.id}}'>{{mode.name}}</option>
    </select>
    <label for='loopAccessModeSelect'>{{ 'LOOP_ACCESS_MODE.STATUS_OPTIONS_LABEL' | translate }}</label>
  </div>
  <div class='form-element'>
    <nb-form-field>
      <input [(ngModel)]='selectedLoopAccessDate' [nbDatepicker]='datePicker' fieldSize='large' fullWidth
             nbInput
             placeholder='Pick Date'
             shape='semi-round'>
      <nb-datepicker #datePicker [max]="max" [min]="min"></nb-datepicker>
      <nb-icon (click)='clearDatePicker()' *ngIf='selectedLoopAccessDate'
               icon='close-outline' nbSuffix pack='eva'></nb-icon>
    </nb-form-field>
  </div>
  <div class='form-element'>
    <nb-form-field>
      <input [(ngModel)]='selectedLoopAccessTime' [nbTimepicker]='timePicker' fieldSize='large' fullWidth
             nbInput
             placeholder='Pick Time'
             shape='semi-round'>
      <nb-timepicker #timePicker></nb-timepicker>
      <nb-icon (click)='clearTimePicker()' *ngIf='selectedLoopAccessTime'
               icon='close-outline' nbSuffix pack='eva'></nb-icon>
    </nb-form-field>
  </div>
</div>
<div class='form-actions'>
  <button
    (click)='clickButton()'
    [disabled]='submitDisabled'
    fullWidth
    id="submit-btn"
    nbButton
    shape='semi-round'
    size='large'
    status='success'
  >{{ 'LOOP_ACCESS_MODE.SET_ACCESS_MODE' | translate }}</button>
</div>
