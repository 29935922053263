import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbMenuItem } from '@nebular/theme';
import { MainMenuConfigComponent } from '../../@shared/components/main-menu/main-menu.config.component';
import { NbAccessChecker } from '@nebular/security';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { slideInOutAnimation } from '../../@shared/animation/animation';
import { ProfileMobileComponent } from '../profile-mobile/profile-mobile.component';

@Component({
  selector: 'app-profile-mobile',
  templateUrl: './profile-menu-mobile.component.html',
  styleUrls: ['./profile-menu-mobile.component.scss'],
  animations: slideInOutAnimation,
})
export class ProfileMenuMobileComponent extends MainMenuConfigComponent implements OnInit {
  showComponent = true;

  constructor(
    public override accessChecker: NbAccessChecker,
    public override translate: TranslateService,
    private dialogService: NbDialogService,
    private router: Router,
    protected profileMobileMenuDialogRef: NbDialogRef<ProfileMenuMobileComponent>
  ) {
    super(accessChecker, translate);
  }

  override ngOnInit(): void {
    this.showComponent = true;
  }

  openMobile(menuItem: NbMenuItem) {
    if (menuItem.data && menuItem.data.mobile && menuItem.data.mobile.useOverlay) {
      this.dialogService.open(ProfileMobileComponent).onClose.subscribe((res) => {
        if (res && res.handleNavigate && res.handleNavigate.changeBack) {
          // do nothing
          // this.profileMobileMenuDialogRef.close();
        } else {
          this.profileMobileMenuDialogRef.close();
        }
      });
    } else {
      if (menuItem.link) {
        this.profileMobileMenuDialogRef.close(false);
        this.router.navigateByUrl(menuItem.link);
        //return this.router.parseUrl('/unauthorized');
      }
    }
  }
}
