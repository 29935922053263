import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { StatusService } from '../../../../../@shared/services/status.service';
import { TerminalStatus } from '../../../../../@core/domain/interfaces/terminal.interface';

@Component({
  selector: 'app-terminal-name',
  templateUrl: './terminal-name.component.html',
  styleUrls: ['./terminal-name.component.scss'],
})
export class TerminalNameComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  status!: TerminalStatus;

  @Input()
  label!: string;

  color!: string;

  constructor(private statusService: StatusService) {}

  ngOnInit(): void {
    this.color = this.statusService.terminalStatusColor(this.status);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.color = this.statusService.terminalStatusColor(this.status);
    }
  }

  ngOnDestroy(): void {
    this.label = '';
    this.color = '';
  }
}
