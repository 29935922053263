<div class='body-container'>
  <div class='body-title'>
    <h4>{{ 'IDENTIFICATIONS.TITLE' | translate }}</h4>
  </div>
  <nb-card *ngIf='accessIdentificationValidation; else elseBlock' class='identification-validation-card'>
    <nb-card-body>
      <div *ngIf='!validationActions' class='col validation-actions'>
        <div class='form-group'>
          <h6>{{ 'IDENTIFICATIONS.SUBTITLE' | translate }}</h6>
          <p>{{ 'IDENTIFICATIONS.SHORT_EXPLANATION' | translate }}</p>
          <app-identification-input
            (barcodeScanned)='onBarcodeScanned($event)'
            (keyup)='handleKeyUp($event)'
            (searchFormCleared)='onSearchFormCleared($event)'
            [(ngModel)]='identification'
            [nbSpinner]='isLoading' nbSpinnerSize='large' nbSpinnerStatus='info'>
          </app-identification-input>
          <app-info-alert *ngIf='identificationNotFoundMessage && identificationNotFoundMessage.length > 0'
                          [showIcon]='true' [type]="'warning'"
                          infoText='{{identificationNotFoundMessage}}'></app-info-alert>
          <div *ngIf='inValidIdentificationInput' class='invalid-value'>
            <div *ngIf='inValidIdentificationInput'>{{ 'IDENTIFICATIONS.INVALID' | translate }}</div>
          </div>
          <button (click)='checkForIdentification(identification)'
                  *ngIf='identification'
                  [disabled]='isLoading'
                  class='mt-2'
                  nbButton
                  shape='semi-round' status='success'>
            {{ 'IDENTIFICATIONS.CONTINUE' | translate }}
            <nb-icon icon='search-outline' pack='eva'></nb-icon>
          </button>
        </div>
      </div>
      <div *ngIf='validationMessages' class='validation-messages'>
        <h4>Validation: {{identification}}</h4>
        <p *ngFor='let message of validationMessages'>
          {{message}}
        </p>
      </div>
      <div *ngIf='validationActions' class='col validation-result-actions'>
        <button (click)='reset()'
                [disabled]='isLoading'
                fullWidth
                nbButton
                outline>
          {{ validationActions.length ? 'CANCEL' : 'CLOSE' }}
          <nb-icon icon='close-outline' pack='eva'></nb-icon>
        </button>
        <button (click)='executeValidationAction(action)'
                *ngFor='let action of validationActions'
                [disabled]='isLoading'
                fullWidth nbButton>
          {{action.label}}
        </button>
      </div>
    </nb-card-body>
  </nb-card>
  <div *ngIf='initialState' class='initial-state-text'>
    <h5>{{ 'IDENTIFICATIONS.INITIAL' | translate }}</h5>
    <p>{{ 'IDENTIFICATIONS.INITIAL_TEXT' | translate }}</p>
  </div>
  <ng-template #elseBlock>
    <h5>{{ 'IDENTIFICATIONS.ACCESS' | translate }}</h5>
  </ng-template>
</div>
