<div>
  <h6>{{ 'PROFILE.DATEFORMATCHOICE' | translate }}</h6>
  <nb-radio-group (ngModelChange)='storeDateformatChoice($event)' [ngModel]='dateFormatChoice'
                  class='radio-group date-format-choice' name='dateFormatChoice'>
    <nb-radio *ngFor='let dateFormatChoice of dateFormatChoiceOptions'
              [name]='dateFormatChoice.value' [value]='dateFormatChoice.value'>
      {{ dateFormatChoice.label }}
    </nb-radio>
  </nb-radio-group>
</div>
