import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericApiService } from '../../../@core/api/generic/generic-api.service';
import {
  TransactionsFilter,
  TransactionsSummary,
} from '../../../@core/domain/models/transactions.model';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  constructor(private genericApiService: GenericApiService) {}

  getManualCompletionReasons(): Observable<any[]> {
    return this.genericApiService.postResource('definitions', '{"types": [1]}');
  }

  getTransactionsSummary(query: TransactionsFilter): Observable<TransactionsSummary> {
    return this.genericApiService.postResource('transactions/summary', {
      query: TransactionsFilter.prepareForApi(query),
    });
  }

  completeTransactions(query: TransactionsFilter, data: any): Observable<any> {
    return this.genericApiService.postResource('transactions/complete', {
      query: TransactionsFilter.prepareForApi(query),
      data,
    });
  }

  getParkerTypes() {
    return this.genericApiService.getResource('definitions/5');
  }
}
