<ng-container *ngIf='styleListItem'>
  <div [class]='styleListItem ? "styleListItem" : ""'>
    <ng-container [ngTemplateOutlet]='skeletonTemplate'></ng-container>
  </div>
</ng-container>
<ng-container *ngIf='!styleListItem'>
  <nb-card>
    <ng-container [ngTemplateOutlet]='skeletonTemplate'></ng-container>
  </nb-card>
</ng-container>
<ng-template #skeletonTemplate>
  <div class='placeholder-container'>
    <div class='placeholder-container__icon'></div>
    <div class='placeholder-container__body'>
      <div class='placeholder-bar'></div>
      <div class='placeholder-bar-short'></div>
    </div>
  </div>
</ng-template>


