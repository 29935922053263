import { Component, OnInit } from '@angular/core';
import { ParkbaseStorageService } from '../../services/parkbase-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from '../../../@core';

@Component({
  selector: 'app-time-format-selector',
  templateUrl: './time-format-selector.component.html',
  styleUrls: ['./time-format-selector.component.scss'],
})
export class TimeFormatSelectorComponent implements OnInit {
  dateFormatChoiceOptions = [
    { value: 'Exact', label: '' },
    { value: 'Relative', label: '' },
  ];
  dateFormatChoice!: string;

  constructor(
    private parkbaseStorageService: ParkbaseStorageService,
    private translate: TranslateService,
    private logger: LogService
  ) {}

  ngOnInit(): void {
    this.dateFormatChoice = this.parkbaseStorageService.retrieveDateformatChoice();
    this.translateLabels();
  }

  translateLabels() {
    this.translate.get('PROFILE.DATEFORMAT.EXACT').subscribe((d) => {
      this.dateFormatChoiceOptions[0].label = d;
    });
    this.translate.get('PROFILE.DATEFORMAT.RELATIVE').subscribe((d) => {
      this.dateFormatChoiceOptions[1].label = d;
    });
  }

  storeDateformatChoice(dateFormatChoice: any) {
    this.parkbaseStorageService.storeDateformatChoice(dateFormatChoice);
    this.logger.info('Stored notifications date format choice', dateFormatChoice);
  }
}
