<div class='language-selector'>
  <h6>{{ "PROFILE.LANGUAGE" | translate }}</h6>
  <nb-form-field>
    <nb-icon icon='flag' nbPrefix pack='eva'></nb-icon>
    <nb-select [(selected)]='selectedLanguage'
               class='appearance-outline status-basic nb-transition'
               fullWidth
               placeholder='Select language'
               shape='semi-round' size='large'>
      <nb-option
        (click)='setLanguage(language)'
        *ngFor='let language of languages'
        value='{{ language }}'
      >{{ language }}</nb-option
      >
    </nb-select>
  </nb-form-field>
</div>
