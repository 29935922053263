<svg
  [attr.fill]='color'
  class='alarm-svg'
  height='8'
  viewBox='0 0 8 8'
  width='8'
  xmlns='http://www.w3.org/2000/svg'
>
  <circle [attr.cx]='size' [attr.cy]='size' [attr.r]='radius'></circle>
</svg>
