import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FacilitySummary } from 'src/app/@core/domain/interfaces/facility-summary.interface';
import { StatusService } from '../../../../../@shared/services/status.service';

@Component({
  selector: 'app-facility-extended',
  templateUrl: './facility-extended.component.html',
  styleUrls: ['./facility-extended.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilityExtendedComponent {
  @Input()
  facilitySummary!: FacilitySummary;

  selection: string;

  constructor(public statusService: StatusService, private router: Router) {
    this.selection = 'notificationsForFacility';
  }

  public gotoFacilityDetails(id: number): void {
    this.router.navigate(['/status/facilities/', id], { skipLocationChange: true }).then((e) => {
      if (e) {
      } else {
      }
    });
  }

  // alarmFromNotificationGoing(): boolean {
  //   let going = false;
  //   this.facilitySummary.notificationCounts.forEach((nc) => {
  //     if ((nc.level === NotificationLevel.Error || nc.level === NotificationLevel.Alarm) && nc.count > 0) {
  //       going = true;
  //     }
  //   });
  //   return going;

  alarmGoing(): boolean {
    let going = false;
    this.facilitySummary.emergencyAlarms.forEach((ea) => {
      if (ea.going) {
        going = true;
      }
    });
    return going;
  }
}
