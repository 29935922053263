<div class='other-occupation'>
  <div class='transient-occupation'>
    {{ 'FACILITIES_SUMMARY.FACILITY.TRANSIENTS' | translate }}
    <div class='other-occupation-number'>
      <ng-container
        *ngIf="transientOccupation; else noTransientOccupation">{{ transientOccupation.occupation }}</ng-container>
      <ng-template #noTransientOccupation>0</ng-template>
    </div>
    {{ 'FACILITIES_SUMMARY.FACILITY.WORD_OFF' | translate }}
    <ng-container
      *ngIf="transientOccupation; else noTransientCapacity">{{ transientOccupation.capacity }}</ng-container>
    <ng-template #noTransientCapacity>0</ng-template>
  </div>
  <div class='subscriber-occupation'>
    {{ 'FACILITIES_SUMMARY.FACILITY.SUBSCRIBERS' | translate }}
    <div class='other-occupation-number'>
      <ng-container
        *ngIf="subscriberOccupation; else noSubscriberOccupation">{{ subscriberOccupation.occupation }}</ng-container>
      <ng-template #noSubscriberOccupation>0</ng-template>
    </div>
    {{ 'FACILITIES_SUMMARY.FACILITY.WORD_OFF' | translate }}
    <ng-container
      *ngIf="subscriberOccupation; else noSubscriberCapacity">{{ subscriberOccupation.capacity }}</ng-container>
    <ng-template #noSubscriberCapacity>0</ng-template>
  </div>
  <div *ngIf='customOccupation' class='custom-occupation'
       nbTooltip="{{ customOccupation.type['name'] }}"
       nbTooltipStatus='primary'>
    {{ customOccupation.type['name'] | slice:0:12 }}
    <div class='other-occupation-number'>
      {{ customOccupation.occupation }}
    </div>
    {{ 'FACILITIES_SUMMARY.FACILITY.WORD_OFF' | translate }}
    {{ customOccupation.capacity }}
  </div>
</div>
