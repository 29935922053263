<nb-card (click)='gotoFacilityDetails(facilitySummary.facility.id)' *ngIf='facilitySummary'
         class='facility-extended-full'>
  <nb-card-header class='facility-card'>
    <div class='facility-header'>
      <div class='facility-icon'>
        <app-facility-status-icon [fullStatus]="facilitySummary.facility.fullStatus"
                                  [openingState]="facilitySummary.facility.openingState"></app-facility-status-icon>
      </div>
      <div class='facility-details'>
        <div class='facility-details__name'>
          <h6>
            {{ facilitySummary.facility.name}}
            <span class="label">
              {{ facilitySummary.terminalCounts.total }}
              <span *ngIf="facilitySummary.terminalCounts.total === 1;">
                {{ 'FACILITIES_SUMMARY.FACILITY.TERMINAL' | translate }}
              </span>
              <span *ngIf="facilitySummary.terminalCounts.total > 1;">
                {{ 'FACILITIES_SUMMARY.FACILITY.TERMINALS' | translate }}
              </span>
            </span>
          </h6>
        </div>
        <div class='facility-status'>
          <app-general-led [color]='statusService.getLedColor(facilitySummary)'></app-general-led>
          {{statusService.getFacilityStatus(facilitySummary)}}
        </div>
      </div>
      <div class='alarms'>
        <app-facility-emergency-alarms
          [alarmsFromSummary]='facilitySummary.emergencyAlarms'
          [facilityId]='facilitySummary.facility.id'
          [showHeaderIcon]='false'
        ></app-facility-emergency-alarms>
        <div class='facility-navigation'>
          <a
            (click)='gotoFacilityDetails(facilitySummary.facility.id)'
            [routerLink]="['/status/facilities', facilitySummary.facility.id]"
          >
            <nb-icon
              class='icon-navigate'
              icon='arrow-ios-forward'
              nbPrefix
              pack='eva'
              status='control'
            ></nb-icon>
          </a>
        </div>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class='facility-extended-full__overview'>
      <div class="occupation">
        <div>
          <app-total-occupation-ratio
            [occupation]='facilitySummary.occupation'
          ></app-total-occupation-ratio>
        </div>
        <div *ngIf='showGraph'>
          <app-bar-chart [facilityId]="facilitySummary.facility.id" [showNavigation]='false'
                         id="c-{{facilitySummary.facility.id}}"></app-bar-chart>
        </div>
      </div>
      <div>
        <app-other-occupation-ratio
          [occupation]='facilitySummary.occupation'
        ></app-other-occupation-ratio>
      </div>
    </div>
  </nb-card-body>
</nb-card>
