<div class="menu-bar">
  <div class="app-logo"></div>
  <div class="menu-bar__main">
    <ul class="menu-items">
      <li
        *ngFor="let menuItem of mainMenuItems; let i = index"
        [ngClass]="profileActive()"
        class="menu-item"
        nbTooltip="{{ menuItem.title }}"
        nbTooltipPlacement="right"
        tag="menu"
      >
        <a
          (click)="onItemClick(i)"
          (mouseenter)="onHoverItem(menuItem)"
          *ngIf="menuItem.link && !menuItem.url && !menuItem.children && !menuItem.group"
          [attr.target]="menuItem.target"
          [class.active]="menuItem.selected"
          [routerLink]="menuItem.link"
        >
          <nb-icon *ngIf="menuItem.icon" [config]="menuItem.icon" class="menu-icon"></nb-icon>
        </a>
      </li>
    </ul>
  </div>
  <div class="menu-bar__login">
    <ul [nbPopover]="templateRef" class="menu-items" nbPopoverPlacement="right" nbPopoverTrigger="click">
      <li
        *ngFor="let menuItem of loginMenuItems"
        class="menu-item"
        nbTooltip="{{ menuItem.title }}"
        nbTooltipPlacement="right"
      >
        <nb-icon
          *ngIf="menuItem.icon"
          [config]="menuItem.icon"
          [ngClass]="{ active: menuItem.selected }"
          class="menu-icon"
        ></nb-icon>
      </li>
    </ul>
  </div>
</div>
<ng-template #templateRef>
  <div class="sidebar-context-menu">
    <nb-menu (click)="close()" [items]="items" class="sidebar-context-menu-items"></nb-menu>
  </div>
</ng-template>
