<div
  class='notification-summary status-color-{{ notification?.level }} g-{{
    notification?.terminal?.id
  }}'
>
  <div class='align-items-center pe-3'>
    <nb-icon
      *ngIf='notification && notification.level'
      class='warning-icon color-{{ notification.level }}-custom-icon__terminalType'
      icon='{{ getIconType(notification) }}'
      nbPrefix
      pack='customIcons'
    ></nb-icon>
  </div>
  <div *ngIf='notification' class='col notification-group__description'>
    <div
      *ngIf='notification && notification.level'
      class="{{ notification.level === 6 ? ' danger' : '' }}{{
        notification.level === 2 ? ' error' : ''
      }}{{ notification.level === 1 ? ' warning' : '' }}"
    >
      <span *ngIf='notification.count === 1'
      >{{ notification.count }} {{ 'NOTIFICATIONS.NOTIFICATION' | translate }}</span
      >
      <span *ngIf='notification.count && notification.count > 1'
      >{{ notification.count }} {{ 'NOTIFICATIONS.NOTIFICATIONS' | translate }}</span
      >
      {{ format(notification.utcOccurrence, notification.level === 6) }}
    </div>
    <div class='terminal-name'>
      {{ notification.terminal?.name }}
    </div>
    <div class='row'>
      <!--      <ng-container *ngIf='notification.description.ticket'>-->
      <!--        <app-ticket [data]='notification.description.ticket'></app-ticket>-->
      <!--      </ng-container>-->
      <ng-container *ngIf='notification.description.license'>
        <app-license-plate [country]='notification.description.license.countryCode'
                           [licence]='notification.description.license.licensePlate'></app-license-plate>
      </ng-container>
      <span *ngIf='notification.description.ticket' [nbTooltip]='getBarcode(notification.description.ticket)'
            class='notification-title'>{{ notification.title }} </span>
      <span *ngIf='!notification.description.ticket' class='notification-title'>{{ notification.title }} </span>
    </div>
  </div>
  <div
    *ngIf='notification?.level === 6; else singleActionTemplate'
    class='notification-card__actions'
  >
    <app-notification-actions *ngIf='notification' [notification]='notification'></app-notification-actions>
  </div>
  <ng-template #singleActionTemplate>
    <!--    <div>-->
    <!--      <nb-icon-->
    <!--        nbSuffix-->
    <!--        icon="arrow-ios-forward-outline"-->
    <!--        pack="eva"-->
    <!--        class="icon-navigate"-->
    <!--        (click)="navigateToFacility(notification)"-->
    <!--      ></nb-icon>-->
    <!--    </div>-->
  </ng-template>
</div>
