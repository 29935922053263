import { Component, OnInit } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { LogService } from 'src/app/@core';
import { ParkbaseStorageService } from '../../services/parkbase-storage.service';

/**
 * Switch between light and dark theme or choose based on browser default
 */
@Component({
  selector: 'app-theme-selector',
  templateUrl: './theme-selector.component.html',
  styleUrls: ['./theme-selector.component.scss'],
})
export class ThemeSelectorComponent implements OnInit {
  selectedTheme!: string;
  themeOptions: string[] = ['parkbaseDark', 'parkbaseLight'];

  constructor(
    private themeService: NbThemeService,
    private parkbaseStorageService: ParkbaseStorageService,
    private logger: LogService
  ) {}

  ngOnInit(): void {
    this.selectedTheme = this.themeService.currentTheme;
    this.themeService.onThemeChange().subscribe((changedValue) => {
      if (changedValue) {
        this.selectedTheme = changedValue.name;
      }
    });
  }

  setTheme(theme: string): void {
    this.logger.info('Theme set to', theme);
    this.themeService.changeTheme(theme);
    this.parkbaseStorageService.setTheme(theme);
  }
}
