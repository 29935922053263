import { Component, Input, OnInit } from '@angular/core';
import { StatusService } from '../../services/status.service';
import { TerminalOverview } from '../../../@core/domain/interfaces/terminal-response.interface';
import { FacilitySummary } from '../../../@core/domain/interfaces/facility-summary.interface';

export interface GenericInfo {
  title: string;
  message?: string;
}

@Component({
  selector: 'app-cell',
  templateUrl: './cell.component.html',
  styleUrls: ['./cell.component.scss'],
})
export class CellComponent implements OnInit {
  @Input()
  data!: FacilitySummary;

  @Input()
  terminalOverview!: TerminalOverview;

  @Input()
  genericInfo!: GenericInfo;

  @Input()
  title!: string;

  @Input()
  showNav = true;

  @Input()
  navIcon = 'arrow-ios-forward-outline';

  constructor(public statusService: StatusService) {}

  ngOnInit(): void {}
}
