<div [@slideInOut]>
  <div class='main-menu-mobile-content'>
    <app-cell (click)='openFacilitySelectMobile()' icon="facility"
              title="{{ 'MOBILE.FACILITIES' | translate }}"></app-cell>
    <app-cell (click)='openTerminalSelectMobile()' icon="monitor"
              title="{{ 'MOBILE.TERMINALS' | translate }}"></app-cell>
    <div class='menu-items-mobile'>
      <ul class='menu-items-mobile-list'>
        <li (click)='openMobile(menuItem)' *ngFor='let menuItem of mainMenuItemsForMobile; let i = index;'
            class='menu-item'>
          <div class='menu-item-title'>
            <div class='menu-item-title__icon'>
              <nb-icon *ngIf='menuItem.icon' [config]='menuItem.icon' class='menu-icon' status='control'></nb-icon>
            </div>
            <div>{{menuItem.title}}</div>
          </div>
          <div>
            <nb-icon
              icon='arrow-ios-forward-outline' nbButton
              nbPrefix
              pack='eva'
              status='control'></nb-icon>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
