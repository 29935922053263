<div class='led-icon'>
  <div>
    <svg
      [attr.fill]='color'
      class='led-svg'
      height='16'
      viewBox='0 0 16 16'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='6' cy='6' r='6'></circle>
    </svg>
  </div>
  <div *ngIf='label' class='terminal-status'>
    {{label}}
  </div>
</div>

