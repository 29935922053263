<div class='total-occupation'>
  {{ 'FACILITIES_SUMMARY.FACILITY.TOTAL_OCCUPATION' | translate }}
  <div class='occupation-number'>
    <ng-container
      *ngIf='occupation.occupationRatios[0]; else noOccupationRatios'>{{occupation.occupationRatios[0].occupation|number: '1.0-0'}}</ng-container>
    <ng-template #noOccupationRatios>0</ng-template>
  </div>
  <div class='center'>
    {{ 'FACILITIES_SUMMARY.FACILITY.WORD_OFF' | translate }}
    <ng-container
      *ngIf='occupation.occupationRatios[0]; else noCapacityRatios'>{{ occupation.occupationRatios[0].capacity }}</ng-container>
    <ng-template #noCapacityRatios>0</ng-template>
  </div>
</div>
