import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Occupation } from 'src/app/@core/domain/interfaces/occupation.interface';
import { TrackingService } from 'src/app/@shared/services/tracking.service';
import { UntypedFormGroup } from '@angular/forms';
import { NbDialogService, NbLayoutRulerService } from '@nebular/theme';
import { FacilitiesApiService } from '../../../../@core/api/facilities-api.service';
import { StatusPageService } from '../../services/status-page.service';

@Component({
  selector: 'app-totals',
  templateUrl: './totals.component.html',
  styleUrls: ['./totals.component.scss'],
})
export class TotalsComponent implements OnInit, OnDestroy {
  @Input()
  facilityId!: number;

  totals!: Observable<Occupation>;
  totalsForm!: UntypedFormGroup;

  errorMessage!: string;
  loaded: boolean;
  editing!: boolean;
  label = '';
  showTotalsForm = true;
  useMobileComponents = false;
  showChart = true;
  saveTotals!: boolean;
  savingForm!: boolean;

  constructor(
    private facilitiesApiService: FacilitiesApiService,
    private translate: TranslateService,
    private trackingService: TrackingService,
    private dialogService: NbDialogService,
    private layoutRulerService: NbLayoutRulerService,
    private statusPageService: StatusPageService
  ) {
    this.loaded = false;
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.getWindowSize();
  }

  ngOnInit(): void {
    if (!this.facilityId) {
      return;
    }

    this.getWindowSize();

    this.totals = this.facilitiesApiService.occupationPerHourCurrent;
    this.facilitiesApiService.loadTotals(this.facilityId);
    // this.totals = of({
    //   occupationRatios: [],
    //   reservationOccupationRatios: [],
    // });
  }

  ngOnDestroy(): void {
    this.totalsForm = new UntypedFormGroup({});
  }

  toggleEditMode(): void {
    this.editing = !this.editing;
    if (this.editing) {
      // not a trigger, it should be a halt
      this.statusPageService.triggerRefreshFacilitiesSummary(false);
    }
    if (this.useMobileComponents) {
      console.log('this.useMobileComponents', this.useMobileComponents);
      this.showChart = false;
      this.showTotalsForm = true;
    } else {
      this.showChart = true;
    }
    this.trackingService.eventEmitter('totalsComponent', 'action', '', 'toggleEditMode', 0);
  }

  cancelEditMode(): void {
    this.editing = false;
    this.saveTotals = false;
    this.savingForm = false;
    this.trackingService.eventEmitter('totalsComponent', 'action', '', 'cancelEditMode', 0);
    if (this.useMobileComponents) {
      this.showTotalsForm = false;
      this.showChart = true;
    }
  }

  saveTotalsForm() {
    this.saveTotals = true;
  }

  onFormSaved($event: boolean) {
    if ($event) {
      this.saveTotals = false;
      this.savingForm = false;
      this.editing = false;
      this.trackingService.eventEmitter('totalsComponent', 'action', '', 'formSaved', 0);
      if (this.useMobileComponents) {
        this.showTotalsForm = false;
        this.showChart = true;
      }
    }
  }

  onFormCanceled($event: boolean) {
    if ($event) {
      this.editing = false;
      this.saveTotals = false;
      this.totals = this.facilitiesApiService.occupationPerHourCurrent;
      this.facilitiesApiService.loadTotals(this.facilityId);
      if (this.useMobileComponents) {
        this.showTotalsForm = false;
        this.showChart = true;
      }
    }
  }

  onFormSaving($event: boolean) {
    if ($event) {
      this.savingForm = $event;
      this.editing = false;
      this.saveTotals = false;
    }
  }

  private getWindowSize() {
    this.layoutRulerService.getDimensions().subscribe((data) => {
      this.showTotalsForm = data.clientWidth > 576;
      this.useMobileComponents = data.clientWidth <= 768;
    });
  }
}
