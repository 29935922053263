import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity-line',
  templateUrl: './activity-line.component.html',
  styleUrls: ['./activity-line.component.scss'],
})
export class ActivityLineComponent implements OnInit {
  @Input() facilityId!: number;

  constructor() {}

  ngOnInit(): void {
    if (!this.facilityId) {
      return;
    }
  }
}
