<div *ngIf='show' id='terminal-title'>
  <h4 *ngIf='showHeading'>{{ 'GRANT_PASSAGE.TERMINAL' | translate }}</h4>
  <div *ngIf='terminalOverview' class='terminal-container'>
    <app-status-led [terminalOverview]='terminalOverview'></app-status-led>
    <div class='terminal'>
      <span class='terminal-name' id='terminal-name'>{{ terminalOverview.name }}
        <span *ngIf='terminalType' class='terminal-type'>{{ terminalOverview.type.name }}</span>
      </span>
      <span class='terminal-status {{statusService.terminalStatusColorClass(terminalOverview)}}'>
        {{ terminalOverview.status.name }}
        <span *ngIf="loopAccessEndTime">({{loopAccessEndTime}})</span>
      </span>
    </div>
  </div>
</div>
