import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NbLayoutRulerService } from '@nebular/theme';
import { TrackingService } from '../../../services/tracking.service';
import { TerminalsApiService } from '../../../../@core/api/terminals-api.service';
import { StatusPageService } from '../../../../pages/status-page/services/status-page.service';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from '../../../../@core';
import { AnonymousGrantPassageWithReasonTerminalAction } from '../../../../@core/domain/interfaces/terminal-action.interface';
import { IdentificationSearchService } from '../../../services/identifications-search.service';
import { ResponseStatus } from '../../../../@core/domain/interfaces/facility.interface';
import { ToastMessageService } from '../../../services/toast-message.service';
import { Terminal } from '../../../../@core/domain/interfaces/terminal.interface';

type Nullable<T> = T | null;

export interface SimpleAction {
  type: number;
  name: string;
}

@Component({
  selector: 'app-anonymous-passage',
  templateUrl: './anonymous-passage.component.html',
  styleUrls: ['./anonymous-passage.component.scss'],
})
export class AnonymousPassageComponent implements OnInit {
  @Input()
  terminalId!: number;

  @Input()
  action!: SimpleAction;

  @Input()
  showTerminalTitle = true;

  @Output()
  changeComponent = new EventEmitter<string>();

  terminal!: Terminal;
  selectedItem: any;
  grantPassageForm!: UntypedFormGroup;
  index!: number;
  awaitTerminalActionComplete = false;
  passageResponseMessage!: string;
  passageResponseStatus = 'success';
  errorMessage!: Nullable<string>;
  hideElement = false;
  disableSubmit = false;

  constructor(
    private terminalsApiService: TerminalsApiService,
    private formBuilder: UntypedFormBuilder,
    private statusPageService: StatusPageService,
    private translate: TranslateService,
    private logger: LogService,
    private layoutRuleService: NbLayoutRulerService,
    private identificationsSearchService: IdentificationSearchService,
    private trackingService: TrackingService,
    private toastMessageService: ToastMessageService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.terminalsApiService.loadTerminalById(this.terminalId).subscribe((t) => {
      this.terminal = t;
      this.selectedItem = '';
      this.errorMessage = null;
      this.buildForm();
      this.getWindowSize();
    });
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.getWindowSize();
  }

  getWindowSize() {
    this.layoutRuleService.getDimensions().subscribe((data) => {});
  }

  buildForm() {
    if (this.action && this.action.type === 28) {
      this.grantPassageForm = this.formBuilder.group({
        reason: ['', [Validators.required]],
        remarks: ['', []],
      });
    } else {
      this.grantPassageForm = this.formBuilder.group({
        reason: [''],
        remarks: [''],
      });
    }
  }

  submitAnonymousGrantPassage(): void {
    const reason = this.grantPassageForm.value.reason;
    const remarks = this.grantPassageForm.value.remarks;
    const check =
      +reason === 0 &&
      (this.action.type === 27 || this.action.type === 28) &&
      reason &&
      Number(reason) === 0 &&
      remarks &&
      Number(remarks.length) === 0;
    if (check) {
      this.translate.get('GRANT_PASSAGE.REMARK_REQUIRED').subscribe((t) => {
        this.errorMessage = t;
        return;
      });
    } else {
      this.errorMessage = null;
      this.trackingService.eventEmitter('passageComponent', 'action', '', 'anonymousGrantPassage', 0);
      if (this.grantPassageForm.valid) {
        const terminalAction: AnonymousGrantPassageWithReasonTerminalAction = {
          reason: +this.grantPassageForm.value.reason,
          remarks: this.grantPassageForm.value.remarks,
          type: this.action.type,
        };
        this.awaitTerminalActionComplete = true;
        this.terminalsApiService.sendTerminalAction(this.terminal.id, terminalAction).subscribe((result) => {
          if (result.status === ResponseStatus.Success) {
            this.passageResponseStatus = 'success';
            this.awaitTerminalActionComplete = false;
            this.grantPassageForm.reset();
            this.logger.info('Terminal action ' + terminalAction + ' performed on terminal' + this.terminal.id, []);
            this.passageResponseMessage = result.message;
            this.toastMessageService.showSuccess(result.message);
          } else if (result.status === ResponseStatus.Failure) {
            this.logger.info('Terminal action ' + terminalAction + ' failed on terminal' + this.terminal.id, []);
            this.passageResponseStatus = 'danger';
            this.awaitTerminalActionComplete = false;
            this.grantPassageForm.reset();
            this.passageResponseMessage = result.message;
            this.toastMessageService.showFailure(result.message);
            this.selectedItem = '';
          } else {
            this.selectedItem = '';
            this.toastMessageService.showFailure(result.message);
          }
        });
      }
    }
  }

  switchControlledGrantPassage() {
    this.changeComponent.emit('grantControlledPassage');
  }

  onSelect() {
    const reason = this.grantPassageForm.value.reason;
    this.disableSubmit = reason && Number(reason) === 0 && Number(this.grantPassageForm.value.remarks.length) === 0;
    if (this.disableSubmit) {
      this.translate.get('GRANT_PASSAGE.REMARK_REQUIRED').subscribe((t) => {
        this.errorMessage = t;
        return;
      });
    } else {
      this.errorMessage = null;
      this.disableSubmit = false;
    }
  }
}
