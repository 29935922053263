import { Component, OnInit } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-toast-message', //[toastMessageInput] to make it required
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
})
export class ToastMessageComponent extends Toast implements OnInit {
  classType!: string;
  private translations: string[] = [];

  constructor(
    protected override toastrService: ToastrService,
    public override toastPackage: ToastPackage,
    private translate: TranslateService
  ) {
    super(toastrService, toastPackage);
  }

  ngOnInit(): void {
    this.classType = this.options.payload.type;
    this.translate
      .get(['TOASTR.FAILURE', 'TOASTR.SUCCESS', 'TOASTR.INPUT_REQUIRED', 'TOASTR.PARTIAL_FAILURE'])
      .subscribe((t) => {
        this.translations = Object.keys(t).map((key) => t[key]);
        switch (this.options.payload.type) {
          case 'failure':
            this.title = this.translations[0];
            break;
          case 'success':
            this.title = this.translations[1];
            break;
          case 'input-required':
            this.title = this.translations[2];
            break;
          case 'partial-failure':
            this.title = this.translations[3];
            break;
        }
      });
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    return false;
  }

  dismiss() {
    this.toastrService.toasts.forEach((t) => {
      this.toastrService.clear(t.toastId);
    });
  }
}
