import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-search-identity-card-result',
  templateUrl: './identity-card-result.component.html',
  styleUrls: ['./identity-card-result.component.scss'],
})
export class IdentityCardResultComponent {
  @Input()
  data!: any;

  constructor() {}
}
