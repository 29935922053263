<!--<app-terminal-screen [terminal]='terminal'></app-terminal-screen>-->
<!--<app-lost-ticket [terminal]='terminal'></app-lost-ticket>-->
<!--<app-info-alert-->
<!--  type="success"-->
<!--  [showIcon]="true"-->
<!--  infoText="Dit is een test">-->
<!--</app-info-alert>-->
<!--<app-date-time-selector -->
<!--                        [(ngModel)]='filter.startDate'-->
<!--                        [maxDate]='filter.endDate'-->
<!--                        [reset]='resetDateTimeSelector'-->
<!--                        dateLabel="{{ 'TRANSACTIONS.START_DATE' | translate }}"-->
<!--                        name='startDate'-->
<!--                        timeLabel='Time'>-->
<!--</app-date-time-selector>-->
<!--<app-terminal-screen [terminal]='terminal'></app-terminal-screen>-->
<!--<app-toast-message [toastMessageInput]='toastMessageInput'></app-toast-message>-->
<!--<app-alarm-bell></app-alarm-bell>-->
<!--<app-lost-ticket></app-lost-ticket>-->
<!--<app-two-way-lane [terminal]='terminal'></app-two-way-lane>-->
<!--<span [innerHTML]='data | sanitizeHtml'></span>-->
<!--<app-alarm-bell></app-alarm-bell>-->
<!--<app-lost-ticket [terminal]='terminal'></app-lost-ticket>-->
<!--<div [nbSpinner]='loading' class='terminals-test' nbSpinnerSize='giant' nbSpinnerStatus='primary'>-->
<!--  <div *ngFor='let f of facilityWithTerminals; let i = index;' class='facility-block'>-->
<!--    <h3>{{f.name}}</h3>-->
<!--    <div *ngIf='f.terminals && f.terminals.length > 0' class='terminals-list'>-->
<!--      <app-cell (click)='openTerminal(t)' *ngFor='let t of f.terminals'-->
<!--                [terminal]='t'></app-cell>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<div>-->
<!--  <ul>-->
<!--    <li *ngFor='let f of facilities'>{{f.name}} - {{f.id}}</li>-->
<!--  </ul>-->
<!--</div>-->
<!--<app-totals [facilityId]="12"></app-totals>-->
<!--<app-date-time-selector></app-date-time-selector>-->
<!--<div>-->
<!--  <app-activity-chart-->
<!--    [facilityId]='12'-->
<!--    [showNavigation]='true'-->
<!--    [style]="'activity-chart-auto'"-->
<!--    class='activity-chart'-->
<!--  ></app-activity-chart>-->
<!--</div>-->
<!--<div style="width: 800px; height: 400px;">-->
<!--  <app-bar-chart [facilityId]="12"></app-bar-chart>-->
<!--</div>-->
<!--<app-multi-select [itemList]="itemList"></app-multi-select>-->
<div class="page-container">
  <div>
    <app-multi-select-dropdown (change)="changeValue($event)" [(value)]="multiSelect" [config]="config"
                               [options]="itemList"></app-multi-select-dropdown>
  </div>
  <div>
    <app-multi-select [itemList]="itemList"></app-multi-select>
  </div>
</div>
<div>
  <pre>theValues:{{theValues|json}}</pre>
  <pre>value:{{multiSelect|json}}</pre>
</div>
<!--<app-terminal-search></app-terminal-search>-->
<!--<app-activity-chart-->
<!--  [facilityId]='7'-->
<!--  [showNavigation]='false'-->
<!--  [style]="'activity-chart-low'"-->
<!--  class='activity-chart'-->
<!--&gt;</app-activity-chart>-->
<nb-card>
  <app-bar-chart [debug]="true" [facilityId]='7' [showDate]="true"></app-bar-chart>
</nb-card>
