<div class='activity-handle'>
  <h5>
    <nb-icon class='activity-icon' icon='trending-up' nbPrefix pack='eva'></nb-icon>
    {{ 'ACTIVITY.TITLE' | translate }}
  </h5>
</div>
<nb-card>
  <nb-card-body class='activity-card'>
    <app-bar-chart
      [facilityId]='facilityId'
      [showDate]='true'
      [showNavigation]='true'
      [style]="'activity-chart-high'"
    ></app-bar-chart>
  </nb-card-body>
</nb-card>
