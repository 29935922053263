import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransactionsComponent } from './transactions.component';
import { TransactionsRoutingModule } from './transactions-routing.module';
import { SharedModule } from '../../@shared/shared.module';
import { TransactionsCompleteComponent } from './components/transactions-complete/transactions-complete.component';
import { TransactionsCompletedComponent } from './components/transactions-completed/transactions-completed.component';
import { TransactionsFilterFormComponent } from './components/transactions-filter-form/transactions-filter-form.component';
import { TransactionsSummaryComponent } from './components/transactions-summary/transactions-summary.component';
import { FacilitySelectorComponent } from './components/facility-selector/facility-selector.component';
import { SubscriptionModelSelectorComponent } from './components/subscription-model-selector/subscription-model-selector.component';
import { NbAlertModule } from '@nebular/theme';
import { ThemeModule } from '../../@theme/theme.module';
import { DateTimeSelectorComponent } from './components/date-time-selector/date-time-selector.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from '../../app.module';

@NgModule({
  declarations: [
    TransactionsComponent,
    TransactionsCompleteComponent,
    TransactionsCompletedComponent,
    TransactionsFilterFormComponent,
    TransactionsSummaryComponent,
    FacilitySelectorComponent,
    DateTimeSelectorComponent,
    SubscriptionModelSelectorComponent,
  ],
  imports: [
    CommonModule,
    TransactionsRoutingModule,
    ThemeModule,
    SharedModule,
    NbAlertModule,
    TranslateModule.forChild({
      defaultLanguage: 'en-US',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [FacilitySelectorComponent, DateTimeSelectorComponent],
})
export class TransactionsModule {}
