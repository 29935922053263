<div *ngIf='facilitySummary && facilitySummary.occupation.occupationRatios.length >= 3' class='total-occupation'>
  <p>{{ 'FACILITIES_SUMMARY.FACILITY.TOTAL_OCCUPATION' | translate }}</p>
  <div *ngIf='facilitySummary.occupation.occupationRatios[3]; else occupationRatio' class='occupation-number'>
    {{
    facilitySummary.occupation.occupationRatios[1].occupation +
    facilitySummary.occupation.occupationRatios[2].occupation +
    facilitySummary.occupation.occupationRatios[3].occupation
    }}
  </div>
  <ng-template #occupationRatio
  >
    <div class='occupation-number'>
      {{
      facilitySummary.occupation.occupationRatios[1].occupation +
      facilitySummary.occupation.occupationRatios[2].occupation
      }}
    </div>
  </ng-template
  >
  <p>
    {{ 'FACILITIES_SUMMARY.FACILITY.WORD_OFF' | translate }}
    {{ facilitySummary.occupation.occupationRatios[0].capacity }}
  </p>
</div>
<div *ngIf='facilitySummary && facilitySummary.occupation.occupationRatios.length >= 3' class='other-occupation'>
  <div class='transient-occupation'>
    <p>{{ 'FACILITIES_SUMMARY.FACILITY.TRANSIENTS' | translate }}</p>
    <div *ngIf="transientOccupation" class='other-occupation-number'>
      {{ transientOccupation.capacity }}
    </div>
    <p>
      {{ 'FACILITIES_SUMMARY.FACILITY.WORD_OFF' | translate }}
      {{ transientOccupation.capacity }}
    </p>
  </div>
  <div *ngIf="subscriberOccupation" class='subscriber-occupation'>
    <p>{{ 'FACILITIES_SUMMARY.FACILITY.SUBSCRIBERS' | translate }}</p>
    <div class='other-occupation-number'>
      {{ subscriberOccupation.occupation }}
    </div>
    <p>
      {{ 'FACILITIES_SUMMARY.FACILITY.WORD_OFF' | translate }}
      {{ subscriberOccupation.occupation }}
    </p>
  </div>
  <div *ngIf='customOccupation && facilitySummary.occupation.occupationRatios[3]' class='subscriber-occupation'>
    <p>{{ customOccupation.type['name'] }}</p>
    <div class='other-occupation-number'>
      {{ customOccupation.occupation }}
    </div>
    <p>
      {{ 'FACILITIES_SUMMARY.FACILITY.WORD_OFF' | translate }}
      {{ customOccupation.capacity }}
    </p>
  </div>
</div>
