import { Component, HostListener, OnInit } from '@angular/core';
import { NbLayoutRulerService, NbMediaBreakpointsService } from '@nebular/theme';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { CookieService } from 'ngx-cookie-service';

/**
 * Provides main layout, menu and router outlet for feature modules
 */
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  showSideNav = true;

  private clientWidth = 0;
  private deviceInfo!: DeviceInfo;

  constructor(
    public deviceService: DeviceDetectorService,
    private breakPointService: NbMediaBreakpointsService,
    private layoutRulerService: NbLayoutRulerService,
    private cookieService: CookieService
  ) {}

  ngOnInit(): void {
    this.getWindowSizeForNotifications();
    this.detectDevice();
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.getWindowSizeForNotifications();
    this.detectDevice();
  }

  private getWindowSizeForNotifications() {
    this.layoutRulerService.getDimensions().subscribe((data) => {
      this.clientWidth = data.clientWidth;
      this.showSideNav = this.clientWidth > 768;
    });
  }

  /**
   * Should provide entire app with information for responsive and conditional components
   * Does not work very accurate !!
   * @private
   */
  private detectDevice() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.cookieService.delete('DI');

    if (this.deviceService.isMobile()) {
      this.cookieService.set('DI', 'isMobile');
    }
    if (this.deviceService.isTablet()) {
      this.cookieService.set('DI', 'isTablet');
    }
    if (this.deviceService.isDesktop()) {
      this.cookieService.set('DI', 'isDesktop');
    }
  }
}
