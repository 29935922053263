import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Terminal } from '../../../../@core/domain/interfaces/terminal.interface';
import { TerminalsApiService } from '../../../../@core/api/terminals-api.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-terminal-screen',
  templateUrl: './terminal-screen.component.html',
  styleUrls: ['./terminal-screen.component.scss'],
})
export class TerminalScreenComponent implements OnInit, OnDestroy {
  @Input()
  terminalId!: number;

  @Input()
  showTerminalTitle = true;

  terminal!: Terminal;
  imageToShow$!: Observable<any>;
  terminalScreenOk = false;
  interval: any;
  updateInterval = 5;
  selectedInterval = this.updateInterval;
  counter = this.updateInterval;
  counterTimer: any;
  counterValue = 100;
  hideElement = false;

  constructor(private terminalApiService: TerminalsApiService) {}

  ngOnInit(): void {
    this.terminalApiService.loadTerminalById(this.terminalId).subscribe((t) => {
      this.terminal = t;

      this.imageToShow$ = this.terminalApiService.terminalsScreen;

      if (this.terminal) {
        this.updateInterval = this.selectedInterval;
        this.terminalApiService.loadScreenCapture(this.terminal.id);
        this.timer();
        this.refreshImage();
      }
    });
  }

  refreshImage() {
    this.interval = setInterval(() => {
      this.timer();
      this.terminalApiService.loadScreenCapture(this.terminal.id);
    }, this.updateInterval * 1000);
  }

  onSelectedChange($event: any) {
    this.selectedInterval = $event;
    this.updateInterval = this.selectedInterval;
    clearInterval(this.interval);
    clearInterval(this.counterTimer);
    this.refreshImage();
  }

  ngOnDestroy(): void {
    this.imageToShow$ = of([]);
    this.terminalScreenOk = false;
    this.selectedInterval = 5;
    this.updateInterval = this.selectedInterval;
    clearInterval(this.interval);
    clearInterval(this.counterTimer);
  }

  private timer() {
    this.counterTimer = setInterval(() => {
      this.counter -= 1;
      this.counterValue = (this.counter / this.updateInterval) * 100;
      if (this.counter === 0) {
        this.counter = this.updateInterval;
        clearInterval(this.counterTimer);
      }
    }, 1000);
  }
}
