import { Injectable } from '@angular/core';
import {
  OccupationRatio,
  OccupationType2,
  ReservationOccupationRatio,
} from '../../../../@core/domain/interfaces/occupation.interface';
import { FullStatus, FullStatus2 } from '../../../../@core/domain/interfaces/facility.interface';

@Injectable()
export class TotalsHelper {
  private static colorsTotal: string[] = ['#12403d', '#204D51', '#2e5a65'];
  private static colorsCalm: string[] = ['#80fffd', '#59EFCC', '#33DE9C', '#0cce6b'];
  private static colorsNormal: string[] = ['#f9c80e', '#F9B10E', '#F99A0E', '#f9830e'];
  private static colorsBusy: string[] = ['#ff450e', '#EA3223', '#D51E39', '#c00b4e'];
  private static colorsReservation: string[] = ['#ffffff', '#FFAAE2', '#FF55C6', '#ff00a9'];

  /**
   * Get the color for a count category based on OccupationRatio fullStatus
   * @param value: OccupationRatio
   */
  static getCategoryColor(value: OccupationRatio): string {
    if (value && value.fullStatus) {
      if (value.type.id === OccupationType2.Total) {
        return this.getStatusColor(value.fullStatus, 0);
      }

      if (value.type.id === OccupationType2.Subscriber) {
        return this.getStatusColor(value.fullStatus, 1);
      }

      if (value.type.id === OccupationType2.Transient) {
        return this.getStatusColor(value.fullStatus, 2);
      }

      if (value.type.id === OccupationType2.Custom) {
        return this.getStatusColor(value.fullStatus, 3);
      }
    }

    return '';
  }

  /**
   * get the color based on allocation and occupation
   * @private
   */
  static getReservationCategoryColor(value: ReservationOccupationRatio) {
    return this.colorsReservation[0]; // no idea how to calculate these colors yet
  }

  /**
   * Get the color for a count category based on fullStatus
   * @param fullStatus, status from ParkBase setting
   * @param categoryId, subscriber, transient, extra
   * @private
   */
  private static getStatusColor(fullStatus: FullStatus, categoryId: number) {
    let color = '';

    if (categoryId === 0) {
      color = this.colorsTotal[fullStatus.id];
    } else {
      if (fullStatus.id === FullStatus2.NotFull) {
        color = this.colorsCalm[categoryId];
      } else if (fullStatus.id === FullStatus2.PreFull) {
        color = this.colorsNormal[categoryId];
      } else if (fullStatus.id === FullStatus2.Full) {
        color = this.colorsBusy[categoryId];
      }
    }

    return color;
  }
}
