import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbRoleProvider, NbSecurityModule } from '@nebular/security';
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
  NbTimepickerModule,
} from '@nebular/theme';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import localeEnUs from '@angular/common/locales/en';
import localeEnGB from '@angular/common/locales/en-GB';
import { Router } from '@angular/router';
import { AccountComponent } from './pages/account/account.component';
import { ThemeModule } from './@theme/theme.module';
import { StatusPageComponent } from './pages/status-page/status-page.component';
import { SharedModule } from './@shared/shared.module';
import { MobileModule } from './mobile/mobile.module';
import { ShellModule } from './shell/shell.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ConfigService } from './@shared/services/config.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { DirectAccessGuard } from './auth/guards/direct-access.guard';
import { RoleProvider } from './@shared/services/role.provider';
import { AuthConfigModule } from './auth-config.module';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { ForbiddenComponent } from './pages/forbidden/forbidden.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PARKBASE_SERVICE_STORAGE, ParkbaseStorageService } from './@shared/services/parkbase-storage.service';
import { LOCAL_STORAGE } from 'ngx-webstorage-service';
import { AuthInterceptor, EventTypes, OidcClientNotification, PublicEventsService } from 'angular-auth-oidc-client';
import { ProfileComponent } from './pages/profile/profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';
import { TemplateComponent } from './pages/template/template.component';
import { IdentificationInputComponent } from './pages/identification/identification-input/identification-input.component';
import { IdentificationValidationComponent } from './pages/identification/identification-validation/identification-validation.component';
import { IdentificationScannerComponent } from './pages/identification/identification-scanner/identification-scanner.component';
import { LicenseplateSettingsComponent } from './pages/settings/licenseplate-settings/licenseplate-settings.component';
import { TransactionsModule } from './pages/transactions/transactions.module';
import { LicenseplateSettingsModule } from './pages/settings/licenseplate-settings/licenseplate-settings.module';
import { ErrorHandlerInterceptor, LogService } from './@core';
import { AppInjector } from './@shared/services/app.injector';
import { filter, take } from 'rxjs';
import { TestPageComponent } from './pages/test-page/test-page.component';
import { ComponentService } from './@shared/utils/component.service';
import { ToastrModule } from 'ngx-toastr';
import { ToastMessageComponent } from './@shared/components/toast-message/toast-message.component';
import { TerminalsPageComponent } from './pages/terminals-page/terminals-page.component';
import { NgxMaskModule } from 'ngx-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { ClipboardModule } from 'ngx-clipboard';
import { StatusPageModule } from './pages/status-page/status-page.module';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { NgxEchartsModule } from 'ngx-echarts';

registerLocaleData(localeNl);
registerLocaleData(localeEnUs);
registerLocaleData(localeEnGB);

/**
 * ACL
 */
const aclConfig = {
  accessControl: {
    ConfigurationManagers: {
      manage: ['apiSettings'],
    },
    ConfigurationUsers: {
      manage: ['bulkTransactionClose'],
    },
    Cashiers: {
      manage: ['identificationValidation'],
    },
    Operators: {
      manage: ['statusPage'],
    },
  },
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', `.json?v=${new Date().getTime()}`);
}

export function ConfigLoader(injector: Injector) {
  return () => injector.get(ConfigService).loadConfiguration();
}

@NgModule({
  declarations: [
    AppComponent,
    AccountComponent,
    StatusPageComponent,
    UnauthorizedComponent,
    ForbiddenComponent,
    NotFoundComponent,
    ProfileComponent,
    SubscriptionsComponent,
    TemplateComponent,
    IdentificationInputComponent,
    IdentificationValidationComponent,
    IdentificationScannerComponent,
    LicenseplateSettingsComponent,
    TestPageComponent,
    ToastMessageComponent,
    TerminalsPageComponent,
    ErrorPageComponent,
    DashboardComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    // GridsterModule,
    ScrollingModule,
    AppRoutingModule,
    AuthConfigModule,
    ThemeModule,
    ShellModule,
    SharedModule,
    MobileModule,
    LicenseplateSettingsModule,
    TransactionsModule,
    NgxCurrencyModule,
    ClipboardModule,
    NbMenuModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbSecurityModule.forRoot(aclConfig),
    NbThemeModule.forRoot({ name: 'parkbaseDark' }),
    NgxMaskModule.forRoot(),
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
      countDuplicates: true,
      toastComponent: ToastMessageComponent,
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en-US',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgbModule,
    StatusPageModule,
  ],
  providers: [
    DirectAccessGuard,
    ComponentService,
    ParkbaseStorageService,
    LogService,
    {
      provide: PARKBASE_SERVICE_STORAGE,
      useExisting: LOCAL_STORAGE,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [Injector],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'nl' },
    { provide: NbRoleProvider, useClass: RoleProvider },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {
  constructor(
    private readonly eventService: PublicEventsService,
    private router: Router,
    injector: Injector,
    logger: LogService
  ) {
    AppInjector.setInjector(injector);

    setTimeout(() => {
      this.eventService
        .registerForEvents()
        .pipe(take(2))
        .subscribe((e: OidcClientNotification<any>) => {
          if (e.type === EventTypes.ConfigLoaded) {
            logger.info('Auth ConfigLoaded!', e.type === EventTypes.ConfigLoaded);
          }
        });

      this.eventService
        .registerForEvents()
        .pipe(filter((notification: any) => notification.type === EventTypes.ConfigLoadingFailed))
        .subscribe((config: any) => {
          logger.warning('Auth ConfigLoadingFailed', EventTypes.ConfigLoadingFailed);
          router.navigateByUrl('/unauthorized');
        });
    }, 5000);
  }
}
