<div class='header-mobile-container'>
  <div>
    <nb-icon
      (click)='openMobileNav()'
      icon='menu-outline'
      id='nav-back'
      nbPrefix
      pack='eva'
    ></nb-icon>
  </div>
  <div><h6>{{ 'MOBILE.STATUS_PAGE' | translate }}</h6></div>
  <div class='header-actions'>
    <div>
      <nb-icon
        (click)='openMobileSearch()'
        icon='search-outline' nbButton
        nbPrefix
        pack='eva'
        status='control'></nb-icon>
    </div>
    <div>
      <app-alarm-bell (click)='openNotificationsMobile()'></app-alarm-bell>
    </div>
  </div>
</div>
