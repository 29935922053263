<div class='search-result'>
  <div>
    <nb-tabset>
      <nb-tab tabTitle='{{ tabName }}'>
        <table class='table table-borderless data-table'>
          <tbody>
          <tr>
            <th scope='row'>{{ 'IDENTIFICATION.NAME' | translate }}</th>
            <td class='w-25'>
              <span *ngIf='data && fullName(data).length > 0' id='name'> {{ fullName(data) }}</span>
              <span *ngIf='data && (!data.firstNames || !data.middleName || !data.surname)'>-</span>
            </td>
            <th scope='row'>{{ 'IDENTIFICATION.BIRTHDATE' | translate }}</th>
            <td class='w-25'>
              <span *ngIf='data && data.birthDate'>{{ data.birthDate | date }}</span>
              <span *ngIf='data && !data.birthDate'>-</span>
            </td>
          </tr>
          <tr>
            <th scope='row'>{{ 'IDENTIFICATION.COMPANY_NAME' | translate }}</th>
            <td class='w-25'>
              <span *ngIf='data && data.company'>{{ data.company.name }}</span>
              <span *ngIf='data && !data.company'>-</span>
            </td>
            <th scope='row'>{{ 'IDENTIFICATION.ADDRESS' | translate }}</th>
            <td class='w-25'>
            <span *ngIf='data && data.address'
            >{{ data.address?.street }} {{ data.address?.houseNumber }}</span
            >
              <span *ngIf='data && !data.address'>-</span>
            </td>
          </tr>
          <tr>
            <th scope='row'>{{ 'IDENTIFICATION.POSTALCODE' | translate }}</th>
            <td class='w-25'>
              <span *ngIf='data && data.address'>{{ data.address?.postalCode }}</span>
              <span *ngIf='data && !data.address'>-</span>
            </td>
            <th scope='row'>{{ 'IDENTIFICATION.CITY' | translate }}</th>
            <td class='w-25'>
              <span *ngIf='data && data.address && data.address?.city'>{{ data.address?.city }}</span>
              <span *ngIf='data && !data.address?.city'>-</span>
            </td>
          </tr>
          <tr>
            <th scope='row'>{{ 'IDENTIFICATION.COUNTRY' | translate }}</th>
            <td class='w-25'>
              <span *ngIf='data && data.address'>{{ data.address?.country }}</span>
              <span *ngIf='data && !data.address?.country'>-</span>
            </td>
          </tr>
          </tbody>
        </table>
      </nb-tab>
      <nb-tab badgeText='{{ data?.licensePlates?.length }}' tabTitle='Licenseplates'>
        <div *ngIf='data && data.licensePlates && data.licensePlates.length === 0' class='no-results'>
          {{ 'GLOBAL_SEARCH.NO_RESULT' | translate }}
        </div>
        <div
          *ngIf='data && data.licensePlates && data.licensePlates.length > 0'
          class='license-plates-table'
        >
          <table
            *ngFor='let licensePlate of data.licensePlates'
            class='table table-borderless result-table data-table'
          >
            <tbody>
            <tr>
              <th scope='row'>{{ 'IDENTIFICATION.LICENSEPLATE' | translate }}</th>
              <td class='w-25'>
                <span *ngIf='data'>{{ licensePlate.country }} {{ licensePlate.license }}</span>
                <span *ngIf='data && !licensePlate.license'>-</span>
              </td>
              <th scope='row'>{{ 'IDENTIFICATION.PRESENCE' | translate }}</th>
              <td class='w-25'>
                <span *ngIf='data && licensePlate.presence'>{{ licensePlate.presence.name }}</span>
                <span *ngIf='data && !licensePlate.presence'>-</span>
              </td>
            </tr>
            <tr>
              <th scope='row'>{{ 'IDENTIFICATION.STATUS' | translate }}</th>
              <td class='w-25'>
              <span *ngIf='licensePlate && licensePlate.status'>{{
                licensePlate.status.name
                }}</span>
                <span *ngIf='licensePlate && !licensePlate.status'>-</span>
              </td>
              <th scope='row'>{{ 'IDENTIFICATION.FIRSTUSE' | translate }}</th>
              <td class='w-25'>
              <span *ngIf='data && data.firstUse'>{{
                'IDENTIFICATION.LABEL_TRUE' | translate
                }}</span>
                <span *ngIf='data && !data.firstUse'>{{
                  'IDENTIFICATION.LABEL_FALSE' | translate
                  }}</span>
              </td>
            </tr>
            <tr>
              <th scope='row'>{{ 'IDENTIFICATION.VALIDITY_FROM' | translate }}</th>
              <td class='w-25'>
                <ng-container
                  *ngIf='licensePlate && licensePlate.validity && licensePlate.validity.from'
                >
                  {{ licensePlate.validity.from | date }}
                </ng-container>
                <span *ngIf='licensePlate && !licensePlate.validity'>-</span>
              </td>
              <th scope='row'>{{ 'IDENTIFICATION.VALIDITY_TO' | translate }}</th>
              <td class='w-25'>
                <ng-container
                  *ngIf='licensePlate && licensePlate.validity && licensePlate.validity.to'
                >
                  {{ licensePlate.validity.to | date }}
                </ng-container>
                <span *ngIf='licensePlate && !licensePlate.validity'>-</span>
              </td>
            </tr>
            <tr>
              <th scope='row'>{{ 'IDENTIFICATION.OWNERTYPE' | translate }}</th>
              <td class='w-25'>
              <span *ngIf='licensePlate && licensePlate.ownerType'>{{
                licensePlate.ownerType.name
                }}</span>
                <span *ngIf='licensePlate && !licensePlate.ownerType'>-</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </nb-tab>
      <nb-tab badgeText='{{ data?.cards?.length }}' tabTitle='Cards'>
        <div *ngIf='data && data.cards && data.cards.length === 0' class='no-results'>
          {{ 'GLOBAL_SEARCH.NO_RESULT' | translate }}
        </div>
        <div *ngIf='data && data.cards && data.cards.length > 0' class='cards-table'>
          <table *ngFor='let card of data.cards' class='table table-borderless result-table data-table'>
            <tbody>
            <tr>
              <th scope='row'>{{ 'IDENTIFICATION.HRI' | translate }}</th>
              <td class='w-25'>
                <span *ngIf='card'>{{ card.hri }}</span>
                <span *ngIf='card && !card.hri'>-</span>
              </td>
              <th scope='row'>{{ 'IDENTIFICATION.PRESENCE' | translate }}</th>
              <td class='w-25'>
                <span *ngIf='card && card.presence'>{{ card.presence.name }}</span>
                <span *ngIf='card && !card.presence'>-</span>
              </td>
            </tr>
            <tr>
              <th scope='row'>{{ 'IDENTIFICATION.VALIDITY_FROM' | translate }}</th>
              <td class='w-25'>
                <ng-container *ngIf='card && card.validity && card.validity.from'>
                  {{ card.validity.from | date }}
                </ng-container>
                <span *ngIf='card && card.validity && !card.validity.from'>-</span>
              </td>
              <th scope='row'>{{ 'IDENTIFICATION.VALIDITY_TO' | translate }}</th>
              <td class='w-25'>
                <ng-container *ngIf='card && card.validity && card.validity.to'>
                  {{ card.validity.to | date }}
                </ng-container>
                <span *ngIf='card && card.validity && !card.validity.to'>-</span>
              </td>
            </tr>
            <tr>
              <th scope='row'>{{ 'IDENTIFICATION.OWNERTYPE' | translate }}</th>
              <td class='w-25'>
                <span *ngIf='card && card.ownerType'>{{ card.ownerType.name }}</span>
                <span *ngIf='card && !card.ownerType'>-</span>
              </td>
              <th scope='row'>{{ 'IDENTIFICATION.PUBLISHER' | translate }}</th>
              <td class='w-25'>
                <span *ngIf='card && card.publisher'>{{ card.publisher.name }}</span>
                <span *ngIf='card && !card.publisher'>-</span>
              </td>
            </tr>
            <tr>
              <th scope='row'>{{ 'IDENTIFICATION.SPECIALCARDTYPE' | translate }}</th>
              <td class='w-25'>
                <span *ngIf='card && card.specialCardType'>{{ card.specialCardType.name }}</span>
                <span *ngIf='card && !card.specialCardType'>-</span>
              </td>
              <th scope='row'>{{ 'IDENTIFICATION.STATUS' | translate }}</th>
              <td class='w-25'>
                <span *ngIf='card && card.status'>{{ card.status.name }}</span>
                <span *ngIf='card && !card.status'>-</span>
              </td>
            </tr>
            <tr>
              <th scope='row'>{{ 'IDENTIFICATION.TYPE' | translate }}</th>
              <td class='w-25'>
                <span *ngIf='card && card.type'>{{ card.type.name }}</span>
                <span *ngIf='card && !card.type'>-</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </nb-tab>
    </nb-tabset>
  </div>
</div>
