import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ParkbaseStorageService } from 'src/app/@shared/services/parkbase-storage.service';
import { DefinitionsApiService } from 'src/app/@core/api/definitions-api.service';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import { TrackingService } from 'src/app/@shared/services/tracking.service';
import { LogService } from '../../../../@core';
import { ChangeContext, LabelType, Options } from '@angular-slider/ngx-slider';
import { NotificationFilterSet } from '../notificationFilterSet';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();
dayjs.extend(relativeTime);

export type SelectedOutput = {
  filterSet: any;
  closeAccordion: boolean;
};

@Component({
  selector: 'app-notifications-filter',
  templateUrl: './notifications-filter.component.html',
  styleUrls: ['./notifications-filter.component.scss'],
})
export class NotificationsFilterComponent implements OnChanges {
  @Input()
  refreshFilter = false;

  @Input()
  filterSet!: NotificationFilterSet;

  @Output()
  filtersSelectedOutput: EventEmitter<SelectedOutput> = new EventEmitter<SelectedOutput>();

  @Output()
  filtersSelectedCountOutput: EventEmitter<number> = new EventEmitter<number>();

  searchTerm!: string;

  @ViewChild('item') accordion!: any;

  filtering = false;
  filterSelectedCount = 0;
  filterHigh!: Dayjs;
  filterLow!: Dayjs;

  minValue = 0;
  maxValue = 29;

  options: Options = {
    floor: this.minValue,
    ceil: this.maxValue,
    rightToLeft: true,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return this.getValueLabel(value);
        case LabelType.High:
          return this.getValueLabel(value);
        default:
          return '';
      }
    },
  };

  constructor(
    private parkbaseStorageService: ParkbaseStorageService,
    private definitionsApiService: DefinitionsApiService,
    private trackingService: TrackingService,
    private logger: LogService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.setFilterRange();
      this.countFiltersSelected();
    }
  }

  setFilterRange(): void {
    if (this.filterSet.from) {
      this.filterLow = this.filterSet.from;
      this.minValue = this.filterSet.getDaysFrom();
    }
    if (this.filterSet.to) {
      this.filterHigh = this.filterSet.to;
      this.maxValue = this.filterSet.getDaysTo();
    }
  }

  countFiltersSelected(): void {
    this.filterSelectedCount = 0;
    this.filterSet.filtersSelected.forEach((e) => {
      if (e.checked) {
        this.filterSelectedCount += 1;
      }
    });
    this.filtersSelectedCountOutput.emit(this.filterSelectedCount);
  }

  clearFilterForm(): void {
    this.searchTerm = '';
    this.filtersSelectedCountOutput.emit(this.filterSelectedCount);
  }

  clearFilter(): void {
    this.filtering = false;
    this.filterSet.filtersSelected.forEach((e) => {
      e.checked = false;
    });
    this.filterSelectedCount = 0;
  }

  toggle(checked: boolean, id: number): void {
    this.filterSet.filtersSelected.forEach((e) => {
      if (e.id === id) {
        e.checked = checked;
        if (checked) {
          this.filterSelectedCount += 1;
        } else {
          this.filterSelectedCount -= 1;
        }
      }
    });

    this.filtersSelectedCountOutput.emit(this.filterSelectedCount);
  }

  saveFilterSet(): void {
    this.filterSet.from = dayjs(this.filterLow);
    this.parkbaseStorageService.saveNotificationTypeFilterSet(this.filterSet);
    this.filterSet.setFromTo(this.filterLow, this.filterHigh);
    this.filtersSelectedOutput.emit({ filterSet: this.filterSet, closeAccordion: true });
    this.trackingService.eventEmitter('notificationFilter', 'action', 'saveFilterSet', '', 0);
    this.logger.info('Notification filter set saved', []);
  }

  resetFilterSet(): void {
    this.clearFilter();
    this.clearFilterForm();
    this.trackingService.eventEmitter('notificationFilter', 'action', 'resetFilterSet', '', 0);
    this.logger.info('Notification filter set reset', []);
  }

  applyFilterSet(): void {
    this.filtersSelectedOutput.emit({ filterSet: this.filterSet, closeAccordion: false });
    this.filtering = true;
    this.trackingService.eventEmitter('notificationFilter', 'action', 'applyFilterSet', '', 0);
    this.logger.info('Notification filter set applied', []);
  }

  cancelFilterSet(): void {
    this.clearFilter();
    // this.filterSet = this.parkbaseStorageService.retrieveFilterSet();
    // this.filterSet.setFromTo(dayjs(), dayjs());
    // this.filterSet.setFiltersSelected(this.filterSet.filtersSelected);
    this.filtersSelectedOutput.emit({ filterSet: this.filterSet, closeAccordion: true });
    this.trackingService.eventEmitter('notificationFilter', 'action', 'cancelFilterSet', '', 0);
    this.logger.info('Notification filter set canceled', []);
  }

  setEndRange($event: ChangeContext) {
    const now = dayjs();
    if ($event && $event.value) {
      this.filterLow = now.subtract($event.value, 'day');
    }
    if ($event && $event.highValue) {
      this.filterHigh = now.subtract($event.highValue, 'day');
    }
  }

  private getValueLabel(value: number) {
    return dayjs().subtract(value, 'day').format('DD-MM-YYYY');
  }
}
