import _ from 'lodash';
import { BaseApiService } from './base-api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  NotificationActionResponse,
  NotificationActionType,
  NotificationGroupsResponse,
  NotificationResponse,
  NotificationsResponse,
} from '../domain/interfaces/notifications.interface';
import dayjs, { Dayjs } from 'dayjs';
import { NotificationsCard } from 'src/app/@shared/components/notifications/notifications.component';
import { ConfigService } from 'src/app/@shared/services/config.service';
import { FacilityName } from '../domain/interfaces/facility.interface';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.guess();

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiService extends BaseApiService {
  private _divisionNotifications = new BehaviorSubject<NotificationResponse>({});
  readonly divisionNotifications = this._divisionNotifications.asObservable();
  private _cardData = new BehaviorSubject<any>({});
  readonly cardData = this._cardData.asObservable();

  private _dataStore: {
    divisionNotifications: NotificationResponse;
    facilityNotificationsSummary: NotificationGroupsResponse;
    cardData: any;
  } = {
    divisionNotifications: {},
    facilityNotificationsSummary: {},
    cardData: {},
  };

  constructor(configService: ConfigService, httpClient: HttpClient) {
    super(configService, httpClient);
  }

  loadAsyncWithCardData(cardData: NotificationsCard, pageSize: number, notificationsType: string, facilityId?: number) {
    let apiUrl = '';
    const selection: FacilityName = this.parkbaseStorageService.retrieveFacilitySelection();
    if (selection && selection.id && selection.id > 0) {
      facilityId = selection.id;
      if (notificationsType === 'notificationsForFacility') {
        apiUrl = this.parkbaseBl9BaseUrl + '/facilities/' + facilityId + '/notifications/summary';
      }
    } else {
      if (notificationsType === 'divisionsNotifications') {
        apiUrl = this.parkbaseBl9BaseUrl + '/divisions/notifications/summary';
      }
    }

    if (apiUrl === '') {
      return;
    }

    try {
      const page = cardData.pageToLoadNext;
      const types = cardData.types;
      const levels = cardData.levels;
      const terminalIds = cardData.terminalIds;
      const fromDate = cardData.filterSet.from;
      const toDate = cardData.filterSet.to;
      const body = {
        query: {
          page,
          pageSize,
          types,
          levels,
          terminalIds,
          from: fromDate,
          to: toDate,
        },
      };
      const headers = this.setHeaders();
      this.http
        .post<NotificationGroupsResponse>(apiUrl, body, {
          headers,
        })
        .subscribe((nextData: any) => {
          cardData.placeholders = [];
          cardData.notificationGroups.push(...nextData.notificationGroups);
          cardData.notificationGroups = _.uniqWith(cardData.notificationGroups, _.isEqual);
          cardData.loading = false;
          cardData.pageToLoadNext++;
          this._dataStore.cardData = cardData;
          this._cardData.next(Object.assign({}, this._dataStore).cardData);
        });
    } catch (error) {
      console.log('error', error);
    }
  }

  loadNotificationsFilteredForTerminal(
    cardData: NotificationsCard,
    pageSize: number
  ): Observable<NotificationsResponse> {
    let apiUrl = '';
    let facilityId;

    const selection: FacilityName = this.parkbaseStorageService.retrieveFacilitySelection();
    if (selection && selection.id && selection.id > 0) {
      facilityId = selection.id;
      apiUrl = this.parkbaseBl9BaseUrl + '/facilities/' + facilityId + '/notifications';
    } else {
      apiUrl = this.parkbaseBl9BaseUrl + '/divisions/notifications';
    }

    const page = cardData.pageToLoadNext;
    const types = cardData.types;
    const levels = cardData.levels;
    const terminalIds = cardData.terminalIds;
    const fromDate = dayjs().subtract(4, 'hour').toJSON();
    const body = {
      query: {
        page,
        pageSize,
        types,
        levels,
        terminalIds,
        from: fromDate,
        to: dayjs(),
      },
    };
    const headers = this.setHeaders();
    return this.http.post<NotificationsResponse>(apiUrl, body, {
      headers,
    });
  }

  /**
   * POST /api/gui/v1.0/divisions/notifications
   */
  loadDivisionsNotifications(
    page: number,
    pageSize: number,
    types: number[],
    levels: number[],
    terminalIds: number[],
    from: Dayjs,
    to: Dayjs
  ): void {
    const apiUrl = this.parkbaseBl9BaseUrl + '/divisions/notifications';
    const fromDate = dayjs(from).toJSON();
    const toDate = dayjs(to).toJSON();
    const body = {
      query: {
        page,
        pageSize,
        types,
        levels,
        terminalIds,
        from: fromDate,
        to: toDate,
      },
    };

    const headers = this.setHeaders();
    this.http
      .post<NotificationResponse>(apiUrl, body, {
        headers,
      })
      .subscribe(
        (data) => {
          this._dataStore.divisionNotifications = data;
          this._divisionNotifications.next(Object.assign({}, this._dataStore).divisionNotifications);
        },
        (error) => this.handleError('Could not load notifications.', error)
      );
  }

  /**
   * POST /api/gui/v1.0/notifications/{id}/action
   */
  sendNotificationsAction(id: string, type: NotificationActionType): Observable<NotificationActionResponse> {
    if (!id) {
      throw new Error('sendNotificationsAction, id not provided');
    }
    const apiUrl = this.parkbaseBl9BaseUrl + '/notifications/' + id + '/action';
    const body = {
      type,
    };
    return this.http.post<NotificationActionResponse>(apiUrl, body, { headers: this.setHeaders() }).pipe(
      map((result) => {
        return result;
      })
    );
  }
}
