<div class='body-container'>
  <div class='body-title'>
    <h4>{{ 'PROFILE.TITLE' | translate }} - {{ 'PROFILE.SETTINGS' | translate }}</h4>
  </div>
  <nb-card class='profile-card'>
    <div class='col'>
      <div class='form-group'>
        <app-language-selector></app-language-selector>
        <app-theme-selector></app-theme-selector>
        <app-facility-layout-selector></app-facility-layout-selector>
      </div>
      <div class='alarm-dateformat-selector'>
        <div class='form-group x'>
          <div>
            <h6>{{ 'PROFILE.DATEFORMATCHOICE' | translate }}</h6>
            <nb-radio-group (ngModelChange)='storeDateformatChoice($event)' [ngModel]='dateFormatChoice'
                            class='radio-group' name='dateFormatChoice'>
              <nb-radio *ngFor='let dateFormatChoice of dateFormatChoiceOptions' [name]='dateFormatChoice.value'
                        [value]='dateFormatChoice.value'>
                {{ dateFormatChoice.label }}
              </nb-radio>
            </nb-radio-group>
          </div>
          <div>
            <h6>{{ 'PROFILE.TOUR.TITLE' | translate }}</h6>
            <nb-radio-group (ngModelChange)='storeTourChoice($event)' [ngModel]='tourChoice' class='radio-group'
                            name='tourChoice'>
              <nb-radio *ngFor='let tourChoice of tourChoiceOptions' [name]='tourChoice.value'
                        [value]='tourChoice.value'>
                {{ tourChoice.label }}
              </nb-radio>
            </nb-radio-group>
          </div>
        </div>
      </div>
      <app-dashboard-facilities-selector></app-dashboard-facilities-selector>
    </div>
    <div class='col ps-xl-5 ps-md-3'>
      <ng-container *ngIf='userProfile'>
        <nb-user
          name='{{ userProfile.preferred_username }}'
          picture='../../../../assets/theme/profile.png'
          size='giant'
          title='{{ userProfile.email }}'>
        </nb-user>
        <div class='session-data'>
          <span>{{ 'PROFILE.LASTLOGIN' | translate }}: {{ userProfile.lastLoginDate | date: 'short' }}</span>-
          <span>sessionId: {{ userProfile.sessionId }}</span>
        </div>
        <div class='roles'>
          <div class='form-group'>
            <h6>{{ 'PROFILE.ROLES' | translate }}</h6>
            <button (click)='collapse.toggle()' [attr.aria-expanded]='!isCollapsed'
                    aria-controls='collapseRoles'
                    class='btn btn-outline-secondary'
                    type='button'>
              <span>{{ 'PROFILE.SHOW_ROLES' | translate }}</span>
              <nb-icon icon='eye-outline' nbPrefix pack='eva'></nb-icon>
            </button>
            <div #collapse='ngbCollapse' [(ngbCollapse)]='isCollapsed'>
              <ul class='list-unstyled card-columns-2 roles-list'>
                <li *ngFor='let role of userProfile.role'>{{role}}</li>
              </ul>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </nb-card>
</div>

