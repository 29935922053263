<div class='toast-message {{classType}}'>
  <div class='toast-message-icon'>
    <nb-icon *ngIf="classType === 'failure'" icon='alert-triangle-outline' nbPrefix pack='eva'></nb-icon>
    <nb-icon *ngIf="classType === 'success'" icon='checkmark-circle-2' nbPrefix pack='eva'></nb-icon>
    <nb-icon *ngIf="classType === 'input-required'" icon='question-mark-circle-outline' nbPrefix pack='eva'></nb-icon>
    <nb-icon *ngIf="classType === 'partial-failure'" icon='alert-circle-outline' nbPrefix pack='eva'></nb-icon>
  </div>
  <div class='toast-message-body'>
    <div>
      <div class='title'>{{title}}</div>
      <div class='message'>{{message}}</div>
    </div>
    <div class='close-icon'>
      <nb-icon (click)='dismiss()' icon='close-outline' nbPrefix pack='eva'></nb-icon>
    </div>
  </div>
</div>
