<nb-card>
  <nb-accordion *ngIf='facilitySummary'>
    <nb-accordion-item class='facility'>
      <nb-accordion-item-header class='facility__header'>
        <div class='facility-name'>
          <div class='facility-icon'>
            <app-facility-status-icon [fullStatus]="facilitySummary.facility.fullStatus"
                                      [openingState]="facilitySummary.facility.openingState"></app-facility-status-icon>
          </div>
          <div class='facility-details'>
            <div class='facility-details__name'>
              <h6>
                {{ facilitySummary.facility.name}}
                <span class="label">
                  {{ facilitySummary.terminalCounts.total }}
                  <span *ngIf="facilitySummary.terminalCounts.total === 1;">
                    {{ 'FACILITIES_SUMMARY.FACILITY.TERMINAL' | translate }}
                  </span>
                  <span *ngIf="facilitySummary.terminalCounts.total > 1;">
                    {{ 'FACILITIES_SUMMARY.FACILITY.TERMINALS' | translate }}
                  </span>
                </span>
              </h6>
            </div>
            <div class='facility-status'>
              <app-general-led [color]='statusService.getLedColor(facilitySummary)'></app-general-led>
              {{statusService.getFacilityStatus(facilitySummary)}}
            </div>
          </div>
        </div>
        <div>
          <ng-container>
            <div class='alarms'>
              <app-facility-emergency-alarms
                (click)='gotoFacilityDetails(facilitySummary.facility.id)'
                *ngIf='facilitySummary.facility.id'
                [alarmsFromSummary]='facilitySummary.emergencyAlarms'
                [facilityId]='facilitySummary.facility.id'
                [showHeaderIcon]='false'
              ></app-facility-emergency-alarms>
              <div class='facility-navigation'>
                <a
                  (click)='gotoFacilityDetails(facilitySummary.facility.id)'
                  [routerLink]="['/status/facilities', facilitySummary.facility.id]"
                >
                  <nb-icon
                    class='icon-navigate'
                    icon='arrow-ios-forward'
                    nbPrefix
                    pack='eva'
                    status='control'
                  ></nb-icon>
                </a>
              </div>
            </div>
          </ng-container>
        </div>
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        <div class='facility-summary'>
          <app-occupation-ratios [facilitySummary]='facilitySummary'></app-occupation-ratios>
        </div>
      </nb-accordion-item-body>
    </nb-accordion-item>
  </nb-accordion>
</nb-card>
