import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { FacilitySummary } from 'src/app/@core/domain/interfaces/facility-summary.interface';

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilityComponent {
  @Input()
  facilitySummary!: FacilitySummary;

  constructor(private router: Router) {}

  public gotoFacilityDetails(id: number): void {
    this.router.navigate(['/status/facilities/', id], { skipLocationChange: true });
  }
}
