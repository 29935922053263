import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NbPositionValues } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from 'src/app/@core';
import { NotificationsApiService } from 'src/app/@core/api/notification-api.service';
import { Notification } from 'src/app/@core/domain/interfaces/notifications.interface';
import { StatusPageService } from 'src/app/pages/status-page/services/status-page.service';
import { ResponseStatus } from '../../../../../@core/domain/interfaces/facility.interface';
import { ToastMessageService } from '../../../../services/toast-message.service';

@Component({
  selector: 'app-notification-actions',
  templateUrl: './notification-actions.component.html',
  styleUrls: ['./notification-actions.component.scss'],
})
export class NotificationActionsComponent implements OnInit {
  icon!: any;
  dialogOpen = false;
  notificationHandled = false;
  direction: NbPositionValues = 'left';
  successMessage!: string;
  errorMessage!: string;

  @Input()
  notification!: Notification;

  @ViewChild('popoverTemplateRef', { read: TemplateRef }) popoverTemplateRef!: TemplateRef<any>;

  constructor(
    private notificationsApiService: NotificationsApiService,
    private translate: TranslateService,
    private statusPageService: StatusPageService,
    private toastMessageService: ToastMessageService,
    private logger: LogService
  ) {}

  ngOnInit(): void {
    if (this.notification && this.notification.terminal && this.notification.terminal.type) {
      // this.icon = 'terminalType_' + this.notification.terminal.type['id'];
    }
    this.translate.get('NOTIFICATIONS.SUCCESS').subscribe((res: string) => {
      this.successMessage = res;
    });
    this.translate.get('NOTIFICATIONS.ERROR').subscribe((res: string) => {
      this.errorMessage = res;
    });
    this.dialogOpen = false;
  }

  open(): void {
    this.dialogOpen = !this.dialogOpen;
    if (this.dialogOpen) {
      //this.statusPageService.triggerPreventClick(true);
      this.statusPageService.triggerRefreshNotifications(false);
    } else {
      this.statusPageService.triggerRefreshNotifications(true);
    }
  }

  getIconType(notification: Notification): string {
    if (notification && notification.terminal) {
      const terminalTypeEnumValue = Object.values(notification.terminal.type)[0];
      return 'terminalType_' + terminalTypeEnumValue;
    }
    return 'terminalType_0';
  }

  close(): void {
    this.statusPageService.triggerRefreshNotifications(true);
    this.dialogOpen = false;
  }

  handleNotificationAction(id: string, type: number): void {
    this.notificationHandled = true;
    this.close();
    this.notificationsApiService.sendNotificationsAction(id, type).subscribe((result) => {
      if (result.status === ResponseStatus.Success) {
        this.statusPageService.triggerRefreshNotifications(true);
        this.logger.info('Notification action type' + type + ' performed on notification ' + id);
        this.toastMessageService.showSuccess(this.successMessage + ' - ' + result.message);
      } else if (result.status === ResponseStatus.Failure) {
        this.logger.info('Error performing notification action type' + type + ' on notification ' + id);
        this.toastMessageService.showFailure(this.errorMessage + ' - ' + result.message);
      } else if (result.status === ResponseStatus.InputRequired) {
        this.toastMessageService.showInputRequired(result.message);
      } else if (result.status === ResponseStatus.PartialFailure) {
        this.toastMessageService.showPartialFailure(result.message);
      }
    });
  }
}
