import { Alarm } from 'src/app/@core/domain/interfaces/alarm.interface';
import { AlarmsApiService } from 'src/app/@core/api/alarms-api.service';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FacilityHubService } from 'src/app/@core/hubs/facility-hub.service';
import { distinctUntilChanged, ReplaySubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-facility-emergency-alarms',
  templateUrl: './facility-emergency-alarms.component.html',
  styleUrls: ['./facility-emergency-alarms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FacilityEmergencyAlarmsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  showHeaderIcon!: boolean;

  @Input()
  facilityId!: number;

  @Input()
  alarmsFromSummary: Alarm[] = [];

  alarms$ = new ReplaySubject<Alarm[]>();
  alarmGoing!: Alarm | undefined;
  alarmsSubscription!: Subscription;

  constructor(private alarmsApiService: AlarmsApiService, private facilityHubService: FacilityHubService) {}

  ngOnInit(): void {
    const facilityId = +this.facilityId;
    this.facilityHubService.callHub(facilityId);

    if (this.alarmsFromSummary.length > 0) {
      this.alarms$.next(this.alarmsFromSummary);
      return;
    } else {
      this.alarmsSubscription = this.alarmsApiService.alarms$.subscribe((data) => {
        if (data.length >= 4) {
          this.alarms$.next(data);
        }
      });

      this.alarmsApiService.loadFacilityEmergencyAlarms(this.facilityId);

      this.alarms$.subscribe((alarms) => {
        this.checkAlarmGoing(alarms);
      });
    }
  }

  ngAfterViewInit() {
    this.getEmergencyAlarmChanged();
  }

  ngOnDestroy(): void {
    if (this.alarmsSubscription) {
      this.alarmsSubscription.unsubscribe();
    }
  }

  private getEmergencyAlarmChanged() {
    this.facilityHubService.facilityEmergencyAlarms$.pipe(distinctUntilChanged()).subscribe((data) => {
      if (data) {
        this.alarms$.next(data);
        this.checkAlarmGoing(data);
      }
    });
  }

  private checkAlarmGoing(alarms: Alarm[]) {
    this.alarmGoing = undefined;
    alarms.forEach((alarm) => {
      if (alarm.going) {
        this.alarmGoing = alarm;
      }
    });
  }
}
