import { BaseApiService } from './base-api.service';
import { BehaviorSubject, takeLast } from 'rxjs';
import { Division } from '../domain/interfaces/division.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable, VERSION } from '@angular/core';
import { ConfigService } from 'src/app/@shared/services/config.service';
import { ParkbaseStorageService } from 'src/app/@shared/services/parkbase-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DivisionsApiService extends BaseApiService {
  private _division = new BehaviorSubject<Division>({ name: '', city: '' });
  readonly division$ = this._division.asObservable();

  constructor(configService: ConfigService, httpClient: HttpClient, parkbaseStorageService: ParkbaseStorageService) {
    super(configService, httpClient);
    super.setStorage(parkbaseStorageService);
    this.loadApiVersion();
    this.loadDivisions();
  }

  checkHealth() {
    const apiUrl = this.parkbaseBl9BaseUrl + '/health';
    return this.http.get(apiUrl, { observe: 'response' }).pipe(takeLast(1));
  }

  /**
   * GET /version
   */
  loadApiVersion(): void {
    const apiUrl = this.parkbaseBl9BaseUrl + '/health/version';
    this.http.get(apiUrl, { headers: this.setHeaders(), responseType: 'text', withCredentials: false }).subscribe({
      next: (data) => {
        const version = 'ParkBaseGui 9 - Angular v' + VERSION.full + ' - Development prototype - API: ' + data;
        this.parkbaseStorageService.storeApiVersion(version);
      },
      error: (error) => {
        this.handleError('Could not load API version.', error);
      },
    });
  }

  /**
   * GET /api/gui/v1.0/divisions
   */
  loadDivisions(): void {
    this.http
      .get<Division>(this.parkbaseBl9BaseUrl + '/divisions', {
        headers: this.setHeaders(),
      })
      .subscribe(
        (data) => {
          this._division.next(data);
          this.parkbaseStorageService.storeDivision(data);
        },
        (error) => this.handleError('Could not load divisions.', error)
      );
  }
}
