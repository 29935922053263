<div *ngIf='showComponent' @slideIn @slideOut>
  <app-header-nav-mobile [componentRef]='globalSearchMobileDialogRef'
                         [handleNavigate]='true' title="{{ 'MOBILE.SEARCH' | translate }}">
  </app-header-nav-mobile>
  <div class='global-search-mobile-content'>
    <div class='global-search'>
      <nb-form-field class='global-search-form-field'>
        <nb-icon icon='search' nbPrefix pack='eva'></nb-icon>
        <input (ngModelChange)='changed()'
               [(ngModel)]='searchInput' fullWidth nbInput placeholder="{{ 'GLOBAL_SEARCH.PLACEHOLDER' | translate }}"
               shape='semi-round'
               size='large' status='basic'
               type='text'>
        <button
          (click)='clearSearchForm()'
          *ngIf='searching'
          class='clear-button'
          ghost
          nbButton
          nbSuffix
          type='button'
        >
          {{ 'GLOBAL_SEARCH.CLEAR' | translate }}
        </button>
      </nb-form-field>
    </div>
    <div class='search-result-container'>
      <div *ngFor='let result of searchResult' class='search-result-category'>
        <div *ngIf='result && result.type.id === 0' class='result-category'>
          <h5>
            {{result.type.name}}
            <nb-badge position='top right' status='info' text='{{result.count}}'></nb-badge>
          </h5>
          <div (click)='openSearchResult(x, 0)' *ngFor='let x of result.results' class='search-result'>
            <app-cell [genericInfo]="formatSearchResult(x, SearchResultType.LicensePlate)"></app-cell>
          </div>
        </div>
        <div *ngIf='result && result.type.id === 1' class='result-category'>
          <h5>
            {{result.type.name}}
            <nb-badge position='top right' status='info' text='{{result.count}}'></nb-badge>
          </h5>
          <div (click)='openSearchResult(x, 1)' *ngFor='let x of result.results' class='search-result'>
            <app-cell [genericInfo]="formatSearchResult(x, SearchResultType.IdentificationCard)"></app-cell>
          </div>
        </div>
        <div *ngIf='result && result.type.id === 2' class='result-category'>
          <h5>
            {{result.type.name}}
            <nb-badge position='top right' status='info' text='{{result.count}}'></nb-badge>
          </h5>
          <div (click)='openSearchResult(x, 2)' *ngFor='let x of result.results' class='search-result'>
            <app-cell [genericInfo]="formatSearchResult(x, SearchResultType.Subscriber)"></app-cell>
          </div>
        </div>
        <div *ngIf='result && result.type.id === 3' class='result-category'>
          <h5>
            {{result.type.name}}
            <nb-badge position='top right' status='info' text='{{result.count}}'></nb-badge>
          </h5>
          <div (click)='openSearchResult(x, 3)' *ngFor='let x of result.results' class='search-result'>
            <app-cell [genericInfo]="formatSearchResult(x, SearchResultType.Visitor)"></app-cell>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

