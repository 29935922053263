import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, map, tap } from 'rxjs/operators';
import { ConfigService } from 'src/app/@shared/services/config.service';
import { SearchResult } from '../domain/interfaces/search-result.interface';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root',
})
export class SearchApiService extends BaseApiService {
  private _searchResult = new BehaviorSubject<SearchResult[]>([]);
  readonly searchResult = this._searchResult.asObservable();
  private _dataStore: {
    searchResult: SearchResult[];
  } = {
    searchResult: [],
  };

  constructor(configService: ConfigService, httpClient: HttpClient) {
    super(configService, httpClient);
  }

  /**
   * POST /api/gui/v1.0/search
   */
  search(searchTerm: string): Observable<SearchResult[]> {
    if (searchTerm) {
      const apiUrl = this.parkbaseBl9BaseUrl + '/search';
      const body = {
        term: searchTerm,
      };
      return this.http.post<SearchResult[]>(apiUrl, body, { headers: this.setHeaders() }).pipe(
        tap((result) => {
          if (result.length > 1 && searchTerm.length >= 2) {
            this.parkbaseStorageService.storeRecentSearch(searchTerm);
          }
        }),
        map((retVal) => {
          return retVal;
        })
      );
    }
    return of([]);
  }

  testApi(): Observable<SearchResult[]> {
    return of([
      {
        type: { id: 0, name: 'test' },
        count: 12,
        results: [
          { id: 0 },
          { id: 0 },
          { id: 0 },
          { id: 0 },
          { id: 0 },
          { id: 0 },
          { id: 0 },
          { id: 0 },
        ],
      },
    ]);
  }

  /**
   * POST /api/gui/v1.0/search/{type}
   */
  searchByType(searchTerm: string, type: number, page: number): Observable<SearchResult> {
    const apiUrl = this.parkbaseBl9BaseUrl + '/search/' + type;
    const body = {
      page: page,
      pageSize: 25,
      term: searchTerm,
    };
    return this.http.post<SearchResult>(apiUrl, body, { headers: this.setHeaders() }).pipe(
      debounceTime(500),
      tap((result) => {
        if (result.results.length > 1 && searchTerm.length > 3) {
          this.parkbaseStorageService.storeRecentSearch(searchTerm);
        }
      }),
      map((retVal) => retVal)
    );
  }
}
