import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { SearchResultAction, SearchResultItem } from '../../@core/domain/interfaces/search-result.interface';
import { slideInOutAnimation } from '../../@shared/animation/animation';
import { AnonymousPassageMobileComponent } from '../anonymous-passage-mobile/anonymous-passage-mobile.component';

@Component({
  selector: 'app-global-search-result-mobile',
  templateUrl: './global-search-result-mobile.component.html',
  styleUrls: ['./global-search-result-mobile.component.scss'],
  animations: slideInOutAnimation,
})
export class GlobalSearchResultMobileComponent implements OnInit {
  @Input()
  searchResult!: SearchResultItem;

  @Input()
  searchResultType!: number;
  showComponent = true;

  constructor(
    private dialogService: NbDialogService,
    protected globalSearchResultMobileDialogRef: NbDialogRef<GlobalSearchResultMobileComponent>
  ) {}

  ngOnInit(): void {}

  openAvailableActionMobile(action: SearchResultAction) {
    let component: any;
    // console.log('now we should open the available action', action);
    if (action.type === 0) {
      component = AnonymousPassageMobileComponent;
    }
    this.dialogService
      .open(component, {
        context: {
          action: action,
        },
      })
      .onClose.subscribe((res) => {
        if (res && res.handleNavigate.changeToSubscriber) {
          this.openAvailableActionMobile({ name: 'test', type: 29 });
        } else if (res && res.handleNavigate.changeToAnonymous) {
          this.openAvailableActionMobile({ name: 'test', type: 28 });
        } else if (res && res.handleNavigate.changeBack) {
          // do nothing
          this.globalSearchResultMobileDialogRef.close();
        } else {
          this.globalSearchResultMobileDialogRef.close();
        }
      });
  }
}
