import { AfterViewChecked, AfterViewInit, Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { InfoComponent } from '../info/info.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { distinctUntilChanged, filter, fromEvent, pairwise, share, throttleTime } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NbDialogService, NbPopoverDirective } from '@nebular/theme';
import { NotificationsComponent } from '../notifications/notifications.component';

enum VisibilityState {
  Expanded = 'expanded',
  Normal = 'normal',
}

enum Direction {
  Up = 'Up',
  Down = 'Down',
}

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  animations: [
    trigger('toggle', [
      state(
        VisibilityState.Expanded,
        style({
          background: 'var(--color-primary-900)',
          margin: '32px 16px 36px -16px',
          padding: '16px',
        })
      ),
      state(VisibilityState.Normal, style({ background: 'transparent' })),
      transition('* => *', animate('200ms ease-in')),
    ]),
  ],
})
export class PageHeaderComponent implements OnInit, AfterViewInit, AfterViewChecked {
  infoComponent = InfoComponent;
  notificationsComponent = NotificationsComponent;
  active = false;
  infoActive = true;
  notificationsActive = true;
  notificationsClickable = true;

  @Input()
  showHeader = false;

  @ViewChild(NbPopoverDirective) popover!: NotificationsComponent;
  private isNormal = true;

  constructor(private routes: ActivatedRoute, private router: Router, private dialogService: NbDialogService) {}

  @HostBinding('@toggle')
  get toggle(): VisibilityState {
    return this.isNormal ? VisibilityState.Normal : VisibilityState.Expanded;
  }

  ngOnInit(): void {
    const urlParts = this.router.url.split('/');

    this.routes.params.subscribe((params: Params) => {
      if (params && params['id']) {
        const currentFacility = { id: params['id'], name: '', availableActions: [] };
      }
    });
  }

  handleClick() {
    this.active = !this.active;
  }

  ngAfterViewInit() {
    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(10),
      map(() => window.pageYOffset),
      pairwise(),
      map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
      distinctUntilChanged(),
      share()
    );

    const goingUp$ = scroll$.pipe(filter((direction) => direction === Direction.Up));
    const goingDown$ = scroll$.pipe(filter((direction) => direction === Direction.Down));

    goingUp$.subscribe(() => (this.isNormal = true));
    goingDown$.subscribe(() => (this.isNormal = false));
  }

  ngAfterViewChecked(): void {
    const currentRouteParts = this.router.url.split('/');
    // this.notificationsClickable = currentRouteParts.length !== 4;
  }
}
