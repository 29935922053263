import { Company } from './shared/company.interface';

export interface SearchResult {
  type: SearchResultType;
  count: number;
  results: SearchResultItem[];
}

type OwnerOneOf = Owner;

export type SearchResultItemAnyOf =
  | LicensePlateSearchResultItem
  | CardSearchResultItem
  | SubscriberSearchResultItem
  | VisitorSearchResultItem;

interface Owner {
  lastName?: string;
  middleName?: string;
  firstNames?: string;
}

interface LicensePlateSearchResultItem {
  id: number;
  licensePlate: string;
  owner?: Owner;
  countryCode?: string;
}

interface CardSearchResultItem {
  id: number;
  hri: string;
  publisher?: string;
  type?: string;
  owner?: Owner;
}

interface SubscriberSearchResultItem {
  id: number;
  lastName: string;
  middleName?: string;
  firstnames?: string;
  company?: string;
}

interface VisitorSearchResultItem {
  id: number;
  lastName: string;
  middleName?: string;
  firstnames?: string;
  company?: string;
}

export interface SearchResultItem {
  id: number;
  license?: string;
  country?: string;
  owner?: Owner;
  hri?: string;
  publisher?: any;
  type?: any;
  lastName?: string;
  middleName?: string;
  firstNames?: string;
  company?: Company;
  availableActions?: SearchResultAction[];
}

export interface SearchResultAction {
  type: number;
  name: string;
}

export interface SearchResultType {
  id: SearchResultType2;
  name: string;
}

export enum SearchResultType2 {
  LicensePlate = 0,
  IdentityCard = 1,
  Visitor = 2,
  Subscriber = 3,
}
