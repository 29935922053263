import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Terminal } from '../../../../../@core/domain/interfaces/terminal.interface';

@Component({
  selector: 'app-two-way-lane-confirmation',
  templateUrl: './two-way-lane-confirmation.component.html',
  styleUrls: ['./two-way-lane-confirmation.component.scss'],
})
export class TwoWayLaneConfirmationComponent implements OnInit {
  @Input()
  message!: string;

  @Input()
  terminal!: Terminal;

  constructor(protected dialogRef: NbDialogRef<TwoWayLaneConfirmationComponent>) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close({ confirmed: false });
  }

  confirm() {
    this.dialogRef.close({ confirmed: true });
  }
}
