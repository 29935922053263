<div *ngIf='showComponent' @slideIn @slideOut>
  <app-header-nav-mobile [componentRef]='settingsMobileMenuDialogRef'
                         [handleNavigate]='true' title="{{ 'MOBILE.SETTINGS' | translate }}">
  </app-header-nav-mobile>
  <div class='mobile-content'>
    <ul class='menu-items'>
      <li (click)='openMobile(menuItem)' *ngFor='let menuItem of settingsItems; let i = index;' class='menu-item'>
        <div class='menu-item-title'>
          <div>{{menuItem.title}}</div>
        </div>
        <div>
          <nb-icon
            icon='arrow-ios-forward-outline' nbButton
            nbPrefix
            pack='eva'
            status='control'></nb-icon>
        </div>
      </li>
    </ul>
  </div>
</div>

