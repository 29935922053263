import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AuthModule, LogLevel, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Config } from './@core/domain/interfaces/config.interface';

export const httpLoaderFactory = (httpClient: HttpClient) => {
  const config$ = httpClient.get<Config>('assets/app.config.json').pipe(
    map((config: Config) => {
      const RESOURCES_URL = config.server.resources;
      const API_RESOURCES_URL = config.server.resources;
      const BL9_RESOURCES_URL = config.server.resourcesBL9;
      const IDS_RESOURCE_URL = config.server.auth;
      const LOG_URL = config.server.log + '/log';

      return {
        authority: config.server.auth,
        redirectUrl: config.server.self,
        postLogoutRedirectUri: config.server.self,
        clientId: 'parkbase9',
        scope: 'openid profile ParkBaseApi ParkBase9Api IdentityServerApi offline_access Runner9Api',
        responseType: 'code',
        silentRenew: true,
        automaticSilentRenew: true,
        useRefreshToken: true,
        silentRenewUrl: `${config.server.self}/silent-renew.html`,
        renewTimeBeforeTokenExpiresInSeconds: 10,
        maxIdTokenIatOffsetAllowedInSeconds: 300,
        historyCleanupOff: true,
        logLevel: environment.production ? LogLevel.None : LogLevel.Error,
        secureRoutes: [RESOURCES_URL, API_RESOURCES_URL, BL9_RESOURCES_URL, IDS_RESOURCE_URL, LOG_URL],
        forbiddenRoute: '/forbidden',
        unauthorizedRoute: '/account',
      };
    })
  );

  return new StsConfigHttpLoader(config$);
};

@NgModule({
  imports: [
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [AuthModule],
})
export class AuthConfigModule {}
