import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FeeSchedule,
  LostTicketFeeType,
  PayDeviceProfile,
  Terminal,
  TerminalActionType,
} from '../../../../@core/domain/interfaces/terminal.interface';
import { ParkbaseStorageService } from '../../../services/parkbase-storage.service';
import { Definition } from '../../../../@core/domain/interfaces/definition.interface';
import { DefinitionType } from '../../../../@core/domain/enums/definition-type.enum';
import { NgForm } from '@angular/forms';
import { ToastMessageService } from '../../../services/toast-message.service';
import { TerminalsApiService } from '../../../../@core/api/terminals-api.service';
import { ResponseStatus } from '../../../../@core/domain/interfaces/facility.interface';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { NbDialogService } from '@nebular/theme';
import { ConfirmationData, ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-lost-ticket',
  templateUrl: './lost-ticket.component.html',
  styleUrls: ['./lost-ticket.component.scss'],
})
export class LostTicketComponent implements OnInit {
  @Input()
  terminalId!: number;

  @Input()
  showTerminalTitle = true;

  @ViewChild('lostTicketForm') form!: NgForm;

  terminal!: Terminal;
  awaitTerminalActionComplete: any;
  hideElement = false;
  lostTicketFeeTypes!: Definition[];
  feeSchedule: FeeSchedule | undefined;
  payDeviceProfileForLostTicket: PayDeviceProfile | undefined;
  rateMethod = 1;
  inputOptions: any;
  error: any;
  issueTickedChecked = false;
  finalTicketAmount = 0;
  checkTicket!: any;
  ticketIssued!: any;
  terminalActionComplete = false;
  finalTerminalActionComplete = false;
  userName!: string;
  loadingImage = true;
  imageToShow$!: Observable<any>;
  private interval: any;
  private terminalActionValues: any;
  private buttonClicked = new Subject<boolean>();

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private parkBaseStorageService: ParkbaseStorageService,
    private toastMessageService: ToastMessageService,
    private terminalsApiService: TerminalsApiService,
    private dialogService: NbDialogService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.imageToShow$ = this.terminalsApiService.terminalsScreen;
    this.terminalsApiService.loadTerminalById(this.terminalId).subscribe((t) => {
      this.terminal = t;
      this.lostTicketFeeTypes = this.parkBaseStorageService.retrieveDefinitionList(DefinitionType.LostTicketFeeTypes);
      this.feeSchedule = this.getLostTicketFeeSchedule();
      this.payDeviceProfileForLostTicket = this.getPayDeviceProfile();
      this.inputOptions = this.parkBaseStorageService.getCurrencyFormatForLang();
      this.oidcSecurityService.getUserData().subscribe((data) => {
        this.userName = data.name;
      });
    });

    const buttonClickedDebounced = this.buttonClicked.pipe(debounceTime(200));
    buttonClickedDebounced.subscribe(() => {
      this.checkIssueTicket();
    });
  }

  checkIssueTicket() {
    if (this.form.valid && !this.issueTickedChecked) {
      this.terminalActionValues = this.setFormValuesWithoutIssueTicket();
      this.terminalsApiService.sendTerminalAction(this.terminalId, this.terminalActionValues).subscribe((response) => {
        if (response.status === ResponseStatus.Success) {
          // success is not expected in the first call
          this.error = null;
        } else if (response.status === ResponseStatus.Failure) {
          this.error = response;
          this.toastMessageService.showFailure(response.message);
        } else if (response.status === ResponseStatus.BadRequestResponse) {
          this.error = response;
          this.toastMessageService.showFailure(response.message);
        } else if (response.status === ResponseStatus.InputRequired) {
          this.error = null;
          this.issueTickedChecked = true;
          this.terminalActionComplete = true;
          this.finalTerminalActionComplete = false;
          this.checkTicket = response;
          this.finalTicketAmount = response.result.amount;
          let translations: string[] = [];
          this.translate
            .get([
              'LOST_TICKET.CANCEL_ISSUE_TICKET',
              'LOST_TICKET.ISSUE_TICKET',
              'LOST_TICKET.CHECK_ISSUE_TICKET',
              'LOST_TICKET.AMOUNT_TICKET',
              'PROFILE.USERNAME',
            ])
            .subscribe((t) => {
              translations = Object.keys(t).map((key) => t[key]);
            });
          const msg = `<p>${translations[2]}!<br><span>${translations[3]}: ${this.checkTicket.result.amount}</span><br>${translations[4]}: <span>${this.userName}</span></p>`;
          const configOptions: ConfirmationData = {
            buttonCancel: translations[0],
            buttonConfirm: translations[1],
            headerTitle: translations[2],
            infoAlertText: this.checkTicket.message,
            showInfoAlert: true,
            message: msg,
            htmlMessage: true,
            title: '',
          };
          this.dialogService
            .open(ConfirmationDialogComponent, {
              context: {
                confirmationData: configOptions,
              },
            })
            .onClose.subscribe((res) => {
              if (res) {
                this.issueTicket();
              } else {
                this.resetForm();
              }
            });
        }
      });
    }
  }

  onSelectRateMethod($event: number) {
    this.rateMethod = $event;
  }

  clickButton() {
    this.buttonClicked.next(true);
  }

  resetForm() {
    this.terminalActionComplete = false;
    this.finalTicketAmount = 0;
    this.cancelIssueTicket();
  }

  cancelIssueTicket() {
    this.error = null;
    this.issueTickedChecked = false;
    this.terminalActionValues = {};
    this.terminalActionComplete = false;
    this.finalTicketAmount = 0;
    this.interval = undefined;
  }

  clearInput(inputField: string) {
    this.form.controls[inputField].reset();
  }

  private issueTicket() {
    if (this.issueTickedChecked) {
      this.terminalActionValues.issue = true;
      this.terminalsApiService.sendTerminalAction(this.terminalId, this.terminalActionValues).subscribe((response) => {
        if (response.status === ResponseStatus.Success) {
          this.handleSuccess(response);
          this.refreshImage();
          this.toastMessageService.showSuccess(response.message);
        } else if (response.status === ResponseStatus.Failure) {
          this.error = response;
          this.toastMessageService.showFailure(response.message);
        } else if (response.status === ResponseStatus.InputRequired) {
          this.toastMessageService.showInputRequired(response.message);
        }
      });
    }
  }

  private handleSuccess(response: any) {
    this.error = null;
    this.issueTickedChecked = true;
    this.terminalActionComplete = true;
    this.finalTerminalActionComplete = true;
    this.ticketIssued = response;
    this.finalTicketAmount = response.result.amount;
  }

  private refreshImage() {
    this.interval = setInterval(() => {
      this.terminalsApiService.loadScreenCapture(this.terminal.id);
      this.loadingImage = false;
    }, 5000);
  }

  private setFormValuesWithoutIssueTicket(): { issue: boolean; type: number; feeType: number } {
    let opt = {};
    if (Number(this.rateMethod) === LostTicketFeeType.FixedAmount) {
      opt = { amount: this.form.value['amountInput'] };
    } else if (Number(this.rateMethod) === LostTicketFeeType.Duration) {
      opt = { duration: this.form.value['durationInput'].toString() };
    } else if (Number(this.rateMethod) === LostTicketFeeType.EntryTime) {
      opt = { time: this.form.value['entryTimeInput'].toString() };
    }
    const valueOption = {
      issue: false,
      type: Number(TerminalActionType.LostTicketWithOperatorOverride),
      feeType: Number(this.rateMethod),
    };
    return { ...valueOption, ...opt };
  }

  private getPayDeviceProfile(): PayDeviceProfile | undefined {
    if (this.terminal.payDeviceProfile && this.terminal.payDeviceProfile.lostTicketMode) {
      return this.terminal.payDeviceProfile;
    }
    return undefined;
  }

  private getLostTicketFeeSchedule(): FeeSchedule | undefined {
    if (this.terminal && this.terminal.feeSchedules && this.terminal.feeSchedules.length > 0) {
      return this.terminal.feeSchedules[0];
    }
    return undefined;
  }
}
