<nb-card>
  <nb-card-header (click)="gotoFacilityDetails(facilitySummary.facility.id)" class="facility-card">
    <div class="facility-name">
      <div class="facility-icon">
        <app-facility-status-icon [fullStatus]="facilitySummary.facility.fullStatus"
                                  [openingState]="facilitySummary.facility.openingState"></app-facility-status-icon>
      </div>
      <div class="facility-details">
        <div class="facility-details__name">
          <h6>{{ facilitySummary.facility.name }}</h6>
        </div>
        <div class="terminal-counts">
          <div *ngIf="facilitySummary.terminalCounts.inUse > 0">
            <app-alarm-led [ledConfig]="{ label: false, color: 'green' }"></app-alarm-led>
            {{ facilitySummary.terminalCounts.inUse }}
            {{ 'FACILITIES_SUMMARY.FACILITY.IN_USE' | translate }}
          </div>
          <div *ngIf="facilitySummary.terminalCounts.offline > 0">
            <app-alarm-led [ledConfig]="{ label: false, color: 'red' }"></app-alarm-led>
            {{ facilitySummary.terminalCounts.offline }}
            {{ 'FACILITIES_SUMMARY.FACILITY.OFFLINE' | translate }}
          </div>
          <div *ngIf="facilitySummary.terminalCounts.outOfUse > 0">
            <app-alarm-led [ledConfig]="{ label: false, color: 'grey' }"></app-alarm-led>
            {{ facilitySummary.terminalCounts.outOfUse }}
            {{ 'FACILITIES_SUMMARY.FACILITY.OUT_OF_USE' | translate }}
          </div>
          <div *ngIf="facilitySummary.terminalCounts.total === 0">
            {{ facilitySummary.terminalCounts.total }}
            {{ 'FACILITIES_SUMMARY.FACILITY.TERMINALS' | translate }}
          </div>
        </div>
      </div>
      <div>
        <nb-icon
          (click)="gotoFacilityDetails(facilitySummary.facility.id)"
          class="icon-navigate"
          icon="arrow-ios-forward-outline"
          nbSuffix
          pack="eva"
        ></nb-icon>
      </div>
    </div>
  </nb-card-header>
</nb-card>
