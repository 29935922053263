import { Injectable } from '@angular/core';
import { LogApiService } from '../api/log-api.service';
import { LogPublisher, LogToApi } from './log-publishers.service';

@Injectable()
export class LogPublishersService {
  publishers: LogPublisher[] = [];

  constructor(private logApiService: LogApiService) {
    this.buildPublishers();
  }

  buildPublishers(): void {
    const logPub: LogPublisher = new LogToApi(this.logApiService);
    this.publishers.push(logPub);
  }
}
