import { Component, Input, OnInit } from '@angular/core';
import { FacilitySummary } from 'src/app/@core/domain/interfaces/facility-summary.interface';
import { OccupationRatio } from '../../../../../../@core/domain/interfaces/occupation.interface';

@Component({
  selector: 'app-occupation-ratios',
  templateUrl: './occupation-ratios.component.html',
  styleUrls: ['./occupation-ratios.component.scss'],
})
export class OccupationRatiosComponent implements OnInit {
  @Input()
  facilitySummary!: FacilitySummary;

  transientOccupation!: OccupationRatio;
  subscriberOccupation!: OccupationRatio;
  customOccupation!: OccupationRatio;

  constructor() {}

  ngOnInit(): void {
    this.facilitySummary.occupation.occupationRatios.forEach((o) => {
      switch (o.type.id) {
        case 1:
          this.subscriberOccupation = o;
          break;
        case 2:
          this.transientOccupation = o;
          break;
        case 3:
          this.customOccupation = o;
          break;
      }
    });
  }
}
