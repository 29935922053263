import { Component, Input, OnInit } from '@angular/core';
import { ManualCompletionReason } from '../../../../@core/domain/models/transactions.model';
import { TransactionsService } from '../../services/transactions.service';
import { FacilityService } from '../../services/facility.service';
import { NbDialogRef } from '@nebular/theme';
import { Definition } from '../../../../@core/domain/interfaces/definition.interface';
import { Required } from '../../../../@shared/utils/required-input';
import { LogService } from '../../../../@core';
import dayjs from 'dayjs';
import _ from 'lodash';

type Nullable<T> = T | null;

export enum DateType {
  SPECIFIC,
  RELATIVE,
  UNDEFINED = 999,
}

export interface ParkerType {
  type: Definition;
  selected: boolean;
}

@Component({
  selector: 'app-transactions-complete',
  templateUrl: './transactions-complete.component.html',
  styleUrls: ['./transactions-complete.component.scss'],
})
export class TransactionsCompleteComponent implements OnInit {
  @Input()
  @Required()
  transactionsFilter!: any;

  @Input()
  @Required()
  transactionsSummary!: any;
  confirmationMode = false;
  completionDate!: Nullable<Date>;
  duration: Nullable<number> = null;
  reason!: string;
  selectedExitTerminalId!: string;
  minCompletionDate!: Date;
  maxCompletionDate: Date = new Date();
  completionReasons!: ManualCompletionReason[];
  exitTerminals!: any[];
  dateType: DateType = DateType.UNDEFINED;
  errorMessage!: string;
  selectedParkerTypeNames!: string[];
  infoTitle = 'Selecteer uitrijtijd als specifieke datum of in minuten';
  dateTypeSpecificSelected = false;
  dateTypeRelativeSelected = false;
  protected readonly DateType = DateType;

  constructor(
    private dialogRef: NbDialogRef<TransactionsCompleteComponent>,
    private transactionsService: TransactionsService,
    private facilityService: FacilityService,
    private logger: LogService
  ) {}

  ngOnInit(): void {
    if (this.transactionsSummary) {
      this.minCompletionDate = this.transactionsSummary.last?.arrivalDate || this.transactionsSummary.first.arrivalDate;
    }

    this.transactionsService.getManualCompletionReasons().subscribe((definitions: any[]) => {
      this.completionReasons = <ManualCompletionReason[]>definitions[0].definitions;
    });

    this.facilityService.getExitTerminalsForFacility(this.transactionsFilter.facilityId).subscribe((data) => {
      this.exitTerminals = data;
    });
  }

  completeTransactionsResume() {
    this.confirmationMode = true;
  }

  completeTransactions() {
    if (!this.duration && !this.isCompletionDateValid()) {
      this.errorMessage = 'The completion time must be after the last open transaction time.';
      this.return();
      return;
    }
    this.transactionsService.completeTransactions(this.transactionsFilter, this.getCompletionDate()).subscribe(() => {
      this.logger.info('Completed transactions', []);
      this.dialogRef.close({ completed: true });
    });
  }

  cancel(): void {
    this.dialogRef.close({ completed: false });
  }

  getExitTerminalName(id: string): string {
    // @ts-ignore
    return this.exitTerminals.find((terminal) => {
      return Number(terminal.id) === Number(id);
    }).name;
  }

  onDateTypeChange(dateType: string): void {
    switch (dateType) {
      case 'absolute':
        this.duration = null;
        break;
      case 'relative':
        this.completionDate = null;
        break;
    }
  }

  return(): void {
    this.confirmationMode = false;
  }

  isCompletionDateValid(): boolean {
    return dayjs(this.completionDate).isAfter(this.minCompletionDate);
  }

  setDateTypeSpecific() {
    this.dateType = DateType.SPECIFIC;
    this.dateTypeSpecificSelected = true;
    this.dateTypeRelativeSelected = false;
  }

  setDateTypeRelative() {
    this.dateType = DateType.RELATIVE;
    this.dateTypeSpecificSelected = false;
    this.dateTypeRelativeSelected = true;
  }

  getManualCompletionReason(reason: string): ManualCompletionReason {
    return <ManualCompletionReason>_.find(this.completionReasons, (m) => {
      return m.id === reason ? m : null;
    });
  }

  getParkerTypes() {
    const selectedParkerTypeNames: string[] = [];
    this.transactionsFilter.parkerTypes.forEach((p: ParkerType) => {
      if (p.selected) {
        selectedParkerTypeNames.push(p.type.name);
      }
    });
    return selectedParkerTypeNames.toString();
  }

  private getCompletionDate(): any {
    if (this.dateType === DateType.SPECIFIC) {
      return {
        reasonId: this.reason,
        exitTerminalId: parseInt(this.selectedExitTerminalId, 10),
        duration: null,
        departureDate: this.completionDate,
      };
    }
    if (this.dateType === DateType.RELATIVE) {
      return {
        reasonId: this.reason,
        exitTerminalId: parseInt(this.selectedExitTerminalId, 10),
        duration: this.duration,
        departureDate: null,
      };
    }
  }
}
