import { AfterContentInit, Component, OnDestroy, OnInit, VERSION } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { I18nService } from './i18n';
import { AuthService } from './auth/services/auth.service';
import { environment } from '../environments/environment';
import { IconService } from './@shared/services/icon.service';
import { ParkbaseStorageService } from './@shared/services/parkbase-storage.service';
import { NbThemeService } from '@nebular/theme';
import { LogService } from './@core';
import { CookieService } from 'ngx-cookie-service';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { default as appConfig } from 'src/assets/app.config.json';
import { filter } from 'rxjs';
import dayjs from 'dayjs';
import { DivisionsApiService } from './@core/api/divisions-api.service';
import { DefinitionsApiService } from './@core/api/definitions-api.service';
import { FacilitiesApiService } from './@core/api/facilities-api.service';

declare const gtag: Function;

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterContentInit, OnInit, OnDestroy {
  alive = true;
  title = 'ParkBase 9';
  appVersion: string;
  year = dayjs().year();

  constructor(
    private router: Router,
    private i18nService: I18nService,
    private authService: AuthService,
    private iconService: IconService,
    private definitionsApiService: DefinitionsApiService,
    private facilitiesApiService: FacilitiesApiService,
    private parkbaseStorageService: ParkbaseStorageService,
    private themeService: NbThemeService,
    private logger: LogService,
    private cookieService: CookieService,
    public oidcSecurityService: OidcSecurityService,
    private divisionsApiService: DivisionsApiService,
    private eventService: PublicEventsService
  ) {
    // set version
    this.appVersion = environment.version;

    // set title
    this.title = 'ParkBase 9 - ' + this.appVersion + ' - Angular v' + VERSION.full;

    // configure logging
    this.logger.config(appConfig.logging);
  }

  ngOnInit() {
    // check API health
    this.divisionsApiService.checkHealth().subscribe((response) => {
      this.alive = response.status === 200 && response.statusText === 'OK';
      if (!this.alive) {
        this.router.navigateByUrl('/error').then((t) => {
          console.log('API health did not return OK');
        });
      }
    });

    // Setup Eva and custom icons
    this.iconService.registerIcons();

    // Load default theme or theme selection stored by user
    const selectedTheme = this.parkbaseStorageService.getTheme();
    this.themeService.changeTheme(selectedTheme);

    // Setup translations
    const lang = this.cookieService.get('lang');
    if (lang) {
      this.i18nService.init(lang, environment.supportedLanguages);
    } else {
      this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);
    }

    // GTag
    if (environment.production) {
      this.addGAScript();
    }

    // test error logging
    // this.localError();

    // Setup auth with angular-auth-oidc-client
    this.oidcSecurityService.checkAuth().subscribe(({ isAuthenticated, userData, accessToken, idToken, configId }) => {
      // console.log('app authenticated', isAuthenticated);
      // console.log(`Current access token is '${accessToken}'`);

      if (userData && isAuthenticated) {
        this.authService.setCredentials();

        this.facilitiesApiService.loadFacilities();

        // Store
        this.definitionsApiService.storeDefinitions();

        // throws an error
        this.oidcSecurityService.forceRefreshSession().subscribe((result) => {
          // this.router.navigateByUrl('/account').then(() => {});
        });
      }
    });

    this.eventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.CheckSessionReceived))
      .subscribe((value) => {
        // console.log('CheckSessionReceived with value from app', value)
      });
  }

  ngAfterContentInit(): void {
    if (!environment.production) {
      this.logger.info('GUI started!', []);
    }
  }

  ngOnDestroy(): void {
    this.authService.clearCredentials();
  }

  private addGAScript() {
    const gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.trackingId;
    document.head.prepend(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count  **/
    // gtag('config', environment.trackingId, { send_page_view: false });
  }

  private localError(): void {
    this.logger.error('The app component has thrown an error!', []);
    throw Error('The app component has thrown an error!');
  }
}
