import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShellComponent } from './shell.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../@shared/shared.module';
import { StatusPageModule } from '../pages/status-page/status-page.module';
import { MobileModule } from '../mobile/mobile.module';
import { ThemeModule } from '../@theme/theme.module';

@NgModule({
  declarations: [ShellComponent],
  imports: [
    CommonModule,
    RouterModule,
    ThemeModule,
    SharedModule,
    MobileModule,
    StatusPageModule,
  ],
})
export class ShellModule {}
