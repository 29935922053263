import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { slideInOutAnimation } from '../../@shared/animation/animation';

@Component({
  selector: 'app-notification-mobile',
  templateUrl: './notifications-mobile.component.html',
  styleUrls: ['./notifications-mobile.component.scss'],
  animations: slideInOutAnimation,
})
export class NotificationsMobileComponent {
  showComponent = true;
  currentFacility: any;
  selection: any;

  constructor(protected notificationMobileDialogRef: NbDialogRef<NotificationsMobileComponent>) {}
}
