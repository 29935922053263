import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GenericApiService } from '../../../@core/api/generic/generic-api.service';
import { SubscriptionModelInterface } from '../../../@core/domain/interfaces/subscription-model.interface';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  private $getSubscriptionModels!: Observable<SubscriptionModelInterface[]>;

  constructor(private genericApiService: GenericApiService) {}

  getSubscriptionModels(): Observable<SubscriptionModelInterface[]> {
    return (
      this.$getSubscriptionModels ||
      (this.$getSubscriptionModels = this.genericApiService.getResource('subscription-models').pipe(
        tap((models: SubscriptionModelInterface[]) => {
          models.forEach((model: SubscriptionModelInterface) => {
            model.id = Number(model.id.toString());
            model.selected = false;
          });
        })
      ))
    );
  }

  getSubscriptionModelName(subscriptionModelId: string | number): Observable<string> {
    return this.getSubscriptionModels().pipe(
      map((subscriptionModels) => {
        const subscription = subscriptionModels.find((subscriptionModel) => {
          return subscriptionModel.id === subscriptionModelId;
        });

        return subscription ? subscription.name : 'Subscription name not found for id' + subscriptionModelId;
      })
    );
  }

  addSubscriptionModel(model: SubscriptionModelInterface): Observable<any> {
    return this.genericApiService.postResource('subscription-models', model);
  }
}
