import { TerminalRole } from '../enums/terminal-type.enum';

/**
 * Representation of hardware terminal
 */
export interface Terminal {
  id: number;
  name: string;
  type: TerminalType;
  status: TerminalStatus;
  temporaryStatusUtcEndTime?: string;
  availableActions: TerminalAction[];
  twoWayLane?: TwoWayLane;
  payDeviceProfile?: PayDeviceProfile;
  feeSchedules?: FeeSchedule[];
  selectedForFilter?: boolean;
}

/**
 * Representation of hardware pay device connected to a terminal
 */
export interface PayDeviceProfile {
  id: number;
  name: string;
  lostTicketMode: LostTicketMode;
}

export interface FeeSchedule {
  id: number;
  name: string;
  type: FeeType;
}

export enum FeeType {
  LostTicket = 0,
}

export interface LostTicketMode {
  id: number;
  name: string;
}

export interface TwoWayLane {
  id: number;
  name: string;
  status: TwoWayLaneStatus;
  entryTerminal: TwoWayLaneTerminal;
  exitTerminal: TwoWayLaneTerminal;
  barrierOpenWhenTerminalInactive: boolean;
}

export enum TwoWayLaneStatus {
  DisabledAndOpen,
  DisabledAndClosed,
  Entry,
  Exit,
}

export interface TwoWayLaneTerminal {
  id: number;
  name: string;
  type: TerminalType;
  status: TerminalStatus;
}

/**
 * Action that can be executed on terminal
 */
export interface TerminalAction {
  type: TerminalActionType;
  name: string;
}

/**
 * Current terminal status
 */
export interface TerminalStatus {
  id: TerminalStatus2;
  name: string;
  color: TerminalStatusColor;
}

/**
 * Overview by terminal type
 */
export interface TerminalSummaryPerTerminalType {
  type: TerminalType;
  counts: TerminalCounts;
  terminals: Terminal[];
}

export interface TerminalCounts {
  total: number;
  inUse: number;
  inUseAndOpen: number;
  offline: number;
  outOfUse: number;
  loopAccessMode: number;
  congestionMode: number;
  latched: number;
}

export interface TerminalType {
  id: TerminalRole;
  name: string;
  selected?: boolean;
}

export enum TerminalStatusColor {
  Red,
  Green,
  Grey,
  Blue,
  Pink,
  Brown,
}

export enum TerminalActionType {
  Enable = 1,
  Disable = 2,
  DisableAndOpen = 3,
  DisableAndClose = 4,
  CloseBarrier = 21,
  EnableTerminalWithOpen = 23,
  GrantPassageToAnonymous = 27,
  GrantPassageToAnonymousWithReason = 28,
  GrantPassageToIdentification = 29,
  CongestionMode = 30,
  ScreenCapture = 31,
  LoopAccessMode = 32,
  TwoWayLaneStatus = 33,
  LostTicket = 34,
  LostTicketWithOperatorOverride = 35,
  ShowTerminalEvents = 9999,
}

export enum TerminalStatus2 {
  OffLine,
  InUse,
  Disabled,
  DisabledAndOpen,
  DisabledAndClosed,
  Latched,
  LatchedAndOpen,
  LatchedAndClosed,
  VehicleLoopAccess,
  CongestionMode,
  InUseAndOpen,
  LatchedInUseAndOpen,
}

export enum LostTicketFeeType {
  None = 0,
  FixedAmount = 1,
  Duration = 2,
  EntryTime = 3,
}
