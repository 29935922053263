import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppInjector } from 'src/app/@shared/services/app.injector';
import { ConfigService } from 'src/app/@shared/services/config.service';
import { ParkbaseStorageService } from 'src/app/@shared/services/parkbase-storage.service';
import { LogService } from '../logging/log.service';

/**
 * Simple class to access shared api functions
 */
export abstract class BaseApiService {
  http: HttpClient;
  parkbaseApiBaseUrl = '';
  parkbaseBl9BaseUrl = '';
  idsBaseUrl = '';
  parkbaseStorageService!: ParkbaseStorageService;
  logger!: LogService;

  /**
   * Set httpClient and storage for API services
   * @param configService ConfigService
   * @param http HttpClient
   */
  protected constructor(configService: ConfigService, http: HttpClient) {
    this.http = http;

    const injector = AppInjector.getInjector();
    if (injector) {
      this.parkbaseStorageService = injector.get(ParkbaseStorageService);
      this.logger = injector.get(LogService);
    }

    this.parkbaseApiBaseUrl = configService.configuration.server.resources + '/api/v1.0';
    this.parkbaseBl9BaseUrl = configService.configuration.server.resourcesBL9 + '/v1.0';
    this.idsBaseUrl = configService.configuration.server.auth + '/api/v1.0';
  }

  setStorage(storage: any) {
    this.parkbaseStorageService = storage;
  }

  /**
   * Set headers specific to API
   * @returns HttpHeaders
   */
  setHeaders(): HttpHeaders {
    return new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Ipp-Language', this.parkbaseStorageService.getLanguageForApi());
  }

  /**
   * Catch client and server side errors
   * TODO: handle error messages in front-end using toast
   * @param error the error
   * @returns Observable<never>
   */
  getError(error: any): Observable<any> {
    const message = '';
    if (error.error instanceof ErrorEvent) {
      // handle client-side errors
      //this.logger.error('Client side error in baseApi', error.error);
      //message = `Error: ${error.error.message}`;
    } else {
      // handle server-side errors
      //message = `Error Code: ${error.status}\nMessage: ${error.message}`;
      //this.logger.error('Server side error in baseApi', message, error.error);
    }
    // return throwError(() => new Error('Error!'));
    return of(error);
  }

  // Can use tap operator if U have to transform response in other format else can remove above tap operator
  formatResponse(response: any) {
    const obj = {
      status: 200,
      statusText: 'OK',
      response: response.id,
    };
    return (response['response'] = obj);
  }

  handleError(msg?: string, error?: Error) {
    return (error: HttpErrorResponse): Observable<any> => {
      //this.logger.error('HandleError in baseApi', error, error.error);
      return of(error);
    };
  }
}
