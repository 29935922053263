import { Component, Input, OnInit } from '@angular/core';
import { Terminal } from 'src/app/@core/domain/interfaces/terminal.interface';
import { NbDialogService } from '@nebular/theme';
import { TerminalActionsDialogComponent } from '../terminal-actions-dialog/terminal-actions-dialog.component';
import { LogService } from '../../../../../@core';
import { TerminalActionsMobileComponent } from '../../../../../mobile/terminal-actions-mobile/terminal-actions-mobile.component';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.scss'],
})
export class TerminalComponent implements OnInit {
  @Input()
  terminal: Terminal | undefined;

  @Input()
  useMobile = false;
  terminalTypeIcon = 'terminalType_0';

  constructor(private dialogService: NbDialogService, private logger: LogService) {}

  ngOnInit(): void {
    if (this.terminal) {
      const terminalTypeEnumValue = Object.values(this.terminal.type)[0];
      this.terminalTypeIcon = 'terminalType_' + terminalTypeEnumValue;
    }
  }

  openModal() {
    this.logger.info('Open terminal actions dialog: this.useMobile', this.useMobile);
    if (this.useMobile) {
      this.dialogService.open(TerminalActionsMobileComponent, {
        context: {
          terminalOverview: this.terminal,
        },
      });
    } else {
      this.dialogService.open(TerminalActionsDialogComponent, {
        closeOnBackdropClick: true,
        closeOnEsc: true,
        hasBackdrop: true,
        context: {
          terminal: this.terminal,
        },
      });
    }
  }
}
