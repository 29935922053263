import { Component, OnInit } from '@angular/core';
import { FacilitiesChoice, ParkbaseStorageService } from '../../services/parkbase-storage.service';
import { LogService } from '../../../@core';
import { FacilityName } from '../../../@core/domain/interfaces/facility.interface';
import _ from 'lodash';
import { StatusPageService } from '../../../pages/status-page/services/status-page.service';

@Component({
  selector: 'app-dashboard-facilities-selector',
  templateUrl: './dashboard-facilities-selector.component.html',
  styleUrls: ['./dashboard-facilities-selector.component.scss'],
})
export class DashboardFacilitiesSelectorComponent implements OnInit {
  allFacilitiesSelected: any;
  facilitiesChoiceOptions: any;
  facilityOptions!: FacilityName[];

  constructor(
    private statusPageService: StatusPageService,
    private parkbaseStorageService: ParkbaseStorageService,
    private logger: LogService
  ) {}

  ngOnInit(): void {
    this.facilityOptions = this.parkbaseStorageService.retrieveFacilities();
    const storedOptions = this.parkbaseStorageService.retrieveFacilitiesChoice();
    if (storedOptions && storedOptions.length > 0) {
      this.facilitiesChoiceOptions = storedOptions;
      const result = _(this.facilitiesChoiceOptions)
        .groupBy('selected')
        .map((item: any, itemId) => {
          return {
            selected: itemId,
            count: item.length,
          };
        })
        .value();
      if (result[0].selected === 'false') {
        //
      } else if (result[0].selected === 'true') {
        this.allFacilitiesSelected = this.facilitiesChoiceOptions.length === result[0]['count'];
      } else if (result[1].selected === 'true') {
        this.allFacilitiesSelected = this.facilitiesChoiceOptions.length === result[1]['count'];
      }
    } else {
      this.allFacilitiesSelected = false;
    }
  }

  storeFacilitiesChoice(status: boolean, choice: FacilitiesChoice) {
    this.allFacilitiesSelected = false;
    this.facilitiesChoiceOptions.forEach((c: { facility: { id: number }; selected: boolean }) => {
      if (c.facility.id === choice.facility.id) {
        c.selected = status;
      }
    });
    this.parkbaseStorageService.storeFacilitiesChoice(this.facilitiesChoiceOptions);
    this.statusPageService.triggerRefreshFacilitiesSummary(true);
    this.logger.info('Stored status dashboard facilities choice', []);
  }

  storeAllFacilitiesChoice(event$: any) {
    this.allFacilitiesSelected = !this.allFacilitiesSelected;
    this.facilitiesChoiceOptions.forEach((c: { selected: any }) => {
      c.selected = this.allFacilitiesSelected;
    });
    this.parkbaseStorageService.storeFacilitiesChoice(this.facilitiesChoiceOptions);
    this.statusPageService.triggerRefreshFacilitiesSummary(true);
  }
}
