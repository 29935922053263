<div class='alarm-bell'>
  <nb-icon class='alarm-bell-icon' icon='bell' nbPrefix pack='eva' status='control'></nb-icon>
  <div
    *ngIf='(divisionAlarmsGoingFromHub$|async) || (alarmsGoingFromApi$|async) || (alarmsGoingFromFacilitiesApi$|async)'
    class='alarm-going'>
    <svg
      [attr.fill]='color'
      class='alarm-svg tiny'
      height='16'
      viewBox='0 0 16 16'
      width='16'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        [attr.cx]='size'
        [attr.cy]='size'
        [attr.r]='radius'
        [attr.stroke-width]='2'
      ></circle>
    </svg>
  </div>
</div>
