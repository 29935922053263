import { Injectable } from '@angular/core';
import { NbRoleProvider } from '@nebular/security';
import { Observable, of } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';
import { map } from 'rxjs/operators';

/**
 * Provides user role determination, authentication agnostic
 */
@Injectable()
export class RoleProvider implements NbRoleProvider {
  constructor(private authService: AuthService) {}

  getRole(): Observable<string[]> {
    return this.authService.getUser('RoleProvider getRole').pipe(
      map((userData) => {
        return userData.role;
      })
    );
  }
}
