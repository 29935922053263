import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { slideInOutAnimation } from '../../@shared/animation/animation';
import { FacilityName, FacilityTerminalSummary } from '../../@core/domain/interfaces/facility.interface';
import { FacilitiesApiService } from '../../@core/api/facilities-api.service';
import { TerminalOverview } from '../../@core/domain/interfaces/terminal-response.interface';
import { ParkbaseStorageService } from '../../@shared/services/parkbase-storage.service';
import _ from 'lodash';
import { TerminalActionsMobileComponent } from '../terminal-actions-mobile/terminal-actions-mobile.component';

const { flow, orderBy, map } = _;

@Component({
  selector: 'app-terminal-overview-mobile',
  templateUrl: './terminal-overview-mobile.component.html',
  styleUrls: ['./terminal-overview-mobile.component.scss'],
  animations: slideInOutAnimation,
})
export class TerminalOverviewMobileComponent implements OnInit {
  searching = false;

  loadingTerminals = false;

  showComponent = true;

  facilityTerminalSummary!: FacilityTerminalSummary[];

  facilities: FacilityName[] = [];

  searchTerm!: string;

  constructor(
    private parkbaseStorageService: ParkbaseStorageService,
    private facilitiesApiService: FacilitiesApiService,
    private dialogService: NbDialogService,
    protected terminalOverviewDialogRef: NbDialogRef<TerminalOverviewMobileComponent>
  ) {}

  ngOnInit(): void {
    this.searchTerm = this.parkbaseStorageService.retrieveSearchTerm();
    this.loadTerminals(true);
  }

  onSearchFilter() {
    const fn = flow((data: FacilityTerminalSummary[]) =>
      map(data, ({ terminals, ...f }) => ({
        ...f,
        terminals: this.searchFilterTerminalTypes(terminals),
      }))
    );
    this.facilityTerminalSummary = fn(this.facilityTerminalSummary);
  }

  clearSearchForm() {
    this.searching = false;
    this.searchTerm = '';

    this.onSearchFilter();
  }

  terminalsToShow(f: FacilityTerminalSummary) {
    if (
      this.facilityTerminalSummary.filter(
        (facilityTerminalSummary) => f.facility.id === facilityTerminalSummary.facility.id
      )
    ) {
      if (f.terminals) {
        return _.filter(f.terminals, ['selectedForFilter', true]).length !== 0;
      }
      return true;
    }
    return false;
  }

  openTerminalMobile(terminalOverview: TerminalOverview) {
    this.dialogService
      .open(TerminalActionsMobileComponent, {
        context: {
          terminalOverview: terminalOverview,
        },
      })
      .onClose.subscribe((res) => {
        if (res && res.handleNavigate.changeBack) {
          // do nothing
          // this.terminalOverviewDialogRef.close();
        } else {
          this.terminalOverviewDialogRef.close();
        }
      });
  }

  private loadTerminals(showSpinner: boolean) {
    if (showSpinner) {
      this.loadingTerminals = true;
    }
    this.facilitiesApiService.loadFacilityWithTerminals().subscribe((data: FacilityTerminalSummary[]) => {
      if (showSpinner) {
        this.loadingTerminals = false;
      }

      data.forEach((ft) => {
        this.facilities.push(ft.facility);
        ft.terminals = ft.terminals.map((t) => ({ ...t, selectedForFilter: true }));
      });

      this.facilityTerminalSummary = data;

      this.onSearchFilter();
    });
  }

  private searchFilterTerminalTypes(terminals: TerminalOverview[] | undefined) {
    terminals?.forEach((t) => {
      t.selectedForFilter = this.matches(t, this.parkbaseStorageService.storeAndReturnSearchTerm(this.searchTerm));
    });
    if (this.searchTerm.length !== 0) {
      this.searching = true;
    }
    return terminals;
  }

  private matches(terminal: TerminalOverview, term: string): boolean {
    if (terminal && terminal.name && terminal.id) {
      return (
        terminal.status.name.toString().toLowerCase().includes(term.toLowerCase()) ||
        terminal.name.toString().toLowerCase().includes(term.toLowerCase()) ||
        terminal.type.name.toString().toLowerCase().includes(term.toLowerCase()) ||
        terminal.id.toString().toLowerCase().includes(term.toLowerCase())
      );
    }
    return false;
  }
}
