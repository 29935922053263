import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacilitiesComponent } from './components/facilities/facilities.component';
import { FacilityDetailComponent } from './components/facility-detail/facility-detail.component';
import { StatusPageRoutingModule } from './status-page-routing.module';
import { FacilityComponent } from './components/facilities/facility/facility.component';
import { FacilityExtendedComponent } from './components/facilities/facility-extended/facility-extended.component';
import { FacilityExtendedFullComponent } from './components/facilities/facility-extended-full/facility-extended-full.component';
import { SharedModule } from '../../@shared/shared.module';
import { ThemeModule } from '../../@theme/theme.module';
import { ParkBaseModule } from '../../@parkbase/park-base.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../../app.module';
import { HttpClient } from '@angular/common/http';
import { FacilityEmergencyAlarmsComponent } from './components/facility-emergency-alarms/facility-emergency-alarms.component';
import { OtherOccupationRatioComponent } from './components/facilities/facility-extended-full/other-occupation-ratio/other-occupation-ratio.component';
import { TotalOccupationRatioComponent } from './components/facilities/facility-extended-full/total-occupation-ratio/total-occupation-ratio.component';
import { OccupationRatiosComponent } from './components/facilities/facility-extended/occupation-ratios/occupation-ratios.component';
import { TerminalsComponent } from './components/terminals/terminals.component';
import { ActivityComponent } from './components/activity/activity.component';
import { TotalsComponent } from './components/totals/totals.component';
import { ActivityLineComponent } from './components/activity-line/activity-line.component';
import { TerminalComponent } from './components/terminals/terminal/terminal.component';
import { TerminalActionsDialogComponent } from './components/terminals/terminal-actions-dialog/terminal-actions-dialog.component';
import { TerminalNameComponent } from './components/terminals/terminal-name/terminal-name.component';
import { TerminalTypeComponent } from './components/terminals/terminal-type/terminal-type.component';
import { BypassAlarmsComponent } from './components/bypass-alarms/bypass-alarms.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ConfirmDialogComponent } from './components/totals/confirm-dialog/confirm-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TerminalStatusComponent } from './components/terminals/terminal-actions-dialog/terminal-status/terminal-status.component';
import { TerminalActionComponent } from './components/terminals/terminal-actions-dialog/terminal-action/terminal-action.component';
import { TotalsFormComponent } from './components/totals/totals-form/totals-form.component';
import { TotalsChartComponent } from './components/totals/totals-chart/totals-chart.component';
import { CapacityCountComponent } from './components/capacity-count/capacity-count.component';
import { AUTO_SIZE_INPUT_OPTIONS, AutoSizeInputModule, AutoSizeInputOptions } from 'ngx-autosize-input';

const CUSTOM_AUTO_SIZE_INPUT_OPTIONS: AutoSizeInputOptions = {
  extraWidth: 0,
  includeBorders: true,
  includePadding: true,
  includePlaceholder: true,
  maxWidth: 100,
  minWidth: -1,
  setParentWidth: false,
  usePlaceHolderWhenEmpty: true,
};

@NgModule({
  declarations: [
    FacilitiesComponent,
    FacilityDetailComponent,
    FacilityComponent,
    FacilityExtendedComponent,
    FacilityExtendedFullComponent,
    FacilityEmergencyAlarmsComponent,
    OccupationRatiosComponent,
    TotalOccupationRatioComponent,
    OtherOccupationRatioComponent,
    TerminalsComponent,
    ActivityComponent,
    TotalsComponent,
    BypassAlarmsComponent,
    ActivityLineComponent,
    TerminalComponent,
    TerminalActionsDialogComponent,
    TerminalNameComponent,
    TerminalTypeComponent,
    BypassAlarmsComponent,
    ConfirmDialogComponent,
    TerminalStatusComponent,
    TerminalActionComponent,
    TotalsFormComponent,
    TotalsChartComponent,
    CapacityCountComponent,
  ],
  imports: [
    CommonModule,
    StatusPageRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ThemeModule,
    SharedModule,
    ParkBaseModule,
    TranslateModule.forChild({
      defaultLanguage: 'en-US',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ScrollingModule,
    AutoSizeInputModule,
  ],
  providers: [{ provide: AUTO_SIZE_INPUT_OPTIONS, useValue: CUSTOM_AUTO_SIZE_INPUT_OPTIONS }],
  exports: [TotalsComponent],
})
export class StatusPageModule {
  constructor() {}
}
