<nb-card>
  <nb-card-header>{{confirmationData.headerTitle}}</nb-card-header>
  <nb-card-body>
    <app-info-alert *ngIf='confirmationData.showInfoAlert && confirmationData.infoAlertText'
                    [infoText]='confirmationData.infoAlertText'></app-info-alert>
    <div>
      <h1>{{confirmationData.title}}</h1>
      <p *ngIf='!confirmationData.htmlMessage'>{{confirmationData.message}}</p>
      <p *ngIf='confirmationData.htmlMessage' [innerHTML]='confirmationData.message'></p>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <div class='footer-buttons'>
      <button (click)='cancel()' class='margin-bottom-1' fullWidth ghost id='cancel' nbButton
              shape='semi-round'
              status='danger'>
        {{confirmationData.buttonCancel}}
      </button>
      <button (click)='confirm()' class='margin-bottom-1' fullWidth id='confirm' nbButton shape='semi-round'
              status='success'>
        {{confirmationData.buttonConfirm}}
      </button>
    </div>
  </nb-card-footer>
</nb-card>
