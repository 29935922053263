<h5>{{ 'TERMINAL_SCREEN.TITLE' | translate }}</h5>
<app-terminal-title [hidden]='hideElement' [showHeading]='false'
                    [show]='showTerminalTitle' [terminalOverview]='terminal'>
</app-terminal-title>
<p class='description'>
  {{ 'TERMINAL_SCREEN.DESCRIPTION' | translate }} {{updateInterval}} {{ 'TERMINAL_SCREEN.SECONDS' | translate }}
</p>
<div *ngIf='imageToShow$ | async as image; else placeholder'>
  <img [src]='image' alt='' class='terminal-screen'>
  <nb-progress-bar [value]='counterValue' size='small'></nb-progress-bar>
  <div class='mt-3'>
    <nb-select (selectedChange)='onSelectedChange($event)' [(ngModel)]='updateInterval' [(selected)]='updateInterval'
               class='appearance-outline-secondary nb-transition' fullWidth
               placeholder="{{ 'TERMINAL_SCREEN.UPDATE_INTERVAL' | translate }}" size='large'>
      <nb-select-label>{{ 'TERMINAL_SCREEN.UPDATE_INTERVAL' | translate }} ({{updateInterval}})</nb-select-label>
      <nb-option *ngFor='let number of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]; let i = index'
                 value='{{i+3}}'>{{i + 3}}</nb-option>
    </nb-select>
  </div>
</div>
<ng-template #placeholder>
  <app-info-alert
    [showIcon]='true'
    infoText="{{ 'TERMINAL_SCREEN.ERROR_LOADING' | translate }}">
  </app-info-alert>
  <p>{{ 'TERMINAL_SCREEN.ERROR_EXPLANATION' | translate }}</p>
  <img alt='Terminal screen' class='terminal-screen' src='/assets/theme/camera-placeholder.png'>
</ng-template>
