import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  LAST_COMPLETED_STORAGE_KEY,
  TransactionsCompletionData,
  TransactionsFilter,
  TransactionsSummary,
} from 'src/app/@core/domain/models/transactions.model';
import { Observable } from 'rxjs';
import { FacilityService } from '../../services/facility.service';
import { SubscriptionService } from '../../../subscriptions/services/subscriptions.service';
import { Definition } from '../../../../@core/domain/interfaces/definition.interface';
import { TranslateService } from '@ngx-translate/core';

type Nullable<T> = T | null;

@Component({
  selector: 'app-transactions-completed',
  templateUrl: './transactions-completed.component.html',
  styleUrls: ['./transactions-completed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsCompletedComponent {
  @Input() data!: Nullable<TransactionsCompletionData>;
  @Input() summary!: Nullable<TransactionsSummary>;
  @Output() repeatSearch: EventEmitter<TransactionsFilter> = new EventEmitter();

  parkerTypes: Definition[] = [];
  message: any = null;

  constructor(
    private facilitiesService: FacilityService,
    private subscriptionService: SubscriptionService,
    private translate: TranslateService
  ) {}

  getFacilityName(facilityId: string): Observable<string> {
    return this.facilitiesService.getFacilityName(facilityId);
  }

  getSubscriptionModelName(subscriptionModelId: string): Observable<string> {
    return this.subscriptionService.getSubscriptionModelName(subscriptionModelId);
  }

  replayFilter(): void {
    if (this.data) {
      this.repeatSearch.emit(this.data.filter);
      this.data = null;
      this.message = 'Search is being repeated.';
    }
  }

  remove(): void {
    let confirmation = '';
    this.translate.get('TRANSACTIONS.DISCARD_CONFIRMATION').subscribe((d) => {
      if (d) {
        confirmation = d;
      }
    });
    const confirmed: boolean = window.confirm(confirmation);

    if (confirmed) {
      localStorage.removeItem(LAST_COMPLETED_STORAGE_KEY);
      this.data = null;
    }
  }
}
