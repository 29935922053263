<div class='header-nav-mobile'>
  <div>
    <nb-icon
      (click)='back()'
      icon='arrow-back-outline' nbButton
      nbPrefix
      pack='eva'
      status='control'></nb-icon>
  </div>
  <div><h6>{{title}}</h6></div>
  <div>
    <nb-icon
      (click)='close()'
      icon='close-outline' nbButton
      nbPrefix
      pack='eva'
      status='control'></nb-icon>
  </div>
</div>
