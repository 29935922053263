import { Component, Input, OnInit } from '@angular/core';
import { StatusService } from '../../services/status.service';
import { TerminalOverview } from '../../../@core/domain/interfaces/terminal-response.interface';

/**
 * Status led for terminal icons used in terminal overlays
 */
@Component({
  selector: 'app-status-led',
  templateUrl: './status-led.component.html',
  styleUrls: ['./status-led.component.scss'],
})
export class StatusLedComponent implements OnInit {
  @Input()
  terminalOverview!: TerminalOverview;

  terminalTypeIcon!: any;

  constructor(public statusService: StatusService) {}

  ngOnInit(): void {
    const terminalTypeEnumValue = Object.values(this.terminalOverview.type)[0];
    this.terminalTypeIcon = 'terminalType_' + terminalTypeEnumValue;
  }
}
