<div *ngIf='showComponent' @slideIn @slideOut>
  <app-header-nav-mobile [componentRef]='terminalActionsScreenMobileDialogRef'
                         [handleNavigate]='true' title="{{ 'MOBILE.TERMINAL_SCREEN' | translate }}">
  </app-header-nav-mobile>
  <div class='terminal-actions-mobile-content'>
    <app-cell [showNav]='false' [terminalOverview]='terminalOverview'></app-cell>
    <div class='terminal-actions-mobile-content__forms'>
      <app-terminal-screen [showTerminalTitle]=false [terminalId]='terminalOverview.id'></app-terminal-screen>
    </div>
  </div>
</div>
