import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-general-led',
  templateUrl: './general-led.component.html',
  styleUrls: ['./general-led.component.scss'],
})
export class GeneralLedComponent implements OnInit {
  @Input()
  color = '';
  size = 4;
  radius = 4;

  constructor() {}

  ngOnInit(): void {}
}
