<ng-container *ngIf='notificationResponse | async as data'>
  <div class="accordion-group accordion-{{ accordionOpen ? 'open' : 'close' }}">
    <cdk-virtual-scroll-viewport [itemSize]='notificationGroup.count' [style]='"height: " + itemCount * 96 + "px"'
                                 class='viewport'>
      <ng-container *ngFor='let notification of data.notifications'>
        <app-notification [notification]='notification' class='notification'></app-notification>
      </ng-container>
    </cdk-virtual-scroll-viewport>
  </div>
</ng-container>
