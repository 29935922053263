import { Component, OnInit } from '@angular/core';
import { LogService } from 'src/app/@core';
import { FacilityHubService } from 'src/app/@core/hubs/facility-hub.service';
import { I18nService } from 'src/app/i18n';
import { DivisionHubService } from '../../../@core/hubs/division-hub.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit {
  selectedLanguage!: string;

  constructor(
    private i18nService: I18nService,
    private logger: LogService,
    private divisionHubService: DivisionHubService,
    private facilityHubService: FacilityHubService
  ) {}

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  ngOnInit(): void {
    this.selectedLanguage = this.i18nService.language;
  }

  setLanguage(language: string): void {
    this.i18nService.language = language;
    this.divisionHubService.reconnect();
    this.facilityHubService.reconnect();
    this.logger.info('Language set to', language);
  }
}
