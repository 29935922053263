import { Component, HostListener, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Division } from '../../../@core/domain/interfaces/division.interface';
import { TerminalType } from '../../../@core/domain/interfaces/terminal.interface';
import { Definition } from '../../../@core/domain/interfaces/definition.interface';
import { FacilityTerminalSummary, FacilityWithTerminals } from '../../../@core/domain/interfaces/facility.interface';
import { StatusPageService } from '../../../pages/status-page/services/status-page.service';
import { DivisionsApiService } from '../../../@core/api/divisions-api.service';
import { DefinitionsApiService } from '../../../@core/api/definitions-api.service';
import { NbDialogService, NbLayoutRulerService } from '@nebular/theme';
import { FacilitiesApiService } from '../../../@core/api/facilities-api.service';
import { ParkbaseStorageService } from '../../services/parkbase-storage.service';
import { TerminalOverview } from '../../../@core/domain/interfaces/terminal-response.interface';
import { TerminalActionsMobileComponent } from '../../../mobile/terminal-actions-mobile/terminal-actions-mobile.component';
import { TerminalActionsDialogComponent } from '../../../pages/status-page/components/terminals/terminal-actions-dialog/terminal-actions-dialog.component';
import _ from 'lodash';
import { ActivatedRoute } from '@angular/router';

const { flow, orderBy, map } = _;

@Component({
  selector: 'app-terminal-search',
  templateUrl: './terminal-search.component.html',
  styleUrls: ['./terminal-search.component.scss'],
})
export class TerminalSearchComponent implements OnInit {
  division$: Observable<Division>;
  terminalTypes: Definition[] = [];
  facilityWithTerminals!: FacilityTerminalSummary[];
  filterTerminalTypes: TerminalType[] = [];
  isEveryModelSelected = true;
  searchTerm!: string;
  searching = false;
  loadingTerminals = false;
  private useMobileComponents = false;
  private terminals$ = new BehaviorSubject<TerminalOverview[]>([]);
  private terminalId: number | undefined;

  constructor(
    private route: ActivatedRoute,
    private statusPageService: StatusPageService,
    private divisionsApiService: DivisionsApiService,
    private definitionsApiService: DefinitionsApiService,
    private layoutRulerService: NbLayoutRulerService,
    private dialogService: NbDialogService,
    private facilitiesApiService: FacilitiesApiService,
    private parkbaseStorageService: ParkbaseStorageService
  ) {
    this.division$ = this.divisionsApiService.division$;
    this.divisionsApiService.loadDivisions();
  }

  ngOnInit(): void {
    this.statusPageService.triggerRefreshTerminalSummary(false);
    this.statusPageService.refreshTerminalSummary.subscribe((status) => {
      if (status) {
        this.loadTerminals(false);
      }
    });
    this.definitionsApiService.loadDefinitionsById(0).subscribe((data: Definition[]) => {
      this.terminalTypes = data;
      this.terminalTypes.forEach((t) => {
        const terminalType = { selected: true, id: t.id, name: t.name };
        this.filterTerminalTypes.push(terminalType);
      });
    });
    this.getWindowSize();
    this.toggleSelectAll();
    this.loadTerminals(true);

    this.searchTerm = this.parkbaseStorageService.retrieveSearchTerm();

    // TODO: simple hashing algorithm to create opaque data urls
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.terminalId = Number(id);
    }

    this.terminals$.subscribe((data) => {
      if (data && data.length > 0 && this.terminalId !== undefined) {
        const t = _.filter(data, { id: this.terminalId })[0];
        if (t.id === this.terminalId) {
          // TODO: test from PostMan when published on WestField
          // this.openTerminal(t);
        }
      }
    });
  }

  @HostListener('window:resize', [])
  onResize(): void {
    this.getWindowSize();
  }

  openTerminal(t: TerminalOverview) {
    if (this.useMobileComponents) {
      this.dialogService.open(TerminalActionsMobileComponent, {
        closeOnBackdropClick: true,
        closeOnEsc: true,
        hasBackdrop: true,
        context: {
          terminalOverview: t,
        },
      });
    } else {
      this.dialogService.open(TerminalActionsDialogComponent, {
        closeOnBackdropClick: true,
        closeOnEsc: true,
        hasBackdrop: true,
        context: {
          terminalOverview: t,
        },
      });
    }
  }

  clearSearchForm() {
    this.searching = false;
    this.searchTerm = '';

    this.onSearchFilter();
  }

  toggle($event: boolean, terminalType: TerminalType) {
    terminalType.selected = $event;
    this.filter(this.facilityWithTerminals, terminalType);
  }

  toggleSelectAll() {
    if (this.isEveryModelSelected) {
      this.toggleForFilter(true);
    } else {
      this.toggleForFilter(false);
    }
  }

  onSearchFilter() {
    const fn = flow((data: FacilityWithTerminals[]) =>
      map(data, ({ terminals, ...f }) => ({
        ...f,
        terminals: this.searchFilterTerminalTypes(terminals),
      }))
    );
    this.facilityWithTerminals = fn(this.facilityWithTerminals);
  }

  terminalsToShow(f: FacilityTerminalSummary) {
    if (
      this.facilityWithTerminals.filter(
        (facilityTerminalSummary) => f.facility.id === facilityTerminalSummary.facility.id
      )
    ) {
      if (f.terminals) {
        return _.filter(f.terminals, ['selectedForFilter', true]).length !== 0;
      }
      return true;
    }
    return false;
  }

  private getWindowSize() {
    this.layoutRulerService.getDimensions().subscribe((data) => {
      this.useMobileComponents = data.clientWidth <= 768;
    });
  }

  private toggleForFilter(state: boolean) {
    this.filterTerminalTypes.forEach((f) => {
      f.selected = state;
      this.filter(this.facilityWithTerminals, f);
    });
    this.isEveryModelSelected = state;
  }

  private searchFilterTerminalTypes(terminals: TerminalOverview[] | undefined) {
    terminals?.forEach((t) => {
      t.selectedForFilter = this.matches(t, this.parkbaseStorageService.storeAndReturnSearchTerm(this.searchTerm));
    });
    if (this.searchTerm.length !== 0) {
      this.searching = true;
    }
    return terminals;
  }

  private matches(terminal: TerminalOverview, term: string): boolean {
    if (terminal && terminal.name) {
      return (
        terminal.status.name.toString().toLowerCase().includes(term.toLowerCase()) ||
        terminal.name.toString().toLowerCase().includes(term.toLowerCase()) ||
        terminal.type.name.toString().toLowerCase().includes(term.toLowerCase())
      );
    }
    return false;
  }

  private filter(facilityWithTerminals: FacilityTerminalSummary[], choice: TerminalType) {
    const fn = flow((data: FacilityTerminalSummary[]) =>
      map(data, ({ terminals, ...f }) => ({
        ...f,
        terminals: this.filterSelectedTerminalTypes(terminals, choice),
      }))
    );
    this.facilityWithTerminals = fn(facilityWithTerminals);
  }

  private filterSelectedTerminalTypes(terminals: TerminalOverview[] | undefined, choice: TerminalType) {
    terminals?.forEach((t) => {
      if (choice.id === t.type.id) {
        t.selectedForFilter = choice.selected;
      } else if (t.selectedForFilter) {
        t.selectedForFilter = true;
      }
    });
    if (terminals) {
      if (_.filter(terminals, { selectedForFilter: true })) {
        this.isEveryModelSelected = true;
      }
    }
    // store the filter types choice
    return terminals;
  }

  private loadTerminals(showSpinner: boolean): void {
    if (showSpinner) {
      this.loadingTerminals = true;
    }
    this.facilitiesApiService.loadFacilityWithTerminals().subscribe((data: FacilityTerminalSummary[]) => {
      if (showSpinner) {
        this.loadingTerminals = false;
      }
      const fn = flow((data: FacilityWithTerminals[]) =>
        map(data, ({ terminals, ...f }) => ({
          ...f,
          terminals: orderBy(terminals, ['name', 'status.id', 'type'], ['asc', 'desc', 'desc']),
        }))
      );
      this.facilityWithTerminals = fn(data);
      if (this.searchTerm.length > 0) {
        this.onSearchFilter();
      }
      if (this.searchTerm.length === 0) {
        let terminals: TerminalOverview[] = [];
        this.facilityWithTerminals.forEach((f) => {
          terminals = terminals.concat(f.terminals);
          f.terminals.forEach((t) => {
            t.selectedForFilter = true;
          });
        });
        this.terminals$.next(terminals);
      }
    });
  }
}
